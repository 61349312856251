import React, { FC, useEffect, useState } from 'react';
import {Helmet} from "react-helmet-async";
import OrganizationLogin from "./OrganizationLogin";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux/rootReducer";
import {
    fetchOrganizationFailure,
    fetchOrganizationStart,
    fetchOrganizationSuccess, setOrganizationUserSuccess
} from "../../../../redux/slices/organizationsSlice";
import http from "../../../../services/api";
import {IOrganization} from "../../../../interfaces/organization.interface";
import {base_url, organizations_url, public_organizations_url} from "../../../../constants/contants";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import OrganizationChatContainer from "./OrganizationChatContainer";
import './public-organization.scss';


const OrganizationFeatureContainer: FC = () => {
    const { id } = useParams();
    let user = localStorage.getItem("organizationUser");
    const { organizationMember, loading, error } = useSelector((state: RootState) => state.organizations);
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const [organization, setOrganization] = useState<IOrganization>();

    const fetchOrganization = async () => {

        try {
            let response = await axios.get<IOrganization>(`${base_url}/${public_organizations_url}/${id}`)


            console.log("response",response.status)
            setOrganization(response.data);


        } catch (error) {

            localStorage.removeItem('sessionId')
            localStorage.removeItem('organizationUser')

            navigate(`/`)
        }




    };

    useEffect(() => {
        fetchOrganization();


    }, [dispatch]);


    useEffect(() => {

        if(user){
            let organizationMemberData = JSON.parse(user)
            dispatch(setOrganizationUserSuccess(organizationMemberData));
        }

    }, []);

    return (
        <>
            <Helmet>
                {organization && (
                    <title>{organization?.organizationName} - Brainaque Chat</title>
                )}
                <link href="/oraganization-assets/css/bootstrap.css" id="bootstrap-style" rel="stylesheet"
                      type="text/css"/>
                <link href="/oraganization-assets/css/icons.css" rel="stylesheet" type="text/css"/>
                <link href="/oraganization-assets/css/app.css" id="app-style" rel="stylesheet" type="text/css"/>

            </Helmet>
            {organization? user === null ?
                <OrganizationLogin organization={organization} />:
                <OrganizationChatContainer />
                :''
            }

        </>
    );
};
export default OrganizationFeatureContainer;

import React, { useState, useEffect } from 'react';
import { Modal, Spinner } from "react-rainbow-components";
import { IDocument } from '../../../../interfaces/website.interface';

interface DocumentManageProps {
    document: IDocument;
    showModal: boolean;
    setShowModal: (modalState: boolean) => void;
}

const DocumentViewer: React.FC<DocumentManageProps> = ({ document, showModal, setShowModal }) => {

    return (
        <Modal
            id="modal-view-document"
            title={document?.filename?.split('_').pop() || "Document Viewer"}
            isOpen={showModal}
            size={`large`}
            onRequestClose={() => setShowModal(false)}
        >
            <iframe
                src={document?.signedUrl}
                width="100%"
                height="700px"
                frameBorder="0"
                title="Document"
            />


        </Modal>
    );
};

export default DocumentViewer;

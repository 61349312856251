import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
    createWebsiteStart,
    createWebsiteSuccess,
    createWebsiteFailure,
    updateWebsiteStart,
    updateWebsiteSuccess,
    updateWebsiteFailure,
    fetchWebsiteStart,
    fetchWebsiteSuccess,
    fetchWebsiteFailure,
} from '../../../../redux/slices/websitesSlice';
import { IWebsite } from '../../../../interfaces/website.interface';
import {RootState} from "../../../../redux/rootReducer";
import {websites_url} from "../../../../constants/contants";
import * as Yup from "yup";
import http from "../../../../services/api";
import {toastMessage} from "../../../../utils/util";
import FormInput from "../../../components/form/FormInput";
import {Button, Modal, Option, Picklist} from "react-rainbow-components";
import FormButton from "../../../components/form/bottons/FormButton";
import {updateUserSuccess} from "../../../../redux/slices/userSlice";

interface WebsiteFormProps {
    website: IWebsite; // If provided, it's edit mode; otherwise, it's create mode
    showModal:boolean;
    setShowModal:any;
}



const WebsiteForm: React.FC<WebsiteFormProps> = ({ website,showModal,setShowModal }) => {
    const dispatch = useDispatch();

    const {  user } = useSelector((state: RootState) => state.user);
    const { loading} = useSelector((state: RootState) => state?.websites);

    const validationSchema = Yup.object({
        websiteName: Yup.string()
            .required('Website Name is required'),
        websiteUrl: Yup.string()
            .url('Invalid URL format')
            .required('Website URL is required'),
        websiteAiToken: Yup.number()
            .min(100,'Website Token must be greater than 100 ')
            .max(user?.aiTokens!,`Website Token must be less than ${user?.aiTokens} `)
            .required('Website Token is required'),
        websiteType: Yup.object({
            name: Yup.string().required('Website type is required'),
        }).required('Website type is required'),
    });

    const formik = useFormik({
        initialValues: {
            websiteName: website?.websiteName || '',
            websiteUrl: website?.websiteUrl || '',
            websiteType:   { name: website?.websiteType, label: website?.websiteType } ||   { name: '', label: '' },
            websiteAiToken:  website?.websiteAiToken || 100,
        },
        enableReinitialize: true, // This allows the form to update when website changes
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            if (website) {

                dispatch(updateWebsiteStart());

                try {
                    const response = await http.put<IWebsite>(`${websites_url}/${website._id}`, {
                        websiteName: values.websiteName,
                        websiteUrl: values.websiteUrl,
                        websiteType: values.websiteType.name,
                        websiteAiToken: values.websiteAiToken,
                        app:'chat-bot'
                    });

                    if(user && values.websiteAiToken !== website.websiteAiToken){



                        dispatch(updateUserSuccess({
                            ...user,
                            aiTokens : website.websiteAiToken > values.websiteAiToken ?user.aiTokens+(website.websiteAiToken  - values.websiteAiToken ): user.aiTokens-(values.websiteAiToken -website.websiteAiToken  )
                        }));

                    }

                    dispatch(updateWebsiteSuccess(response.data));


                    toastMessage('Website Updated successfully', 'success')
                } catch (error:any) {
                    dispatch(updateWebsiteFailure(error!));
                }
            } else {
                dispatch(createWebsiteStart());
                try {
                    let response = await http.post<IWebsite>(websites_url, {
                        websiteName: values.websiteName,
                        websiteUrl: values.websiteUrl,
                        websiteType: values.websiteType.name,
                        websiteAiToken: values.websiteAiToken,
                        app: 'chat-bot',
                    });
                    dispatch(createWebsiteSuccess(response.data));
                    if(user){
                        dispatch(updateUserSuccess({
                            ...user,
                            aiTokens:(user.aiTokens-values.websiteAiToken)
                        }));

                    }

                    toastMessage('Website Created successfully', 'success');
                } catch (error: any) {
                    dispatch(createWebsiteFailure('Failed to create website'));
                    console.error('Failed to create website', error);
                    toastMessage('Failed to create website', 'error');
                }
            }

            setShowModal(false)
            formik.resetForm();
        },
    });

    return (
        <Modal
            id="modal-add-website"
            title={website? 'Update Website': 'Create Website'}

            isOpen={showModal}
            onRequestClose={() => {
                setShowModal(false)
                formik.resetForm();
            }}
            footer={
                <div className="d-flex justify-content-end">
                    {loading ? (
                            <Button isLoading label="Button Brand" variant="brand" className="rainbow-m-around_medium" style={{borderRadius: 5}} />
                        ) : (
                            <FormButton type={`submit`} text={website? 'Update': 'Create'} onClick={formik.handleSubmit} />
                        )
                    }
                </div>
            }
        >


            <form onSubmit={formik.handleSubmit}>
                <div className="row gy-3">
                    <div className="col-12">
                        <FormInput
                            label="Website Name"
                            name="websiteName"
                            type="text"
                            placeholder="Enter Website Name"
                            value={formik.values.websiteName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.websiteName}
                        />
                    </div>
                    <div className="col-12">
                        <FormInput
                            label="Website URL"
                            name="websiteUrl"
                            type="text"
                            placeholder="Enter Website URL"
                            value={formik.values.websiteUrl}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.websiteUrl}
                        />
                    </div>
                    <div className="col-12">
                        <FormInput
                            label="Website Tokens"
                            name="websiteAiToken"
                            type="number"
                            placeholder="Enter Website Tokens"
                            value={formik.values.websiteAiToken}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.websiteAiToken}
                        />
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label className="form-label">Website type</label>
                            <div className="form-control-wrap">
                                <Picklist
                                    onChange={(value) => formik.setFieldValue('websiteType', value)}
                                    value={formik.values.websiteType}
                                    className="pick-list-custom-input"
                                    borderRadius="semi-square"
                                    placeholder={`Select website type`}
                                >
                                    <Option name="html" label="HTML Website"/>
                                    <Option name="react" label="React Website"/>
                                    <Option name="wordpress" label="Wordpress Site"/>
                                </Picklist>
                                {formik.errors.websiteType ? (
                                    <div className="fs-7 text-danger mt-1">{formik.errors.websiteType.name}</div>
                                ) : null}
                            </div>
                        </div>

                    </div>
                </div>
            </form>
        </Modal>
    );
};

export default WebsiteForm;

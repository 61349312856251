import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
    createWebsiteStart,
    createWebsiteSuccess,
    createWebsiteFailure,
    updateWebsiteStart,
    updateWebsiteSuccess,
    updateWebsiteFailure,
    fetchWebsiteStart,
    fetchWebsiteSuccess,
    fetchWebsiteFailure, deleteWebsiteStart, deleteWebsiteSuccess, deleteWebsiteFailure,
} from '../../../../redux/slices/websitesSlice';
import { IWebsite } from '../../../../interfaces/website.interface';
import {RootState} from "../../../../redux/rootReducer";
import {
    cancel_subscription_url,
    delete_account_url,
    update_password_url,
    websites_url
} from "../../../../constants/contants";
import * as Yup from "yup";
import http from "../../../../services/api";
import {toastMessage} from "../../../../utils/util";
import FormInput from "../../../components/form/FormInput";
import {Button, Modal, Option, Picklist} from "react-rainbow-components";
import FormButton from "../../../components/form/bottons/FormButton";
import {set} from "lodash";
import {updateUserSuccess} from "../../../../redux/slices/userSlice";
import {IUser} from "../../../../interfaces/user.interface";

interface UserFormProps {
    showModal:boolean;
    setShowModal:any;
}


const CancelSubscription: React.FC<UserFormProps> = ({showModal ,setShowModal }) => {


    const dispatch = useDispatch();

    const {  user } = useSelector((state: RootState) => state.user);

    const handleCancel =  async () => {

        console.log('handleCancel')
        try {

            const response =  await http.post<IUser>(cancel_subscription_url);

            dispatch(updateUserSuccess(response.data));


            toastMessage('Password updated successfully', 'success');
        }catch (e){
            toastMessage('Failed to cancel subscription', 'error');

        }
    };


    return (
        <Modal
            id="modal-delete-website"
            title={"Cancel Subscription"}


            isOpen={showModal}
            onRequestClose={() => {
                setShowModal('')
            }}
            footer={
                <div className="d-flex justify-content-end gap-2">
                    <FormButton type={`button`} text={`Keep Plan`} onClick={() => setShowModal('')}/>

                    <Button label="I Understand, Cancel" size={`small`} variant="destructive" borderRadius="semi-square"
                            className="rainbow-m-around_medium" onClick={() => {
                        handleCancel()
                    }}/>
                </div>
            }
        >
            <p>Are you sure you want to cancel your subscription? Your paid plan is valid till <span
                className="text-dark">23 Mar, 2023</span>.</p>
                <p>Don't forget to review our other <a href="pricing-plans.html">pricing plans</a> before cancelling
                    your current subscription. If you still want to cancel, your account will change to a free plan.</p>
                <div className="card bg-lighter bg-opacity-80 shadow-none mt-2">
                    <div className="card-body">
                        <h5>What you will lose after cancelling you subscription?</h5>
                        <ul className="list-dot gap gy-2 mt-2">
                            <li>All your generated in Copygen will be removed.</li>
                            <li>You will lose access to unlimited content generation.</li>
                            <li>You will get regular updates exclusive only for our premium users.</li>
                            <li>Server response may become slow as we highly prioritized our premium users.</li>
                            <li>There will be no recovery options once you cancel subscription.</li>
                        </ul>
                    </div>
                </div>

        </Modal>
    );
};

export default CancelSubscription;

import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
    updateOrganizationStart,
    updateOrganizationSuccess,
    updateOrganizationFailure,
} from '../../../../redux/slices/organizationsSlice';
import { IOrganization } from '../../../../interfaces/organization.interface';
import { RootState } from '../../../../redux/rootReducer';
import { organizations_url } from '../../../../constants/contants';
import * as Yup from 'yup';
import http from '../../../../services/api';
import { toastMessage } from '../../../../utils/util';
import FormInput from '../../../components/form/FormInput';
import { CheckboxToggle, ColorInput, Modal } from 'react-rainbow-components';
import FormButton from '../../../components/form/bottons/FormButton';

interface OrganizationFormProps {
    organization: IOrganization; // If provided, it's edit mode; otherwise, it's create mode
    showModal: boolean;
    setShowModal: any;
}

const OrganizationChatBotBasicSettings: React.FC<OrganizationFormProps> = ({ organization, showModal, setShowModal }) => {
    const dispatch = useDispatch();

    const { user } = useSelector((state: RootState) => state.user);

    const [primaryColor, setPrimaryColor] = useState<{ hex: string; alpha: number; isValid: boolean }>({
        hex: organization?.bot.primaryColor.hex,
        alpha: organization?.bot.primaryColor.alpha,
        isValid: true,
    });

    const [chatBotPrimaryColor, setChatBotPrimaryColor] = useState<{ hex: string; alpha: number; isValid: boolean }>({
        hex: organization?.bot.chatBot.primaryColor.hex,
        alpha: organization?.bot.chatBot.primaryColor.alpha,
        isValid: true,
    });

    const [chatBotSecondaryColor, setChatBotSecondaryColor] = useState<{ hex: string; alpha: number; isValid: boolean }>({
        hex: organization?.bot.chatBot.secondaryColor.hex,
        alpha: organization?.bot.chatBot.secondaryColor.alpha,
        isValid: true,
    });

    const [chatUserPrimaryColor, setChatUserPrimaryColor] = useState<{ hex: string; alpha: number; isValid: boolean }>({
        hex: organization?.bot.chatUser.primaryColor.hex,
        alpha: organization?.bot.chatUser.primaryColor.alpha,
        isValid: true,
    });

    const [chatUserSecondaryColor, setChatUserSecondaryColor] = useState<{ hex: string; alpha: number; isValid: boolean }>({
        hex: organization?.bot.chatUser.secondaryColor.hex,
        alpha: organization?.bot.chatUser.secondaryColor.alpha,
        isValid: true,
    });

    const validationSchema = Yup.object({
        botName: Yup.string().required('Bot Name is required'),
        botTitle: Yup.string().required('Bot Title is required'),
        botWelcomeMessage: Yup.string().required('Welcome Message is required'),
    });

    const formik = useFormik({
        initialValues: {
            botName: organization?.bot.botName || '',
            botTitle: organization?.bot.botTitle || '',
            botShowForm: organization?.bot.botShowForm || false,
            botIcon: organization?.bot.botIcon || '',
            botLogo: organization?.bot.botLogo || '',
            botWelcomeMessage: organization?.bot.botWelcomeMessage || '',
            primaryColor: organization?.bot.primaryColor || {},
            bot: {
                chatBot: {
                    primaryColor: { hex: organization?.bot.chatBot.primaryColor.hex, alpha: organization?.bot.chatBot.primaryColor.alpha, isValid: true } || {},
                    secondaryColor: { hex: organization?.bot.chatBot.secondaryColor.hex, alpha: organization?.bot.chatBot.secondaryColor.alpha, isValid: true } || {},
                },
                chatUser: {
                    primaryColor: { hex: organization?.bot.chatUser.primaryColor.hex, alpha: organization?.bot.chatUser.primaryColor.alpha, isValid: true } || {},
                    secondaryColor: { hex: organization?.bot.chatUser.secondaryColor.hex, alpha: organization?.bot.chatUser.secondaryColor.alpha, isValid: true } || {},
                },
            },
        },
        enableReinitialize: true, // This allows the form to update when organization changes
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            dispatch(updateOrganizationStart());

            try {
                const response = await http.put<IOrganization>(`${organizations_url}/${organization._id}`, {
                    bot: {
                        botName: values.botName,
                        botTitle: values.botTitle,
                        botShowForm: values.botShowForm,
                        botIcon: values.botIcon,
                        botLogo: values.botLogo,
                        botWelcomeMessage: values.botWelcomeMessage,
                        primaryColor: primaryColor,
                        chatBot: {
                            primaryColor: chatBotPrimaryColor,
                            secondaryColor: chatBotSecondaryColor,
                        },
                        chatUser: {
                            primaryColor: chatUserPrimaryColor,
                            secondaryColor: chatUserSecondaryColor,
                        },
                    },
                });

                dispatch(updateOrganizationSuccess(response.data));
                toastMessage('Organization Updated successfully', 'success');
            } catch (error: any) {
                dispatch(updateOrganizationFailure(error!));
            }
        },
    });

    return (
        <Modal
            id="modal-add-organization"
            title={`Chat bot settings`}
            isOpen={showModal}
            onRequestClose={() => {
                setShowModal(false);
                formik.resetForm();
            }}
            footer={
                <div className="d-flex justify-content-end">
                    <FormButton type={`submit`} text={'Update'} onClick={formik.handleSubmit} />
                </div>
            }
        >
            {organization && (
                <form onSubmit={formik.handleSubmit}>
                    <div className="row gy-3">
                        <div className="col-12">
                            <FormInput
                                label="Bot Name"
                                name="botName"
                                type="text"
                                placeholder="Enter Bot Name"
                                value={formik.values.botName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.errors.botName}
                            />
                        </div>
                        <div className="col-12">
                            <FormInput
                                label="Bot Title"
                                name="botTitle"
                                type="text"
                                placeholder="Enter Bot Title"
                                value={formik.values.botTitle}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.errors.botTitle}
                            />
                        </div>
                        <div className="col-12">
                            <div className="form-group row">
                                <div className={`col`}>
                                    <label className="form-label">Show Form</label>
                                </div>
                                <div className={`col align-content-end justify-content-end text-end`}>
                                    <div className="form-control-wrap">
                                        <CheckboxToggle
                                            id="checkbox-toggle-component-1"
                                            value={formik.values.botShowForm}
                                            onChange={() => formik.setFieldValue('botShowForm', !formik.values.botShowForm)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <FormInput
                                label="Icon URL"
                                name="botIcon"
                                type="text"
                                placeholder="Paste Icon URL"
                                value={formik.values.botIcon}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.errors.botIcon}
                            />
                        </div>
                        <div className="col-12">
                            <FormInput
                                label="Logo URL"
                                name="botLogo"
                                type="text"
                                placeholder="Paste Logo URL"
                                value={formik.values.botLogo}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.errors.botLogo}
                            />
                        </div>
                        <div className="col-12">
                            <FormInput
                                label="Welcome Message"
                                name="botWelcomeMessage"
                                type="text"
                                placeholder="Enter Welcome Message"
                                value={formik.values.botWelcomeMessage}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.errors.botWelcomeMessage}
                            />
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <label className="form-label">Primary Color</label>
                                <ColorInput
                                    variant={`default`}
                                    value={primaryColor}
                                    onChange={(e) => {
                                        setPrimaryColor(e);
                                    }}
                                    borderRadius="semi-rounded"
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <label className="form-label">Chat Bot</label>
                                <ColorInput
                                    variant={`default`}
                                    value={chatBotPrimaryColor}
                                    onChange={(e) => {
                                        setChatBotPrimaryColor(e);
                                    }}
                                    borderRadius="semi-rounded"
                                />
                                <ColorInput
                                    variant={`default`}
                                    className={`mt-3`}
                                    value={chatBotSecondaryColor}
                                    onChange={(e) => {
                                        setChatBotSecondaryColor(e);
                                    }}
                                    borderRadius="semi-rounded"
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <label className="form-label">Chat User</label>
                                <ColorInput
                                    variant={`default`}
                                    value={chatUserPrimaryColor}
                                    onChange={(e) => {
                                        setChatUserPrimaryColor(e);
                                    }}
                                    borderRadius="semi-rounded"
                                />
                                <ColorInput
                                    className={`mt-3`}
                                    variant={`default`}
                                    value={chatUserSecondaryColor}
                                    onChange={(e) => {
                                        setChatUserSecondaryColor(e);
                                    }}
                                    borderRadius="semi-rounded"
                                />
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </Modal>
    );
};

export default OrganizationChatBotBasicSettings;

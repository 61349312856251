//export const base_url = process.env.REACT_APP_API_URL;
import {IPricingPlan} from "../interfaces/pricingPlans.interface";



export const base_url =  process.env.REACT_APP_API_URL;
export const wss_url = process.env.REACT_APP_SOCKET_URL;
export const register_url = `/register`;
export const resend_welcome_email_url = `/resend-welcome-email`;
export const verify_email_url = `/verify-email`;
export const login_url = `/login`;
export const google_login_url = `/google-login`;

export const multiple_Members_url = `/multiple-members`;

export const delete_account_url = `/delete/account`;
export const me_url = `/me`;
export const update_account_url = `/update-account`;
export const update_password_url = `/update-password`;
export const update_country_url = `/update-country`;

export const websites_url = `/websites`;
export const organizations_url = `/organizations`;
export const public_organizations_url = `/public-organizations`;
export const public_member_organizations_url = `/public-member-organization`;
export const organization_chats_url = `/organization-chats/organization`;
export const group_url = `/group`;
export const groups_url = `/groups`;

export const shops_url = `/shops`;
export const shop_display_chat_url = `https://server.brainaque.com/voice`;
export const shop_chats_url = `/shop-chats/shop`;
export const dashboard_url = `/dashboard`;
export const payment_history_url = `/payment-history`;
export const website_chats_url = `/website-chats/website`;
export const pricing_plans_url = `/pricing-plans`;
export const documents_url = `/documents`;
export const create_subscription_url = `/create-subscription`;
export const verify_subscription_url = `/verify-subscription`;
export const cancel_subscription_url = `/cancel-subscription`;

export const promoCode_validate_url = `/promocode/validate`;

export const create_order_url = `/create-order`;
export const verify_order_url = `/verify-order`;

import React from 'react';
import EmptyChat from '../../../assets/no-chat.svg';
import EmptyDoc from '../../../assets/no-documents.svg'; // Ensure this import is correct

interface EmptyMessageProps {
    type: 'chat' | 'doc' | 'website' | 'shop' | 'organization';
    title: string;
    description: string;
    buttonText: string;
    buttonCallback: any;
    noButton?: boolean;
}


const EmptyMessage: React.FC<EmptyMessageProps> = ({ type, title, description, buttonText, buttonCallback, noButton }) => {
    return (
        <div className="col m-5">
            <div className="mb-2 text-center">
                <img
                    src={type === 'chat' ? EmptyChat : EmptyDoc}
                    alt="Empty state illustration"
                    width={150}
                />
            </div>
            <div className="nk-block-head text-center">
                <div className="nk-block-head-content">
                    <h1 className="nk-block-title mb-2">{title}</h1>
                    <p>{description}</p>
                </div>
            </div>
            {!noButton && (
                <div className="text-center mt-4">
                    <button className="btn btn-primary" onClick={buttonCallback}>{buttonText}</button>
                </div>
            )}
        </div>
    );
};

export default EmptyMessage;

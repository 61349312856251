import FormButton from "../../../components/form/bottons/FormButton";
import {Button, Modal} from "react-rainbow-components";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux/rootReducer";
import {
    deleteWebsiteFailure,
    deleteWebsiteStart,
    deleteWebsiteSuccess, updateWebsiteFailure,
    updateWebsiteStart, updateWebsiteSuccess
} from "../../../../redux/slices/websitesSlice";
import http from "../../../../services/api";
import {organizations_url, shops_url} from "../../../../constants/contants";
import {updateUserSuccess} from "../../../../redux/slices/userSlice";
import {toastMessage} from "../../../../utils/util";
import {IShop} from "../../../../interfaces/shop.interface";
import {IMember} from "../../../../interfaces/organization.interface";
import {deleteMemberStart, deleteMemberSuccess} from "../../../../redux/slices/organizationsSlice";

interface Props {
    member?: IMember; // If provided, it's edit mode; otherwise, it's create mode
    manageModal:string;
    setManageModal:any;
}

const MemberManage: React.FC<Props> = ({ member,manageModal,setManageModal }) => {


    const dispatch = useDispatch();

    const {  organization, loading } = useSelector((state: RootState) => state.organizations);

    const handleDelete =  async (organisationId: string) => {
        const memberId = member?._id;
        dispatch(deleteMemberStart());
        try {
            await http.delete(`${organizations_url}/${organisationId}/members/${memberId}`);
            if(memberId){
                dispatch(deleteMemberSuccess({orgId: organisationId, userId: memberId}));
            }
            setManageModal('')

            toastMessage('Member Deleted successfully', 'success')
        } catch (error: any) {
            dispatch(deleteWebsiteFailure('Failed to delete shop'));
        }
    };
    const handleUpdate =  async (shopId: string) => {
        dispatch(updateWebsiteStart());
        try {
            const response = await http.put(`${shops_url}/${shopId}`);
            dispatch(updateWebsiteSuccess(response.data));
            setManageModal('')

            toastMessage('Website Deactivated successfully', 'success')
        } catch (error: any) {
            dispatch(updateWebsiteFailure('Failed to Deactivated shop'));
        }
    };

    return(
        <Modal
            id="modal-delete-shop"
            title={"Delete Members"}

            isOpen={!!manageModal}
            onRequestClose={() => {
                setManageModal('')
            }}
            footer={
                <div className="d-flex justify-content-end gap-2">
                    <FormButton type={`button`} text={`Cancel`}  onClick={()=>setManageModal('')} />
                        <Button isLoading={loading} label="Delete" size={`small`} variant="destructive" borderRadius="semi-square" className="rainbow-m-around_medium" onClick={()=>{
                            handleDelete(organization?._id!)
                        }} />
                </div>
            }
        >

            Are you sure you want to delete this Member?
        </Modal>
    )
}
export default MemberManage;
import React, {FC, useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import FormButton from "../../../components/form/bottons/FormButton";
import FormInput from "../../../components/form/FormInput";
import {ButtonMenu, FileSelector, MenuItem, Modal, Option, Picklist} from "react-rainbow-components";
import * as Yup from "yup";
import {useFormik} from "formik";
import http from "../../../../services/api";
import {IDocument, IShop} from "../../../../interfaces/shop.interface";
import FileUpload from "../../../components/upload/fileUpload";
import dayjs from "dayjs";
import {useSelector} from "react-redux";
import {RootState} from "../../../../redux/rootReducer";
import ShopDocumentManage from "../modals/ShopDocumentManage";
import EmptyMessage from "../../../components/empty/EmptyMessage";
import DocumentViewer from "../../websites/modals/DocumentViewer";


interface FileUploadHandle {
    uploadFiles: () => Promise<void>;
}


const ShopDocuments: React.FC = () => {

    const navigate = useNavigate();

    const [showUploadModal, setShowUploadModal] = useState(false);
    const [showPresentationUploadModal, setShowPresentationUploadModal] = useState(false);
    const [showDocumentModal, setShowDocumentModal] = useState(false);

    const [fileCount, setFileCount] = useState(0);
    const [manageModal, setManageModal] = useState('');

    const [selectedDocument, setSelectedDocument] = useState<IDocument>();

    const fileUploadRef = useRef<FileUploadHandle>(null);
    const { shop, loading, error } = useSelector((state: RootState) => state.shops);

    const handleUpload = () => {
        if (fileUploadRef.current) {
            fileUploadRef.current.uploadFiles();
        }
    };



    return (
        <div className="tab-pane fade show active" id="profile-tab-pane">
            <div
                className="d-flex align-items-center justify-content-between  mt-5 pb-1">
                <h5>Voice Documents</h5>
                <button className="btn btn-primary" onClick={()=>setShowUploadModal(true)} ><em className="icon ni ni-plus"></em><span>Upload</span></button>
            </div>
            <div className="nk-block">

                <div className="card shadow-none">
                    <table className="table table-middle mb-0">
                        <tbody>

                        {shop?.documents.length? shop?.documents.slice()
                                .sort((a, b) => dayjs(b.createdAt).valueOf() - dayjs(a.createdAt).valueOf()).map((document) => (

                                    <tr>
                                        <td className="tb-col">
                                            <div className="caption-text line-clamp-2 cursor-pointer" onClick={() => {
                                                setSelectedDocument(document)
                                                setShowDocumentModal(true)
                                            }}>{document.name}</div>
                                        </td>
                                        <td className="tb-col tb-col-sm">
                                            <div
                                                className="badge text-bg-dark-soft rounded-pill px-2 py-1 fs-6 lh-sm">{document.extension}</div>
                                        </td>
                                        <td className="tb-col">
                                            <div
                                                className={`badge rounded-pill px-2 py-1 fs-6 lh-sm ${document.isProcessed ? 'text-bg-success-soft ' : 'text-bg-warning-soft'}`}>{document.isProcessed ? 'Processed' : 'Processing'}</div>
                                        </td>
                                        <td className="tb-col tb-col-sm">
                                            <div
                                                className={`badge rounded-pill px-2 py-1 fs-6 lh-sm ${document.isProcessed ? 'text-bg-success-soft ' : 'text-bg-warning-soft'}`}>{document.logs && document.logs.length ? document.logs[document.logs.length - 1].percent : ''}</div>
                                        </td>
                                        <td className="tb-col tb-col-md">
                                            <div className="fs-6 text-light d-inline-flex flex-wrap gap gx-2">
                                                <span>{dayjs(document.createdAt).format('MMM DD, YYYY')}</span>
                                                <span>{dayjs(document.createdAt).format('hh:mm A')}</span>
                                            </div>
                                        </td>
                                        <td className="tb-col tb-col-end">
                                            <ButtonMenu
                                                menuAlignment="right"
                                                menuSize="x-small"
                                                buttonVariant="base"
                                                icon={<em className="icon ni ni-more-h"></em>}
                                            >

                                                <MenuItem icon={<em className="icon ni ni-trash"></em>} onClick={() => {

                                                    setSelectedDocument(document)
                                                    setManageModal('delete')
                                                }} label="Delete"/>
                                            </ButtonMenu>
                                        </td>
                                    </tr>

                                )) :

                            <EmptyMessage
                                type="doc"
                                title="No documents available."
                                description="You have no documents stored. Upload documents to start organizing your files."
                                buttonText="Upload Document"
                                buttonCallback={()=>{
                                    setShowUploadModal(true)
                                }}
                            />
                        }


                        </tbody>
                    </table>

                </div>

            </div>
            <div
                className="d-flex align-items-center justify-content-between  mt-5 pb-1">
                <h5>Presentation Documents</h5>
                <button className="btn btn-primary" onClick={()=>setShowPresentationUploadModal(true)} ><em className="icon ni ni-plus"></em><span>Upload</span></button>
            </div>
            <div className="nk-block">

                <div className="card shadow-none">
                    <table className="table table-middle mb-0">
                        <tbody>

                        {shop?.presentationDocuments? shop?.presentationDocuments.slice()
                                .sort((a, b) => dayjs(b.createdAt).valueOf() - dayjs(a.createdAt).valueOf()).map((document) => (

                                    <tr>
                                        <td className="tb-col">
                                            <div className="caption-text line-clamp-2 cursor-pointer" onClick={() => {
                                                setSelectedDocument(document)
                                                setShowDocumentModal(true)
                                            }}>{document.name}</div>
                                        </td>
                                        <td className="tb-col tb-col-sm">
                                            <div
                                                className="badge text-bg-dark-soft rounded-pill px-2 py-1 fs-6 lh-sm">{document.extension}</div>
                                        </td>
                                        <td className="tb-col">
                                            <div
                                                className={`badge rounded-pill px-2 py-1 fs-6 lh-sm ${document.isProcessed ? 'text-bg-success-soft ' : 'text-bg-warning-soft'}`}>{document.isProcessed ? 'Processed' : 'Processing'}</div>
                                        </td>
                                        <td className="tb-col tb-col-sm">
                                            <div
                                                className={`badge rounded-pill px-2 py-1 fs-6 lh-sm ${document.isProcessed ? 'text-bg-success-soft ' : 'text-bg-warning-soft'}`}>{document.logs && document.logs.length ? document.logs[document.logs.length - 1].percent : ''}</div>
                                        </td>
                                        <td className="tb-col tb-col-md">
                                            <div className="fs-6 text-light d-inline-flex flex-wrap gap gx-2">
                                                <span>{dayjs(document.createdAt).format('MMM DD, YYYY')}</span>
                                                <span>{dayjs(document.createdAt).format('hh:mm A')}</span>
                                            </div>
                                        </td>
                                        <td className="tb-col tb-col-end">
                                            <ButtonMenu
                                                menuAlignment="right"
                                                menuSize="x-small"
                                                buttonVariant="base"
                                                icon={<em className="icon ni ni-more-h"></em>}
                                            >

                                                <MenuItem icon={<em className="icon ni ni-trash"></em>} onClick={() => {

                                                    setSelectedDocument(document)
                                                    setManageModal('delete')
                                                }} label="Delete"/>
                                            </ButtonMenu>
                                        </td>
                                    </tr>

                                )) :

                            <EmptyMessage
                                type="doc"
                                title="No documents available."
                                description="You have no documents stored. Upload documents to start organizing your files."
                                buttonText="Upload Document"
                                buttonCallback={()=>{
                                    setShowPresentationUploadModal(true)
                                }}
                            />
                        }


                        </tbody>
                    </table>

                </div>

            </div>
            <Modal
                id="modal-upload-documents"
                title="Upload Documents"

                isOpen={showUploadModal||showPresentationUploadModal}
                onRequestClose={() => {
                    setShowPresentationUploadModal(false)
                    setShowUploadModal(false)
                }}
                footer={
                    <div className="d-flex justify-content-end">

                        <FormButton type={`button`} text={`Upload`} disabled={fileCount === 0} onClick={handleUpload}/>

                    </div>
                }
            >
                <form>
                    <div className="row gy-3">
                        <div className="col-12">

                                <FileUpload uploadType={showUploadModal? `shop-upload`: `shop-presentation-upload`} ref={fileUploadRef} _id={shop?._id!}
                                            onFileCountChange={(count) => setFileCount(count)}/>


                        </div>

                    </div>
                </form>
            </Modal>
            <ShopDocumentManage document={selectedDocument!}  setManageModal={(e:string)=> setManageModal(e)} manageModal={manageModal} />
            <DocumentViewer document={selectedDocument!} setShowModal={(e: boolean) => setShowDocumentModal(e)}
                            showModal={showDocumentModal}/>
        </div>
    );
};
export default ShopDocuments;

import React, { FC, useEffect, useState } from 'react';
import { Badge, Breadcrumb, Breadcrumbs, Chart, Dataset } from "react-rainbow-components";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/rootReducer";
import {IWebsiteChats} from "../../../../interfaces/websitechat.interface";
import EmptyMessage from "../../../components/empty/EmptyMessage";
import dayjs from "dayjs";

const WebsiteDashboard: FC = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const { website, websites, loading, error } = useSelector((state: RootState) => state.websites);
    const { websiteChats } = useSelector((state: RootState) => state.websiteChats);

    console.log('id', id)

    console.log('website', website)
    console.log('websites', websites)
    console.log('websiteChats', websiteChats)
    console.log('websites find', websites?.find((w) => w._id === website?._id))

    // Filter chats based on the createdAt date
    const filteredChats = websiteChats.filter((chat: IWebsiteChats) => new Date(chat.createdAt).getFullYear() === new Date().getFullYear());

    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const values = Array(12).fill(0);

    filteredChats.forEach((chat: IWebsiteChats) => {
        const month = new Date(chat.createdAt).getMonth();
        values[month] += chat.chats.length;
    });
    const getPercentageIncrease = (current: number, previous: number): number => {
        if (previous === 0) {
            return current > 0 ? 100 : 0; // Avoid division by zero
        }
        return parseFloat(((current - previous) / previous * 100).toFixed(2));
    };

    const calculateMonthlyChatIncrease = () => {
        const currentMonth = dayjs().startOf('month');
        const previousMonth = dayjs().subtract(1, 'month').startOf('month');

        // Get chat counts for the current month
        const currentMonthChats = websiteChats.filter((chat: any) =>
            dayjs(chat.createdAt).isSame(currentMonth, 'month')
        ).length;

        // Get chat counts for the previous month
        const previousMonthChats = websiteChats.filter((chat: any) =>
            dayjs(chat.createdAt).isSame(previousMonth, 'month')
        ).length;

        // Calculate percentage increase or decrease
        return getPercentageIncrease(currentMonthChats, previousMonthChats);
    };

    const BarChartExample = () => (
        <div className="rainbow-p-vertical_large">
            <div className="rainbow-align-content_center rainbow-m-vertical_large rainbow-m_auto">
                {filteredChats.length ? (
                    <Chart labels={labels} type="bar">
                        <Dataset key="Chats" title="Chats" values={values} backgroundColor="#01b6f5" />
                    </Chart>
                ):(
                    <EmptyMessage
                    type="website"
                    title="No Website Data"
                    description="No website data is available. Add a website to start managing your favorites here."
                    buttonText="Add Website"
                    buttonCallback={()=> ''}
                noButton={true}
            />
                )}

            </div>
        </div>
    );

    // Aggregate chat data by country
    const chatsByCountry: Record<string, number> = filteredChats.reduce((acc:any, chat) => {
        const country = chat.countryName || 'Unknown';
        if (!acc[country]) {
            acc[country] = 0;
        }
        acc[country] += 1;
        return acc;
    }, {});

    // Prepare pie chart data
    const pieChartData = Object.keys(chatsByCountry).map(country => ({
        label: country,
        value: chatsByCountry[country],
        color: '#' + Math.floor(Math.random() * 16777215).toString(16)
    }));

    const PieChartExample: FC = () => (
        <div className="rainbow-p-vertical_xx-large rainbow-p-horizontal_medium">
            <div className="rainbow-align-content_center rainbow-m-vertical_large rainbow-m_auto">
                {pieChartData?.length ? (
                    <Chart labels={pieChartData.map(data => data.label)} type="pie" legendPosition="right">
                        <Dataset
                            title="Chats by Country"
                            values={pieChartData.map(data => data.value)}
                            backgroundColor={pieChartData.map(data => data.color)}
                        />
                    </Chart>
                ):(
                    <EmptyMessage
                        type="website"
                        title="No Website Data"
                        description="No website data is available. Add a website to start managing your favorites here."
                        buttonText="Add Website"
                        buttonCallback={()=> ''}
                        noButton={true}
                    />
                )}
            </div>
        </div>
    );
    return (
        <div className="tab-pane fade show active" id="profile-tab-pane">
            <div className="d-flex align-items-center justify-content-between mt-2 pb-2">
                <h5>Overview</h5>
                {/*<button type="button" className="btn btn-xs btn-icon btn-primary rounded-pill" data-bs-dismiss="alert">*/}
                {/*    <em className="icon ni ni-calendar"></em>*/}
                {/*</button>*/}
            </div>
            <div className="nk-block">
                <div className="row g-gs">
                    <div className="col-sm-6 col-xxl-3">
                        <div className="card card-full bg-purple bg-opacity-10 border-0">
                            <div className="card-body">
                                <div className="d-flex align-items-center justify-content-between mb-1">
                                    <div className="fs-6 text-light mb-0">Tokens Available</div>
                                    {/*<span onClick={() => navigate(`/website/${website?._id}/usage`)} className="link link-primary">See History</span>*/}
                                </div>
                                <h5 className="fs-1">{website?.websiteAiToken} <small className="fs-3">Tokens</small></h5>
                                <div className="fs-7 text-light mt-1">
                                    <span className="text-dark">{filteredChats.reduce((total, chat) => total + (chat.tokensUsed || 0), 0)}</span> / {website?.websiteAiToken} tokens used
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xxl-3">
                        <div className="card card-full bg-blue bg-opacity-10 border-0">
                            <div className="card-body">
                                <div className="d-flex align-items-center justify-content-between mb-1">
                                    <div className="fs-6 text-light mb-0">Documents</div>
                                    {/*<span onClick={() => navigate(`/website/${website?._id}/documents`)} className="link link-primary">See All</span>*/}
                                </div>
                                <h5 className="fs-1">{website?.documents.length} <small className="fs-3">Documents</small></h5>
                                <div className="fs-7 text-light mt-1">
                                    <span className="text-dark">{website?.documents.filter(doc => doc.isProcessed).length}</span>/{website?.documents.length} documents processed
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xxl-3">
                        <div className="card card-full bg-indigo bg-opacity-10 border-0">
                            <div className="card-body">
                                <div className="d-flex align-items-center justify-content-between mb-1">
                                    <div className="fs-6 text-light mb-0">Conversations</div>
                                    {/*<a href="document-saved.html" className="link link-indigo">See All</a>*/}
                                </div>
                                <h5 className="fs-1">{filteredChats.length} <small
                                    className="fs-3">Conversations</small></h5>
                                <div className="fs-7 text-light mt-1"><span
                                    className="text-dark"></span>{calculateMonthlyChatIncrease()}%
                                    more chats this month
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xxl-3">
                        <div className="card card-full bg-cyan bg-opacity-10 border-0">
                            <div className="card-body">
                                <div className="d-flex align-items-center justify-content-between mb-1">
                                    <div className="fs-6 text-light mb-0">Tokens Used</div>
                                    {/*<a href="templates.html" className="link link-cyan">All Tools</a>*/}
                                </div>
                                <h5 className="fs-1">
                                    {filteredChats.reduce((total, chat) => total + (chat.tokensUsed || 0), 0)} <small className="fs-3">Tokens</small>
                                </h5>
                                <div className="fs-7 text-light mt-1">
                                    <span className="text-dark">{website?.websiteAiToken && website?.websiteAiToken - filteredChats.reduce((total, chat) => total + (chat.tokensUsed || 0), 0)}</span> tokens remaining
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-center justify-content-between mt-4 pb-1">
                <h5>Statistics</h5>
            </div>
            <div className="nk-block">
                <div className="row g-gs">
                    <div className="col-sm-6 col-xxl-6">
                        <div className="card card-full bg-purple bg-opacity-10 border-0">
                            <div className="card-body">
                                <BarChartExample />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xxl-6">
                        <div className="card card-full bg-blue bg-opacity-10 border-0">
                            <div className="card-body">
                                <PieChartExample />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default WebsiteDashboard;

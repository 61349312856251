import React, {FC, useEffect, useRef, useState} from 'react';
import {
    IPublicOrganization
} from "../../../../interfaces/organization.interface";
import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime';
import EmptyMessage from "../../../components/empty/EmptyMessage";



interface Props {
    organization: IPublicOrganization;
    setActiveGroup: any;
}


const OrganizationChat: FC<Props> = ({organization, setActiveGroup}) => {

    dayjs.extend(relativeTime);

    const [searchQuery, setSearchQuery] = useState<string>("");

    const chatListRef = useRef<HTMLDivElement>(null); // Use HTMLDivElement type for ref
    const activeSessionId = localStorage.getItem('sessionId');
    const prevGroupId = localStorage.getItem('prevGroupId');

    console.log('activeSessionId', activeSessionId)

    useEffect(() => {
        if(activeSessionId && organization && organization?.messages?.length){
            const message = organization?.messages.find((m)=> m?.sessionId?.toString() === activeSessionId?.toString());
            setActiveGroup(organization?.memberGroups.find((memberGroup)=>memberGroup._id === message?.groupId), activeSessionId)
        }
    }, [activeSessionId]);

    useEffect(() => {
        if (activeSessionId && chatListRef.current) { // Check if chatListRef.current is not null
            const activeMessageElement = chatListRef.current.querySelector(`li[data-sessionid="${activeSessionId}"]`);
            if (activeMessageElement) {
                // Scroll to the active message element
                activeMessageElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }
        }
    }, [activeSessionId, organization?.messages]); // Rerun effect when sessionId or messages change


    return (
        <div className="tab-pane fade show active" id="pills-chat" role="tabpanel" aria-labelledby="pills-chat-tab" style={{height: '100vh'}}>
            <div>
                <div className="pt-4 mx-2">
                    <h4 className="mb-4">Chats</h4>
                    <div className="search-box chat-search-box px-3 bg-light"  style={{borderRadius: 10, backgroundColor: 'red'}}>
                        <div className="input-group mb-3">
                                        <span className="input-group-text text-muted bg-light pe-1 ps-3"
                                              id="basic-addon1">
                                            <i className="ri-search-line search-icon font-size-18"></i>
                                        </span>
                            <input type="text" onChange={(e)=> setSearchQuery(e.target.value)} value={searchQuery} className="form-control bg-light" placeholder="Search messages"
                                   aria-label="Search messages" aria-describedby="basic-addon1"/>
                        </div>
                    </div>
                </div>

                <div >
                    <h5 className="mb-3 px-3 font-size-16">Recent</h5>

                    <div className="chat-message-list overflow-auto px-2" style={{height: '70vh'}} ref={chatListRef}>
                        <ul className="list-unstyled chat-list chat-user-list">
                            {organization && organization?.messages && organization?.messages?.length ? (
                                organization?.messages
                                    .filter((m) => (m.groupName ? m.groupName.toLowerCase().includes(searchQuery.toLowerCase()) : true))
                                    .sort((a, b) =>
                                        dayjs(b.chats[b.chats.length - 1]?.createdAt).valueOf() -
                                        dayjs(a.chats[a.chats.length - 1]?.createdAt).valueOf()
                                    )
                                    .map((message) => (
                                        <li
                                            key={message.sessionId} // Ensure to add a key
                                            data-sessionid={message.sessionId} // Set the sessionId as a data attribute
                                            onClick={() => {
                                                setActiveGroup(
                                                    organization.memberGroups.find((memberGroup) => memberGroup._id === message.groupId),
                                                    message.sessionId
                                                );
                                                localStorage.setItem('sessionId', message.sessionId);
                                            }}
                                            className={`${activeSessionId === message.sessionId ? 'active' : ''}`}
                                        >
                                            <a href="#">
                                                <div className="d-flex gap-2">
                                                    <div className="flex-grow-1 overflow-hidden">
                                                        <h5 className="text-truncate font-size-15 mb-1">{message?.groupName}</h5>
                                                        <p className="chat-user-message text-truncate mb-0 ellipsis">
                                                            {message?.chats?.length && message?.chats[message?.chats?.length - 1]?.message}
                                                        </p>
                                                    </div>
                                                    <div className="font-size-11">
                                                        {message?.chats?.length && dayjs(message.chats[message?.chats?.length - 1]?.createdAt)?.fromNow()}
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                    ))
                            ) : (
                                <div className='h-100 mt-3 w-100 d-flex justify-content-center align-items-center'>
                                    <div className='d-flex flex-column align-items-center'>
                                        <EmptyMessage
                                            type="chat"
                                            title="No Chats Available!"
                                            description="Select a group or chat to begin your conversation with our AI assistant."
                                            buttonText="Add Shop"
                                            buttonCallback={() => {
                                            }}
                                            noButton={true}
                                        />
                                    </div>
                                </div>
                            )}
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    );
};
export default OrganizationChat;

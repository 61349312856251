import React, { FC, useEffect, useState } from 'react';
import {Helmet} from "react-helmet-async";
import {IOrganization, IPublicOrganization} from "../../../../interfaces/organization.interface";
import {Avatar, AvatarMenu, MenuDivider, MenuItem} from "react-rainbow-components";
import axios from "axios";
import {base_url, public_member_organizations_url, public_organizations_url} from "../../../../constants/contants";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {RootState} from "../../../../redux/rootReducer";
import EmptyMessage from "../../../components/empty/EmptyMessage";



interface Props {
    organization: IPublicOrganization;
}


const OrganizationMembers: FC<Props> = ({organization}) => {

    const [searchQuery, setSearchQuery] = useState<string>("");

    return (
        <div className="tab-pane fade show active" id="pills-groups" role="tabpanel" aria-labelledby="pills-groups-tab">
            <div>
                <div className="pt-4 mx-2">
                    <h4 className="mb-4">Members</h4>
                    <div className="search-box chat-search-box px-3 bg-light"
                         style={{borderRadius: 10, backgroundColor: 'red'}}>
                        <div className="input-group mb-3">
                                        <span className="input-group-text text-muted bg-light pe-1 ps-3"
                                              id="basic-addon1">
                                            <i className="ri-search-line search-icon font-size-18"></i>
                                        </span>
                            <input type="text" onChange={(e) => setSearchQuery(e.target.value)} value={searchQuery}
                                   className="form-control bg-light" placeholder="Search Members Name"
                                   aria-label="Search Members..." aria-describedby="basic-addon1"/>
                        </div>
                    </div>
                </div>

                <div className="chat-message-list chat-group-list ms-3">
                    {organization.memberGroups?.length ? (
                        organization.memberGroups
                                .filter((g) =>
                                    g.groupMembers?.some((m) =>
                                        m?.firstname?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
                                        m?.lastname?.toLowerCase().includes(searchQuery?.toLowerCase())
                                    )
                                )
                                .map((memberGroup) => (
                                    <div key={memberGroup._id}>
                                        <div className="fw-bold text-primary text-capitalize">
                                            {memberGroup.groupName}
                                        </div>

                                        <ul className="list-unstyled contact-list">
                                            {memberGroup.groupMembers
                                                .filter((m) =>
                                                    m?.firstname?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
                                                    m?.lastname?.toLowerCase().includes(searchQuery?.toLowerCase())
                                                )
                                                .map((member) => (
                                                    <li key={member._id} className='m-0'>
                                                        <div className="d-flex align-items-center">
                                                            <div className="flex-grow-1">
                                                                <h5 className="font-size-14 m-0">{member.firstname} {member.lastname}</h5>
                                                                <p className="chat-user-message text-truncate mb-0">{member.email}</p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                ))
                    ): (
                        <div className='d-flex justify-content-center align-items-center'>
                            <div className='d-flex flex-column align-items-center'>
                                <EmptyMessage
                                    type="chat"
                                    title="No Members Available!"
                                    description="It seems you currently don't have any members in this group. Please add members to get started."
                                    buttonText="Add Members"
                                    buttonCallback={() => {
                                    }}
                                    noButton={true}
                                />
                            </div>
                        </div>
                    )}
                </div>

            </div>
        </div>
    );
};
export default OrganizationMembers;

import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../../redux/rootReducer";
import React from "react";
import {
    IGroup,
    IMember, IPublicMemberGroup,
} from "../../../../../interfaces/organization.interface";
import {
    Button,
    Modal,
    Option,
    Picklist
} from "react-rainbow-components";
import http from "../../../../../services/api";
import {
    base_url,
    organizations_url,
    public_member_organizations_url,
    public_organizations_url
} from "../../../../../constants/contants";
import * as Yup from "yup";
import {useFormik} from "formik";
import FormButton from "../../../../components/form/bottons/FormButton";
import FormInput from "../../../../components/form/FormInput";
import {
    updatePublicOrganizationMemberFailure,
    updatePublicOrganizationMemberStart,
    updatePublicOrganizationMemberSuccess
} from "../../../../../redux/slices/publicOrganizationSlice";
import axios from "axios";

interface MemberFormProps {
    member?: IMember; // If provided, it's edit mode; otherwise, it's create mode
    showModal: boolean;
    isPublicMember?: boolean;
    setShowModal: any;
    group?: IGroup;
    setActiveGroup?: any;
}

const PublicMembersForm: React.FC<MemberFormProps> = ({ member, isPublicMember=false, showModal, setShowModal, group , setActiveGroup}) => {
    const dispatch = useDispatch();
    const { publicOrganization, loading } = useSelector((state: RootState) => state.publicOrganization); // Get the current organization
    const {organizationMember} = useSelector((state: RootState) => state.organizations);

    const validationSchema = Yup.object({
        firstname: Yup.string().required('First Name is required'),
        lastname: Yup.string().required('Last Name is required'),
        email: Yup.string().email('Invalid email address').required('Email is required'),
        password: Yup.string().min(6, 'Password must be at least 6 characters'), // Validation for the password
        role: Yup.object({
            name: Yup.string().required('Role is required'), // example of a field inside the object
        }).required('Role is required'),
    });


    const formik = useFormik({
        initialValues: {
            firstname: member?.firstname || '',
            lastname: member?.lastname || '',
            password: '',
            email: member?.email || '',
            role: member?.role
                ? { name: `${member?.role}`, label: `${member?.role}` }
                : { name: '', label: '' },
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            let createMemberResponse: any = {};

            if (member) {
                // Update existing member
                dispatch(updatePublicOrganizationMemberStart());
                try {
                    if (publicOrganization) {
                        createMemberResponse = await http.put<IMember>(
                            isPublicMember
                                ? `${public_member_organizations_url}/${publicOrganization._id}/member`
                                : `${organizations_url}/${publicOrganization._id}/members/${member._id}`,
                            {
                                firstname: values.firstname,
                                lastname: values.lastname,
                                email: values.email,
                                password: values.password,
                                role: values.role?.name,
                            }
                        );

                    }
                } catch (error: any) {
                    dispatch(updatePublicOrganizationMemberFailure(error.message));
                    console.error('Failed to update member:', error);
                }
            } else {
                try {
                    if (publicOrganization) {
                        createMemberResponse = await axios.post<IMember>(
                            `${base_url}${public_organizations_url}/${publicOrganization._id}/members`,
                            {
                                firstname: values.firstname,
                                lastname: values.lastname,
                                email: values.email,
                                password: values.password,
                                role: values.role?.name,
                            },
                            {
                                headers: {
                                    Authorization: `Bearer ${organizationMember?.member.token}`,
                                },
                            }
                        );

                        console.log('createMemberResponse', createMemberResponse);

                        if (createMemberResponse?.data?.member && setActiveGroup) {
                            const existingGroup: IPublicMemberGroup | null = publicOrganization?.memberGroups?.find((g)=> g?._id === group?._id) || null

                            if (setActiveGroup && existingGroup) {
                                // Merge existingGroup with newData
                                const newData = {
                                    ...existingGroup, // Include all fields from the existing group
                                    groupMembers: [...existingGroup.groupMembers, createMemberResponse?.data?.member],
                                };

                                setActiveGroup(newData);
                            }
                        }

                    }
                } catch (error: any) {
                    dispatch(updatePublicOrganizationMemberFailure(error.response.data.message));
                    console.error('Failed to create member:', error);
                }
            }

            // Update Group Logic after member creation
            if (!member && publicOrganization && group) {
                try {
                    const newMemberDetails = group?.groupMembers?.map((m) => m?._id) || [];
                    newMemberDetails.push(createMemberResponse.data.member._id);  // Add new member's ID

                    const response: any = await http.put<IGroup>(
                        `${organizations_url}/${publicOrganization._id}/groups/${group?._id}`,
                        {
                            groupName: group?.groupName,
                            groupMembers: newMemberDetails,
                            groupDocuments: group?.groupDocuments,
                            loginRequired: group?.loginRequired,
                            showDocuments: group?.showDocuments,
                            showMembers: group?.showMembers,
                        }
                    );

                    {group && (
                        dispatch(updatePublicOrganizationMemberSuccess({
                            groupId: group?._id,
                            group: response.data.group,
                        }))
                    )}
                } catch (error: any) {
                    dispatch(updatePublicOrganizationMemberFailure(error.message));
                    console.error('Failed to update group:', error);
                }
            }

            // Close modal and reset form
            setShowModal(false);
            formik.resetForm();
        },
    });
    return (
        <Modal
            id="modal-add-member"
            title={member ? 'Update Member' : 'Create Member'}
            isOpen={showModal}
            onRequestClose={() => {
                setShowModal(false);
                formik.resetForm();
            }}
            footer={
                <div className="d-flex justify-content-end">
                    { loading ? (
                        <Button isLoading label="Button Brand" variant="brand" className="rainbow-m-around_medium"  style={{borderRadius: 5}} />
                    ) : (
                        <FormButton type="submit" text={member ? 'Update' : 'Create'} onClick={formik.handleSubmit} />
                    ) }
                </div>
            }
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="row gy-3">
                    <div className="col-12">
                        <FormInput
                            label="First Name"
                            name="firstname"
                            type="text"
                            placeholder="Enter First Name"
                            value={formik.values.firstname}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.firstname as string | undefined}
                        />
                    </div>
                    <div className="col-12">
                        <FormInput
                            label="Last Name"
                            name="lastname"
                            type="text"
                            placeholder="Enter Last Name"
                            value={formik.values.lastname}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.lastname as string | undefined}
                        />
                    </div>
                    {! isPublicMember && (
                        <div className="col-12">
                            <FormInput
                                label="Email"
                                name="email"
                                type="email"
                                disabled={!!member}
                                placeholder="Enter Email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.errors.email as string | undefined}
                            />
                        </div>
                    )}

                    <div className="col-12">
                        <FormInput
                            label="Password"
                            name="password"
                            type="password"
                            placeholder="Enter Password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.password as string | undefined}
                        />
                    </div>
                    <div className="col-12">

                        {! isPublicMember && (
                            <Picklist
                                label="Select Role"
                                labelAlignment={`left`}
                                name='role'
                                placeholder="Choose Role"
                                borderRadius="semi-square"
                                onChange={(value) => {
                                    formik.setFieldValue('role', value);
                                }}
                                value={formik.values.role}
                                error={formik.errors.role?.name as string | undefined}
                            >

                                {publicOrganization?.roles?.map((role)=>{

                                    return(
                                        <Option name={role._id} label={  role.name.charAt(0).toUpperCase()  + role.name.slice(1)}  />
                                    )
                                })}

                            </Picklist>
                        )}


                    </div>
                </div>
            </form>
        </Modal>
    );
};
export default PublicMembersForm;
import React, { useState, useEffect } from 'react';
import {Badge, Modal, Spinner} from "react-rainbow-components";
import {IDocument, IGroup} from "../../../../interfaces/organization.interface";
import {TagList} from "react-helmet-async";
import ReactMarkdown from 'react-markdown';


import '../public-app/public-organization.scss'
import {useSelector} from "react-redux";
import {RootState} from "../../../../redux/rootReducer";
interface DocumentManageProps {
    group?: IGroup;
    document: IDocument;
    showModal: boolean;
    setShowModal: (modalState: boolean) => void;
}

const DocumentViewer: React.FC<DocumentManageProps> = ({ group, document, showModal, setShowModal }) => {

    const {loading} = useSelector((state: RootState) => state.organizations);

    console.log('DocumentViewer group', group)
    console.log('DocumentViewer document', document)

    const [activeSubTab, setActiveSubTab] = useState('Document')

    return (
        <Modal
            id="modal-view-document"
            title={document?.filename?.split('_').pop() || "Document Viewer"}
            isOpen={showModal}
            size={`large`}
            onRequestClose={() => {
                setShowModal(false);
                setActiveSubTab('Document');
            }}
        >
            <div>
                <ul className="nav nav-tabs my-3 nav-tabs-s1 gap-4">
                    <li className="nav-item-custom">
                        <button
                            className={`nav-link-custom ${activeSubTab === 'Document' ? 'custom-active' : ''}`}
                            onClick={() => setActiveSubTab('Document')}
                            type="button">Document
                        </button>
                    </li>
                    <li className="nav-item-custom">
                        <button
                            className={`nav-link-custom ${activeSubTab === 'Summery' ? 'custom-active' : ''}`}
                            onClick={() => setActiveSubTab('Summery')}
                            type="button">Summery
                        </button>
                    </li>
                    <li className="nav-item-custom">
                        <button
                            className={`nav-link-custom ${activeSubTab === 'Questions' ? 'custom-active' : ''}`}
                            onClick={() => setActiveSubTab('Questions')}
                            type="button">Questions
                        </button>
                    </li>
                    <li className="nav-item-custom">
                        <button
                            className={`nav-link-custom ${activeSubTab === 'Tags' ? 'custom-active' : ''}`}
                            onClick={() => setActiveSubTab('Tags')}
                            type="button">Tags
                        </button>
                    </li>
                </ul>
            </div>
            {activeSubTab === 'Document' && (
                <iframe
                    src={document?.signedUrl}
                    width="100%"
                    height="700px"
                    frameBorder="0"
                    title="Document"
                />
            )}
            {activeSubTab === 'Summery' && (
                group && group?.summaries?.filter((filterSummary)=> filterSummary?.documentid?.toString() === document?._id?.toString()).length > 0 ? (
                    <div className='m-2 summary-content'>
                        {group?.summaries?.filter((filterSummary) => filterSummary?.documentid?.toString() === document?._id?.toString()).map((summary) => (
                            <ReactMarkdown>{summary?.summary}</ReactMarkdown>
                        ))}
                    </div>
                ): (
                    <div className='m-5 fs-3 text-center'>
                        Sorry! No Summaries Found.
                    </div>
                )
            )}
            {activeSubTab === 'Questions' && (
                <div>
                    {group?.questions?.filter((question) => question?.documentid?.toString() === document?._id?.toString()).map((question, index) =>
                        <div key={`questions_${index}`} role={'button'}
                             className="mb-2 px-3 py-2 question-container" // Add a custom class
                             style={{
                                 backgroundColor: 'rgb(239,248,255)',
                                 borderRadius: 10
                             }}
                        >
                            {index + 1}. {question.question}
                        </div>
                    )}
                </div>
            )}
            {activeSubTab === 'Tags' && (
                <div className='mt-3 ms-2'>
                    {group?.tags?.filter((tag)=> tag?.documentid?.toString() === document?._id?.toString()).map((tagObj, index) => {
                        // Split the tag string by ' - ' and filter out empty strings
                        const tagsArray = tagObj.tag
                            .split('-')
                            .map((item: any) => item.trim())
                            .filter((item: any) => item !== "");

                        return (
                            <React.Fragment key={index}>
                                {tagsArray.map((tagItem: any, tagIndex: number) => (

                                    <Badge
                                        className="rainbow-m-around_medium m-1 py-2 px-4 badge-hover"
                                        label={tagItem}
                                        variant="outline-brand"
                                        title={tagItem}
                                    />
                                ))}
                            </React.Fragment>
                        );
                    })}
                </div>
            )}
        </Modal>
    );
};

export default DocumentViewer;

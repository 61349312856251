import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios';
import { base_url } from '../constants/contants';
import { toastMessage } from '../utils/util';
import { getUserToken } from './auth';

const http: AxiosInstance = axios.create({
    baseURL: base_url,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Add a request interceptor to set the token in the headers for each request
http.interceptors.request.use(
    async (config) => {
        const token = getUserToken();
        if (token) {
            config.headers.Authorization = 'Bearer ' + token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Add a response interceptor to handle responses
http.interceptors.response.use(
    (response: AxiosResponse) => {
        if ((response.status >= 200 && response.status < 300) || response.status === 429) {
            return response;
        }
        return response;
    },
    (error: AxiosError) => {
        const { response, request }: { response?: AxiosResponse; request?: XMLHttpRequest } = error;

        if (response) {
            if (response.status === 400) {
                toastMessage(response.data.message, 'error');
                return Promise.reject(response);
            } else {
                // showAlert(response.data?.message, 'error');
                return Promise.reject(response);
            }
        } else if (request) {
            // showAlert('Request failed. Please try again.', 'error');
            return Promise.reject(request);
        }
        return Promise.reject(error);
    }
);

export default http;

// src/slices/pricingPlansSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPricingPlan } from '../../interfaces/pricingPlans.interface';

interface PricingPlansState {
    plans: IPricingPlan[];
    loading: boolean;
    error: string | null;
}

const initialState: PricingPlansState = {
    plans: [],
    loading: false,
    error: null,
};

const pricingPlansSlice = createSlice({
    name: 'pricingPlans',
    initialState,
    reducers: {
        fetchPricingPlansStart(state) {
            state.loading = true;
            state.error = null;
        },
        fetchPricingPlansSuccess(state, action: PayloadAction<IPricingPlan[]>) {
            state.plans = action.payload;
            state.loading = false;
        },
        fetchPricingPlansFailure(state, action: PayloadAction<string>) {
            state.error = action.payload;
            state.loading = false;
        },
    },
});

export const {
    fetchPricingPlansStart,
    fetchPricingPlansSuccess,
    fetchPricingPlansFailure,
} = pricingPlansSlice.actions;

export default pricingPlansSlice.reducer;

import React, {FC, useEffect, useState} from 'react';
import {Badge, Breadcrumb, Breadcrumbs, Button, Chart, Dataset} from "react-rainbow-components";
import {useNavigate, useParams} from "react-router-dom";
import http from "../../../services/api";
import {IWebsite} from "../../../interfaces/website.interface";
import {pricing_plans_url, website_chats_url, websites_url} from "../../../constants/contants";
import WebsiteDashboard from "./tabs/WebsiteDashboard";
import WebsiteDocuments from "./tabs/WebsiteDocuments";
import WebsiteSettings from "./tabs/WebsiteSettings";
import {
    fetchPricingPlansFailure,
    fetchPricingPlansStart,
    fetchPricingPlansSuccess
} from "../../../redux/slices/pricingPlansSlice";
import {IPricingPlan} from "../../../interfaces/pricingPlans.interface";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/rootReducer";
import {fetchWebsiteFailure, fetchWebsiteStart, fetchWebsiteSuccess} from "../../../redux/slices/websitesSlice";


import { BrainaqueChatBot } from 'brainaque-chat-sdk'
import WebsiteChatHistory from "./tabs/WebsiteChatHistory";
import {
    fetchWebsiteChatsFailure,
    fetchWebsiteChatsStart,
    fetchWebsiteChatsSuccess
} from "../../../redux/slices/websiteChatSlice";
import {IWebsiteChats} from "../../../interfaces/websitechat.interface";
import WebsiteChatBotSettings from "./tabs/WebsiteChatBotSettings";

const WebsiteContainer: FC = () => {

    const navigate = useNavigate();
    const { id, _page } = useParams();



    const [activeTab, setActiveTab] = useState('overview')


    const dispatch = useDispatch();
    const { website, loading, error } = useSelector((state: RootState) => state.websites);

    const fetchWebsite = async () => {
        dispatch(fetchWebsiteStart());

        try {
            let response = await http.get<IWebsite>(`${websites_url}/${id}`);
            console.log('response.data', response.data)
            dispatch(fetchWebsiteSuccess(response.data));
        } catch (error: any) {
            dispatch(fetchWebsiteFailure('Failed to fetch website'));
        }
    };

    const fetchWebsiteChats = async () => {
        dispatch(fetchWebsiteChatsStart());

        try {
            let response = await http.get<IWebsiteChats[]>(`${website_chats_url}/${id}`);
            dispatch(fetchWebsiteChatsSuccess(response.data));
        } catch (error: any) {
            dispatch(fetchWebsiteChatsFailure('Failed to fetch website'));
        }
    };



    useEffect(() => {


        fetchWebsite();
        fetchWebsiteChats();

    }, [dispatch, id]);


    useEffect(() => {

        if(_page){
            setActiveTab(_page);
        }
    }, [_page]);

    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const values = [18, 42, 58, 50, 19, 0, 24, 50, 33, 20, 5, 35]

    const BarChartExample = () => (
        <div className="rainbow-p-vertical_large">
            <div
                className="rainbow-align-content_center rainbow-m-vertical_large rainbow-m_auto"
            >
                <Chart labels={labels} type="bar">
                    <Dataset key="Sales" title="Sales" values={values} backgroundColor="#01b6f5" />
                </Chart>
            </div>
        </div>
    );


    const labels2 = ['Data-Red', 'Data-Orange', 'Data-Yellow', 'Data-Green'];
    const dataset = [
        {
            value: 10,
            color: '#fe4849',
        },
        {
            value: 15,
            color: '#ff6837',
        },
        {
            value: 42,
            color: '#ffcc00',
        },
        {
            value: 33,
            color: '#1ad1a3',
        },
    ];
    function renderDataset() {
        const data:any = [];
        const colors:any = [];
        dataset.forEach(set => {
            data.push(set.value);
            colors.push(set.color);
        });

        return <Dataset title="Data" values={data} backgroundColor={colors} />;
    }

    const PieChartExample = () => (
        <div className="rainbow-p-vertical_xx-large rainbow-p-horizontal_medium">
            <div
                className="rainbow-align-content_center rainbow-m-vertical_large rainbow-m_auto"
            >
                <Chart labels={labels} type="pie" legendPosition="right">
                    {renderDataset()}
                </Chart>
            </div>
        </div>
    );

    return (
        <div className="nk-content">
            <div className="container-xl">
                <div className="nk-content-inner">
                    <div className="nk-content-body">
                        <div className="nk-block-head nk-page-head">
                            <div className="nk-block-head-between">
                                <div className="nk-block-head-content">
                                    <h2 className="display-6 d-flex align-items-start">{website?.websiteName}
                                        <Badge
                                            className="rainbow-s-around_small fs-8"
                                            label={website?.deactivated? 'Deactivated':'Active'}
                                            variant={website?.deactivated? 'error':'success'}
                                            title={website?.deactivated? 'Deactivated':'Active'}
                                            style={{  letterSpacing:'initial' }}
                                        />

                                    </h2>
                                    <span>{website?.websiteUrl}</span>
                                </div>
                            </div>
                        </div>
                        {!website?.apiKey && (
                            <div className="nk-block">
                                <div className="alert alert-danger alert-dismissible fade show mb-4 rounded-3"
                                     role="alert">
                                    <p className="small">Create a token to test and use the chats now! You can create it from the <a className={`link link-danger`} onClick={()=>setActiveTab('settings')}> settings tab</a>.</p>
                                </div>
                            </div>

                        )}

                        <div className="nk-block">
                            <ul className="nav nav-tabs mb-3 nav-tabs-s1">
                                <li className="nav-item">
                                    <button className={`nav-link ${activeTab === 'overview' ? 'active' : ''}`}
                                            onClick={() => navigate(`/website/${id}/overview`)} type="button">Overview
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button className={`nav-link ${activeTab === 'documents'? 'active':''}`} onClick={()=> navigate(`/website/${id}/documents`) } type="button" >Documents
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button className={`nav-link ${activeTab === 'chat-history'? 'active':''}`} onClick={()=> navigate(`/website/${id}/chat-history`) } type="button" >Chats History
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button className={`nav-link ${activeTab === 'settings'? 'active':''}`} onClick={()=> navigate(`/website/${id}/settings`)} type="button" >Settings
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button className={`nav-link ${activeTab === 'chatbot-settings'? 'active':''}`} onClick={()=> navigate(`/website/${id}/chatbot-settings`)} type="button" >ChatBot Settings
                                    </button>
                                </li>
                            </ul>
                            <div className="tab-content">

                                {activeTab === 'overview' &&(
                                        <WebsiteDashboard  />
                                    )
                                }
                                {activeTab === 'documents' && id && website?.documents &&(
                                        <WebsiteDocuments />
                                    )
                                }

                                {activeTab === 'chat-history' && id &&(
                                        <WebsiteChatHistory />
                                    )
                                }


                                {activeTab === 'settings' && id && website?.documents &&(
                                        <WebsiteSettings  />
                                    )
                                }

                                {activeTab === 'chatbot-settings' && id && website?.documents &&(
                                        <WebsiteChatBotSettings  />
                                    )
                                }

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <BrainaqueChatBot apiKey={website?.apiKey!} />
        </div>
    );
};
export default WebsiteContainer;

import React, { FC, ReactElement, useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import Footer from "../components/footer/Footer";
import io, { Socket } from "socket.io-client";

import LogoImg from "../../assets/logo.png";
import LogoFullImg from "../../assets/logo-full.png";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import { fetchUserFailure, fetchUserStart, fetchUserSuccess, setUser } from "../../redux/slices/userSlice";
import {
    fetchPricingPlansFailure,
    fetchPricingPlansStart,
    fetchPricingPlansSuccess
} from "../../redux/slices/pricingPlansSlice";
import http from "../../services/api";
import { me_url, pricing_plans_url, wss_url } from "../../constants/contants";
import { getInitials, toastMessage } from "../../utils/util";
import { IPricingPlan } from "../../interfaces/pricingPlans.interface";
import { Avatar, Sidebar, SidebarItem } from "react-rainbow-components";
import {IUser} from "../../interfaces/user.interface";

interface pageInterface {
    title: string,
    path: string
}

const mediaTextStyle = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
};

const pages = [
    {
        title: 'Dashboard',
        path: '/dashboard'
    },
    {
        title: 'Websites',
        path: '/websites'
    },
    {
        title: 'Websites',
        path: '/website'
    },
    {
        title: 'Organizations',
        path: '/organizations'
    },
    {
        title: 'Organizations',
        path: '/organization'
    },
    {
        title: 'Shops',
        path: '/shops'
    },
    {
        title: 'Shops',
        path: '/shop'
    },
    {
        title: 'Sdk',
        path: '/sdk'
    },
    {
        title: 'Account',
        path: '/account'
    },
    {
        title: 'Plans',
        path: '/plans'
    },
]

const useViewport = () => {
    const [width, setWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    // Return the width so we can use it in our components
    return { width };
}

const FeatureContainer: FC<{ children: ReactElement }> = ({ children }) => {
    const dispatch = useDispatch();
    const { user, loading, error } = useSelector((state: RootState) => state.user);
    const socketRef = useRef<Socket | null>(null);
    const navigate = useNavigate();
    const location = useLocation();

    const page = pages.find(page => {
        const basePath = page.path.split('/').filter(Boolean).join('/');
        const locationPath = location.pathname.split('/').filter(Boolean).join('/');
        return locationPath.startsWith(basePath);
    });

    const [selectedItem, setSelectedItem] = useState<any>(page?.title)
    const [activeMenu, setActiveMenu] = useState<any>(page?.title);
    const [toggleSideBar, setToggleSideBar] = useState(false);
    const [showSidebar, setShowSidebar] = useState(false);

    useEffect(() => {

        if( process.env.NODE_ENV === 'production'){
            if (!socketRef.current && user) {
                socketRef.current = io(wss_url ? wss_url : "");

                socketRef.current.on("connect", () => {
                    console.log("connected");

                    socketRef.current?.emit('join', { userId: user._id }, (response: any) => {
                        console.log("join", response);
                    });
                });

                socketRef.current.on('document-process', function (message: any) {
                    console.log("message", message);
                });
            }

            return () => {
                socketRef.current?.disconnect();
                socketRef.current = null;
            };
        }

    }, [user]);


    useEffect(() => {
        const fetchPricingPlans = async () => {
            dispatch(fetchPricingPlansStart());

            try {
                let response = await http.get<IPricingPlan[]>(pricing_plans_url);
                dispatch(fetchPricingPlansSuccess(response.data));
            } catch (error: any) {
                dispatch(fetchPricingPlansFailure('Failed to fetch pricing plans'));
            }
        };

        fetchPricingPlans();
    }, [dispatch]);

    useEffect(() => {
        if (page) {
            setActiveMenu(page?.title);
        }
    }, [page]);

    useEffect(() => {
        const fetchUser = async () => {
            dispatch(fetchUserStart());

            try {
                let response = await http.get<IUser>(me_url);
                dispatch(fetchUserSuccess(response.data));
                localStorage.setItem('user', JSON.stringify(response.data));
            } catch (error: any) {
                dispatch(fetchUserFailure('Failed to fetch user'));
            }
        };

        fetchUser();
    }, [dispatch]);

    const getItemStyles = (isSelected: any) => ({
        color: isSelected ? '#e8e8fd' : '#061c3f',
    });

    console.log('user', user)

    return (
        <div className="nk-app-root" data-sidebar-collapse="lg">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{page?.title}</title>
            </Helmet>
            <div className="nk-main">
                {showSidebar && (
                    <div className="sidebar-overlay" onClick={() => setShowSidebar(false)}></div>

                )}
                <div className={`nk-sidebar nk-sidebar-fixed overflow-hidden ${showSidebar ? 'sidebar-active':''}`} id="sidebar"
                     style={toggleSideBar ? {width: '100px'} : {}}>
                    <div className="nk-compact-toggle">
                        <button
                            className="btn btn-xs btn-outline-light btn-icon compact-toggle text-light bg-white rounded-3"
                            onClick={() => setToggleSideBar(!toggleSideBar)}
                        >
                            {toggleSideBar ?
                                <em className="icon off ni ni-chevron-right"></em>
                                :
                                <em className="icon off ni ni-chevron-left"></em>
                            }
                        </button>
                    </div>
                    <div className="nk-sidebar-element nk-sidebar-head">
                        <div className="nk-sidebar-brand">
                            <span onClick={() => navigate("/dashboard")} className="logo-link">
                                <div className="logo-wrap align-items-center text-center">
                                    {toggleSideBar ?

                                        <img className="logo-img" src={LogoImg} width={30} style={{ marginLeft: '15px'}}  alt=""/>

                                        :
                                        <img className="logo-img" src={LogoFullImg} width={150}  alt=""/>
                                    }
                                </div>
                            </span>
                        </div>
                    </div>
                    <div className="nk-sidebar-element nk-sidebar-body">
                        <div className="nk-sidebar-content h-100" style={{overflow: "auto"}}>
                            <div className="nk-sidebar-menu">
                                {toggleSideBar ?
                                    <Sidebar selectedItem={page?.title} onSelect={(menuItem) => {
                                        setSelectedItem(page?.title)
                                    }} className="toggle-sidebar">
                                        <SidebarItem
                                            icon={<em className="icon ni ni-dashboard-fill fs-1 text-primary"></em>}
                                            onClick={() => {
                                                setActiveMenu(page?.title);
                                                navigate('/dashboard')
                                            }} name="Dashboard" label="Dashboard"
                                            style={getItemStyles(selectedItem === 'Dashboard')}/>
                                        <SidebarItem icon={<em className="icon ni ni-globe fs-1 text-primary"></em>}
                                                     onClick={() => {
                                                         setActiveMenu(page?.title);
                                                         navigate('/websites')
                                                     }} name="Websites" label="Websites"
                                                     style={getItemStyles(selectedItem === 'Websites')}/>
                                        {/* <SidebarItem icon={<em className="icon ni ni-globe fs-1 text-primary"></em>}
                                                     onClick={() => {
                                                         setActiveMenu(page?.title);
                                                         navigate('/shops')
                                                     }} name="Shops" label="Shops"
                                                     style={getItemStyles(selectedItem === 'Shops')}/>*/}
                                        <SidebarItem icon={<em className="icon ni ni-building fs-1 text-primary"></em>}
                                                     onClick={() => {
                                                         setActiveMenu(page?.title);
                                                         navigate('/organizations')
                                                     }} name="Organizations" label="Organizations"
                                                     style={getItemStyles(selectedItem === 'Organizations')}/>
                                        <SidebarItem icon={<em className="icon ni ni-download fs-1 text-primary"></em>}
                                                     onClick={() => {
                                                         setActiveMenu(page?.title);
                                                         navigate('/sdk')
                                                     }} name="Sdk" label="Sdk"
                                                     style={getItemStyles(selectedItem === 'Sdk')}/>
                                        <SidebarItem icon={<em className="icon ni ni-list fs-1 text-primary"></em>}
                                                     onClick={() => {
                                                         setActiveMenu(page?.title);
                                                         navigate('/plans')
                                                     }} name="Plans" label="Plans"
                                                     style={getItemStyles(selectedItem === 'Plans')}/>
                                        <SidebarItem icon={<em className="icon ni ni-user fs-1 text-primary"></em>}
                                                     onClick={() => {
                                                         setActiveMenu(page?.title);
                                                         navigate('/account')
                                                     }} name="Account" label="Account"
                                                     style={getItemStyles(selectedItem === 'Account')}/>
                                    </Sidebar>
                                    :
                                    <ul className="nk-menu">
                                        <li className={`nk-menu-item ${activeMenu === 'Dashboard' ? 'active' : ''}`}>
                                            <span onClick={() => {
                                                setActiveMenu(page?.title);
                                                setShowSidebar(false)
                                                navigate('/dashboard')
                                            }} className="nk-menu-link">
                                                <span className="nk-menu-icon">
                                                    <em className="icon ni ni-dashboard-fill"></em>
                                                </span>
                                                <span className="nk-menu-text">Dashboard</span>
                                            </span>
                                        </li>
                                        <li className={`nk-menu-item ${activeMenu === 'Websites' ? 'active' : ''}`}>
                                            <span onClick={() => {
                                                setActiveMenu(page?.title);
                                                setShowSidebar(false)
                                                navigate('/websites')
                                            }} className="nk-menu-link">
                                                <span className="nk-menu-icon">
                                                    <em className="icon ni ni-globe"></em>
                                                </span>
                                                <span className="nk-menu-text">Websites</span>
                                            </span>
                                        </li>
                            {/*            <li className={`nk-menu-item ${activeMenu === 'Shops' ? 'active' : ''}`}>
                                            <span onClick={() => {
                                                setActiveMenu(page?.title);
                                                setShowSidebar(false)
                                                navigate('/shops')
                                            }} className="nk-menu-link">
                                                <span className="nk-menu-icon">
                                                    <em className="icon ni ni-building"></em>
                                                </span>
                                                <span className="nk-menu-text">Shops</span>
                                            </span>
                                        </li>*/}
                                        <li className={`nk-menu-item ${activeMenu === 'Organizations' ? 'active' : ''}`}>
                                            <span onClick={() => {
                                                setActiveMenu(page?.title);
                                                setShowSidebar(false)
                                                navigate('/organizations')
                                            }} className="nk-menu-link">
                                                <span className="nk-menu-icon">
                                                    <em className="icon ni ni-building"></em>
                                                </span>
                                                <span className="nk-menu-text">Organizations</span>
                                            </span>
                                        </li>
                                        <li className={`nk-menu-item ${activeMenu === 'Sdk' ? 'active' : ''}`}>
                                            <span onClick={() => {
                                                setActiveMenu(page?.title);
                                                setShowSidebar(false)
                                                navigate('/sdk')
                                            }} className="nk-menu-link">
                                                <span className="nk-menu-icon">
                                                    <em className="icon ni ni-download"></em>
                                                </span>
                                                <span className="nk-menu-text">SDK Installation</span>
                                            </span>
                                        </li>
                                        <li className={`nk-menu-item ${activeMenu === 'Plans' ? 'active' : ''}`}>
                                            <span onClick={() => {
                                                setActiveMenu(page?.title);
                                                setShowSidebar(false)
                                                navigate('/plans')
                                            }} className="nk-menu-link">
                                                <span className="nk-menu-icon">
                                                    <em className="icon ni ni-list"></em>
                                                </span>
                                                <span className="nk-menu-text">Plans</span>
                                            </span>
                                        </li>
                                        <li className={`nk-menu-item ${activeMenu === 'Account' ? 'active' : ''}`}>
                                            <span className="nk-menu-link" onClick={() => navigate('/account')}>
                                                <span className="nk-menu-icon">
                                                    <em className="icon ni ni-user"></em>
                                                </span>
                                                <span className="nk-menu-text">Account</span>
                                            </span>
                                        </li>
                                    </ul>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="nk-sidebar-element nk-sidebar-footer">
                        <div
                            className={`${toggleSideBar ? 'd-flex justify-content-center' : 'nk-sidebar-footer-extended'} pt-3`}>
                            {toggleSideBar ?
                                <span onClick={() => navigate('/account/profile')}>
                                    <Avatar
                                        className="rainbow-m-around_x-small link"
                                        assistiveText={user?.fullName}
                                        initials={getInitials(user?.fullName!)}
                                        title={user?.fullName}
                                    />
                                </span>
                                :
                                <div className="border border-light rounded-3">
                                    <div className="px-3 py-2 bg-white border-bottom border-light rounded-top-3">

                                        <div
                                            className="d-flex flex-wrap align-items-center justify-content-between mb-3">
                                            <h6 className="lead-text mb-0">{user?.plan.planId.title}</h6>
                                            <span className="link link-primary text-capitalize">
                                                  {user?.plan.planId?.subtitle}
                                            </span>
                                        </div>
                                        <div className="progress progress-md">
                                            <div className="progress-bar"
                                                 style={{width: `${(user?.aiTokens! / user?.plan.planId.tokens!) * 100}%`}}></div>
                                        </div>
                                        <h6 className="lead-text mt-2">{user?.aiTokens} <span className="text-light">tokens left</span>
                                        </h6>
                                    </div>
                                    <span className="d-flex px-1 py-2 bg-primary bg-opacity-10 rounded-bottom-3 link"
                                          onClick={() => navigate('/account/profile')}>
                                        <div className="media-group">
                                            <div className="media media-sm media-middle media-circle text-bg-primary">
                                                <Avatar
                                                    className="rainbow-m-around_x-small"
                                                    assistiveText={user?.fullName}
                                                    initials={getInitials(user?.fullName!)}
                                                    title={user?.fullName}
                                                    backgroundColor="#37b6ff"
                                                    size={`small`}
                                                />
                                            </div>
                                            <div className="media-text" style={{width: 140}}>
                                                <h6 className="fs-6 mb-0"
                                                    style={{overflow: 'hidden',
                                                        whiteSpace: 'nowrap',
                                                        textOverflow: 'ellipsis',
                                                    }}
                                                >{user?.fullName}</h6>
                                                <span className="text-light fs-7 text-wrap"
                                                      style={{overflow: 'hidden',
                                                          whiteSpace: 'nowrap',
                                                          textOverflow: 'ellipsis',
                                                }}>{user?.email}</span>
                                            </div>
                                            <em className="icon ni ni-chevron-right ms-auto ps-1"></em>
                                        </div>
                                    </span>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="nk-wrap" style={toggleSideBar ? {paddingLeft: '100px'} : {}}>
                    <div className="nk-header nk-header-fixed">
                        <div className="container-fluid">
                            <div className="nk-header-wrap">
                                <div className="nk-header-logo ms-n1">
                                    <div className="nk-sidebar-toggle me-1" onClick={()=>setShowSidebar(true)}>
                                        <button className="btn btn-sm btn-zoom btn-icon sidebar-toggle d-sm-none">
                                            <em className="icon ni ni-menu"> </em>
                                        </button>
                                        <button
                                            className="btn btn-md btn-zoom btn-icon sidebar-toggle d-none d-sm-inline-flex">
                                            <em className="icon ni ni-menu"> </em>
                                        </button>
                                    </div>
                                    <a href="index.html" className="logo-link">
                                        <div className="logo-wrap">
                                            <img className="logo-img logo-light" src="images/logo.png"
                                                 srcSet="images/logo2x.png 2x" alt=""/>
                                            <img className="logo-img logo-dark" src="images/logo-dark.png"
                                                 srcSet="images/logo-dark2x.png 2x" alt=""/>
                                            <img className="logo-img logo-icon" src="images/logo-icon.png"
                                                 srcSet="images/logo-icon2x.png 2x" alt=""/>
                                        </div>
                                    </a>
                                </div>
                                <div className="nk-header-tools">
                                    <ul className="nk-quick-nav ms-2">
                                        <li className="dropdown d-inline-flex">
                                            <a data-bs-toggle="dropdown" className="d-inline-flex" href="#">
                                                <div
                                                    className="media media-md media-circle media-middle text-bg-primary"  onClick={() => navigate('/account/profile')}>
                                                    <Avatar
                                                        className="rainbow-m-around_x-small link"
                                                        assistiveText={user?.fullName}
                                                        initials={getInitials(user?.fullName!)}
                                                        title={user?.fullName}
                                                    />
                                                </div>
                                            </a>

                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="nk-content">
                        {React.cloneElement(children, {socket: socketRef.current})}
                    </div>
                    <Footer/>
                </div>
            </div>
        </div>
    );
};

export default FeatureContainer;

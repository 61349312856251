import React, {FC, useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import dayjs from "dayjs";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux/rootReducer";

import {IChatMessage, IShopChats} from "../../../../interfaces/shopchat.interface";
import EmptyMessage from "../../../components/empty/EmptyMessage";


interface FileUploadHandle {
    uploadFiles: () => Promise<void>;
}


const ShopChatHistory: React.FC = () => {

    const { id } = useParams();



    const dispatch = useDispatch();
    const { shopChats } = useSelector((state: RootState) => state.shopChats);
    const { shop, loading, error } = useSelector((state: RootState) => state.shops);
    const [showModal, setShowModal] = useState(false);
    const [chats, setChats] = useState<IChatMessage[]>();



    return (
        <div className="tab-pane fade show active" id="profile-tab-pane">
            <div
                className="d-flex align-items-center justify-content-between  mt-5 pb-1">
                <h5>Chat history</h5>
            </div>
            <div className="nk-block">

                <div className="card shadow-none">

                    {shopChats?.length?
                        <table className="table table-middle mb-0">
                            <tbody>
                            { shopChats?.map((chat) => {

                                if(chat.chats.length){
                                    return (
                                        <tr className={``}>
                                            <td className="tb-col ">
                                                <div
                                                    className="caption-text line-clamp-2 ">{dayjs(chat.chats[0].createdAt).format('DD MMMM YYYY')} </div>
                                            </td>
                                            <td className="tb-col tb-col-sm ">
                                                <div
                                                    className="caption-text line-clamp-2"> {dayjs(chat.chats[0].createdAt).format(' h:mm A')} - {dayjs(chat.chats[chat.chats.length-1].createdAt).format(' h:mm A')} </div>
                                            </td>
                                            <td className="tb-col tb-col-sm">
                                                <div
                                                    className="caption-text line-clamp-2"> {chat.chats.length} conversations </div>
                                            </td>
                                            <td className="tb-col tb-col-sm">
                                                <div
                                                    className="caption-text line-clamp-2">{chat.tokensUsed} Tokens </div>
                                            </td>
                                            <td className="tb-col">
                                                <div
                                                    className="caption-text line-clamp-2">{`${chat.countryName? `${chat.countryName} `:'Unknown'}`} </div>
                                            </td>
                                            <td className="tb-col ">
                                                <div
                                                    className="caption-text line-clamp-2 link link-primary" onClick={()=>{
                                                    setShowModal(true)
                                                    setChats(chat.chats)
                                                }}> View</div>
                                            </td>
                                        </tr>
                                    )
                                }

                            })}
                            </tbody>
                        </table>
                    :
                        <EmptyMessage
                            type="chat"
                            title="No chat history found."
                            description="You haven't started any conversations yet. Begin chatting to see your message history here."
                            buttonText="Start Chat"
                            buttonCallback={()=>{

                            }}
                        />
                    }


                </div>

            </div>

        </div>
    );
};
export default ShopChatHistory;

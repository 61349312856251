import {Button, CheckboxGroup, Input, Lookup, Modal} from "react-rainbow-components";
import FormButton from "../../../../components/form/bottons/FormButton";
import React, {useEffect, useState} from "react";
import {IGroup, IMember} from "../../../../../interfaces/organization.interface";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../../redux/rootReducer";
import EmptyMessage from "../../../../components/empty/EmptyMessage";
import {updateGroupFailure, updateGroupStart, updateGroupSuccess} from "../../../../../redux/slices/organizationsSlice";
import http from "../../../../../services/api";
import {organizations_url} from "../../../../../constants/contants";
import {toastMessage} from "../../../../../utils/util";
import {useNavigate} from "react-router-dom";

interface GroupMemberManageInterface {
    showModal: boolean;
    setShowModal: any;
    groupId: string;
}

interface IOption {
    value: string;
    label: string;
    disabled?: boolean;
}

const GroupMemberManage: React.FC<GroupMemberManageInterface> = ({showModal, setShowModal, groupId}) => {

    const navigate = useNavigate();

    const {organization} = useSelector((state: RootState) => state.organizations);

    const dispatch = useDispatch();

    const [values, setValues] = useState<IOption[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [selectedMembersId, setSelectedMembersId] = useState<string[]>([]);
    const [group, setGroup] = useState<IGroup | undefined>(undefined);

    const findRole = (roleId: string) => {
        const role = organization?.roles.find((role) => role?._id === roleId);
        return role?.name || 'Unknown Role'; // Provide fallback if role is not found
    };

    useEffect(() => {
        if (organization) {
            const filteredGroup = organization?.groups?.find((g) => g._id === groupId);
            setGroup(filteredGroup);

            const initialMemberIds = filteredGroup?.groupMembers?.map((groupMember) => groupMember?._id);

            if (initialMemberIds) {
                setSelectedMembersId(initialMemberIds);
            }
        }
    }, [organization, groupId]);

    // This function is used to define what happens when the user clicks item.
    const clickItem = (memberId: string) => {
        const alreadySelected = selectedMembersId?.includes(memberId);

        if(alreadySelected){
            let previousMemberIds = selectedMembersId?.filter((prevMemberId) => prevMemberId !== memberId);
            setSelectedMembersId(previousMemberIds);
        }else {
            let previousMemberIds = [...selectedMembersId];
            previousMemberIds?.push(memberId);
            setSelectedMembersId(previousMemberIds)
        }
    }

    // This function is used to define what happens when the user clicks update button.
    const updateMembers = async () => {
        dispatch(updateGroupStart());
        try {
            if (organization) {
                let newMemberDetails = selectedMembersId?.map((memberId) => organization?.members?.find((member) => member?._id === memberId));
                const response: any = await http.put<IGroup>(
                    `${organizations_url}/${organization._id}/groups/${group?._id}`,
                    {
                        groupName: group?.groupName,
                        groupMembers: newMemberDetails,
                        groupDocuments: group?.groupDocuments,
                        loginRequired: group?.loginRequired,
                        showDocuments: group?.showDocuments,
                        showMembers: group?.showMembers,}
                );

                dispatch(updateGroupSuccess({
                    orgId: organization._id,
                    group: response.data.group,
                }));
                toastMessage('Group Updated successfully', 'success');
            }
        } catch (error: any) {
            dispatch(updateGroupFailure(error.message));
            toastMessage('Failed to update group', 'error');
        }finally {
            setShowModal(false);
        }
    }

    return(
        <>
            <Modal
                id="modal-add-member"
                title={"Manage Members"}
                isOpen={showModal}
                onRequestClose={() => {
                    setShowModal(false);
                }}
                footer={
                    <div className="d-flex justify-content-end">
                        <Button disabled={!group?.groupMembers.length && !selectedMembersId.length} onClick={updateMembers} label="Update" size={`small`} variant="brand" className="rainbow-m-around_medium"
                                style={{borderRadius: 5}}/>
                    </div>
                }
            >
                <div>
                    {organization && organization.members.length > 0 ? (
                        <>
                            <Input
                                id="lookup-1"
                                labelAlignment={'left'}
                                label="Search Members"
                                onChange={(e)=> setSearchQuery(e.target.value)}
                                value={searchQuery}
                                placeholder="Find Members"
                                borderRadius="semi-square"
                                className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
                            />
                            {organization.members.filter((member)=>
                                member?.firstname?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                                member?.lastname?.toLowerCase().includes(searchQuery.toLowerCase())
                            ).map((member, index) => (
                                <div role='button' className='mt-3 p-1 hover' onClick={()=> clickItem(member?._id)} key={`organization-members_${index}`} style={{borderRadius: 5}}>
                                    <div className='d-flex align-items-start gap-1'>
                                        <input type="checkbox"
                                               checked={selectedMembersId?.includes(member._id)}
                                               // onChange={(e) => setShowModal(e.target.checked)}
                                               style={{transform: 'scale(1.5)', margin: '10px'}}/>
                                        <div className='d-flex flex-column gap-0'>
                                            <div style={{marginBottom: -5}}>{member.firstname} {member.lastname}</div>
                                            <div className='fs-6 text-light'>{findRole(member.role)}</div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </>
                    ) : (
                        <EmptyMessage
                            type='organization'
                            title="No Members Available."
                            description="It seems there are no Members to display at the moment. You can create a Member by either clicking the button below or the one in the top right corner."
                            buttonText="Create Members"
                            buttonCallback={() => {
                                navigate(`/organization/${organization?._id}/members`);
                            }}
                        />
                    )}
                </div>
            </Modal>
        </>
    )
};

export default GroupMemberManage;
import React from 'react';
import Markdown from 'react-markdown';


interface ChatBubbleMeProps {
    chat: {
        user: string;
        message: string;
        createdAt?: string;
    };
    theme?: {
        primaryColor: string,
        chatBot:{
            primaryColor: string,
            secondaryColor:string,
        };
        chatUser:{
            primaryColor: string,
            secondaryColor:string,
        }

    };
    defaultThemeStyles: {
        primaryColor: string,
        secondaryColor:string,
    };
}

const ChatBubbleMe: React.FC<ChatBubbleMeProps> = ({ chat, theme, defaultThemeStyles }) => {

    return (
        <div className="chat-bubble me p-2 text-wrap" style={{backgroundColor:theme?.chatUser.primaryColor || defaultThemeStyles.primaryColor, color:theme?.chatUser.secondaryColor || defaultThemeStyles.secondaryColor, borderRadius: '15px 0px 15px 15px', maxWidth: '90%', textAlign: 'end'}}>

                {chat.message}
        </div>
    );
};

export default ChatBubbleMe;

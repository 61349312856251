import React, { FC } from 'react';

const SdkOverview: FC = () => {
    return (
        <div className="row g-gs">
            <div className="nk-block-head nk-block-head-sm mt-5">
                <div className="nk-block-head-content">
                    <h3 className="fs-4 fw-bolder">Why Use Our SDK?</h3>
                    <p>Our SDK allows you to easily configure and integrate an AI chatbot using Retrieval-Augmented Generation (RAG) into your website or application, providing powerful conversational capabilities with minimal effort.</p>
                </div>
            </div>
            <div className="col-md-6 col-xl-4">
                <a href="usecase-details.html"
                   className="card card-hover-translate rounded-4 border-1 shadow-tiny h-100">
                    <div className="card-body">
                        <div className="feature">
                            <div className="feature-media">
                                <div className="media media-middle media-xl text-bg-success-soft rounded-pill">
                                    <em className="icon ni ni-globe"></em>
                                </div>
                            </div>
                            <div className="feature-text text-base">
                                <h3 className="title fs-5 fw-medium">Easy Integration</h3>
                                <p className="fs-6">Quickly integrate our chatbot into your website with our React SDK available on npmjs.</p>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <div className="col-md-6 col-xl-4">
                <a href="usecase-details.html"
                   className="card card-hover-translate rounded-4 border-1 shadow-tiny h-100">
                    <div className="card-body">
                        <div className="feature">
                            <div className="feature-media">
                                <div className="media media-middle media-xl text-bg-warning-soft rounded-pill">
                                    <em className="icon ni ni-file-text"></em>
                                </div>
                            </div>
                            <div className="feature-text text-base">
                                <h3 className="title fs-5 fw-medium">Customizable</h3>
                                <p className="fs-6">Tailor the chatbot's behavior and appearance to match your brand and specific needs.</p>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <div className="col-md-6 col-xl-4">
                <a href="usecase-details.html"
                   className="card card-hover-translate rounded-4 border-1 shadow-tiny h-100">
                    <div className="card-body">
                        <div className="feature">
                            <div className="feature-media">
                                <div className="media media-middle media-xl text-bg-pink-soft rounded-pill">
                                    <em className="icon ni ni-cart"></em>
                                </div>
                            </div>
                            <div className="feature-text text-base">
                                <h3 className="title fs-5 fw-medium">Powerful AI</h3>
                                <p className="fs-6">Leverage the power of RAG to provide accurate and context-aware responses to user queries.</p>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    );
};

export default SdkOverview;

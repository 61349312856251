import React, { useState, useEffect, useRef } from 'react';
import './chat-bot.css';
import { MathJaxContext, MathJax } from 'better-react-mathjax';
import ChatBubbleMe from "./ChatBubbleMe";
import ChatBubbleBot from "./ChatBubbleBot";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from 'yup';
import http from "../../../services/api";
import {
  updatePublicOrganizationChatMessageSuccess,
  updatePublicOrganizationChatsSuccess
} from "../../../redux/slices/publicOrganizationSlice";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/rootReducer";
import EmptyMessage from "../empty/EmptyMessage";

const serverOrganizationUrl = 'https://server.brainaque.com/organization-chat'
//const apiUrl = 'http://localhost:4000'

const config = {
  loader: { load: ["input/asciimath"] }
};

interface ChatBotProps {
  resetNewQuery: any;
  apiKey: string;
  newQuery?: string;
  groupId?: string;
  userId?: string;
  headerText?: string;
  activeSessionId?: string;
  theme?: {
    primaryColor: string,
    title:string,
    icon:string,
    logo:string,
    chatBot:{
      primaryColor: string,
      secondaryColor:string,
    };
    chatUser:{
      primaryColor: string,
      secondaryColor:string,
    }

  };
}

const defaultThemeStyles = {
  primaryColor: '#724ae8',
  secondaryColor: '#f2f2f2',
};

interface IMessage {
  user: string;
  message: string;
  createdAt?: string;
}


const ValidationSchema = Yup.object().shape({
  name: Yup.string()
      .required('Name is required')
      .min(2, 'Name is too short')
      .max(50, 'Name is too long'),
  email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
});
export const BrainaqueChatMessage: React.FC<ChatBotProps> = ({resetNewQuery, newQuery, apiKey,activeSessionId,userId,  groupId, theme, headerText }) => {
  const [showChatWindow, setShowChatWindow] = useState(false);
  const [showOptionMenu, setShowOptionMenu] = useState(false);
  const [sessionId, setSessionId] = useState('');
  const [defaultTheme, setDefaultTheme] = useState(theme);
  const dispatch = useDispatch();
  const { publicOrganization } = useSelector((state: RootState) => state.publicOrganization);


  const [messages, setMessages] = useState<IMessage[]>([
    {
      user: 'bot',
      message: 'Hi there 👋  \nHow can I help you today?\n',
      createdAt: new Date().toISOString(), // Add the current date and time
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [expand, setExpand] = useState(false);
  const [chatBotData, setChatBotData] = useState<any>();
  const containerRef = useRef<HTMLDivElement>(null);
  const chatBodyRef = useRef<HTMLDivElement>(null);


  const hexWithAlpha = (hex: string, alpha: number): string => {
    // Remove the hash if present
    hex = hex.replace('#', '');

    // Convert alpha to a 0-255 range
    const alphaValue = Math.round(alpha * 255);

    // Convert alpha value to a 2-digit hexadecimal
    const alphaHex = alphaValue.toString(16).padStart(2, '0');

    // Return the hex color with the alpha appended
    return `#${hex}${alphaHex}`;
  };

// Function to check the API key
  const checkApiKey = async () => {
    try {
      const localSessionId =  localStorage.getItem('sessionId');

      const response = await http.post(`/organizations/validate-bot`, { apiKey, groupId,localSessionId,userId });

      setChatBotData(response.data)

      setDefaultTheme({
        primaryColor: hexWithAlpha(response.data.bot.primaryColor.hex, response.data.bot.primaryColor.alpha),
        title:response.data.bot.botTitle,
        icon:response.data.bot.botIcon,
        logo:response.data.bot.botLogo,
        chatBot:{
          primaryColor: hexWithAlpha(response.data.bot.chatBot.primaryColor.hex, response.data.bot.chatBot.primaryColor.alpha),
          secondaryColor:hexWithAlpha(response.data.bot.chatBot.secondaryColor.hex, response.data.bot.chatBot.secondaryColor.alpha),
        },
        chatUser:{
          primaryColor: hexWithAlpha(response.data.bot.chatUser.primaryColor.hex, response.data.bot.chatUser.primaryColor.alpha),
          secondaryColor:hexWithAlpha(response.data.bot.chatUser.secondaryColor.hex, response.data.bot.chatUser.secondaryColor.alpha),
        }
      })

      if(localSessionId){
        setSessionId(localSessionId)

      }else{
        setSessionId(response.data.sessionId)
      }


      if(response.data.chats && response.data.chats.length){
        setMessages(response.data.chats)
      }else{
        setMessages([
          {
            user: 'bot',
            message: response.data.bot.botWelcomeMessage,
            createdAt: new Date().toISOString(), // Add the current date and time
          },
        ])

        const isCheckSectionId = publicOrganization?.messages.some((m)=> m?.sessionId.toString() === response.data.sessionId.toString());
        const group = publicOrganization?.memberGroups?.find((g)=> g._id.toString() === groupId?.toString());
        if(!isCheckSectionId){
          const newData = {
            _id: '',
            chatEnded: false,
            chats: [{
              user: 'bot',
              message: 'Hi there 👋  \nHow can I help you today?\n',
              createdAt: new Date().toISOString(), // Add the current date and time
            }],
            groupId: groupId,
            groupName: group?.groupName,
            organizationId: publicOrganization?._id,
            sessionId: response.data.sessionId,
            userId: userId,
          }
          dispatch(updatePublicOrganizationChatsSuccess({ chat: newData }));
        }else {
          console.log('isCheckSectionId else', isCheckSectionId)
        }

        setSessionId(response.data.sessionId)
        localStorage.setItem('sessionId', response.data.sessionId);
      }

      return response.data.sessionId

    } catch (error) {
      console.error('Error checking API key:', error);
      return false;
    }
  };


  useEffect(() => {

      if(apiKey){
        checkApiKey()
      }



  },[activeSessionId])


  useEffect(() => {
    const handleQuery = async () => {
      if (apiKey && newQuery) {
        let newSessionId = await checkApiKey();
        sendMessage(newQuery, newSessionId);
        resetNewQuery();
      }
    };
    handleQuery(); // Call the async function
  }, [newQuery]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setShowOptionMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const addMessage = (message: IMessage) => {
    setMessages((prevMessages) => [...prevMessages!, message]);
    setTimeout(() => {
      chatBodyRef.current?.scrollTo({
        top: chatBodyRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }, 100);
  };

  useEffect(() => {
    if (chatBodyRef.current) {
      chatBodyRef.current?.scrollTo({
        top: chatBodyRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [messages, loading]);

  const sendMessage = async (messageText: string, newSessionId:string) => {
    addMessage({ user: 'me', message: messageText });

    const data = {
      user: 'me',
      message: messageText,
      createdAt: new Date().toISOString()
    }
    dispatch(updatePublicOrganizationChatMessageSuccess({sessionId: newSessionId, chat: data}))

    setLoading(true);
    try {
      const response = await http.post(serverOrganizationUrl, { chatQuery: messageText, apiKey, sessionId:newSessionId, groupId, userId });
      if (response.data && response.data.message) {
        const data = {
          user: 'bot',
          message: response.data.message,
          createdAt: new Date().toISOString()
        }
        dispatch(updatePublicOrganizationChatMessageSuccess({sessionId: newSessionId, chat: data}))
        setLoading(false);
        addMessage({ user: 'bot', message: response.data.message });
      }
    } catch (error) {
      setLoading(false);
    }
  };



  const hexToRgbaCss = (hex:any, opacity:any) => {
    let r = 0, g = 0, b = 0;
    if (hex.length === 4) {
      r = parseInt(hex[1] + hex[1], 16);
      g = parseInt(hex[2] + hex[2], 16);
      b = parseInt(hex[3] + hex[3], 16);
    } else if (hex.length === 7) {
      r = parseInt(hex[1] + hex[2], 16);
      g = parseInt(hex[3] + hex[4], 16);
      b = parseInt(hex[5] + hex[6], 16);
    }
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  return (
      <MathJaxContext config={config}>
        <div style={{height: '94vh', position: 'relative', backgroundColor: '#fbfbfb'}}>
          <div style={{height:'90%'}}>
            {chatBotData?.status ?
                <div className="chat-body" style={{height: '85vh', overflowY: 'auto', overflowX: 'hidden'}} ref={chatBodyRef}>
                  {chatBotData?.showForm ? (
                      <div className="form-wrap">
                        <Formik
                            initialValues={{name: '', email: ''}}
                            validationSchema={ValidationSchema}
                            onSubmit={async (values, {setSubmitting}) => {
                              let response = await fetch(`/website-chats/user`, {
                                method: 'PUT',
                                headers: {
                                  'Content-Type': 'application/json',
                                },
                                body: JSON.stringify({
                                  name: values.name,
                                  email: values.email,
                                  sessionId: sessionId,
                                  websiteId: chatBotData.websiteId,
                                }),
                              });

                              if (response.ok && apiKey) {
                                checkApiKey();
                              }

                              setSubmitting(false);
                            }}
                        >
                          {({isSubmitting}) => (
                              <Form>
                                <div className="row logo-container">
                                  {chatBotData.bot.botLogo && (
                                      <img
                                          src={chatBotData.bot.botLogo}
                                          width={100}
                                          className="me-2 logo"
                                          alt="Bot Logo"
                                      />
                                  )}
                                </div>
                                <div className="row">
                                  <h2
                                      id="title"
                                      className="text-center title"
                                      style={{color: defaultTheme?.chatBot.primaryColor}}
                                  >
                                    Tell Us About Yourself
                                  </h2>
                                  <p id="description" className="text-center description">
                                    Share your details to help us assist you better.
                                  </p>
                                </div>
                                <div className="row">
                                  <div className="form-group">
                                    <label id="name-label" htmlFor="name">Name</label>
                                    <Field
                                        type="text"
                                        name="name"
                                        id="name"
                                        placeholder="Enter your name"
                                        className="form-control"
                                    />
                                    <ErrorMessage name="name" component="div" className="text-danger"/>
                                  </div>
                                  <div className="form-group">
                                    <label id="email-label" htmlFor="email">Email</label>
                                    <Field
                                        type="email"
                                        name="email"
                                        id="email"
                                        placeholder="Enter your email"
                                        className="form-control"
                                    />
                                    <ErrorMessage name="email" component="div" className="text-danger"/>
                                  </div>
                                </div>
                                <div className="row">
                                  <button
                                      type="submit"
                                      id="submit"
                                      className="btn btn-primary btn-block"
                                      disabled={isSubmitting}
                                  >
                                    Submit
                                  </button>
                                </div>
                              </Form>
                          )}
                        </Formik>
                      </div>
                  ) : (
                      <>
                        {messages?.map((message, index) =>
                            message.user === 'me' ? (
                                <MathJax key={index} className='m-4 d-flex align-items-center justify-content-end'>
                                  <ChatBubbleMe chat={message} theme={defaultTheme}
                                                defaultThemeStyles={defaultThemeStyles}/>
                                </MathJax>
                            ) : (
                                <MathJax key={index} className='m-4 d-flex align-items-center justify-content-start'>
                                  <ChatBubbleBot chat={message} theme={defaultTheme}
                                                 defaultThemeStyles={defaultThemeStyles}/>
                                </MathJax>
                            )
                        )}
                        {loading && (
                            <div className='m-4 d-flex align-items-center justify-content-start p-2'
                                 style={{
                                   backgroundColor: defaultTheme?.chatBot.primaryColor || defaultThemeStyles.secondaryColor,
                                   color: defaultTheme?.chatBot.secondaryColor || defaultThemeStyles.secondaryColor,
                                   borderRadius: '0 15px 15px 15px',
                                   width: '55px',
                                 }}>
                              <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  style={{
                                    margin: 'auto',
                                    display: 'block',
                                    shapeRendering: 'auto',
                                    width: '43px',
                                    height: '20px'
                                  }}
                                  viewBox="0 0 100 100"
                                  preserveAspectRatio="xMidYMid"
                              >
                                <circle cx="0" cy="44.1678" r="15" fill="#7A7A7A">
                                  <animate
                                      attributeName="cy"
                                      calcMode="spline"
                                      keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5"
                                      repeatCount="indefinite"
                                      values="57.5;42.5;57.5;57.5"
                                      keyTimes="0;0.3;0.6;1"
                                      dur="1s"
                                      begin="-0.6s"
                                  ></animate>
                                </circle>
                                <circle cx="45" cy="43.0965" r="15" fill="#7A7A7A">
                                  <animate
                                      attributeName="cy"
                                      calcMode="spline"
                                      keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5"
                                      repeatCount="indefinite"
                                      values="57.5;42.5;57.5;57.5"
                                      keyTimes="0;0.3;0.6;1"
                                      dur="1s"
                                      begin="-0.39999999999999997s"
                                  ></animate>
                                </circle>
                                <circle cx="90" cy="52.0442" r="15" fill="#7A7A7A">
                                  <animate
                                      attributeName="cy"
                                      calcMode="spline"
                                      keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5"
                                      repeatCount="indefinite"
                                      values="57.5;42.5;57.5;57.5"
                                      keyTimes="0;0.3;0.6;1"
                                      dur="1s"
                                      begin="-0.19999999999999998s"
                                  ></animate>
                                </circle>
                              </svg>
                            </div>
                        )}
                      </>
                  )}
                </div>

                :
                <div className="chat-body" ref={chatBodyRef} style={{height: '83vh', overflowY: 'auto'}}>
                  <span>{chatBotData?.message === 'Upload documents' ? (
                      <EmptyMessage
                          type="doc"
                          title="No Document Uploaded!"
                          description="Please upload a document to start chatting with the AI. Without an uploaded document, AI conversation on related content is unavailable."
                          buttonText="Upload Document"
                          buttonCallback={() => {
                            // Add your callback logic here, if needed
                          }}
                          noButton={true}
                      />
                  ):(
                      chatBotData?.message
                  )}</span>
                </div>
            }
          </div>
          <div style={{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            // backgroundColor: '#efefef'
            backgroundColor: 'rgb(245 247 251)',
            borderRadius: '10px'
          }}>
            <div style={{
              backgroundColor: '#fff',
              padding: 15,
              margin: '10px 5px',
              borderRadius: 10,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
              <input
                  style={{
                    width: '95%',
                    border: '0px solid gray',
                    outline: 'none', // Disable the focus border
                  }}
                  type="text"
                  id="messageInput"
                  autoComplete="off"
                  onKeyDown={(event) => {
                    if (chatBotData?.status) {
                      if (event.key === 'Enter') {
                        const messageInput = document.getElementById('messageInput') as HTMLInputElement;
                        if (messageInput.value.trim()) {
                          sendMessage(messageInput.value, sessionId);
                          messageInput.value = '';
                        }
                      }
                    }
                  }}
                  placeholder="Type a message..."
              />
              <div
                  className="input-action-icon link-primary"
                  onClick={() => {

                    if (chatBotData?.status) {
                      const messageInput = document.getElementById('messageInput') as HTMLInputElement;
                      if (messageInput.value.trim()) {
                        sendMessage(messageInput.value, sessionId);
                        messageInput.value = '';
                      }
                    }

                  }}
              >
                <a>
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-send"
                  >
                    <line x1="22" y1="2" x2="11" y2="13"></line>
                    <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>


        {/*<div className={`brainaque-chat-message-container`}>*/}
        {/*  <div className={`chat-screen show-chat`}*/}
        {/*       style={{width: '100%', minWidth: '100%', right: '0px', bottom: '0px', top: '0px', position: 'relative'}}>*/}


        {/*    <div className="chat-input">*/}
        {/*      <input*/}
        {/*          type="text"*/}
        {/*          id="messageInput"*/}
        {/*          autoComplete="off"*/}
        {/*          onKeyDown={(event) => {*/}
        {/*            if (chatBotData?.status) {*/}
        {/*              if (event.key === 'Enter') {*/}
        {/*                const messageInput = document.getElementById('messageInput') as HTMLInputElement;*/}
        {/*                if (messageInput.value.trim()) {*/}
        {/*                  sendMessage(messageInput.value, sessionId);*/}
        {/*                  messageInput.value = '';*/}
        {/*                }*/}
        {/*              }*/}
        {/*            }*/}


        {/*          }}*/}
        {/*          placeholder="Type a message..."*/}
        {/*      />*/}
        {/*      <div*/}
        {/*          className="input-action-icon"*/}
        {/*          onClick={() => {*/}

        {/*            if (chatBotData?.status) {*/}
        {/*              const messageInput = document.getElementById('messageInput') as HTMLInputElement;*/}
        {/*              if (messageInput.value.trim()) {*/}
        {/*                sendMessage(messageInput.value, sessionId);*/}
        {/*                messageInput.value = '';*/}
        {/*              }*/}
        {/*            }*/}

        {/*          }}*/}
        {/*      >*/}
        {/*        <a>*/}
        {/*          <svg*/}
        {/*              xmlns="http://www.w3.org/2000/svg"*/}
        {/*              width="24"*/}
        {/*              height="24"*/}
        {/*              viewBox="0 0 24 24"*/}
        {/*              fill="none"*/}
        {/*              stroke="currentColor"*/}
        {/*              strokeWidth="2"*/}
        {/*              strokeLinecap="round"*/}
        {/*              strokeLinejoin="round"*/}
        {/*              className="feather feather-send"*/}
        {/*          >*/}
        {/*            <line x1="22" y1="2" x2="11" y2="13"></line>*/}
        {/*            <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>*/}
        {/*          </svg>*/}
        {/*        </a>*/}
        {/*      </div>*/}
        {/*    </div>*/}

        {/*    <div className="chat-session-end hide">*/}
        {/*      <h5>This chat session has ended</h5>*/}
        {/*      <p>Thank you for chatting with us, If you can take a minute and rate this chat:</p>*/}
        {/*      <div className="rate-me">*/}
        {/*        <div className="rate-bubble great">*/}
        {/*        <span>*/}
        {/*          <svg*/}
        {/*              xmlns="http://www.w3.org/2000/svg"*/}
        {/*              width="20"*/}
        {/*              height="20"*/}
        {/*              viewBox="0 0 24 24"*/}
        {/*              fill="none"*/}
        {/*              stroke="#ffffff"*/}
        {/*              strokeWidth="2"*/}
        {/*              strokeLinecap="round"*/}
        {/*              strokeLinejoin="round"*/}
        {/*              className="feather feather-thumbs-up"*/}
        {/*          >*/}
        {/*            <path*/}
        {/*                d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3"></path>*/}
        {/*          </svg>*/}
        {/*        </span>*/}
        {/*          Great*/}
        {/*        </div>*/}
        {/*        <div className="rate-bubble bad">*/}
        {/*        <span>*/}
        {/*          <svg*/}
        {/*              xmlns="http://www.w3.org/2000/svg"*/}
        {/*              width="20"*/}
        {/*              height="20"*/}
        {/*              viewBox="0 0 24 24"*/}
        {/*              fill="none"*/}
        {/*              stroke="#ffffff"*/}
        {/*              strokeWidth="2"*/}
        {/*              strokeLinecap="round"*/}
        {/*              strokeLinejoin="round"*/}
        {/*              className="feather feather-thumbs-down"*/}
        {/*          >*/}
        {/*            <path*/}
        {/*                d="M10 15v4a3 3 0 0 0 3 3l4-9V2H5.72a2 2 0 0 0-2 1.7l-1.38 9a2 2 0 0 0 2 2.3zm7-13h2.67A2.31 2.31 0 0 1 22 4v7a2.31 2.31 0 0 1-2.33 2H17"></path>*/}
        {/*          </svg>*/}
        {/*        </span>*/}
        {/*          Bad*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <a className="transcript-chat">Need a Transcript?</a>*/}
        {/*      <div className="powered-by">Powered by Brainaque</div>*/}
        {/*    </div>*/}
        {/*  </div>*/}



        {/*</div>*/}
      </MathJaxContext>
  );
};

import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Modal, Option, Picklist, CheckboxToggle, MultiSelect } from 'react-rainbow-components';
import FormInput from '../../../components/form/FormInput';
import FormButton from '../../../components/form/bottons/FormButton';
import { IDocument, IGridItem } from "../../../../interfaces/shop.interface";

interface GridItemFormProps {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
    onSave: (item: IGridItem) => void;
    itemCount: number;
    shopDocuments: IDocument[];
    documents: IDocument[];
    isChatInGrid?: boolean;
    selectedGridItem: IGridItem;
}

const GridItemForm: React.FC<GridItemFormProps> = ({selectedGridItem, showModal, setShowModal, onSave, itemCount, shopDocuments, documents , isChatInGrid}) => {
    console.log('selectedGridItem', selectedGridItem)
    const validationSchema = Yup.object({
        name: Yup.string().required('Name is required'),
        gridType: Yup.object({
            label: Yup.string().required('Grid Type is required'),
            name: Yup.string().required('Grid Type is required'),
        }).nullable().required('Grid Type is required'),
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            selectedDocuments: [],
            selectedChatDocuments: [],
            gridType: { label: 'Image', name: 'image' },
            loop: false,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const newItem: IGridItem = {
                i: (itemCount + 1).toString(),
                x: 0,
                y: 0,
                w: 2,
                h: 2,
                name: values.name,
                selectedDocuments: values.selectedDocuments,
                gridType: values.gridType,
                loop: values.loop,
            };
            onSave(newItem);
            setShowModal(false); // Close the modal after saving
            formik.resetForm(); // Reset the form for the next creation
        },
    });

    useEffect(() => {
        // Reset selectedDocuments when gridType changes
        formik.setFieldValue('selectedDocuments', []);
    }, [formik.values.gridType]);

    return (
        <Modal
            id="modal-add-grid-item"
            title="Create Grid Item"
            isOpen={showModal}
            onRequestClose={() => setShowModal(false)}
            footer={
                <div className="d-flex justify-content-end">
                    <FormButton type="submit" text="Create" onClick={formik.handleSubmit} />
                </div>
            }
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="row gy-3">
                    <div className="col-12">
                        <FormInput
                            label="Name"
                            name="name"
                            type="text"
                            placeholder="Enter Item Name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.name}
                        />
                    </div>
                    <div className="col-12">
                        <Picklist
                            label="Grid type"
                            labelAlignment={`left`}
                            name="gridType"
                            value={formik.values.gridType}
                            onChange={(option) => formik.setFieldValue('gridType', option)}
                        >
                            <Option name="image" label="Image" />
                            <Option name="video" label="Video" />
                            {!isChatInGrid && (
                                <Option name="chat" label="Chat" />
                            )}
                        </Picklist>
                    </div>
                    <div className="col-12">
                        {formik.values.gridType.name == 'chat' && (
                            <MultiSelect
                                label="Select Documents"
                                placeholder="Select Documents"
                                className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
                                value={formik.values.selectedDocuments}
                                onChange={(option) => formik.setFieldValue('selectedDocuments', option)}
                                showCheckbox
                                borderRadius="semi-square"
                                variant="chip"
                            >
                                {documents.map((document) => (
                                    <Option key={document._id} name={document._id} value={document._id} label={document.name} />
                                ))}
                            </MultiSelect>
                        )}
                        {formik.values.gridType.name == 'image' && (
                            <MultiSelect
                                label="Select Documents"
                                placeholder="Select one or more documents"
                                className="mb-3"
                                value={formik.values.selectedDocuments}
                                onChange={(value) => formik.setFieldValue('selectedDocuments', value)}
                                showCheckbox
                                variant="chip"
                            >
                                {shopDocuments
                                    .filter(doc => formik.values.gridType.name === 'image'
                                        ? ['png', 'jpg', 'jpeg'].includes(doc.extension)
                                        : ['mp4', 'mov'].includes(doc.extension))
                                    .map(doc => (
                                        <Option key={doc._id} name={doc._id} value={doc.signedUrl} label={doc.name} />
                                    ))}
                            </MultiSelect>
                        )}

                        {formik.values.gridType.name == 'video' && (
                            <Picklist
                                id="picklist-1"
                                onChange={(value) => formik.setFieldValue('selectedDocuments', [value])}
                                value={formik.values.selectedDocuments[0]}
                                label="Select Building"
                                hideLabel
                            >
                                {shopDocuments
                                    .filter(doc => formik.values.gridType.name === 'image'
                                        ? ['png', 'jpg', 'jpeg'].includes(doc.extension)
                                        : ['mp4', 'mov'].includes(doc.extension))
                                    .map(doc => (
                                        <Option key={doc._id} name={doc._id} value={doc.signedUrl} label={doc.name} />
                                    ))}
                            </Picklist>
                        )}



                    </div>
                    {/*<div className="col-12">*/}
                    {/*    <CheckboxToggle*/}
                    {/*        label="Loop"*/}
                    {/*        name="loop"*/}
                    {/*        value={formik.values.loop}*/}
                    {/*        onChange={(e) => formik.setFieldValue('loop', e.target.checked)}*/}
                    {/*    />*/}
                    {/*</div>*/}
                </div>
            </form>
        </Modal>
    );
};

export default GridItemForm;

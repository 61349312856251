import React, {FC, useEffect, useState} from 'react';
import PricingPlans from "./PricingPlans";

const PricingPlanContainer: FC = () => {




    return (
        <div className="nk-content">
            <div className="container-xl">
                <div className="nk-content-inner">
                    <div className="nk-content-body">
                        <div className="nk-block-head nk-page-head">
                            <div className="nk-block-head-between">
                                <div className="nk-block-head-content">
                                    <h2 className="display-6">Pricing Plans</h2>
                                    <p>Generate unlimited copy 10X faster with our cost effective plan to write blog
                                        posts, social media ads and many more.</p>
                                </div>
                            </div>
                        </div>


                        <PricingPlans />
                    </div>
                </div>
            </div>


        </div>
    );
};
export default PricingPlanContainer;

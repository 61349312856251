// src/slices/userSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUser } from '../../interfaces/user.interface';

interface UserState {
    user: IUser | null;
    loading: boolean;
    error: string | null;
}

const initialState: UserState = {
    user: null,
    loading: false,
    error: null,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser(state, { payload }: PayloadAction<IUser | null>) {
            state.user = payload;
        },
        createUserStart(state) {
            state.loading = true;
            state.error = null;
        },
        createUserSuccess(state) {
            state.loading = false;
            state.error = null;
        },
        createUserFailure(state, action: PayloadAction<string>) {
            state.error = action.payload;
            state.loading = false;
        },
        emailValidationUserStart(state) {
            state.loading = true;
            state.error = null;
        },
        emailValidationUserSuccess(state) {
            state.loading = false;
            state.error = null;
        },
        emailValidationUserFailure(state, action: PayloadAction<string>) {
            state.error = action.payload;
            state.loading = false;
        },
        fetchUserStart(state) {
            state.loading = true;
            state.error = null;
        },
        fetchUserSuccess(state, action: PayloadAction<IUser>) {
            state.user = action.payload;
            state.loading = false;
        },
        fetchUserFailure(state, action: PayloadAction<string>) {
            state.error = action.payload;
            state.loading = false;
        },
        updateUserSuccess(state, action: PayloadAction<IUser>) {
            state.user = action.payload;
            localStorage.setItem('user', JSON.stringify(action.payload))

        },
        updateUserFailure(state, action: PayloadAction<string>) {
            state.error = action.payload;
        },
    },
});

export const {
    setUser,
    createUserStart,
    createUserSuccess,
    createUserFailure,
    emailValidationUserStart,
    emailValidationUserSuccess,
    emailValidationUserFailure,
    fetchUserStart,
    fetchUserSuccess,
    fetchUserFailure,
    updateUserSuccess,
    updateUserFailure,
} = userSlice.actions;
export default userSlice.reducer;

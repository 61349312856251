import React, {FC, useEffect, useRef, useState} from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/rootReducer';
import FormButton from '../../../components/form/bottons/FormButton';
import EmptyMessage from '../../../components/empty/EmptyMessage';
import GroupForm from '../modals/GroupForm';
import {useNavigate, useParams} from "react-router-dom";
import {IGroup, IMember} from "../../../../interfaces/organization.interface";
import {
    ButtonMenu,
    MenuItem,
    Modal,
    RenderIf,
    Sidebar,
    SidebarItem, VerticalItem,
    VerticalNavigation, VerticalSection
} from "react-rainbow-components";
import {IDocument} from "../../../../interfaces/shop.interface";
import OrganizationGroupViewMore from "./group/OrganizationGroupViewMore";
import styled from "styled-components";

interface OrganizationGroupsInterface {
    initialActiveGroup?: IGroup;
}

interface FileUploadHandle {
    uploadFiles: () => Promise<void>;
}


const OrganizationGroups: React.FC<OrganizationGroupsInterface> = ({initialActiveGroup}) => {

    const navigate = useNavigate();
    const { groupId, _groupPage } = useParams();

    const { organization, loading, error } = useSelector((state: RootState) => state.organizations);

    const [activeGroup, setActiveGroup] = useState<IGroup>();


    const [showDocumentModal, setShowDocumentModal] = useState(false);
    const [fileCount, setFileCount] = useState(0);
    const [manageDocumentModal, setManageDocumentModal] = useState('');
    const [selectedDocument, setSelectedDocument] = useState<IDocument>();

    const [showGroupForm, setShowGroupForm] = useState(false);

    const fileUploadRef = useRef<FileUploadHandle>(null);

    const handleUpload = () => {
        if (fileUploadRef.current) {
            fileUploadRef?.current?.uploadFiles();
        }
    };

    const [selectedItem, setSelectedItem] = useState('');

    const handleOnSelect = ({event, value}: any) => {
        return setSelectedItem(value);
    };

    useEffect(() => {
        if (initialActiveGroup){
            setActiveGroup(initialActiveGroup);
            if(_groupPage){
                navigate(`/organization/${organization?._id}/groups/${initialActiveGroup._id}/${_groupPage}`)

            }else{
                navigate(`/organization/${organization?._id}/groups/${initialActiveGroup._id}`)

            }

        }else {
            setActiveGroup(undefined);
        }
    }, [initialActiveGroup]);

    useEffect(() => {
        if (groupId && organization) {
            setActiveGroup(organization.groups.find((group)=> group._id === groupId));
        }
    }, [groupId, organization]);

    return (
        <>
            <div className="d-flex align-items-center justify-content-between my-2">
                <h5>Groups</h5>
                <button className="btn btn-sm btn-primary" onClick={() => setShowGroupForm(true)}>
                    <em className="icon ni ni-plus"></em><span>Create Group</span>
                </button>
            </div>

            {organization && organization?.groups.length > 0 ? (
                <div className='card d-flex flex-row align-items-start justify-content-start '>
                    <>
                        <div style={{
                            width: '25%',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            borderRadius: '8px',
                            height: '53vh',
                            overflowY: 'auto',
                        }}>
                            <div className='d-flex flex-column align-items-start justify-content-start py-2'>
                                {organization && organization?.groups.length > 0 && (
                                    organization?.groups?.map((group, index: number) => (
                                        <div
                                            key={`${index}_${group?._id}`}
                                            className={`fs-5 group-item ${activeGroup?._id?.toString() === group._id.toString() ? 'active' : ''}`}
                                            onClick={() => {
                                                setActiveGroup(group);
                                                navigate(`/organization/${organization._id}/groups/${group._id}`);
                                            }}
                                        >
                                            {`${group?.groupName}`}
                                        </div>
                                    ))
                                )}
                            </div>


                        </div>
                        {activeGroup ? (

                            <OrganizationGroupViewMore setActiveGroup={setActiveGroup} activeGroup={activeGroup}/>
                        ) : (
                            <div className='d-flex flex-column justify-content-center align-items-center mt-5 w-100'>
                                <div className='d-flex flex-column justify-content-center align-items-center'>
                                    <div className='fs-1 fw-bold'>Select A Group</div>
                                    <div className='fs-5'>You can choose Group which is Show group Details</div>
                                </div>
                            </div>
                        )}
                    </>
                </div>
            ) : (
                <EmptyMessage
                    type='organization'
                    title="No Groups Available."
                    description="It seems there are no groups to display at the moment. You can create a group by either clicking the button below or the one in the top right corner."
                    buttonText="Create Group"
                    buttonCallback={() => {
                        setShowGroupForm(true)
                    }}
                />
            )}
            {/* Group Form Modal */}
            <GroupForm showModal={showGroupForm} setShowModal={(e: boolean) => setShowGroupForm(e)}/>
        </>
    );
};

export default OrganizationGroups;


import {Button, Input, Modal} from "react-rainbow-components";
import React, {useEffect, useState} from "react";
import {IGroup, IPublicMemberGroup} from "../../../../../interfaces/organization.interface";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../../redux/rootReducer";
import {base_url, public_organizations_url} from "../../../../../constants/contants";
import {toastMessage} from "../../../../../utils/util";
import axios from "axios";
import {
    updatePublicGroupMemberFailure,
    updatePublicGroupMemberStart,
    updatePublicGroupMemberSuccess
} from "../../../../../redux/slices/publicOrganizationSlice";

interface GroupMemberManageInterface {
    showModal: boolean;
    setShowModal: (value: boolean)=> void;
    groupId: string;
    setActiveGroup?: (value: IGroup)=> void;
}

interface IOption {
    value: string;
    label: string;
    disabled?: boolean;
}

const PublicExistingMemberManage: React.FC<GroupMemberManageInterface> = ({showModal, setShowModal, groupId, setActiveGroup}) => {

    const {publicOrganization} = useSelector((state: RootState) => state.publicOrganization);
    const {organizationMember} = useSelector((state: RootState) => state.organizations);

    const dispatch = useDispatch();

    console.log('publicOrganization', publicOrganization)
    console.log('publicOrganization', publicOrganization?.memberGroups[0]?.groupMembers)
    const [values, setValues] = useState<IOption[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [selectedMembersId, setSelectedMembersId] = useState<string[]>([]);
    const [group, setGroup] = useState<IPublicMemberGroup | undefined>(undefined);

    useEffect(() => {
        if (publicOrganization) {
            const filteredGroup = publicOrganization?.memberGroups?.find((g) => g._id === groupId);
            setGroup(filteredGroup);

            const initialMemberIds = filteredGroup?.groupMembers?.map((groupMember) => groupMember?._id);

            if (initialMemberIds) {
                setSelectedMembersId(initialMemberIds);
            }
        }
        console.log('useEffect publicOrganization', publicOrganization?.memberGroups[0]?.groupMembers)
    }, [publicOrganization, groupId]);

    // This function is used to define what happens when the user clicks item.
    const clickItem = (memberId: string) => {
        const alreadySelected = selectedMembersId?.includes(memberId);

        if(alreadySelected){
            let previousMemberIds = selectedMembersId?.filter((prevMemberId) => prevMemberId !== memberId);
            setSelectedMembersId(previousMemberIds);
        }else {
            let previousMemberIds = [...selectedMembersId];
            previousMemberIds?.push(memberId);
            setSelectedMembersId(previousMemberIds)
        }
    }

    const findRole = (roleId: string) => {
        const role = publicOrganization?.roles.find((role) => role?._id === roleId);
        return role?.name || 'Unknown Role'; // Provide fallback if role is not found
    };

    // This function is used to define what happens when the user clicks update button.
    const updateMembers = async () => {
        dispatch(updatePublicGroupMemberStart());
        try {
            if (publicOrganization) {
                let newMemberDetails = selectedMembersId?.map((memberId) => publicOrganization?.organizationMembers?.find((member) => member?._id === memberId));
                const response: any = await axios.put<IGroup>(
                    `${base_url}${public_organizations_url}/${publicOrganization._id}/groups/${group?._id}`,
                    {
                        groupName: group?.groupName,
                        groupMembers: newMemberDetails,
                        groupDocuments: group?.groupDocuments,
                        loginRequired: true,
                        showDocuments: group?.showDocuments,
                        showMembers: group?.showMembers,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${organizationMember?.member.token}`,
                        },
                    }
                );

                console.log('response', response);

                dispatch(updatePublicGroupMemberSuccess({
                    group: response.data.group,
                }));
                if (response?.data?.group) {
                    const existingGroup: IGroup | null = response?.data?.group || null;

                    if (setActiveGroup && existingGroup) {
                        // Merge existingGroup with newData
                        const newData = {
                            ...existingGroup, // Include all fields from the existing group
                            ...response.data.group, // Overwrite with the new group data
                            // Ensure that fields like `memberChats`, `questions`, etc. are still present
                            memberChats: response.data.group.memberChats ?? existingGroup.memberChats ?? [],
                            questions: response.data.group.questions ?? existingGroup.questions ?? [],
                            summaries: response.data.group.summaries ?? existingGroup.summaries ?? [],
                            tags: response.data.group.tags ?? existingGroup.tags ?? []
                        };

                        setActiveGroup(newData);
                    }
                }
                toastMessage('Group Updated successfully', 'success');
            }
        } catch (error: any) {
            dispatch(updatePublicGroupMemberFailure(error.message));
            toastMessage('Failed to update group', 'error');
        }finally {
            setShowModal(false);
        }
    }

    return(
        <>
            <Modal
                id="modal-add-member"
                title={"Manage Members"}
                isOpen={showModal}
                onRequestClose={() => {
                    setShowModal(false);
                }}
                footer={
                    <div className="d-flex justify-content-end">
                        <Button disabled={!group?.groupMembers.length && !selectedMembersId.length} onClick={updateMembers} label="Update" size={`small`} variant="brand" className="rainbow-m-around_medium"
                                style={{borderRadius: 5}}/>
                    </div>
                }
            >
                <div>
                    <>
                        <Input
                            id="lookup-1"
                            labelAlignment={'left'}
                            label="Search Members"
                            onChange={(e)=> setSearchQuery(e.target.value)}
                            value={searchQuery}
                            placeholder="Find Members"
                            borderRadius="semi-square"
                            className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
                        />
                            {publicOrganization?.organizationMembers
                                ?.filter((member)=>
                                member?.firstname?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                                member?.lastname?.toLowerCase().includes(searchQuery.toLowerCase())
                            ).map((member, index) => (
                                <div role='button' className='mt-3 p-1 hover' onClick={()=> clickItem(member?._id)} key={`organization-members_${index}`} style={{borderRadius: 5}}>
                                    <div className='d-flex align-items-start gap-1'>
                                        <input type="checkbox"
                                               checked={selectedMembersId?.includes(member._id)}
                                               // onChange={(e) => setShowModal(e.target.checked)}
                                               style={{transform: 'scale(1.5)', margin: '10px'}}/>
                                        <div className='d-flex flex-column gap-0'>
                                            <div style={{marginBottom: -5}}>{member.firstname} {member.lastname}</div>
                                            <div className='fs-6' style={{color: '#a3a3a3'}}>{findRole(member.role)}</div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </>
                </div>
            </Modal>
        </>
    )
};

export default PublicExistingMemberManage;
import React, { FC, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import OrbAnimation from "../../../components/audioVisualizer/OrbAnimation";
import http from "../../../../services/api";
import {IDisplay} from "../../../../interfaces/shop.interface";
import {shop_display_chat_url, shops_url} from "../../../../constants/contants";
import axios from "axios";
import GridLayout from "react-grid-layout";
import {CarouselCard, CarouselImage} from "react-rainbow-components";

const PublicShopDisplay: FC = () => {
    const { id, displayId, apiKey } = useParams();
    const [botResponse, setBotResponse] = useState<{message:string}>();
    const [showModal, setShowModal] = useState(false);
    const [screenOrientation, setScreenOrientation] = useState<any>('horizontal');

    const [chatBotData, setChatBotData] = useState<any>();


    const { transcript, interimTranscript, finalTranscript, resetTranscript } = useSpeechRecognition();
    const [recording, setRecording] = useState(true);
    const [showAnimation, setShowAnimation] = useState(false);
    const [silenceTimeout, setSilenceTimeout] = useState<NodeJS.Timeout | null>(null);
    const [display, setDisplay] = useState<IDisplay>();
    const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
    const [deviceHeight, setDeviceHeight] = useState(window.innerHeight);

    // Update width and height on window resize
    useEffect(() => {
        const handleResize = () => {
            setDeviceWidth(window.innerWidth);
            setDeviceHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const fetchDisplayDetails = async () => {
        try {
            const response = await http.get(`${shops_url}/${id}/displays/${displayId}/${apiKey}`);
            if (response.status === 200){
                const {data} = response;
                setDisplay(data.display);

                setScreenOrientation(data.display.displayType);
            }
        }catch (error){
            console.log('error', error);
        }

    };

    useEffect(() => {
        if(apiKey){
            localStorage.setItem('apiKey',apiKey);
        }if (displayId){
            fetchDisplayDetails();
        }
    }, [displayId]);

    useEffect(() => {
        if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
            console.error("This browser doesn't support speech recognition.");
            return;
        }

        if (recording) {
            SpeechRecognition.startListening({ continuous: true });
        } else {
            SpeechRecognition.stopListening();
        }

        return () => {
            SpeechRecognition.stopListening();
        };
    }, [recording]);

    useEffect(() => {
        if (finalTranscript) {
            setShowAnimation(true)
            // Reset the silence timer if there's a new final transcript
            if (silenceTimeout) {
                clearTimeout(silenceTimeout);
            }

            // Start a new timer for 5 seconds
            setSilenceTimeout(setTimeout(() => {
                setShowAnimation(false);
            }, 5000));
        }
    }, [finalTranscript]);

// Function to check the API key
    const checkApiKey = async () => {
        try {


            const apiKey = localStorage.getItem('apiKey');

            const response = await http.post<any>(`${shops_url}/validate-bot`, {
                apiKey: apiKey,
                displayId: displayId,
                localSessionId: '',
            });

            if(response.data.status === true){
                setChatBotData(response.data);
            }else {

                localStorage.removeItem('apiKey');
                setShowModal(true)
            }

        } catch (error) {
            localStorage.removeItem('apiKey');
            setShowModal(true)
            return false;
        }
    }

// Function to check the API key
    const postChat = async (query:string) => {
        try {

            const apiKey = localStorage.getItem('apiKey');

            const response = await axios.post(shop_display_chat_url, {"chatQuery":query,"apiKey":apiKey,"sessionId":chatBotData.sessionId,displayId});

            const utterance = new SpeechSynthesisUtterance(response.data.message);
            speechSynthesis.speak(utterance);

            setBotResponse(response.data);
            resetTranscript()

        } catch (error) {
            console.error('Error checking API key:', error);
            return false;
        }
    }

/*
    useEffect(() => {

        let apiKey = localStorage.getItem("apiKey");

        if(!apiKey){

            setShowModal(true)
        }else{
            checkApiKey()

        }
    }, []);
*/


    useEffect(() => {
        if(finalTranscript){

            setBotResponse(undefined)
            postChat(finalTranscript)

        }
    }, [finalTranscript]);

    useEffect(() => {
        setBotResponse(undefined)

    }, [interimTranscript]);

    console.log('display', display)

    const getScreenDimensions = () => {
        return screenOrientation === 'vertical'
            ? { width: 270, height: 480, cols: 9, rowHeight: 30 }
            : { width: 480, height: 270, cols: 16, rowHeight: 38.5 };
    };
    const { width, height, cols, rowHeight } = getScreenDimensions();

    return (
        <div className='d-block justify-content-center align-items-center overflow-hidden '>
            <GridLayout
                className="layout"
                margin={[0, 0]}  // Set margin to [0, 0] to remove space between grid items
                containerPadding={[0, 0]}  // Optionally remove padding in the grid container
                layout={display?.gridItems.map(item => ({
                    i: item.i,
                    x: item.x,
                    y: item.y,
                    w: item.w,
                    h: item.h
                }))}
                cols={cols}
                width={deviceWidth}
                rowHeight={screenOrientation === 'vertical'? 112.4: rowHeight}
                maxRows={Math.floor(height / rowHeight)}
                preventCollision={false}
                compactType={null}
                isDraggable={false}
                isResizable={false}
            >
                {display?.gridItems?.map(item => {
                    const isImage = item.gridType.name === 'image' && item.selectedDocuments.length > 0;
                    const isVideo = item.gridType.name === 'video' && item.selectedDocuments.length > 0;
                    const isChat = item.gridType.name === 'chat' && item.selectedDocuments.length > 0;

                    return (
                        <div
                            key={item.i}
                            className="grid-item border"
                            style={{
                                width: "100%",
                                height: "100%",
                                position: "relative",
                                overflow: "hidden",
                            }}
                        >
                            {isChat && (
                                <div className="w-100 h-100 d-flex justify-content-center align-items-center" style={{backgroundColor: '#fff'}}>
                                    <OrbAnimation
                                        isRecording={showAnimation}
                                        message={
                                            botResponse?.message ||
                                            interimTranscript ||
                                            transcript ||
                                            'Ask me something'
                                        }
                                    />
                                </div>
                            )}
                            {isImage && (
                                <CarouselCard
                                    className="carousel-card"
                                    id="carousel-1"

                                >
                                    {item.selectedDocuments.map((selectedDocument) =>
                                        <CarouselImage
                                            src={selectedDocument.signedUrl}
                                        />
                                    )}


                                </CarouselCard>
                            )}

                            {isVideo && (
                                <video
                                    src={item.selectedDocuments[0].signedUrl}
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                    }}
                                    muted
                                    loop
                                    autoPlay
                                    playsInline
                                >
                                    Your browser does not support the video tag.
                                </video>
                            )}
                        </div>
                    );
                })}

            </GridLayout>

            {/*<div className="nk-content">*/}


            {/*    /!*{display && display?.gridItems?.length > 0 && display.gridItems.map((grid, index) => {*!/*/}
            {/*    /!*    if (grid?.gridType?.name === 'chat') {*!/*/}
            {/*    /!*        return (*!/*/}
            {/*                <div className="container-xl" key={`${index}`}>*/}
            {/*                    <OrbAnimation*/}
            {/*                        isRecording={showAnimation}*/}
            {/*                        message={*/}
            {/*                            botResponse?.message ||*/}
            {/*                            interimTranscript ||*/}
            {/*                            transcript ||*/}
            {/*                            'Ask me something'*/}
            {/*                        }*/}
            {/*                    />*/}
            {/*                </div>*/}
            {/*    /!*        );*!/*/}
            {/*    /!*    }*!/*/}
            {/*    /!*    return null;*!/*/}
            {/*    /!*})}*!/*/}

            {/*    /!*<PublicShopAPIKey showModal={showModal} setShowModal={(e: boolean) => {*!/*/}
            {/*    /!*    checkApiKey()*!/*/}
            {/*    /!*    setShowModal(e)*!/*/}
            {/*    /!*}}/>*!/*/}

            {/*</div>*/}
        </div>

    );
};

export default PublicShopDisplay;

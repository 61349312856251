import React, { FC } from 'react';
import { Badge, Breadcrumb, Breadcrumbs, Chart, Dataset } from "react-rainbow-components";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/rootReducer";
import { IOrganizationChats } from "../../../../interfaces/organizationchat.interface";

const OrganizationDashboard: FC = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const { organization, loading, error } = useSelector((state: RootState) => state.organizations);
    const { organizationChats } = useSelector((state: RootState) => state.organizationChats);

    // Filter chats based on the createdAt date
    const filteredChats = organizationChats.filter((chat: IOrganizationChats) => new Date(chat.createdAt).getFullYear() === new Date().getFullYear());

    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const values = Array(12).fill(0);

    filteredChats.forEach((chat: IOrganizationChats) => {
        const month = new Date(chat.createdAt).getMonth();
        values[month] += chat.chats.length;
    });

    const BarChartExample = () => (
        <div className="rainbow-p-vertical_large">
            <div className="rainbow-align-content_center rainbow-m-vertical_large rainbow-m_auto">
                <Chart labels={labels} type="bar">
                    <Dataset key="Chats" title="Chats" values={values} backgroundColor="#01b6f5" />
                </Chart>
            </div>
        </div>
    );

    // Aggregate chat data by country
    const chatsByCountry: Record<string, number> = filteredChats.reduce((acc: any, chat) => {
        const country = chat.countryName || 'Unknown';
        if (!acc[country]) {
            acc[country] = 0;
        }
        acc[country] += 1;
        return acc;
    }, {});

    // Prepare pie chart data
    const pieChartData = Object.keys(chatsByCountry).map(country => ({
        label: country,
        value: chatsByCountry[country],
        color: '#' + Math.floor(Math.random() * 16777215).toString(16)
    }));


    const PieChartExample: FC = () => (
        <div className="rainbow-p-vertical_xx-large rainbow-p-horizontal_medium">
            <div className="rainbow-align-content_center rainbow-m-vertical_large rainbow-m_auto">
                {pieChartData.length > 0 ? (
                    <Chart labels={pieChartData.map(data => data.label)} type="pie" legendPosition="right">
                        <Dataset
                            title="Chats by Country"
                            values={pieChartData.map(data => data.value)}
                            backgroundColor={pieChartData.map(data => data.color)}
                        />
                    </Chart>
                ): (
                    <div className='d-flex flex-column align-items-center justify-content-center mt-5 w-90 mx-auto'>
                        <h2>No Data Available</h2>
                        <div>
                            <p className='text-center'>It seems there is no data to display at the moment. Please check back later or try
                                refreshing the page.</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );

    return (
        <div className="tab-pane fade show active" id="profile-tab-pane">
            <div className="d-flex align-items-center justify-content-between my-2">
                <h5>Overview</h5>
                {/*<button type="button" className="btn btn-xs btn-icon btn-primary rounded-pill" data-bs-dismiss="alert">*/}
                {/*    <em className="icon ni ni-calendar"></em>*/}
                {/*</button>*/}
            </div>
            <div className="nk-block">
                <div className="row g-gs">
                    <div className="col-sm-6 col-xxl-3">
                        <div className="card card-full bg-purple bg-opacity-10 border-0">
                            <div className="card-body">
                                <div className="d-flex align-items-center justify-content-between mb-1">
                                    <div className="fs-6 text-light mb-0">Tokens Available</div>
                                </div>
                                <h5 className="fs-1">{organization?.organizationAiToken} <small className="fs-3">Tokens</small></h5>

                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xxl-3">
                        <div className="card card-full bg-indigo bg-opacity-10 border-0">
                            <div className="card-body">
                                <div className="d-flex align-items-center justify-content-between mb-1">
                                    <div className="fs-6 text-light mb-0">Chats</div>
                                </div>
                                <h5 className="fs-1">{filteredChats.length} <small className="fs-3">{filteredChats.length >1? `Chats`:`Chat`}</small></h5>

                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xxl-3">
                        <div className="card card-full bg-cyan bg-opacity-10 border-0">
                            <div className="card-body">
                                <div className="d-flex align-items-center justify-content-between mb-1">
                                    <div className="fs-6 text-light mb-0">Tokens Used</div>
                                </div>
                                <h5 className="fs-1">
                                    {filteredChats.reduce((total, chat) => total + (chat.tokensUsed || 0), 0)} <small className="fs-3">Tokens</small>
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xxl-3">
                        <div className="card card-full bg-blue bg-opacity-10 border-0">
                            <div className="card-body">
                                <div className="d-flex align-items-center justify-content-between mb-1">
                                    <div className="fs-6 text-light mb-0">Documents</div>
                                </div>
                                <h5 className="fs-1">
                                    {organization?.groups.reduce((total, group) => {
                                        return total + (group?.groupDocuments?.length || 0);
                                    }, 0)}{" "}
                                    <small className="fs-3">
                                        {organization?.groups.reduce((total, group) => total + (group?.groupDocuments?.length || 0), 0) === 1
                                            ? "Document"
                                            : "Documents"}
                                    </small>
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-center justify-content-between mt-4 pb-1">
                <h5>Statistics</h5>
            </div>
            <div className="nk-block">
                <div className="row g-gs">
                    <div className="col-sm-6 col-xxl-6">
                        <div className="card card-full bg-purple bg-opacity-10 border-0">
                            <div className="card-body">
                                <BarChartExample />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xxl-6">
                        <div className="card card-full bg-blue bg-opacity-10 border-0">
                            <div className="card-body">
                                <PieChartExample />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrganizationDashboard;

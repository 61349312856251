// src/slices/userSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDashboard } from '../../interfaces/dashboardStatistics.interface';
import {IChatMessage} from "../../interfaces/websitechat.interface";
import {IWebsite} from "../../interfaces/website.interface";
import {IUser} from "../../interfaces/user.interface";

interface DashboardState {
    dashboardStatistics: IDashboard | null;
    loading: boolean;
    error: string | null;
}




const initialState: DashboardState = {
    dashboardStatistics: null,
    loading: false,
    error: null,
};

const dashboardStatisticsSlice = createSlice({
    name: 'dashboardStatistics',
    initialState,
    reducers: {
        fetchDashboardStart(state) {
            state.loading = true;
            state.error = null;
        },
        fetchDashboardSuccess(state, action: PayloadAction<IDashboard>) {
            state.dashboardStatistics = action.payload;
            state.loading = false;
        },
        fetchDashboardFailure(state, action: PayloadAction<string>) {
            state.error = action.payload;
            state.loading = false;
        },
    },
});

export const {
    fetchDashboardSuccess,
    fetchDashboardFailure,
    fetchDashboardStart
} = dashboardStatisticsSlice.actions;
export default dashboardStatisticsSlice.reducer;

import React, {FC, useEffect, useRef, useState} from 'react';
import {Avatar, Badge, CheckboxToggle, FileSelector, Modal} from "react-rainbow-components";
import FormButton from "../../../components/form/bottons/FormButton";
import FileUpload from "../../../components/upload/fileUpload";
import FormInput from "../../../components/form/FormInput";
import * as Yup from "yup";
import {useFormik} from "formik";
import http from "../../../../services/api";
import {IApiKey, IWebsite} from "../../../../interfaces/website.interface";
import {websites_url} from "../../../../constants/contants";
import {toastMessage} from "../../../../utils/util";
import CopyClipInput from "../../../components/form/CopyClipInput";
import dayjs from "dayjs";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchWebsitesFailure,
    updateApiKeyFailure,
    updateApiKeyStart,
    updateApiKeySuccess
} from "../../../../redux/slices/websitesSlice";
import {RootState} from "../../../../redux/rootReducer";
import ShopChatBotBasicSettings from "../modals/ShopChatBotBasicSettings";



const validationSchema = Yup.object({
    apiKeyName: Yup.string()
        .required('API Key Name is required'),
});


interface ColorInputValue {
    hex: string;
    alpha: number;
    isValid: boolean;
}
const ShopChatBotSettings: React.FC = () => {

    const [showModal, setShowModal] = useState(false);
    const [showBotImageModal, setShowBotImageModal] = useState(false);
    const [showApiKeyModal, setShowApiKeyModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showBotModal, setShowBotModal] = useState(false);
    const [apiKeyData, setApiKeyData] = useState('');

    const [files, setFiles] = useState([]);


    const [value, setValue] = useState<ColorInputValue>({ hex: '#FFFFFF', alpha: 1, isValid: true });
    const [manageModal, setManageModal] = useState('');

    const dispatch = useDispatch();
    const { website, loading, error } = useSelector((state: RootState) => state.websites);


    const formik = useFormik({
        initialValues: {
            apiKeyName: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {

                dispatch(updateApiKeyStart());
                try {
                    const { data } = await http.post<IApiKey>(`${websites_url}/${website?._id}/api-key`, { apiKeyName:values.apiKeyName });
                    setApiKeyData(data.apiKey)
                    dispatch(updateApiKeySuccess({ id:website?._id!, apiKey: data.apiKey, apiKeyName:values.apiKeyName }));
                } catch (error) {
                    dispatch(updateApiKeyFailure('Failed to update api key'));

                }


                setShowModal(false)
                setShowApiKeyModal(true)
            } catch (error:any) {
                console.log('error')
            }
        },
    });


    return (
        <>
            <div className="nk-block">
                <div className="d-flex align-items-center justify-content-between border-bottom border-light mt-5 pb-1">
                    <h5>ChatBot Settings</h5>
                    <span className="link link-primary fw-normal" onClick={() => setShowBotModal(true)}>Edit</span>
                </div>
                {website && (
                    <table className="table table-flush table-middle mb-0">
                        <tbody>
                        <tr>
                            <td className="tb-col">
                                <span className="fs-15px text-light">Bot Name</span>
                            </td>
                            <td className="tb-col">
                            <span className="fs-15px text-base">

                                {website.bot.botName}
                            </span>

                            </td>
                        </tr>
                        <tr>
                            <td className="tb-col">
                                <span className="fs-15px text-light">Bot Title</span>
                            </td>
                            <td className="tb-col">
                            <span className="fs-15px text-base">

                                {website.bot.botTitle}
                            </span>

                            </td>
                        </tr>
                        <tr>
                            <td className="tb-col">
                                <span className="fs-15px text-light">Icon</span>
                            </td>
                            <td className="tb-col">
                                {website?.bot.botIcon ?
                                    <Avatar
                                        src={website?.bot.botIcon!}
                                        assistiveText={website?.websiteName}
                                        size="medium"
                                        backgroundColor={website?.bot.primaryColor.hex}
                                    />

                                    :
                                    <Avatar
                                        icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                   viewBox="0 0 24 24"
                                                   fill="none" stroke="currentColor" stroke-width="2"
                                                   stroke-linecap="round"
                                                   stroke-linejoin="round"
                                                   className="feather feather-message-square animate">
                                            <path
                                                d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                                        </svg>}
                                        assistiveText={website?.websiteName}
                                        size="medium"
                                        backgroundColor={website?.bot.primaryColor.hex}
                                    />
                                }

                            </td>
                        </tr>
                        <tr>
                            <td className="tb-col">
                                <span className="fs-15px text-light">Show Form</span>
                            </td>
                            <td className="tb-col">

                                <Badge className="rainbow-m-around_medium"  label={website?.bot.botShowForm?'Showing': 'No'}  variant={website?.bot.botShowForm? 'success':'error'}/>

                            </td>
                        </tr>

                        <tr>
                            <td className="tb-col">
                                <span className="fs-15px text-light">Logo</span>
                            </td>
                            <td className="tb-col">

                                {website?.bot.botLogo && (

                                    <img src={website?.bot.botLogo!} width={30}/>
                                )}


                            </td>
                        </tr>
                        <tr>
                            <td className="tb-col">
                                <span className="fs-15px text-light">Welcome Message</span>
                            </td>
                            <td className="tb-col">
                                <span className="fs-15px text-base"
                                      dangerouslySetInnerHTML={{__html: website.bot.botWelcomeMessage}}></span>
                            </td>
                        </tr>
                        <tr>
                            <td className="tb-col">
                                <span className="fs-15px text-light">Primary Color</span>
                            </td>
                            <td className="tb-col">
                                <span className="px-2 rounded-2 me-1"
                                      style={{backgroundColor: website.bot.primaryColor.hex}}></span>{website.bot.primaryColor.hex}
                            </td>
                        </tr>
                        <tr>
                            <td className="tb-col">
                                <span className="fs-15px text-light">Bot Bubble Color</span>
                            </td>
                            <td className="tb-col">
                                <span className="px-2 rounded-2 me-1"
                                      style={{backgroundColor: website.bot.chatBot.primaryColor.hex}}></span>{website.bot.chatBot.primaryColor.hex}
                                <span className="px-2 rounded-2 me-1"
                                      style={{backgroundColor: website.bot.chatBot.secondaryColor.hex}}></span>{website.bot.chatBot.secondaryColor.hex}
                            </td>
                        </tr>
                        <tr>
                            <td className="tb-col">
                                <span className="fs-15px text-light">User Bubble Color</span>
                            </td>
                            <td className="tb-col">
                                <span className="px-2 rounded-2 me-1"
                                      style={{backgroundColor: website.bot.chatUser.primaryColor.hex}}></span>{website.bot.chatUser.primaryColor.hex}
                                <span className="px-2 rounded-2 me-1"
                                      style={{backgroundColor: website.bot.chatUser.secondaryColor.hex}}></span>{website.bot.chatUser.secondaryColor.hex}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                )}

            </div>


            <ShopChatBotBasicSettings website={website!} setShowModal={(e: boolean) => setShowBotModal(e)}
                                  showModal={showBotModal}/>

        </>
    );
};
export default ShopChatBotSettings;

import React from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { RootState } from '../../../../redux/rootReducer';
import http from '../../../../services/api';
import { toastMessage } from '../../../../utils/util';
import {CheckboxToggle, Modal, MultiSelect, Option} from 'react-rainbow-components';
import FormInput from '../../../components/form/FormInput';
import FormButton from '../../../components/form/bottons/FormButton';
import { IGroup, IMember, IDocument } from '../../../../interfaces/organization.interface';
import { organizations_url } from '../../../../constants/contants';
import {
    createGroupStart,
    createGroupSuccess,
    createGroupFailure,
    updateGroupStart,
    updateGroupSuccess,
    updateGroupFailure,
} from '../../../../redux/slices/organizationsSlice';

interface GroupFormProps {
    group?: IGroup; // If provided, it's edit mode; otherwise, it's create mode
    showModal: boolean;
    setShowModal: any;
}

const GroupForm: React.FC<GroupFormProps> = ({ group, showModal, setShowModal }) => {
    console.log('GroupForm group', group)
    const dispatch = useDispatch();
    const { organization } = useSelector((state: RootState) => state.organizations);

    const validationSchema = Yup.object({
        groupName: Yup.string().required('Group Name is required'),
    });

    const formik = useFormik({
        initialValues: {
            groupName: group?.groupName || '',
            loginRequired: group?.loginRequired || false,
            showDocuments: group?.showDocuments || true,
            showMembers: group?.showMembers || true,
            groupMembers: group?.groupMembers.map((member) => ({
                label: `${member.firstname} ${member.lastname}`,
                value: member._id,
                name: member._id,
                key: member._id,
            })) || [],
            groupDocuments: group?.groupDocuments?.map((doc: any) => ({
                label: doc?.name || 'Unknown Document',
                value: doc?._id || 'unknown'
                // Return a default value if no matching document is found or if doc is undefined
            })),
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const selectedMembers = values.groupMembers.map((member) => member.value);
            const selectedDocuments = values.groupDocuments?.map((doc) => doc?.value);


            if (group) {
                dispatch(updateGroupStart());

                try {
                    if (organization) {
                        const response: any = await http.put<IGroup>(
                            `${organizations_url}/${organization._id}/groups/${group._id}`,
                            {
                                groupName: values.groupName,
                                groupMembers: selectedMembers,
                                groupDocuments: selectedDocuments,
                                loginRequired: true,
                                showDocuments: values.showDocuments,
                                showMembers: values.showMembers,
                            }
                        );

                        dispatch(updateGroupSuccess({
                            orgId: organization._id,
                            group: response.data.group,
                        }));
                        toastMessage('Group Updated successfully', 'success');
                    }
                } catch (error: any) {
                    dispatch(updateGroupFailure(error.message));
                    toastMessage('Failed to update group', 'error');
                }
            } else {
                dispatch(createGroupStart());

                try {
                    if (organization) {
                        const response: any = await http.post<IGroup>(
                            `${organizations_url}/${organization._id}/groups`,
                            {
                                groupName: values.groupName,
                                groupMembers: selectedMembers,
                                groupDocuments: selectedDocuments,
                                loginRequired: true,
                                showDocuments: values.showDocuments,
                                showMembers: values.showMembers,
                            }
                        );
                        dispatch(createGroupSuccess({
                            orgId: organization._id,
                            group: response.data.group,
                        }));
                        toastMessage('Group Created successfully', 'success');
                    }
                } catch (error: any) {
                    dispatch(createGroupFailure(error.message));
                    toastMessage('Failed to create group', 'error');
                }
            }

            setShowModal(false);
            formik.resetForm();
        },
    });

    return (
        <Modal
            id="modal-add-group"
            title={group ? 'Update Group' : 'Create Group'}
            isOpen={showModal}
            onRequestClose={() => {
                setShowModal(false);
                formik.resetForm();
            }}
            footer={
                <div className="d-flex justify-content-end">
                    <FormButton type="submit" text={group ? 'Update' : 'Create'} onClick={formik.handleSubmit} />
                </div>
            }
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="row gy-3">
                    <div className="col-12">
                        <FormInput
                            label="Group Name"
                            name="groupName"
                            type="text"
                            placeholder="Enter Group Name"
                            value={formik.values.groupName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.groupName}
                        />
                    </div>


                    <div className="col-12">
                        <MultiSelect
                            label="Select Members"
                            labelAlignment="left"
                            placeholder="Select one or more members"
                            className="mb-3"
                            value={formik.values.groupMembers}
                            onChange={(value) => {
                                formik.setFieldValue('groupMembers', value);
                            }}
                            showCheckbox
                            variant="chip"
                            borderRadius="semi-rounded"
                        >
                            {organization?.members?.map((member) => (
                                <Option
                                    key={member?._id}
                                    name={member?._id}
                                    value={member?._id}
                                    label={`${member?.firstname} ${member?.lastname}`}
                                />
                            ))}
                        </MultiSelect>
                    </div>

                    {/*<div className="col-12">*/}
                    {/*    <MultiSelect*/}
                    {/*        label="Select Documents"*/}
                    {/*        placeholder="Select one or more documents"*/}
                    {/*        labelAlignment={`left`}*/}
                    {/*        className="mb-3"*/}
                    {/*        value={formik.values.groupDocuments}*/}
                    {/*        onChange={(value) => formik.setFieldValue('groupDocuments', value)}*/}
                    {/*        showCheckbox*/}
                    {/*        variant="chip"*/}
                    {/*        borderRadius="semi-rounded"*/}
                    {/*    >*/}

                    {/*        {organization?.documents?.map((document: IDocument) => (*/}
                    {/*            <Option key={document._id} name="groupDocuments" value={document._id}*/}
                    {/*                    label={`${document.name}`}/>*/}
                    {/*        ))}*/}
                    {/*    </MultiSelect>*/}
                    {/*</div>*/}


                    {/*<div className="col-4">*/}
                    {/*    <CheckboxToggle*/}
                    {/*        label="Login Required"*/}
                    {/*        labelAlignment="left"*/}
                    {/*        value={formik.values.loginRequired}*/}
                    {/*        onChange={() => formik.setFieldValue('loginRequired', !formik.values.loginRequired)}*/}
                    {/*    />*/}
                    {/*</div>*/}
                    <div className="col-6">
                        <CheckboxToggle
                            label="Show Members"
                            labelAlignment="left"
                            value={formik.values.showMembers}
                            onChange={() => formik.setFieldValue('showMembers', !formik.values.showMembers)}
                        />
                    </div>
                    <div className="col-6">
                        <CheckboxToggle
                            label="Show Documents"
                            labelAlignment="left"
                            value={formik.values.showDocuments}
                            onChange={() => formik.setFieldValue('showDocuments', !formik.values.showDocuments)}
                        />
                    </div>

                </div>
            </form>
        </Modal>
    );
};

export default GroupForm;

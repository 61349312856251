import React, {useEffect, useState} from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
    createWebsiteStart,
    createWebsiteSuccess,
    createWebsiteFailure,
    updateWebsiteStart,
    updateWebsiteSuccess,
    updateWebsiteFailure,
    fetchWebsiteStart,
    fetchWebsiteSuccess,
    fetchWebsiteFailure,
} from '../../../../redux/slices/websitesSlice';
import { IWebsite } from '../../../../interfaces/website.interface';
import {RootState} from "../../../../redux/rootReducer";
import {websites_url} from "../../../../constants/contants";
import * as Yup from "yup";
import http from "../../../../services/api";
import {toastMessage} from "../../../../utils/util";
import FormInput from "../../../components/form/FormInput";
import {CheckboxToggle, ColorInput, Modal, Option, Picklist} from "react-rainbow-components";
import FormButton from "../../../components/form/bottons/FormButton";
import {updateUserSuccess} from "../../../../redux/slices/userSlice";

interface WebsiteFormProps {
    website: IWebsite; // If provided, it's edit mode; otherwise, it's create mode
    showModal:boolean;
    setShowModal:any;
}



const ChatBotBasicSettings: React.FC<WebsiteFormProps> = ({ website,showModal,setShowModal }) => {
    const dispatch = useDispatch();

    const {  user } = useSelector((state: RootState) => state.user);

    const [primaryColor, setPrimaryColor] = useState<{ hex: string;
        alpha: number;
        isValid: boolean;}>({ hex: website?.bot.primaryColor.hex, alpha: website?.bot.primaryColor.alpha,isValid: true});

    const [chatBotPrimaryColor, setChatBotPrimaryColor] = useState<{ hex: string;
        alpha: number;
        isValid: boolean;}>({ hex: website?.bot.chatBot.primaryColor.hex, alpha: website?.bot.chatBot.primaryColor.alpha,isValid: true});

    const [chatBotSecondaryColor, setChatBotSecondaryColor] = useState<{ hex: string;
        alpha: number;
        isValid: boolean;}>({ hex: website?.bot.chatBot.secondaryColor.hex, alpha: website?.bot.chatBot.secondaryColor.alpha,isValid: true});



    const [chatUserPrimaryColor, setChatUserPrimaryColor] = useState<{ hex: string;
        alpha: number;
        isValid: boolean;}>({ hex: website?.bot.chatUser.primaryColor.hex, alpha: website?.bot.chatUser.primaryColor.alpha,isValid: true});

    const [chatUserSecondaryColor, setChatUserSecondaryColor] = useState<{ hex: string;
        alpha: number;
        isValid: boolean;}>({ hex: website?.bot.chatUser.secondaryColor.hex, alpha: website?.bot.chatUser.secondaryColor.alpha,isValid: true});




    const validationSchema = Yup.object({
        botName: Yup.string()
            .required('Bot Name is required'),
        botTitle: Yup.string()
            .required('Bot Title is required'),
        botWelcomeMessage: Yup.string()
            .required('Hello Message is required'),
        websiteAiToken: Yup.number()
    });

    const formik = useFormik({
        initialValues: {
            botName: website?.bot.botName || '',
            botTitle: website?.bot.botTitle || '',
            botShowForm: website?.bot.botShowForm|| false,
            botIcon: website?.bot.botIcon || '',
            botLogo: website?.bot.botLogo || '',
            botWelcomeMessage: website?.bot.botWelcomeMessage || '',
            primaryColor: website?.bot.primaryColor || {},
            bot:  {
                chatBot:{
                    primaryColor:{ hex: website?.bot.chatBot.primaryColor.hex, alpha: website?.bot.chatBot.primaryColor.alpha,isValid: true} || {},
                    secondaryColor:{ hex: website?.bot.chatBot.secondaryColor.hex, alpha: website?.bot.chatBot.secondaryColor.alpha,isValid: true} || {},
                },
                chatUser:{
                    primaryColor:{ hex: website?.bot.chatUser.primaryColor.hex, alpha: website?.bot.chatUser.primaryColor.alpha,isValid: true} || {},
                    secondaryColor:{ hex: website?.bot.chatUser.secondaryColor.hex, alpha: website?.bot.chatUser.secondaryColor.alpha,isValid: true} || {},
                }
            },
        },
        enableReinitialize: true, // This allows the form to update when website changes
        validationSchema: validationSchema,
        onSubmit: async (values) => {

                dispatch(updateWebsiteStart());

                try {
                    const response = await http.put<IWebsite>(`${websites_url}/${website._id}`, {
                        bot: {
                            botName: values.botName,
                            botTitle: values.botTitle,
                            botShowForm: values.botShowForm,
                            botIcon: values.botIcon,
                            botLogo: values.botLogo,
                            botWelcomeMessage: values.botWelcomeMessage,
                            primaryColor: primaryColor,
                            chatBot:{
                                primaryColor: chatBotPrimaryColor,
                                secondaryColor: chatBotSecondaryColor,
                            },
                            chatUser:{
                                primaryColor:chatUserPrimaryColor,
                                secondaryColor:chatUserSecondaryColor,
                            }
                        }
                    });


                    dispatch(updateWebsiteSuccess(response.data));


                    toastMessage('Website Updated successfully', 'success')
                } catch (error:any) {
                    dispatch(updateWebsiteFailure(error!));
                }


        },
    });

    return (
        <Modal
            id="modal-add-website"
            title={`Chat bot settings`}

            isOpen={showModal}
            onRequestClose={() => {
                setShowModal(false)
                formik.resetForm();
            }}
            footer={
                <div className="d-flex justify-content-end">
                    <FormButton type={`submit`} text={'Update'} onClick={formik.handleSubmit} />
                </div>
            }
        >

            {website && (
                <form onSubmit={formik.handleSubmit}>
                    <div className="row gy-3">
                        <div className="col-12">
                            <FormInput
                                label="Bot Name"
                                name="botName"
                                type="text"
                                placeholder="Enter Bot Name"
                                value={formik.values.botName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.errors.botName}
                            />
                        </div>
                        <div className="col-12">
                            <FormInput
                                label="Bot Title"
                                name="botTitle"
                                type="text"
                                placeholder="Enter Bot Title"
                                value={formik.values.botTitle}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.errors.botTitle}
                            />
                        </div>
                        <div className="col-12">
                            <div className="form-group row">
                                <div className={`col`}>
                                    <label className="form-label">Show Form</label>

                                </div>
                                <div className={`col align-content-end justify-content-end text-end`}>
                                    <div className="form-control-wrap">

                                        <CheckboxToggle
                                            id="checkbox-toggle-component-1"
                                            value={formik.values.botShowForm}
                                            onChange={() => formik.setFieldValue('botShowForm', !formik.values.botShowForm)}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-12">
                            <FormInput
                                label="Icon URL"
                                name="botIcon"
                                type="text"
                                placeholder="Paste Icon URL"
                                value={formik.values.botIcon}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.errors.botIcon}
                            />
                        </div>
                        <div className="col-12">
                            <FormInput
                                label="Logo URL"
                                name="botLogo"
                                type="text"
                                placeholder="Paste Logo URL"
                                value={formik.values.botLogo}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.errors.botLogo}
                            />
                        </div>
                        <div className="col-12">
                        <FormInput
                                label="Welcome Message"
                                name="botWelcomeMessage"
                                type="text"
                                placeholder="Enter welcome Message "
                                value={formik.values.botWelcomeMessage}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.errors.botWelcomeMessage}
                            />
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <label className="form-label">Primary Color</label>
                                <ColorInput
                                    variant={`default`}
                                    value={primaryColor}
                                    onChange={(e) => {
                                        setPrimaryColor(e)
                                    }}
                                    borderRadius="semi-rounded"
                                />
                            </div>

                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <label className="form-label">Chat Bot</label>
                                <ColorInput
                                    variant={`default`}
                                    value={chatBotPrimaryColor}
                                    onChange={(e) => {
                                        setChatBotPrimaryColor(e)
                                    }}
                                    borderRadius="semi-rounded"
                                />
                                <ColorInput
                                    variant={`default`}
                                    className={`mt-3`}
                                    value={chatBotSecondaryColor}
                                    onChange={(e) => {
                                        setChatBotSecondaryColor(e)
                                    }}
                                    borderRadius="semi-rounded"
                                />
                            </div>

                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <label className="form-label">Chat User</label>
                                <ColorInput
                                    variant={`default`}
                                    value={chatUserPrimaryColor}
                                    onChange={(e) => {
                                        setChatUserPrimaryColor(e)
                                    }}
                                    borderRadius="semi-rounded"
                                />
                                <ColorInput
                                    className={`mt-3`}
                                    variant={`default`}
                                    value={chatUserSecondaryColor}
                                    onChange={(e) => {
                                        setChatUserSecondaryColor(e)
                                    }}
                                    borderRadius="semi-rounded"
                                />
                            </div>

                        </div>

                    </div>
                </form>

            )}


        </Modal>
    );
};

export default ChatBotBasicSettings;

import React, {FC, useState} from 'react';
import {useSelector} from "react-redux";
import {RootState} from "../../../../redux/rootReducer";
import {Badge} from "react-rainbow-components";
import dayjs from "dayjs";
import PasswordManage from "../modals/PasswordManage";
import CountryManage from "../modals/CountryManage";
import ProfileManage from "../modals/ProfileManage";
import DeleteAccount from "../modals/DeleteAccount";



const AccountProfile: FC = () => {

    const { user } = useSelector((state: RootState) => state.user);


    const [showProfileModal, setShowProfileModal] = useState(false);
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [showCountryModal, setShowCountryModal] = useState(false);
    const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);



    return (
        <div className="tab-pane fade show active">
            <div className="d-flex align-items-center justify-content-between border-bottom border-light mt-5 pb-1">
                <h5>Account Details</h5>
                <span className="link link-primary fw-normal" onClick={()=>setShowProfileModal(true)} >Edit Profile</span>
            </div>
            <table className="table table-flush table-middle mb-0">
                <tbody>
                <tr>
                    <td className="tb-col">
                        <span className="fs-15px text-light">Full Name</span>
                    </td>
                    <td className="tb-col">
                        <span className="fs-15px text-base">{user?.fullName}</span>
                    </td>
                    <td className="tb-col tb-col-end tb-col-sm"></td>
                </tr>
                <tr>
                    <td className="tb-col">
                        <span className="fs-15px text-light">Email</span>
                    </td>
                    <td className="tb-col">
                        <span className="fs-15px text-base">{user?.email}</span>
                    </td>
                    <td className="tb-col tb-col-end tb-col-sm">


                        <Badge variant={`${user?.isVerified ? 'success' : 'error'}`}
                               title={`${user?.isVerified ? 'success' : 'error'}`} className="rainbow-m-around_medium">
                            {`${user?.isVerified ? 'Email verified' : 'Email Not verified'}`}
                        </Badge>


                    </td>
                </tr>
                <tr>
                    <td className="tb-col">
                        <span className="fs-15px text-light">Password</span>
                    </td>
                    <td className="tb-col">
                            <span className="fs-15px text-base">
                                <span className="link link-primary fw-normal"
                                      onClick={() => setShowPasswordModal(true)}>Change Password</span></span>
                    </td>
                    <td className="tb-col tb-col-end tb-col-sm">
                        <span
                            className="fs-13px text-light">{user?.passwordChangeDate ? `Last changed at ${dayjs(user.passwordChangeDate).format('MMM D, YYYY')}` : 'Never changed your password'}</span>
                    </td>
                </tr>
                </tbody>
            </table>
            <div className="d-flex align-items-center justify-content-between border-bottom border-light mt-4 pb-1">
                <h5>Preferences</h5>
            </div>
            <table className="table table-flush">
                <tbody>

                <tr>
                    <td className="py-3">
                        <span className="fs-15px text-light">Country</span>
                    </td>
                    <td className="py-3">
                        <span className="fs-15px text-base">{user?.countryCode}</span>
                    </td>
                    <td className="py-3 text-end"><span className="link link-primary fw-normal"
                                                        onClick={() => setShowCountryModal(true)}>Edit</span></td>
                </tr>
                <tr>
                    <td className="py-3">
                        <span className="fs-15px text-light">Timezone</span>
                    </td>
                    <td className="py-3">
                        <span className="fs-15px text-base">GMT +06:00</span>
                    </td>
                    <td className="py-3 text-end"><a className="link link-primary fw-normal" href="#"></a></td>
                </tr>
                <tr>
                    <td className="py-3">
                        <span className="fs-15px text-light">Currency</span>
                    </td>
                    <td className="py-3">
                        <span className="fs-15px text-base">{user?.countryCode === 'IN' ? `INR` : `USD`}</span>
                    </td>
                    <td className="py-3 text-end"><a className="link link-primary fw-normal" href="#"></a></td>
                </tr>
                </tbody>
            </table>
            <div className="col-12">
                <div className="card shadow-none border mb-0">
                    <div className="card-body"><h6 className="mb-3">Delete Account</h6><p className="mb-3">Would
                        you like to delete your account?<br/>Deleting your account will remove all the content
                        associated with it.</p>
                        <button className="btn btn-primary btn-sm" onClick={()=>setShowDeleteAccountModal(true)}>Delete Account</button>
                    </div>
                </div>
            </div>


            <PasswordManage setShowModal={(e: boolean) => setShowPasswordModal(e)}
                            showModal={showPasswordModal} />
            <CountryManage setShowModal={(e: boolean) => setShowCountryModal(e)}
                            showModal={showCountryModal} />
            <ProfileManage setShowModal={(e: boolean) => setShowProfileModal(e)}
                            showModal={showProfileModal} />
            <DeleteAccount setShowModal={(e: boolean) => setShowDeleteAccountModal(e)}
                            showModal={showDeleteAccountModal} />
        </div>
    );
};
export default AccountProfile;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    deleteDocumentStart,
    deleteDocumentSuccess,
    deleteDocumentFailure,
} from '../../../../redux/slices/shopsSlice';
import { IDocument } from '../../../../interfaces/website.interface';
import { RootState } from "../../../../redux/rootReducer";
import {shops_url, websites_url} from "../../../../constants/contants";
import http from "../../../../services/api";
import FormButton from "../../../components/form/bottons/FormButton";
import { Button, Modal } from "react-rainbow-components";
import { toastMessage } from "../../../../utils/util";

interface DocumentManageProps {
    document: IDocument; // Document to be deleted
    manageModal: string;
    setManageModal: (modalState: string) => void;
}

const ShopDocumentManage: React.FC<DocumentManageProps> = ({ document, manageModal, setManageModal }) => {
    const dispatch = useDispatch();
    const { shop, loading } = useSelector((state: RootState) => state.shops);

    const handleDelete = async (documentId: string) => {
        dispatch(deleteDocumentStart());
        try {
            await http.delete(`${shops_url}/${shop?._id}/documents/${documentId}`);
            dispatch(deleteDocumentSuccess(documentId));
            setManageModal('');

            toastMessage('Document deleted successfully', 'success');
        } catch (error: any) {
            dispatch(deleteDocumentFailure('Failed to delete document'));
        }
    };

    return (
        <Modal
            id="modal-delete-document"
            title={manageModal === 'delete' ? "Delete Document" : ""}
            isOpen={manageModal ? true : false}
            onRequestClose={() => {
                setManageModal('');
            }}
            footer={
                <div className="d-flex justify-content-end gap-2">
                    <FormButton type={`button`} text={`Cancel`} onClick={() => setManageModal('')} />
                    {manageModal === 'delete' &&(
                        loading ? (
                            <Button isLoading label="Button Brand" variant="brand" className="rainbow-m-around_medium" style={{borderRadius: 5}}/>
                        ) : (
                            <Button label="Delete" variant="destructive" borderRadius="semi-square" className="rainbow-m-around_medium" onClick={() => {
                                handleDelete(document?._id!);
                            }} />
                        ))
                    }
                </div>
            }
        >
            Are you sure you want to delete this document?
        </Modal>
    );
};

export default ShopDocumentManage;

import React, {FC, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/rootReducer";
import {Modal, Picklist, Option, ButtonMenu, MenuItem, Button} from "react-rainbow-components";
import FormInput from "../../components/form/FormInput";
import FormButton from "../../components/form/bottons/FormButton";
import {useFormik} from "formik";
import http from "../../../services/api";
import {login_url, pricing_plans_url, register_url, shops_url, websites_url} from "../../../constants/contants";
import * as Yup from "yup";
import {toastMessage} from "../../../utils/util";
import {IShop,} from "../../../interfaces/shop.interface";
import {useNavigate} from "react-router-dom";
import dayjs from "dayjs";

import ShopForm from "./modals/ShopForm";
import ShopManage from "./modals/ShopManage";
import EmptyMessage from "../../components/empty/EmptyMessage";
import Loader from "../../components/loader/Loader";
import {fetchShopsFailure, fetchShopsStart, fetchShopsSuccess} from "../../../redux/slices/shopsSlice";



const ShopsContainer: FC = () => {


    const { shops, loading, error } = useSelector((state: RootState) => state.shops);
    const dispatch = useDispatch();
    const {  user } = useSelector((state: RootState) => state.user);

    const [showModal, setShowModal] = useState(false);
    const [manageModal, setManageModal] = useState('');
    const [activeTab, serActiveTab] = useState('active');
    const [selectedShop, setSelectedShop] = useState<IShop>();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchShops = async () => {
            dispatch(fetchShopsStart());
            try {
                let response = await http.get<IShop[]>(shops_url);
                dispatch(fetchShopsSuccess(response.data));
            } catch (error: any) {
                dispatch(fetchShopsFailure('Failed to fetch websites'));
            }
        };

        fetchShops();
    }, []);



    return (
        <div className="nk-content">
            <div className="container-xl">
                <div className="nk-content-inner">
                    <div className="nk-content-body">
                        <div className="nk-block-head nk-page-head">
                            <div className="nk-block-head-between flex-wrap gap g-2">
                                <div className="nk-block-head-content">
                                    <h2 className="display-6">Shops</h2>
                                    <p>List of Shop you enabled chatbot.</p>
                                </div>

                                {
                                    loading ? (
                                        <Button isLoading label="Button Brand" variant="brand" className="rainbow-m-around_medium" style={{borderRadius: 5}} />
                                    ):(
                                        <Button
                                            label="Create New"
                                            variant="brand"
                                            className="rainbow-m-around_medium"
                                            borderRadius="semi-square"

                                            onClick={()=> {

                                                if(user?.aiTokens! < 100) {

                                                    toastMessage(user?.plan?'Please choose a refill tokens to continue':'Please choose a plan tokens to continue','error')

                                                }else {

                                                    setSelectedShop(undefined)
                                                    setShowModal(true)
                                                }


                                            }}
                                        >
                                            <em className="icon ni ni-plus"/>
                                            <span className='ms-2'> Create New </span>
                                        </Button>
                                    )
                                }
                            </div>
                        </div>
                        <div className="nk-block">
                            <div className="card shadow-none">
                                <ul className="nav nav-tabs nav-tabs-s1 px-4" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className={`nav-link ${activeTab == 'active'&& 'active'}`} type="button" data-bs-toggle="tab" onClick={()=> serActiveTab('active')} aria-selected="true" role="tab">Active</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={`nav-link ${activeTab == 'deactivated'&& 'active'}`} type="button" data-bs-toggle="tab" onClick={()=> serActiveTab('deactivated')} aria-selected="false" role="tab" >Deactivated</button>
                                    </li>
                                </ul>
                                <div className="tab-content">
                                    <div className="tab-pane fade active show" id="recents-documents-tab-pane" role="tabpanel">

                                        {loading ?

                                            <Loader/>
                                            :
                                            <table className="table table-middle mb-0">
                                                <tbody>

                                                {shops?.filter((shop) => activeTab == 'active' ? !shop.deactivated : shop.deactivated).length ?

                                                    shops?.filter((shop) => activeTab == 'active' ? !shop.deactivated : shop.deactivated).map((shop) =>

                                                        <tr>
                                                            <td className="tb-col">
                                                                <div className="caption-text link line-clamp-2"
                                                                     onClick={() => navigate(`/shop/${shop._id}`)}>{shop.shopName} </div>
                                                            </td>
                                                            <td className="tb-col">
                                                                <div className="caption-text link line-clamp-2"
                                                                     onClick={() => navigate(`/shop/${shop._id}`)}>{shop.shopToken} </div>
                                                            </td>
                                                            <td className="tb-col tb-col-sm">
                                                                <div className="caption-text link line-clamp-2" >{shop.shopAiToken} tokens remaining </div>
                                                            </td>
                                                            <td className="tb-col tb-col tb-col-sm">
                                                                <div
                                                                    className="fs-6 text-light d-inline-flex flex-wrap gap gx-2">
                                                                    <span>{dayjs(shop.createdAt).format('MMM DD, YYYY')}</span>
                                                                    <span>{dayjs(shop.createdAt).format('hh:mm A')}</span>
                                                                </div>
                                                            </td>
                                                            <td className="tb-col tb-col-end">
                                                                <ButtonMenu
                                                                    menuAlignment="right"
                                                                    menuSize="x-small"
                                                                    buttonVariant="base"
                                                                    icon={<em className="icon ni ni-more-h"></em>}
                                                                >
                                                                    <MenuItem
                                                                        icon={<em className="icon ni ni-edit"></em>}
                                                                        onClick={() => {

                                                                            setSelectedShop(shop)
                                                                            setShowModal(true)
                                                                        }} label="Edit"/>
                                                                    <MenuItem
                                                                        icon={<em className="icon ni ni-trash"></em>}
                                                                        onClick={() => {

                                                                            setSelectedShop(shop)
                                                                            setManageModal(shop.deactivated ? 'activate' : 'deactivate')

                                                                        }}
                                                                        label={`${shop.deactivated ? 'Activate' : 'Deactivate'}`}/>
                                                                    <MenuItem
                                                                        icon={<em className="icon ni ni-trash"></em>}
                                                                        onClick={() => {

                                                                            setSelectedShop(shop)
                                                                            setManageModal('delete')
                                                                        }} label="Delete"/>
                                                                </ButtonMenu>
                                                            </td>
                                                        </tr>
                                                    ) :
                                                    <EmptyMessage
                                                        type="shop"
                                                        title="No Shops added."
                                                        description="You have not added any Shops yet. Start adding to manage your favorite sites."
                                                        buttonText="Add Shop"
                                                        buttonCallback={() => {
                                                            setShowModal(true)
                                                        }}
                                                    />
                                                }


                                                </tbody>
                                            </table>
                                        }


                                    </div>
                                    <div className="tab-pane fade" id="trash-documents-tab-pane" role="tabpanel">
                                        <div className="row justify-content-center py-5">
                                            <div className="col-md-7 col-lg-4">
                                                <div className="mb-5 text-center">
                                                    <img src="images/illustrations/no-documents.svg" alt=""/>
                                                </div>
                                                <div className="nk-block-head text-center">
                                                    <div className="nk-block-head-content">
                                                        <h1 className="nk-block-title mb-2">No documents in trash.</h1>
                                                        <p>All files have been permanently removed from the trash folder
                                                            and cannot be retrieved.</p>
                                                    </div>
                                                </div>
                                                <div className="text-center mt-4">
                                                    <a className="btn btn-primary" href="templates.html">Explore
                                                        Templates</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <ShopForm shop={selectedShop!} setShowModal={(e: boolean) => setShowModal(e)}
                      showModal={showModal}/>
            <ShopManage shop={selectedShop!} setManageModal={(e: string) => setManageModal(e)}
                        manageModal={manageModal}/>

        </div>
    );
};
export default ShopsContainer;

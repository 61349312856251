import React, { useEffect, useRef } from 'react';

import './orb.scss';

interface OrbAnimationProps {
    isRecording: boolean;
    message: string;
}

const OrbAnimation: React.FC<OrbAnimationProps> = ({ isRecording,message }) => {


    const canvasRef = useRef<HTMLCanvasElement>(null);
    const dpr = window.devicePixelRatio || 1;
    const pi = Math.PI;
    const points = 12;
    const radius = 200 * dpr;
    const h = 600 * dpr;
    const w = 600 * dpr;
    const center = { x: (w / 2) * dpr, y: (h / 2) * dpr };
    const rangeMin = 1;
    const rangeMax = 10;
    const showPoints = true;

    let tick = 0;
    const gradients: CanvasGradient[] = [];

    const circles: Array<
        Array<{
            x: number;
            y: number;
            radian: number;
            range: number;
            phase: number;
        }>
    > = [];

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas?.getContext('2d');

        if (canvas && ctx) {
            const gradient1 = ctx.createLinearGradient(0, 0, w, 0);
            gradient1.addColorStop(0, '#96fbc4');
            gradient1.addColorStop(1, '#f9f586');

            const gradient2 = ctx.createLinearGradient(0, 0, w, 0);
            gradient2.addColorStop(0, '#48c6ef');
            gradient2.addColorStop(1, '#6f86d6');

            const gradient3 = ctx.createLinearGradient(0, 0, w, 0);
            gradient3.addColorStop(0, '#9795f0');
            gradient3.addColorStop(1, '#9be15d');

            const gradient4 = ctx.createLinearGradient(0, 0, w, 0);
            gradient4.addColorStop(0, '#f6d365');
            gradient4.addColorStop(1, '#fda085');

            gradients.push(gradient1, gradient2, gradient3, gradient4);

            ctx.scale(dpr, dpr);
            canvas.width = w * dpr;
            canvas.height = h * dpr;
            canvas.style.width = w + 'px';
            canvas.style.height = h + 'px';

            // Setup swing circle points
            for (let idx = 0; idx <= gradients.length - 1; idx++) {
                const swingpoints = [];
                let radian = 0;

                for (let i = 0; i < points; i++) {
                    radian = (pi * 2) / points * i;
                    const ptX = center.x + radius * Math.cos(radian);
                    const ptY = center.y + radius * Math.sin(radian);

                    swingpoints.push({
                        x: ptX,
                        y: ptY,
                        radian,
                        range: random(rangeMin, rangeMax),
                        phase: 0,
                    });
                }

                circles.push(swingpoints);
            }

            requestAnimationFrame(swingCircle);
        }
    }, []); // Run only once on mount

    const swingCircle = () => {
        const canvas = canvasRef.current;
        const ctx = canvas?.getContext('2d');

        if (canvas && ctx) {
            ctx.clearRect(0, 0, w * dpr, h * dpr);

            ctx.globalAlpha = 1;
            ctx.globalCompositeOperation = 'screen';

            const speedFactor = 2; // Adjust speed based on isRecording

            for (let k = 0; k < circles.length; k++) {
                const swingpoints = circles[k];

                for (let i = 0; i < swingpoints.length; i++) {
                    swingpoints[i].phase += random(1, 10) * -0.01 * speedFactor;

                    const phase = 4 * Math.sin(tick / (65 * speedFactor));

                    const r =
                        radius +
                        swingpoints[i].range * phase * Math.sin(swingpoints[i].phase) -
                        rangeMax;

                    swingpoints[i].radian += pi / 360 * speedFactor;

                    const ptX = center.x + r * Math.cos(swingpoints[i].radian);
                    const ptY = center.y + r * Math.sin(swingpoints[i].radian);

                    if (showPoints === true) {
                        ctx.strokeStyle = '#96fbc4';
                        ctx.beginPath();
                        ctx.arc(ptX, ptY, 2 * dpr, 0, pi * 2, true);
                        ctx.closePath();
                        ctx.stroke();
                    }

                    swingpoints[i] = {
                        x: ptX,
                        y: ptY,
                        radian: swingpoints[i].radian,
                        range: swingpoints[i].range,
                        phase: swingpoints[i].phase,
                    };
                }

                const fill = gradients[k];

                drawCurve(swingpoints, fill, ctx);
            }

            tick++;
            requestAnimationFrame(swingCircle);
        }
    };

    const drawCurve = (
        pts: Array<{ x: number; y: number }>,
        fillStyle: CanvasGradient,
        ctx: CanvasRenderingContext2D
    ) => {
        ctx.fillStyle = fillStyle;
        ctx.beginPath();
        ctx.moveTo(
            (pts[cycle(-1, points)].x + pts[0].x) / 2,
            (pts[cycle(-1, points)].y + pts[0].y) / 2
        );
        for (let i = 0; i < pts.length; i++) {
            ctx.quadraticCurveTo(
                pts[i].x,
                pts[i].y,
                (pts[i].x + pts[cycle(i + 1, points)].x) / 2,
                (pts[i].y + pts[cycle(i + 1, points)].y) / 2
            );
        }
        ctx.closePath();
        ctx.fill();
    };

    const cycle = (num1: number, num2: number) => {
        return (num1 % num2 + num2) % num2;
    };

    const random = (num1: number, num2: number) => {
        const max = Math.max(num1, num2);
        const min = Math.min(num1, num2);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    return (
        <div className="orb-container">
            <canvas id="canvas" ref={canvasRef} style={{ position:'absolute'}}></canvas>
            <div className="copy">
                <p>{message}</p>
            </div>
        </div>
    );
};

export default OrbAnimation;

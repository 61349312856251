import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    deleteDocumentStart,
    deleteDocumentSuccess,
    deleteDocumentFailure,
} from '../../../../redux/slices/websitesSlice';
import {IDocument, IWebsite} from '../../../../interfaces/website.interface';
import { RootState } from "../../../../redux/rootReducer";
import {update_password_url, websites_url} from "../../../../constants/contants";
import http from "../../../../services/api";
import FormButton from "../../../components/form/bottons/FormButton";
import { Button, Modal } from "react-rainbow-components";
import { toastMessage } from "../../../../utils/util";
import FormInput from "../../../components/form/FormInput";
import {useFormik} from "formik";
import {IUser} from "../../../../interfaces/user.interface";
import {updateUserFailure, updateUserSuccess} from "../../../../redux/slices/userSlice";
import * as Yup from "yup";

interface PasswordManageProps {
    showModal:boolean;
    setShowModal:any;
}

const passwordResetSchema = Yup.object({
    oldPassword: Yup.string()
        .required('Old Password is required'),
    newPassword: Yup.string()
        .required('New Password is required')
        .min(6, 'New Password must be at least 6 characters long'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword')], 'Confirm Password must match New Password')
        .required('Confirm Password is required')
});


const PasswordManage: React.FC<PasswordManageProps> = ({ showModal, setShowModal }) => {

    const dispatch = useDispatch();


    const formik = useFormik({
        initialValues: {
            oldPassword: '',
            newPassword:'',
            confirmPassword:'',
        },
        validationSchema: passwordResetSchema,
        onSubmit: async (values) => {
            try {
                try {
                    const response =  await http.put<IUser>(update_password_url, values);

                    dispatch(updateUserSuccess(response.data));


                    toastMessage('Password updated successfully', 'success');

                    setShowModal(false)
                    setShowModal(false)
                } catch (error: any) {
                    dispatch(updateUserFailure('Failed to update account'));
                    toastMessage('Failed to update password', 'error');

                }


            } catch (error:any) {
                console.log('error')
            }
        },
    });



    return (
        <Modal
            id="modal-delete-document"
            title={`Edit Password`}
            isOpen={showModal}
            onRequestClose={() => {
                setShowModal(false);
            }}
            footer={
                <div className="d-flex justify-content-end">
                    <FormButton type={`submit`} text={`Update`}
                                onClick={formik.handleSubmit }/>
                </div>
            }
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="row gy-3">
                    <div className="col-12">
                        <FormInput
                            label="Old Password"
                            name="oldPassword"
                            type="password"
                            placeholder="Enter Old Password"
                            value={formik.values.oldPassword}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.oldPassword}
                        />
                    </div>
                    <div className="col-12">
                        <FormInput
                            label="New Password"
                            name="newPassword"
                            type="password"
                            placeholder="Enter New Password"
                            value={formik.values.newPassword}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.newPassword}
                        />
                    </div>
                    <div className="col-12">
                        <FormInput
                            label="Confirm Password"
                            name="confirmPassword"
                            type="password"
                            placeholder="Enter Confirm Password"
                            value={formik.values.confirmPassword}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.confirmPassword}
                        />
                    </div>
                </div>
            </form>
        </Modal>
    );
};

export default PasswordManage;

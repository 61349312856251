// src/redux/shopChatSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IShopChats } from '../../interfaces/shopchat.interface';

interface ChatState {
    shopChats: IShopChats[];
    loading: boolean;
    error: string | null;
}

const initialState: ChatState = {
    shopChats: [],
    loading: false,
    error: null,
};

const shopChatSlice = createSlice({
    name: 'shopChats',
    initialState,
    reducers: {
        fetchShopChatsStart(state) {
            state.loading = true;
            state.error = null;
        },
        fetchShopChatsSuccess(state, action: PayloadAction<IShopChats[]>) {
            state.shopChats = action.payload;
            state.loading = false;
        },
        fetchShopChatsFailure(state, action: PayloadAction<string>) {
            state.loading = false;
            state.error = action.payload;
        },
        deleteShopChat(state, action: PayloadAction<string>) {
            state.shopChats = state.shopChats.filter(shopChat => shopChat.sessionId !== action.payload);
        },
    },
});

export const {
    fetchShopChatsStart,
    fetchShopChatsSuccess,
    fetchShopChatsFailure,
    deleteShopChat,
} = shopChatSlice.actions;

export default shopChatSlice.reducer;

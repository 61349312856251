import React, {FC, useEffect, useRef, useState} from 'react';
import {Helmet} from "react-helmet-async";
import {
    IDocument,
    IGroup,
    IMember,
    IOrganization, IPublicMemberGroup,
    IPublicOrganization
} from "../../../../interfaces/organization.interface";
import {
    Avatar, AvatarGroup,
    AvatarMenu,
    Badge,
    Button, ButtonMenu, ImportRecordsFlow,
    MenuDivider,
    MenuItem,
    Modal,
    MultiSelect,
    Option
} from "react-rainbow-components";
import axios from "axios";
import {base_url, public_member_organizations_url, public_organizations_url} from "../../../../constants/contants";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useNavigation, useParams} from "react-router-dom";
import {RootState} from "../../../../redux/rootReducer";
import OrganizationChat from "./OrganizationChat";
import OrganizationGroups from "./OrganizationGroups";
import OrganizationMembers from "./OrganizationMembers";
import {BrainaqueChatBot} from "brainaque-chat-sdk";
import http from "../../../../services/api";
import {BrainaqueChatMessage} from "../../../components/ChatMessage/BrainaqueChatMessage";
import DocumentViewer from "../modals/DocumentViewer";
import OrganizationDashboard from "../tabs/OrganizationDashboard";
import OrganizationDocuments from "../tabs/OrganizationDocuments";
import OrganizationChatHistory from "../tabs/OrganizationChatHistory";
import OrganizationSettings from "../tabs/OrganizationSettings";
import OrganizationChatBotSettings from "../tabs/OrganizationChatBotSettings";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import OrganizationMemberSettings from "./OrganizationMemberSettings";
import FormButton from "../../../components/form/bottons/FormButton";
import FileUpload from "../../../components/upload/fileUpload";
import {
    fetchPublicOrganizationFailure,
    fetchPublicOrganizationStart, fetchPublicOrganizationSuccess
} from "../../../../redux/slices/publicOrganizationSlice";
import PublicOrganizationFileUpload from "../../../components/upload/PublicOrganizationFileUpload";
import EmptyMessage from "../../../components/empty/EmptyMessage";
import PublicDocumentManagePage from "./modals/PublicDocumentManagePage";
import GroupMemberManage from "../tabs/modals/GroupMemberManage";
import PublicMembersForm from "./modals/PublicMembersForm";
import PublicMemberManage from "./modals/PublicMemberManage";
import PublicExistingMemberManage from "./modals/PublicExistingMemberManage";
import PublicOrganizationUserProfileManage from "./modals/PublicOrganizationUserProfileManage";
import MemberForm from "../modals/MemberForm";


interface FileUploadHandle {
    uploadFiles: () => Promise<void>;
}


const OrganizationChatContainer: FC = ( ) => {

    dayjs.extend(relativeTime);

    const { id } = useParams();

    const prevGroupId = localStorage.getItem('prevGroupId');
    const activeMainTab = localStorage.getItem('activeMainTab');
    const activeSessionId = localStorage.getItem('sessionId');

    const [activeTab, setActiveTab] = useState('chats')
    const [newQuery, setNewQuery] = useState('')
    const [activeSubTab, setActiveSubTab] = useState('questions')
    const [activeDocuments, setActiveDocuments] = useState<any>([])

    const [showModalForEditProfile, setShowModalForEditProfile] = useState<boolean>(false);

    const dispatch = useDispatch();
    const {publicOrganization, loading, error} = useSelector((state: RootState) => state.publicOrganization);
    const { organizationMember } = useSelector((state: RootState) => state.organizations);
    const [sideBarOpen, setSideBarOpen] = useState(false);
    const [group, setGroup] = useState<IGroup>();
    const [sessionId, setSessionId] = useState<any>();

    const [manageModal, setManageModal] = useState<boolean>(false);
    const [memberManage, setMemberManage] = useState<boolean>(false);

    const [selectedMember, setSelectedMember] = useState<IMember | undefined>(undefined);
    const [newChat, setNewChat] = useState<boolean>(false);

    const [showMemberImport, setShowMemberImport] = useState(false);

    const [showMemberForm, setShowMemberForm] = useState(false);

    const [selectedDocument, setSelectedDocument] = useState<IDocument>();
    const [showDocumentModal, setShowDocumentModal] = useState(false);
    const [fileCount, setFileCount] = useState(0);

    const [uploadDocument, setUploadDocument] = useState<boolean>(false);

    const [existingMemberModal, setExistingMemberModal] = useState<boolean>(false);


    const [defaultTheme, setDefaultTheme] = useState<any>();

    const navigate = useNavigate();

    const fileUploadRef = useRef<FileUploadHandle>(null);

    const handleUpload = () => {
        if (fileUploadRef.current) {
            fileUploadRef?.current?.uploadFiles();
        }
    };

    const fetchOrganization = async () => {
        try {
            dispatch(fetchPublicOrganizationStart());
            let response = await axios.get<IPublicOrganization>(
                `${base_url}/${public_member_organizations_url}/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${organizationMember?.member.token}`, // Replace with the appropriate token type
                    },
                }
            );

            if(response?.status === 200){
                // setOrganization(response.data);
                dispatch(fetchPublicOrganizationSuccess(response.data));
            }else {
                dispatch(fetchPublicOrganizationFailure('error'));
            }


        } catch (error: any) {
            dispatch(fetchPublicOrganizationFailure(error?.message));
            localStorage.removeItem('organizationUser')
            navigate(`/`)
        }
    };

    const currentMemberRole = () => {
        return publicOrganization?.roles.find((role)=> role?._id.toString() === organizationMember?.member?.role.toString())
    };

    useEffect(() => {
        if(organizationMember){
            fetchOrganization();
        }
    }, [dispatch]);

    const logout = () => {
            localStorage.removeItem('organizationUser');
        localStorage.removeItem('sessionId');
        navigate(`/chat-organization/${id}`)
    };


    const findRole = (roleId: string) => {
        const role = publicOrganization?.roles.find((role) => role?._id === roleId);
        return role?.name || 'Unknown Role'; // Provide fallback if role is not found
    };

    const hexWithAlpha = (hex: string, alpha: number): string => {
        // Remove the hash if present
        hex = hex.replace('#', '');

        // Convert alpha to a 0-255 range
        const alphaValue = Math.round(alpha * 255);

        // Convert alpha value to a 2-digit hexadecimal
        const alphaHex = alphaValue.toString(16).padStart(2, '0');

        // Return the hex color with the alpha appended
        return `#${hex}${alphaHex}`;
    };
    useEffect(() => {
        const defaultActiveDocuments = group?.questions
            .filter((question, index, self) =>
                    index === self.findIndex((q) =>
                        group.groupDocuments.some((groupDocument) => groupDocument._id === q.documentid)
                    )
            )
            .map((question) => {
                const activeDocumentQuestion = group?.groupDocuments.find(
                    (groupDocument) => groupDocument._id === question.documentid
                );

                // Ensure activeDocumentQuestion is valid before mapping
                if (activeDocumentQuestion) {
                    return {
                        name: activeDocumentQuestion._id,  // Set documentid as name
                        label: activeDocumentQuestion.name,  // Set document name as label
                        key: activeDocumentQuestion._id
                    };
                }

                // If no active document is found, return null (or simply filter it out)
                return null;
            })
            .filter((document) => document !== null); // Filter out any null values
        setActiveDocuments(defaultActiveDocuments);
    }, [group]);

    useEffect(() => {
        if (activeMainTab) {
            setActiveTab(activeMainTab); // Set the active tab from localStorage
            setActiveSubTab(`questions`)
        }
    }, [prevGroupId, activeMainTab]);


    const setActiveGroup = (group: any, sessionId?:string) => {
        setGroup(group);
        setSessionId(sessionId)
        setActiveSubTab(`questions`)
    };

    const handleMainTabChange = (tab: string) => {
        if( tab === 'chats' && publicOrganization && publicOrganization?.messages?.length && !activeSessionId){
            const filterMessageByGroupId = publicOrganization?.messages.filter((msg) => msg?.groupId?.toString() === group?._id?.toString() && msg?.userId.toString() === organizationMember?.member?._id);
            const message = filterMessageByGroupId[filterMessageByGroupId?.length - 1];
            localStorage.setItem('sessionId', message?.sessionId)
        }
        localStorage.setItem('activeMainTab', tab); // Cache the active tab
        setActiveTab(tab);
    };

    return (
        <div className="layout-wrapper d-lg-flex">


            <div className="side-menu flex-lg-column me-lg-1 ms-lg-0">
                <div className="navbar-brand-box mt-5">
                    {/*{*/}
                    {/*    publicOrganization?.bot?.botLogo ?*/}
                    {/*        <img src={publicOrganization?.bot.botLogo} alt="Sample" height={40} width={40}/> :*/}
                    {/*        */}
                    {/*}*/}

                    <span className="logo link">
                        <div className="rainbow-m-bottom_xx-large rainbow-p-bottom_xx-large">
                            <AvatarMenu
                                className="rainbow-m-around_x-small text-capitalize"
                                assistiveText={publicOrganization?.organizationName}
                                initials={publicOrganization?.organizationName}
                                title={publicOrganization?.organizationName}
                                id="avatar-menu"
                                src={publicOrganization?.bot.botLogo}
                                menuAlignment="left"
                                menuSize="small"
                                avatarSize="medium"
                            >
                                <div className='m-2'>
                                    <div
                                        className='fs-5 fw-bold text-capitalize text-center'>{publicOrganization?.organizationName}</div>
                                    <div className='d-flex flex-row align-items-center justify-content-between mt-3'>
                                        <div className='fs-6 fw-bold'>Groups</div>
                                        <div>
                                            <AvatarGroup
                                                size={"small"}
                                                avatars={publicOrganization?.memberGroups?.map((g) => {
                                                    return (
                                                        {
                                                            initials: g?.groupName,
                                                            assistiveText: g?.groupName,
                                                            title: g?.groupName,
                                                        }
                                                    )
                                                })}
                                                maxAvatars={4}
                                            />
                                        </div>
                                    </div>
                                     <div className='d-flex flex-row align-items-center justify-content-between mt-2'>
                                        <div className='fs-6 fw-bold'>Members</div>
                                        <div>
                                            <AvatarGroup
                                                size={"small"}
                                                avatars={publicOrganization?.organizationMembers?.map((m) => {
                                                    return (
                                                        {
                                                            initials: m?.firstname,
                                                            assistiveText: m?.firstname,
                                                            title: `${m?.firstname} ${m?.firstname}`,
                                                        }
                                                    )
                                                })}
                                                maxAvatars={4}
                                            />
                                        </div>
                                    </div>
                                    <div className='d-flex flex-row align-items-center justify-content-between mt-2'>
                                        <div className='fs-6 fw-bold'>Messages</div>
                                        <div>
                                            <Avatar
                                                size={"small"}
                                                title={`${publicOrganization?.organizationName?.length}`}
                                                assistiveText={`${publicOrganization?.organizationName?.length}`}
                                                initials={`${publicOrganization?.organizationName?.length}`}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </AvatarMenu>
                        </div>
                    </span>
                </div>
                <div className="flex-lg-column my-auto">
                    <ul className="nav nav-pills side-menu-nav justify-content-center" role="tablist">
                        <li className="nav-item" data-bs-toggle="tooltip" data-bs-placement="top" title="Chats">
                            <span className={`link nav-link ${activeTab === 'chats' ? 'active' : ''}`}
                                  onClick={() => handleMainTabChange(`chats`)} role="tab">
                                <i className="ri-message-3-line"></i>
                            </span>
                        </li>
                        <li className="nav-item" data-bs-toggle="tooltip" data-bs-placement="top" title="Groups">
                            <span className={`link nav-link ${activeTab === 'groups' ? 'active' : ''}`}
                                  onClick={() => handleMainTabChange(`groups`)}
                                  role="tab"><i className="ri-group-line"></i>
                            </span>
                        </li>
                        <li className="nav-item" data-bs-toggle="tooltip" data-bs-placement="top" title="Members">
                            <span className={`link nav-link ${activeTab === 'members' ? 'active' : ''}`}
                                  onClick={() => handleMainTabChange(`members`)}
                                  role="tab">
                                <i className="ri-contacts-line"></i>
                            </span>
                        </li>
                        <li className="nav-item" data-bs-toggle="tooltip" data-bs-placement="top" title="Settings">
                            <a className={`link nav-link ${activeTab === 'settings' ? 'active' : ''}`}
                               onClick={() => handleMainTabChange(`settings`)}
                               role="tab">
                                <i className="ri-settings-2-line"></i>
                            </a>
                        </li>
                        <li className="nav-item dropdown profile-user-dropdown d-inline-block d-lg-none">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                               aria-haspopup="true" aria-expanded="false">
                                <img src="assets/images/users/avatar-1.jpg" alt=""
                                     className="profile-user rounded-circle"/>
                            </a>
                            <div className="dropdown-menu">
                                <a className="dropdown-item" href="#">Profile <i
                                    className="ri-profile-line float-end text-muted"></i></a>
                                <a className="dropdown-item" href="#">Setting <i
                                    className="ri-settings-3-line float-end text-muted"></i></a>
                                <div className="dropdown-divider"></div>
                                <a className="dropdown-item" href="#">Log out <i
                                    className="ri-logout-circle-r-line float-end text-muted"></i></a>
                            </div>
                        </li>
                    </ul>
                </div>

                <div className="flex-lg-column d-none d-lg-block">
                    <ul className={`nav side-menu-nav justify-content-center mb-3`}>
                        <AvatarMenu
                            className="rainbow-m-horizontal_medium"
                            id="avatar-menu"
                            menuAlignment="left"
                            menuSize="small"
                            avatarSize="medium"
                            initials={`${organizationMember?.member.firstname?.charAt(0).toUpperCase()}${organizationMember?.member.lastname?.charAt(0).toUpperCase()}`}
                        >
                            <li className="">
                                <div className='d-flex align-items-center justify-content-start gap-2 ps-2'>
                                    <Avatar
                                        className="rainbow-m-around_x-small"
                                        assistiveText={organizationMember?.member.firstname}
                                        initials={`${organizationMember?.member.firstname?.charAt(0).toUpperCase()}${organizationMember?.member.lastname?.charAt(0).toUpperCase()}`}
                                        title={organizationMember?.member.firstname}
                                    />
                                    <div>
                                        <div className="rainbow-m-left_x-small">
                                            {organizationMember?.member.firstname} {organizationMember?.member.lastname}
                                        </div>
                                        <div>
                                            {organizationMember?.member.email}
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <MenuDivider variant="space"/>
                            <MenuItem
                                label="Edit Profile"
                                iconPosition="left"
                                onClick={()=> setShowModalForEditProfile(true)}
                            />
                            <MenuItem
                                onClick={logout}
                                label="Logout"
                                iconPosition="left"
                            />
                        </AvatarMenu>
                    </ul>

                </div>
            </div>


            <div className="chat-leftsidebar me-lg-1 ms-lg-0">

                <div className="tab-content">
                    {activeTab === 'chats' && publicOrganization && (<OrganizationChat organization={publicOrganization} setActiveGroup={setActiveGroup}/>)}
                    {activeTab === 'groups' && publicOrganization && (<OrganizationGroups organization={publicOrganization} setActiveGroup={setActiveGroup} />)}
                    {activeTab === 'members' && publicOrganization && (<OrganizationMembers organization={publicOrganization}/>)}
                    {activeTab === 'settings' && publicOrganization && (<OrganizationMemberSettings organization={publicOrganization}/>)}
                </div>
            </div>
            <div className="user-chat w-100 overflow-hidden" style={{width:'100%', height:'100vh'}}>
                        <div className="w-100 overflow-hidden position-relative d-flex flex-row">
                            <div className='w-100'>

                                {group && (activeTab === 'chats' || activeTab === 'groups') && (
                                    <div className="p-1 p-lg-1 border-bottom user-chat-topbar position-sticky">
                                        <div
                                            className="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                            <div className="">
                                                <div className="d-flex align-items-center">
                                                    <div className="d-block d-lg-none me-2 ms-0">
                                                        <a href="javascript: void(0);"
                                                           className="user-chat-remove text-muted font-size-16 p-2"><i
                                                            className="ri-arrow-left-s-line"></i></a>
                                                    </div>
                                                    <Avatar
                                                        className="rainbow-m-around_x-small me-2 text-capitalize"
                                                        size={'medium'}
                                                        assistiveText={group?.groupName}
                                                        initials={group?.groupName}
                                                        title={group?.groupName}
                                                    />
                                                    <div className="flex-grow-1 overflow-hidden">
                                                        <h5 className="font-size-16 mb-0 text-truncate">
                                                            <a href="#" className="text-reset user-profile-show text-capitalize">
                                                                {group?.groupName}
                                                            </a>
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="me-2">
                                                <button type="button"
                                                        className="btn btn-primary nav-btn user-profile-show"
                                                        onClick={() => {
                                                            setActiveGroup(group)
                                                            setActiveTab('chats')
                                                            localStorage.removeItem('sessionId')
                                                            setNewChat(true);
                                                        }}>
                                                    <i className="ri-chat-1-fill"></i> New Chat
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {group && organizationMember && activeTab === 'chats' && (
                                    <div className={`row`} style={{height: '100vh'}}>

                                        <div className={`col-8`}>

                                            <BrainaqueChatMessage resetNewQuery={() => setNewQuery('')}
                                                                  newQuery={newQuery}
                                                                  apiKey={organizationMember.apiKey}
                                                                  theme={defaultTheme}
                                                                  groupId={group?._id}
                                                                  userId={organizationMember.member._id}
                                                                  activeSessionId={sessionId}
                                            />
                                        </div>
                                        <div className={`col-4`} style={{height: '94%'}}>
                                            <div className='mt-3 me-2'>
                                                <MultiSelect
                                                    label="Filter as per the Document"
                                                    labelAlignment="left"
                                                    placeholder="Select Documents"
                                                    className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
                                                    value={activeDocuments}
                                                    onChange={setActiveDocuments}
                                                    bottomHelpText="You can select several options."
                                                    showCheckbox
                                                    borderRadius="semi-rounded"
                                                >
                                                    {group.questions
                                                        .filter((question, index, self) =>
                                                                index === self.findIndex((q) =>
                                                                    group.groupDocuments.find((groupDocument) => groupDocument._id === q.documentid)?._id ===
                                                                    group.groupDocuments.find((groupDocument) => groupDocument._id === question.documentid)?._id
                                                                )
                                                        )
                                                        .map((question) => {
                                                            const activeDocumentQuestion = group.groupDocuments.find(
                                                                (groupDocument) => groupDocument._id === question.documentid
                                                            );

                                                            return (
                                                                activeDocumentQuestion && (
                                                                    <Option
                                                                        key={activeDocumentQuestion._id}
                                                                        name={activeDocumentQuestion._id}
                                                                        label={activeDocumentQuestion.name}
                                                                    />
                                                                )
                                                            );
                                                        })}
                                                </MultiSelect>

                                                <div className="nk-block mt-3">
                                                    <div>
                                                        <ul className="nav nav-tabs mb-3 nav-tabs-s1 gap-3">
                                                            <li className="nav-item-custom">
                                                                <button
                                                                    className={`nav-link-custom ${activeSubTab === 'questions' ? 'custom-active' : ''}`}
                                                                    onClick={() => setActiveSubTab(`questions`)}
                                                                    type="button">Questions
                                                                </button>
                                                            </li>
                                                            <li className="nav-item-custom">
                                                                <button
                                                                    className={`nav-link-custom ${activeSubTab === 'documents' ? 'custom-active' : ''}`}
                                                                    onClick={() => setActiveSubTab(`documents`)}
                                                                    type="button">Documents
                                                                </button>
                                                            </li>
                                                        </ul>


                                                    </div>

                                                    <div className="tab-content pt-1"
                                                         style={{height: '65vh', overflowX: 'hidden', overflowY: 'auto'}}>
                                                        {activeSubTab === 'questions' && (
                                                            <div>
                                                                {group.questions.length ? (
                                                                    group.questions.filter((question) => activeDocuments?.some((doc: any) => doc.name === question.documentid)).map((question, index) =>
                                                                        <div key={`questions_${index}`} role={'button'}
                                                                             className="mb-2 px-3 py-2 question-item" // Add a custom class
                                                                             style={{
                                                                                 backgroundColor: 'rgb(239,248,255)',
                                                                                 borderRadius: 10
                                                                             }} onClick={() => {
                                                                            setNewQuery(question.question)
                                                                        }}
                                                                        >
                                                                            {question.question}
                                                                        </div>
                                                                    )
                                                                ) : (
                                                                    <EmptyMessage
                                                                        type="organization"
                                                                        title="No Questions available."
                                                                        description="You have no Questions Created. Add Document to start Chat."
                                                                        buttonText="Select Document"
                                                                        noButton={true}
                                                                        buttonCallback={() => {

                                                                        }}
                                                                    />
                                                                )}
                                                            </div>
                                                        )}
                                                        {activeSubTab === 'documents' && (
                                                            <div>
                                                                {group.groupDocuments.length && group.groupDocuments.filter((groupDocument) => activeDocuments?.some((doc: any) => doc.name === groupDocument._id)).length ? (
                                                                    group.groupDocuments.filter((groupDocument) => activeDocuments?.some((doc: any) => doc.name === groupDocument._id)).map((document, index) => {
                                                                        return (
                                                                            <div
                                                                                key={`document_${index}`}
                                                                                role={'button'}
                                                                                className='mb-2 px-3 py-2 d-flex flex-row align-items-center justify-content-start gap-2 document-item'
                                                                                style={{
                                                                                    borderRadius: 10,
                                                                                    backgroundColor: '#f3f3f3'
                                                                                }}
                                                                                onClick={() => {
                                                                                    setSelectedDocument(document)
                                                                                    setShowDocumentModal(true)
                                                                                }}>
                                                                                <div style={{
                                                                                    width: 40,
                                                                                    height: 40,
                                                                                    justifyContent: 'center',
                                                                                    alignItems: 'center',
                                                                                    display: 'flex',
                                                                                    borderRadius: 10,
                                                                                    padding: 10,
                                                                                    backgroundColor: '#b8efff'
                                                                                }}>
                                                                                    <i className="ri-file-text-fill"></i>
                                                                                </div>
                                                                                <div style={{
                                                                                    width: '90%',
                                                                                    whiteSpace: 'normal',
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis'
                                                                                }}>{document?.name} {group.groupDocuments.length}</div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                ) : (
                                                                    <EmptyMessage
                                                                        type="organization"
                                                                        title="No Documents available."
                                                                        description="You have no Document Added. Add Document to start Chat."
                                                                        buttonText="Select Document"
                                                                        noButton={true}
                                                                        buttonCallback={() => {

                                                                        }}
                                                                    />
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                )}

                                {activeTab === 'members' && (
                                    <div className="tab-pane fade show active" id="pills-groups" role="tabpanel"
                                         aria-labelledby="pills-groups-tab">
                                        <div>
                                            <div className="pt-4 mx-2">
                                                <h4 className="mb-4">Members</h4>
                                            </div>

                                            <div className="chat-message-list chat-group-list ms-3 border-light border border-1 pe-5 pt-5">
                                                {publicOrganization && publicOrganization.memberGroups?.length ? (
                                                    publicOrganization.memberGroups.map((memberGroup) => (
                                                            <div key={memberGroup._id}>
                                                                <div className="fw-bold text-primary text-capitalize">
                                                                    {memberGroup.groupName}
                                                                </div>

                                                                <ul className="list-unstyled contact-list">
                                                                    {memberGroup.groupMembers.map((member) => (
                                                                            <li key={member._id} className='m-0'>
                                                                                <div
                                                                                    className="d-flex align-items-center">
                                                                                    <div className="flex-grow-1">
                                                                                        <h5 className="font-size-14 m-0">{member.firstname} {member.lastname}</h5>
                                                                                        <p className="chat-user-message text-truncate mb-0">{member.email}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        ))}
                                                                </ul>
                                                            </div>
                                                        ))
                                                ) : (
                                                    <div className='d-flex justify-content-center align-items-center'>
                                                        <div className='d-flex flex-column align-items-center'>
                                                            <EmptyMessage
                                                                type="chat"
                                                                title="No Members Available!"
                                                                description="It seems you currently don't have any members in this group. Please add members to get started."
                                                                buttonText="Add Members"
                                                                buttonCallback={() => {
                                                                }}
                                                                noButton={true}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>

                                        </div>
                                    </div>
                                )}

                                {activeTab === 'settings' && (
                                    <div>
                                        <div className="pt-4 mx-2">
                                            <h4 className="mb-4">Settings</h4>
                                        </div>

                                        <div className='m-5'>
                                            <div className="text-center border-bottom">
                                                <div className="mb-2 profile-user">
                                                    <Avatar
                                                        className="rainbow-m-around_x-small"
                                                        assistiveText={`${organizationMember?.member.firstname} ${organizationMember?.member.lastname}`}
                                                        initials={`${organizationMember?.member.firstname?.charAt(0).toUpperCase()}${organizationMember?.member.lastname?.charAt(0).toUpperCase()}`}
                                                        title={`${organizationMember?.member.firstname} ${organizationMember?.member.lastname}`}
                                                        size={`large`}
                                                    />
                                                </div>

                                                <h5 className="font-size-16 mb-3 text-truncate">{organizationMember?.member.firstname} {organizationMember?.member.lastname}</h5>
                                            </div>

                                            <div className="px-2 pt-4 user-profile-desc" data-simplebar="init">

                                                <ul className="list-group">


                                                    <li className="list-group-item d-flex justify-content-between">
                                                        <div className="mt-2">
                                                            <p className="text-muted mb-1">Name</p>
                                                            <h5 className="font-size-14">{organizationMember?.member.firstname} {organizationMember?.member.lastname}</h5>
                                                        </div>

                                                        <div className="float-end">
                                                            <button type="button" className="btn btn-light btn-sm"
                                                                    onClick={() => {
                                                                        setShowMemberForm(true)
                                                                    }}><i
                                                                className="ri-edit-fill me-1 ms-0 align-middle"></i> Edit
                                                            </button>
                                                        </div>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <div className="mt-2">
                                                            <p className="text-muted mb-1">Email</p>
                                                            <h5 className="font-size-14">{organizationMember?.member.email}</h5>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {activeTab === 'groups' && group ? (
                                    <div className="nk-block px-2">
                                        <ul className="nav nav-tabs my-3 nav-tabs-s1 gap-4">
                                            <li className="nav-item-custom">
                                                <button
                                                    className={`nav-link-custom ${activeSubTab === 'chats' ? 'custom-active' : ''}`}

                                                    onClick={() => setActiveSubTab('chats')}
                                                    type="button">Recent chats
                                                </button>
                                            </li>
                                            <li className="nav-item-custom">
                                                <button
                                                    className={`nav-link-custom ${activeSubTab === 'questions' ? 'custom-active' : ''}`}
                                                    onClick={() => setActiveSubTab('questions')}
                                                    type="button">Questions
                                                </button>
                                            </li>
                                            <li className="nav-item-custom">
                                                <button
                                                    className={`nav-link-custom ${activeSubTab === 'tags' ? 'custom-active' : ''}`}
                                                    onClick={() => setActiveSubTab('tags')}
                                                    type="button">Tags
                                                </button>
                                            </li>
                                            {group?.showDocuments && (
                                                <li className="nav-item-custom">
                                                    <button
                                                        className={`nav-link-custom ${activeSubTab === 'documents' ? 'custom-active' : ''}`}
                                                        onClick={() => setActiveSubTab('documents')}
                                                        type="button">Documents
                                                    </button>
                                                </li>
                                            )}
                                            {group?.showMembers && (
                                                <li className="nav-item-custom">
                                                    <button
                                                        className={`nav-link-custom ${activeSubTab === 'members' ? 'custom-active' : ''}`}
                                                        onClick={() => setActiveSubTab('members')}
                                                        type="button">Members
                                                    </button>
                                                </li>
                                            )}
                                        </ul>

                                        <div className="tab-content" style={{height: '90vh', overflowY: 'auto'}}>

                                            {activeSubTab === 'chats' && (
                                                <ul className="list-unstyled chat-list chat-user-list pb-5">
                                                    {publicOrganization?.messages
                                                        .filter((message) => message.groupId === group._id)
                                                        .sort((a, b) =>
                                                            dayjs(b.chats[b.chats.length - 1]?.createdAt).valueOf() -
                                                            dayjs(a.chats[a.chats.length - 1]?.createdAt).valueOf()
                                                        ) // Sort messages by most recent
                                                        .length ? (
                                                        publicOrganization.messages
                                                            .filter((message) => message.groupId === group._id)
                                                            .sort((a, b) =>
                                                                dayjs(b.chats[b.chats.length - 1]?.createdAt).valueOf() -
                                                                dayjs(a.chats[a.chats.length - 1]?.createdAt).valueOf()
                                                            )
                                                            .map((message, key) => (
                                                                <li key={key} onClick={() => {
                                                                    const activeNewGroup = publicOrganization.memberGroups.find((memberGroup) => memberGroup._id === message.groupId);
                                                                    setActiveGroup(activeNewGroup, message.sessionId);
                                                                    localStorage.setItem('sessionId', message.sessionId);
                                                                    setActiveTab('chats');
                                                                }}>
                                                                    <a href="#">
                                                                        <div className="d-flex gap-2">
                                                                            <div
                                                                                className="flex-grow-1 overflow-hidden">
                                                                                <h5 className="text-truncate font-size-15 mb-1">{message.groupName}</h5>
                                                                                <p className="chat-user-message text-truncate mb-0 ellipsis"
                                                                                   style={{width: '80%'}}>
                                                                                    {message.chats[message.chats.length - 1]?.message}
                                                                                </p>
                                                                            </div>
                                                                            <div className="font-size-11 text-nowrap">
                                                                                {dayjs(message.chats[message.chats.length - 1]?.createdAt).fromNow()}
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                </li>
                                                            ))
                                                    ) : (
                                                        <EmptyMessage
                                                            type="chat"
                                                            title="No Recent Chats"
                                                            description="You have no recent chats. Start a new conversation by selecting a contact or creating a new chat group."
                                                            buttonText="Start New Chat"
                                                            buttonCallback={() => {
                                                                // Add your callback logic here, if needed
                                                            }}
                                                            noButton={true}
                                                        />
                                                    )}
                                                </ul>
                                            )}

                                            {activeSubTab === 'questions' && (
                                                <div className='mt-3 mx-2 pb-5'>
                                                    {group.questions.filter((question) => activeDocuments?.some((doc: any) => doc.name === question.documentid))?.length ? (
                                                        <>
                                                            <MultiSelect
                                                                label="Filter as per the Document"
                                                                placeholder="Select Documents"
                                                                labelAlignment="left"
                                                                className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
                                                                value={activeDocuments}
                                                                onChange={setActiveDocuments}
                                                                showCheckbox
                                                                borderRadius="semi-rounded"
                                                            >
                                                                {group?.questions
                                                                    .filter((question, index, self) =>
                                                                            index === self.findIndex((q) =>
                                                                                group.groupDocuments.find((groupDocument) => groupDocument._id === q.documentid)?._id ===
                                                                                group.groupDocuments.find((groupDocument) => groupDocument._id === question.documentid)?._id
                                                                            )
                                                                    )
                                                                    .map((question) => {
                                                                        const activeDocumentQuestion = group.groupDocuments.find(
                                                                            (groupDocument) => groupDocument._id === question.documentid
                                                                        );

                                                                           return (
                                                                               activeDocumentQuestion && (
                                                                                   <Option
                                                                                       key={activeDocumentQuestion._id}
                                                                                       name={activeDocumentQuestion._id}
                                                                                       label={activeDocumentQuestion.name}
                                                                                   />
                                                                               )
                                                                           );
                                                                       })}
                                                               </MultiSelect>
                                                               <table className="table table-flush table-middle mb-0 mt-4"
                                                                      style={{height: '50%'}}>
                                                                   <tbody>
                                                                   <tr>
                                                                       {group.questions.filter((question) => activeDocuments?.some((doc: any) => doc.name === question.documentid)).map((question, index) =>
                                                                           <div key={`questions_${index}`} role={'button'}
                                                                                className="mb-2 px-3 py-2 question-item" // Add a custom class
                                                                                style={{
                                                                                    backgroundColor: 'rgb(239,248,255)',
                                                                                    borderRadius: 10
                                                                                }} onClick={() => {
                                                                               setActiveSubTab('questions')
                                                                               setNewQuery(question.question)
                                                                               setActiveGroup(group)
                                                                               setActiveTab('chats')
                                                                               localStorage.removeItem('sessionId')
                                                                           }}
                                                                           >
                                                                               {index + 1}. {question.question}
                                                                           </div>
                                                                       )}
                                                                   </tr>
                                                                   </tbody>
                                                               </table>
                                                           </>
                                                    ):(
                                                        <EmptyMessage
                                                            type="doc"
                                                            title="No Document Uploaded!"
                                                            description="Upload a document to receive AI-generated questions related to its content. Without an uploaded document, AI-generated questions will be unavailable."
                                                            buttonText="Upload Document"
                                                            buttonCallback={() => {
                                                                // Add your callback logic here, if needed
                                                            }}
                                                            noButton={true}
                                                        />
                                                    )}
                                                </div>
                                            )}
                                            {activeSubTab === 'tags' && (
                                                <div className='mt-3 mx-2 pb-5'>
                                                    {group.tags?.length ? (
                                                        group.tags.map((tagObj, index) => {
                                                        // Split the tag string by ' - ' and filter out empty strings
                                                        const tagsArray = tagObj.tag
                                                            .split('-')
                                                            .map((item: any) => item.trim())
                                                            .filter((item: any) => item !== "");

                                                        return (
                                                            <React.Fragment key={index}>
                                                                {tagsArray.map((tagItem: any, tagIndex: number) => (

                                                                    <Badge
                                                                        className="rainbow-m-around_medium m-1 py-2 px-4 badge-hover"
                                                                        label={tagItem}
                                                                        variant="outline-brand"
                                                                        title={tagItem}
                                                                    />
                                                                ))}
                                                            </React.Fragment>
                                                        );
                                                    })
                                                    ):(
                                                        <EmptyMessage
                                                            type="doc"
                                                            title="No Document Uploaded!"
                                                            description="Upload a document to receive AI-generated tags related to its content. Without an uploaded document, AI-generated tags will be unavailable."
                                                            buttonText="Upload Document"
                                                            buttonCallback={() => {
                                                                // Add your callback logic here, if needed
                                                            }}
                                                            noButton={true}
                                                        />
                                                    )}


                                                </div>
                                            )}
                                            {activeSubTab === 'documents' && (
                                                <div className='mt-1 mx-2 pb-5'>
                                                    <div
                                                        className="d-flex align-items-center justify-content-between pb-2">
                                                        <h5>Documents</h5>
                                                        {
                                                            currentMemberRole() && (currentMemberRole()?.isAdmin || currentMemberRole()?.manageDocument) && (
                                                                <button className="btn btn-primary"
                                                                        onClick={() => setUploadDocument(true)}><em
                                                                    className="icon ni ni-plus"></em><span>Upload</span>
                                                                </button>
                                                            )
                                                        }
                                                    </div>
                                                    <div className="nk-block">

                                                        <div className="table-responsive card shadow-none">
                                                            <table className={`table table-middle mb-0`}>
                                                                <tbody>

                                                                {group?.groupDocuments && group?.groupDocuments.length ? group?.groupDocuments.slice()
                                                                        .sort((a, b) => dayjs(b.createdAt).valueOf() - dayjs(a.createdAt).valueOf()).map((document) => (

                                                                            <tr className=''>
                                                                                <td className="tb-col">
                                                                                    <div
                                                                                        role={'button'}
                                                                                        className="caption-text line-clamp-2 cursor-pointer"
                                                                                        onClick={() => {
                                                                                            setSelectedDocument(document)
                                                                                            setShowDocumentModal(true)
                                                                                        }}>{document.name}</div>
                                                                                </td>
                                                                                <td className="tb-col tb-col-sm">
                                                                                    <div
                                                                                        className="badge text-bg-dark rounded-pill px-2 py-1 fs-6 lh-sm">{document.extension}</div>
                                                                                </td>
                                                                                <td className="tb-col">
                                                                                    <div
                                                                                        className={`badge rounded-pill px-2 py-1 fs-6 lh-sm ${document.isProcessed ? 'text-bg-success-soft ' : 'text-bg-warning'}`}>{document.isProcessed ? 'Processed' : 'Processing'}</div>
                                                                                </td>
                                                                                <td className="tb-col tb-col-sm">
                                                                                    <div
                                                                                        className={`badge rounded-pill px-2 py-1 fs-6 lh-sm ${document.isProcessed ? 'text-bg-success-soft' : 'text-bg-warning'}`}>{document.logs && document.logs.length ? document.logs[document.logs.length - 1].percent : ''}</div>
                                                                                </td>
                                                                                <td className="tb-col tb-col-md">
                                                                                    <div
                                                                                        className="fs-6 text-light d-inline-flex flex-wrap gap gx-2">
                                                                                        <span
                                                                                            className='text-dark'>{dayjs(document.createdAt).format('MMM DD, YYYY')}</span>
                                                                                        <span
                                                                                            className='text-dark'>{dayjs(document.createdAt).format('hh:mm A')}</span>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="tb-col tb-col-end">
                                                                                    <ButtonMenu
                                                                                        menuAlignment="right"
                                                                                        menuSize="x-small"
                                                                                        buttonVariant="base"
                                                                                        icon={<em
                                                                                            className="icon ni ni-more-h"></em>}
                                                                                    >

                                                                                        <MenuItem icon={<em
                                                                                            className="icon ni ni-trash"></em>}
                                                                                                  onClick={() => {

                                                                                                      setSelectedDocument(document)
                                                                                                      setManageModal(true)
                                                                                                  }} label="Delete"/>
                                                                                    </ButtonMenu>
                                                                                </td>
                                                                            </tr>

                                                                        )) :

                                                                    <EmptyMessage
                                                                        type="doc"
                                                                        title="No documents available."
                                                                        description="You have no documents stored. Upload documents to start organizing your files."
                                                                        buttonText="Upload Document"
                                                                        buttonCallback={() => {
                                                                            // setShowModal(true)
                                                                        }}
                                                                    />
                                                                }


                                                                </tbody>
                                                            </table>

                                                        </div>

                                                    </div>

                                                </div>
                                            )}
                                            {activeSubTab === 'members' && (
                                                <div className='mt-1 mx-2 pb-5'>
                                                    <div
                                                        className="d-flex align-items-center justify-content-between pb-2">
                                                        <h5>Members</h5>
                                                        {
                                                            currentMemberRole() && (currentMemberRole()?.isAdmin || currentMemberRole()?.manageMember) && (
                                                                <ButtonMenu
                                                                    menuAlignment="right"
                                                                    menuSize="x-small"
                                                                    borderRadius="semi-square"
                                                                    buttonVariant="brand"
                                                                    label={`Add Member`}
                                                                    buttonSize={`small`}
                                                                    icon={<em className="icon ni ni-plus"></em>}
                                                                >
                                                                    <MenuItem
                                                                        icon={<em className="icon ni ni-setting"></em>}
                                                                        onClick={() => {
                                                                            setExistingMemberModal(true)
                                                                        }} label="Manage Member"/>
                                                                    <MenuItem
                                                                        icon={<em className="icon ni ni-edit"></em>}
                                                                        onClick={() => {
                                                                            setShowMemberForm(true)
                                                                        }} label="Create new Member"/>
                                                                    <MenuItem
                                                                        icon={<em className="icon ni ni-download"></em>}
                                                                        onClick={() => {
                                                                            setShowMemberImport(true)
                                                                        }} label="Import Members"/>
                                                                </ButtonMenu>
                                                            )
                                                        }
                                                    </div>
                                                    <div className="nk-block">
                                                        <div className="table-responsive card shadow-none">
                                                            <table className={`table table-middle mb-0`}>
                                                                <tbody>
                                                                {group?.groupMembers && group?.groupMembers.length ? (
                                                                    group.groupMembers.map((groupMember) => (
                                                                        <tr key={groupMember._id} className="">
                                                                            <td className="tb-col">
                                                                                <div
                                                                                    className="d-flex gap-2 align-items-center">
                                                                                    <div
                                                                                        className="chat-user-img online align-self-center ms-0">
                                                                                        <Avatar
                                                                                            className="rainbow-m-around_x-small"
                                                                                            assistiveText={groupMember.firstname}
                                                                                            initials={`${groupMember?.firstname?.charAt(0).toUpperCase()}${groupMember?.lastname?.charAt(0).toUpperCase()}`}
                                                                                            title={groupMember.firstname}
                                                                                        />
                                                                                    </div>
                                                                                    <div
                                                                                        className="flex-grow-1 overflow-hidden">
                                                                                        <h5 className="text-truncate font-size-14 mb-0">
                                                                                            {groupMember.firstname} {groupMember.lastname}
                                                                                        </h5>
                                                                                        <p className="chat-user-message text-truncate mb-0">
                                                                                            {groupMember.email}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </td>

                                                                            {/* User Role */}
                                                                            <td className="tb-col">
                                                                                <div
                                                                                    className="text-dark text-capitalize">
                                                                                    {findRole(groupMember.role)}
                                                                                </div>
                                                                            </td>

                                                                            {/* Creation Date */}
                                                                            <td className="tb-col tb-col-end">
                                                                                <div
                                                                                    className="fs-6 text-light d-inline-flex flex-wrap gap gx-2">
                                                                                    <span className='text-dark'>
                                                                                        {dayjs(groupMember.createdAt).format('MMM DD, YYYY')}
                                                                                    </span>
                                                                                    <span className='text-dark'>
                                                                                        {dayjs(groupMember.createdAt).format('HH:MM A')}
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                ) : (
                                                                    <EmptyMessage
                                                                        type="doc"
                                                                        title="No members available."
                                                                        description="You have no group members listed. Add members to get started."
                                                                        buttonText="Add Member"
                                                                        buttonCallback={() => {
                                                                            // setShowModal(true)
                                                                        }}
                                                                        noButton={true}
                                                                    />
                                                                )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>

                                                </div>
                                            )}

                                        </div>
                                    </div>
                                ):(
                                    <div className='w-100 h-100 d-flex flex-column justify-content-center align-items-center mt-5 px-5'>
                                        {activeTab === 'groups' && !group && (
                                            <EmptyMessage
                                                type="chat"
                                                title="No Group Selected!"
                                                description="It seems you haven't selected a Group, or there are currently no Groups available. Please Create a group to start a conversation. Before proceeding, ensure that you've uploaded the necessary documents to initiate your discussion."
                                                buttonText="Add Shop"
                                                buttonCallback={() => {

                                                }}
                                                noButton={true}
                                            />
                                        )}

                                        {activeTab === 'chats' && !group && (
                                            <EmptyMessage
                                            type="chat"
                                            title="No Chat Selected!"
                                            description="It seems you haven't selected a Chat, or there are currently no chats available. Please select a group to start a conversation or create a new chat. Before proceeding, ensure that you've uploaded the necessary documents to initiate your discussion."
                                            buttonText="Add Shop"
                                            buttonCallback={() => {

                                            }}
                                            noButton={true}
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>


            </div>
            <Modal
                id="modal-upload-documents"
                title="Upload Documents"
                isOpen={uploadDocument}
                onRequestClose={() => {
                    setUploadDocument(false)
                }}
                footer={
                    <div className="d-flex justify-content-end">
                        {loading ? (
                            <Button isLoading label="Uploading" variant="brand" borderRadius="semi-square"
                                    className="rainbow-m-around_medium"/>
                        ) : (
                            <FormButton type={`button`} text={`Upload`} disabled={fileCount === 0}
                                        onClick={handleUpload}/>
                        )}

                    </div>
                }
            >
                <form>
                    <div className="row gy-3">
                        <div className="col-12">

                        <PublicOrganizationFileUpload ref={fileUploadRef} uploadType={`organization`}
                                                          _id={publicOrganization?._id!}
                                                          groupId={group?._id!}
                                                          onFileCountChange={(count) => setFileCount(count)}
                                                          setActiveGroup={setActiveGroup}
                            />
                        </div>

                    </div>
                </form>
            </Modal>

            <DocumentViewer group={group} document={selectedDocument!}
                            setShowModal={(e: boolean) => setShowDocumentModal(e)}
                            showModal={showDocumentModal}/>
            {selectedDocument && group && (
                <PublicDocumentManagePage setActiveGroup={setActiveGroup} groupId={group?._id}
                                          documentId={selectedDocument?._id}
                                          setShowModal={(e: boolean) => setManageModal(e)} showModal={manageModal}/>
            )}


            {group && (<PublicMembersForm setActiveGroup={setActiveGroup} group={group}
                                          setShowModal={(e: boolean) => setShowMemberForm(e)}
                                          showModal={showMemberForm}/>)}

            {/*{group && (<PublicMemberManage group={group} member={selectedMember} manageModal={memberManage} setManageModal={(e: boolean) => setMemberManage(e)}/>)}*/}

            {group && setActiveGroup && (
                <PublicExistingMemberManage setActiveGroup={setActiveGroup} showModal={existingMemberModal}
                                            setShowModal={(e) => setExistingMemberModal(e)} groupId={group?._id}/>)}

            <MemberForm publicOrganizationId={publicOrganization?._id!} member={organizationMember?.member}
                        isPublicMember={true} showModal={showModalForEditProfile}
                        setShowModal={() => setShowModalForEditProfile(false)}/>

            <ImportRecordsFlow
                isOpen={showMemberImport}
                onRequestClose={() => setShowMemberImport(false)}
                schema={{
                    collection: 'contacts',
                    attributes: {
                        firstName: {
                            type: String,
                            required: true,
                        },
                        email: {
                            type: String,
                            required: true,
                        },
                        phone: {
                            type: String,
                            required: true,
                        },
                        lastName: String,
                    },
                }}
                onComplete={data => console.log(data)}
                actionType="add-records"
            />

        </div>
    );
};
export default OrganizationChatContainer;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    createPaymentHistoryFailure,
    createPaymentHistoryStart,
    createPaymentHistorySuccess,
    updatePaymentHistorySuccess,
} from '../../../../redux/slices/paymentHistorySlice';
import { RootState } from '../../../../redux/rootReducer';
import { IPricingPlan } from '../../../../interfaces/pricingPlans.interface';
import { IPaymentHistory } from '../../../../interfaces/paymentHistory.interface';
import http from '../../../../services/api';
import { Button, Modal } from 'react-rainbow-components';
import { toastMessage } from '../../../../utils/util';
import loadRazorpay from '../../../../utils/loadRazorpay';
import PayPalSubscriptionButton from '../../payment/PayPalSubscriptionButton';
import { IUser } from '../../../../interfaces/user.interface';
import { fetchUserFailure, updateUserSuccess } from '../../../../redux/slices/userSlice';

interface ManageProps {
    showModal: boolean;
    setShowModal: any;
    plan: IPricingPlan;
    subscriptionType: string;
}

const BuyToken: React.FC<ManageProps> = ({ showModal, setShowModal, plan, subscriptionType }) => {
    const { user } = useSelector((state: RootState) => state.user);
    const { loading } = useSelector((state: RootState) => state.paymentHistory);

    const dispatch = useDispatch();

    const [gstAmount, setGstAmount] = useState<number>(0);
    const [totalAmount, setTotalAmount] = useState<number>(0);

    const calculateAmounts = () => {
        const baseAmount =
            subscriptionType === 'monthly'
                ? user?.countryCode === 'IN'
                    ? plan.pricing.monthly.inr
                    : plan.pricing.monthly.usd
                : user?.countryCode === 'IN'
                    ? plan.pricing.yearly.inr
                    : plan.pricing.yearly.usd;

        const gst = baseAmount * 0.18;
        const total = baseAmount + gst;

        setGstAmount(gst);
        setTotalAmount(total);
    };

    useEffect(() => {
        calculateAmounts();
    }, [plan, subscriptionType, user]);

    const createSubscription = async () => {
        const amount = user?.countryCode === 'IN'
            ? subscriptionType === 'monthly'
                ? plan.pricing.monthly.inr
                : plan.pricing.yearly.inr
            : subscriptionType === 'monthly'
                ? plan.pricing.monthly.usd
                : plan.pricing.yearly.usd;

        const currency = user?.countryCode === 'IN' ? 'inr' : 'usd';

        try {
            dispatch(createPaymentHistoryStart());

            const response = await http.post<IPaymentHistory>('/create-order', {
                planId: plan._id,
                tokens:plan.tokens,
                razorpayPlanId: plan.razorpayPlanId,
                subscriptionType,
                amount,
                currency,
                gateway: 'razorpay',
            });

            dispatch(createPaymentHistorySuccess(response.data));
            initializeRazorpay(response.data, subscriptionType);
        } catch (error) {
            dispatch(fetchUserFailure('Initial Payment to fetch user'));
        }
    };

    const initializeRazorpay = async (paymentData: IPaymentHistory, subscriptionType: string) => {
        const isLoaded = await loadRazorpay();
        if (!isLoaded) {
            toastMessage('Razorpay SDK failed to load. Are you online?', 'error');
            return;
        }

        const options = {
            key: process.env.REACT_APP_RAZORPAY_KEY,
            subscription_id: paymentData.transactionId,
            name: process.env.REACT_APP_COMPANY_NAME,
            description: `${plan.title} - ${plan.subtitle}`,
            amount: 50,
            handler: async (response: any) => {
                let responseData = await http.post<{ paymentHistory: IPaymentHistory, userData: IUser }>('/verify-order', {
                    razorpay_payment_id: response.razorpay_payment_id,
                    razorpay_signature: response.razorpay_signature,
                    razorpay_subscription_id: response.razorpay_subscription_id,
                    subscriptionType,
                });

                dispatch(updateUserSuccess(responseData.data.userData));
                localStorage.setItem('user', JSON.stringify(responseData.data.userData));

                dispatch(updatePaymentHistorySuccess(responseData.data.paymentHistory));
            },
            theme: { color: '#37b6ff' },
        };

        const rzp = new (window as any).Razorpay(options);
        rzp.open();
        setShowModal(false);
    };

    return (
        <Modal
            id="modal-buy-tokens"
            size="large"
            title="Checkout"
            isOpen={showModal}
            onRequestClose={() => setShowModal(false)}
        >
            <div className='d-flex flex-row flex-wrap justify-content-between align-items-center p-4'
                 style={{ borderRadius: 10, background: 'linear-gradient(to top left, #BFEBFFFF, #F4E5FFFF, #BFEBFFFF)' }}
            >
                <div className='col-12 col-md-6'>
                    <div className="media media-rg media-middle media-circle text-primary bg-primary bg-opacity-20 mb-3">
                        <em className="icon ni ni-spark-fill"></em>
                    </div>
                    <h2 className="h1">Buy Tokens</h2>
                    <p>Are you sure you want to purchase additional tokens? Your current plan is valid till <span className="text-dark">23 Mar, 2023</span>.</p>
                    <div className="card bg-lighter bg-opacity-80 shadow-none mt-2">
                        <div className="card-body">
                            <h5>What will happen after purchasing tokens?</h5>
                            <ul className="list-dot gap gy-2 mt-2">
                                <li>Extra tokens will be added to your account for additional usage.</li>
                                <li>Your plan remains active as per the current subscription.</li>
                                <li>Immediate access to new tokens for AI content generation and other features.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-5 p-4 shadow' style={{ backgroundColor: '#fff', borderRadius: 10 }}>
                    <div className="d-flex flex-wrap flex-grow-1">
                        <div className="w-100 d-flex align-items-center justify-content-center justify-content-lg-end">
                            <div className="wide-xs w-100">
                                <div className="pt-4">
                                    <div className="fs-4 fw-normal">Purchase {plan.tokens} Tokens</div>
                                    <h3 className="display-1 fw-semibold">
                                        {user?.countryCode === 'IN' ? '₹' : '$'}
                                        {subscriptionType === 'monthly'
                                            ? user?.countryCode === 'IN' ? plan.pricing.monthly.inr : plan.pricing.monthly.usd
                                            : user?.countryCode === 'IN' ? plan.pricing.yearly.inr : plan.pricing.yearly.usd}
                                        <span className="caption-text text-light fw-normal"> total</span>
                                    </h3>
                                    <ul className="mt-4 gap gy-4 pb-2">
                                        <li className="d-flex justify-content-between">
                                            <div className="caption-text">Tokens: {plan.tokens}</div>
                                            <div className="caption-text">
                                                {user?.countryCode === 'IN' ? '₹' : '$'}
                                                {subscriptionType === 'monthly'
                                                    ? user?.countryCode === 'IN' ? plan.pricing.monthly.inr : plan.pricing.monthly.usd
                                                    : user?.countryCode === 'IN' ? plan.pricing.yearly.inr : plan.pricing.yearly.usd}
                                            </div>
                                        </li>
                                        <li className="d-flex justify-content-between">
                                            <div className="caption-text">Subtotal</div>
                                            <div className="caption-text">
                                                {user?.countryCode === 'IN' ? '₹' : '$'}
                                                {subscriptionType === 'monthly'
                                                    ? user?.countryCode === 'IN' ? plan.pricing.monthly.inr : plan.pricing.monthly.usd
                                                    : user?.countryCode === 'IN' ? plan.pricing.yearly.inr : plan.pricing.yearly.usd}
                                            </div>
                                        </li>
                                        <li className="d-flex justify-content-between">
                                            <div className="caption-text">GST (18%)</div>
                                            <div className="caption-text">
                                                {user?.countryCode === 'IN' ? '₹' : '$'}
                                                {gstAmount.toFixed(2)}
                                            </div>
                                        </li>
                                    </ul>
                                    <div className="d-flex justify-content-between pt-2">
                                        <div className="caption-text">Total</div>
                                        <div className="caption-text">
                                            {user?.countryCode === 'IN' ? '₹' : '$'}
                                            {totalAmount.toFixed(2)}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center w-100 mt-3">
                                        {user?.countryCode === 'IN' ? (
                                            <Button
                                                isLoading={loading}
                                                label="Proceed with payment"
                                                variant="brand"
                                                className="rainbow-m-around_medium"
                                                onClick={() => createSubscription()}
                                            />
                                        ) : (
                                            <PayPalSubscriptionButton
                                                planId="P-45W37689GJ9125516M2GQ2LQ" // Replace with your actual Plan ID
                                                onSuccess={() => { }}
                                                onError={() => { }}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default BuyToken;

import React, {FC, useEffect, useState} from 'react';
import {ButtonIcon} from "react-rainbow-components";
import {toastMessage} from "../../../utils/util";



type Props = {
    value: string,
}

const CopyClipInput: FC<Props> = ({value}) => {



    return (
        <div className="form-group">
            <div className="form-control-wrap">
                    <span className=" link form-control-icon end">
                        <em className="icon ni ni-clipboard active" onClick={()=>{
                            navigator.clipboard.writeText(value).then(function() {
                                toastMessage('Copied to clipboard', 'success')
                            }, function(err) {
                                // Error feedback
                                console.error('Could not copy text: ', err);
                            });
                        }}></em>
                    </span>



                <input
                    className={`form-control`}
                    type={'text'}
                    name={'name'}
                    value={value}
                />
            </div>
        </div>
    );
};

export default CopyClipInput;

import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import GridLayout, { Layout, ItemCallback } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import {
    createDisplayStart,
    createDisplaySuccess,
    createDisplayFailure,
    updateDisplayStart,
    updateDisplaySuccess,
    updateDisplayFailure,
    fetchShopFailure,
    fetchShopStart,
    fetchShopSuccess,
} from '../../../../redux/slices/shopsSlice';
import { RootState } from '../../../../redux/rootReducer';
import { shops_url } from '../../../../constants/contants';
import http from '../../../../services/api';
import { toastMessage } from '../../../../utils/util';
import FormInput from '../../../components/form/FormInput';
import {Modal, MultiSelect, Option, Picklist, Button, CarouselCard, CarouselImage} from 'react-rainbow-components';
import FormButton from '../../../components/form/bottons/FormButton';
import GridItemForm from "../modals/GridItemForm";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { IDisplay, IGridItem, IShop } from "../../../../interfaces/shop.interface";
import * as Yup from 'yup';

const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
};


const ShopDisplayForm: React.FC<{
    display?: IDisplay;            // Optional display prop
    showModal: boolean;            // Modal visibility
    setShowModal: (show: boolean) => void;  // Function to update modal state
}> = ({ display, showModal, setShowModal }) => {

    const dispatch = useDispatch();
    const { id } = useParams();
    const { shop, loading } = useSelector((state: RootState) => state.shops);

    const [showGridModal, setShowGridModal] = useState(false);
    const [screenOrientation, setScreenOrientation] = useState<any>({ label: display ? display?.displayType : 'Horizontal', name: display ? display?.displayType : 'horizontal' });
    const [selectedDocuments, setSelectedDocuments] = useState<any>([]);
    const [gridItems, setGridItems] = useState<IGridItem[]>(display?.gridItems || []);
    const [selectedGridItem, setSelectedGridItem] = useState<any>(null);
    const [gridItemForm, setGridItemForm] = useState<IGridItem | null>(null);
    const isChatInGrid = gridItems?.some((item: any) => item?.gridType?.name === 'chat');

    console.log('gridItems', gridItems?.some((item: any) => item?.gridType?.name === 'chat'))

    useEffect(() => {
        if (display) {
            const documents = display?.pushedDocuments?.map(doc => ({
                label: shop?.documents.find(d => d._id === doc.documentId)?.name || '',
                value: doc.documentId,
            }));
            setSelectedDocuments(documents);
            console.log('display.gridItems', display.gridItems)
            const newData = display.gridItems.map((item: IGridItem) => {
                return {
                    ...item,
                    selectedDocuments: item.selectedDocuments.map(doc => ({
                        ...doc,
                        value: doc.signedUrl, // Update 'value' to be the same as 'signedUrl'
                    })),
                };
            });

            console.log("newData", newData)
            setGridItems(newData || []);
        }
    }, [display, shop]);

    useEffect(() => {
        const fetchShop = async () => {
            dispatch(fetchShopStart());
            try {
                const response = await http.get<IShop>(`${shops_url}/${id}`);
                dispatch(fetchShopSuccess(response.data));
            } catch (error: any) {
                dispatch(fetchShopFailure('Failed to fetch shop'));
            }
        };
        fetchShop();
    }, [dispatch, id]);

    const validationSchema = Yup.object({
        name: Yup.string().required('Display name is required'),
        pushedDocuments: Yup.array().of(
            Yup.object().shape({
                documentId: Yup.string().required('Document ID is required'),
                datePushed: Yup.date().required('Date is required'),
            })
        ),
    });

    const formik = useFormik({
        initialValues: {
            name: display?.name || '',
            displayType: display?.displayType || '',
            pushedDocuments: display?.pushedDocuments || [],
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            dispatch(updateDisplayStart());
            const payload = {
                name: values.name,
                displayType:screenOrientation.name,
                pushedDocuments: selectedDocuments?.map((doc: any) => ({
                    documentId: doc.value,
                    datePushed: new Date().toISOString(),
                })),
                gridItems,
            };

            if (display) {
                try {
                    const response = await http.put<IDisplay>(`${shops_url}/${shop?._id}/displays/${display._id}`, payload);
                    dispatch(updateDisplaySuccess({ shopId: shop?._id!, display: response.data }));
                    toastMessage('Display updated successfully', 'success');
                } catch (error: any) {
                    dispatch(updateDisplayFailure('Failed to update display'));
                    toastMessage('Failed to update display', 'error');
                }
            } else {
                try {
                    const response = await http.post<IDisplay>(`${shops_url}/${shop?._id}/displays`, payload);
                    dispatch(createDisplaySuccess({ shopId: shop?._id!, display: response.data }));
                    toastMessage('Display created successfully', 'success');
                } catch (error: any) {
                    dispatch(createDisplayFailure('Failed to create display'));
                    toastMessage('Failed to create display', 'error');
                }
            }

            setShowModal(false);
            formik.resetForm();
        },
    });

    const handleSaveNewItem = (newItem: IGridItem) => {
        console.log('newItem', newItem)
        let maxX = 0;
        let rowY = 0;
        gridItems.forEach(item => {
            if (item.y === rowY && item.x + item.w > maxX) {
                maxX = item.x + item.w;
            }
        });

        if (maxX + newItem.w > getScreenDimensions().cols) {
            rowY += 1;
            maxX = 0;
        }

        const updatedNewItem = { ...newItem, x: maxX, y: rowY };
        setGridItems([...gridItems, updatedNewItem]);
    };

    const onLayoutChange = (layout: Layout[]) => {
        const updatedGridItems = layout.map(l => {
            const item = gridItems.find(i => i.i === l.i)!;
            return { ...item, x: l.x, y: l.y, w: l.w, h: l.h };
        });
        setGridItems(updatedGridItems);
    };

    const onDragStop: ItemCallback = (layout, oldItem, newItem) => {
        const { height, rowHeight } = getScreenDimensions();
        const maxRows = Math.floor(height / rowHeight);
        const maxYPosition = maxRows - newItem.h;

        const updatedItems = gridItems.map(item =>
            item.i === newItem.i
                ? { ...item, x: newItem.x, y: Math.min(newItem.y, maxYPosition), w: newItem.w, h: newItem.h }
                : item
        );
        setGridItems(updatedItems);
    };

    const onResizeStop: ItemCallback = (layout, oldItem, newItem) => {
        const { height, rowHeight } = getScreenDimensions();
        const maxRows = Math.floor(height / rowHeight);
        const maxHeight = maxRows - newItem.y;

        if (newItem.h > maxHeight) {
            newItem.h = maxHeight;
        }

        const updatedItems = gridItems.map(item =>
            item.i === newItem.i ? { ...item, w: newItem.w, h: newItem.h } : item
        );
        setGridItems(updatedItems);
    };

    const getScreenDimensions = () => {
        return screenOrientation.name === 'vertical'
            ? { width: 270, height: 480, cols: 9, rowHeight: 30 }
            : { width: 480, height: 270, cols: 16, rowHeight: 10 };
    };

    const handleGridItemSelect = (option: any) => {
        setSelectedGridItem(option.name);
        const selectedItem = gridItems.find(item => item.i === option.name) || null;
        setGridItemForm(selectedItem);
    };

    const handleFormChange = (field: string, value: any) => {
        setGridItemForm(prevState => {
            if (!prevState) return null;

            const updatedForm = { ...prevState, [field]: value };
            const updatedGridItems = gridItems.map(item => (item.i === updatedForm.i ? updatedForm : item));
            setGridItems(updatedGridItems);
            return updatedForm;
        });
    };

    const handleUpdateGridItem = () => {
        if (!gridItemForm) return;
        const updatedGridItems = gridItems.map(item => (item.i === gridItemForm.i ? gridItemForm : item));
        setGridItems(updatedGridItems);
    };

    const { width, height, cols, rowHeight } = getScreenDimensions();

    console.log('gridItems', gridItems)
    return (
        <Modal
            id="modal-add-shop"
            title={display ? 'Update Display' : 'Create Display'}
            isOpen={showModal}
            onRequestClose={() => {
                setShowModal(false);
                formik.resetForm();
                setGridItems([])
            }}
            footer={
                <div className="d-flex justify-content-end">
                    {loading ? (
                        <Button isLoading label="Button Brand" variant="brand" className="rainbow-m-around_medium"  style={{borderRadius: 5}} />
                    ):(
                        <FormButton type="button" text={display ? 'Update' : 'Create'} onClick={formik.submitForm} />
                    )}
                </div>
            }
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="row gy-3">
                    <div className="col-12">
                        <FormInput
                            label="Display Name"
                            name="name"
                            type="text"
                            placeholder="Enter Display Name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.name}
                        />
                    </div>

                    {/*<div className="col-12">*/}
                    {/*    <MultiSelect*/}
                    {/*        label="Select Documents"*/}
                    {/*        placeholder="Select Documents"*/}
                    {/*        className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"*/}
                    {/*        value={selectedDocuments}*/}
                    {/*        onChange={setSelectedDocuments}*/}
                    {/*        bottomHelpText="You can select several options."*/}
                    {/*        showCheckbox*/}
                    {/*        borderRadius="semi-square"*/}
                    {/*        variant="chip"*/}
                    {/*    >*/}
                    {/*        {shop?.documents.map((document) => (*/}
                    {/*            <Option key={document._id} name={document._id} value={document._id} label={document.name} />*/}
                    {/*        ))}*/}
                    {/*    </MultiSelect>*/}
                    {/*</div>*/}

                    <div className="col-12">
                        <Picklist
                            label="Select Screen Orientation"
                            value={screenOrientation}
                            onChange={(option) => setScreenOrientation(option)}
                            className="mb-3"
                        >
                            <Option name="horizontal" label="Horizontal" />
                            <Option name="vertical" label="Vertical" />
                        </Picklist>
                    </div>

                    <div className="col-12">
                        <Button onClick={() => setShowGridModal(true)} label="Add New Grid Item" variant="brand" className="mb-3" />
                    </div>

                    <div className="col-12">
                        <div
                            className="tv-screen-container"
                            style={{
                                width: `${width}px`,
                                height: `${height}px`,
                                border: '2px solid black',
                                margin: '0 auto',
                                padding: '5px',
                                boxSizing: 'border-box',
                                overflow: 'scroll',
                                position: 'relative',
                            }}
                        >
                            <GridLayout
                                className="layout"
                                margin={[0, 0]}  // Set margin to [0, 0] to remove space between grid items
                                containerPadding={[0, 0]}  // Optionally remove padding in the grid container
                                layout={gridItems.map(item => ({
                                    i: item.i,
                                    x: item.x,
                                    y: item.y,
                                    w: item.w,
                                    h: item.h,
                                }))}
                                cols={cols}
                                rowHeight={rowHeight}
                                width={width - 10}
                                maxRows={Math.floor(height / rowHeight)}
                                onLayoutChange={onLayoutChange}
                                onDragStop={onDragStop}
                                onResizeStop={onResizeStop}
                                preventCollision={false}
                                compactType={null}
                            >
                                {gridItems?.map(item => {
                                    const isImage = item.gridType.name === 'image' && item.selectedDocuments.length > 0;
                                    const isVideo = item.gridType.name === 'video' && item.selectedDocuments.length > 0;
                                    return (
                                        <div
                                            key={item.i}
                                            className="grid-item border"
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                position: "relative",
                                                overflow: "hidden",
                                            }}
                                            // onClick={()=> {
                                            //     setSelectedGridItem(item);
                                            //     setShowGridModal(true);
                                            // }}
                                        >
                                            {isImage ? (
                                                <CarouselCard
                                                    className="carousel-card"
                                                    id="carousel-1"

                                                >
                                                    {item.selectedDocuments.map((selectedDocument)=>
                                                        <CarouselImage
                                                            src={selectedDocument.value}
                                                        />
                                                    )}


                                                </CarouselCard>
                                            ) : isVideo ? (
                                                <video
                                                    src={item.selectedDocuments[0].value}
                                                    style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        objectFit: "cover",
                                                        position: "absolute",
                                                        top: 0,
                                                        left: 0,
                                                    }}
                                                    muted
                                                    loop
                                                    autoPlay
                                                    playsInline
                                                >
                                                    Your browser does not support the video tag.
                                                </video>
                                            ) : (
                                                <div>Chat</div>
                                            )}
                                        </div>
                                    );
                                })}

                            </GridLayout>
                        </div>
                    </div>
                </div>
            </form>

            <GridItemForm
                selectedGridItem={selectedGridItem}
                showModal={showGridModal}
                setShowModal={(e)=>{
                    setShowGridModal(e)
                }}
                onSave={handleSaveNewItem}
                itemCount={gridItems.length}
                shopDocuments={shop?.presentationDocuments || []}
                documents={shop?.documents || []}
                isChatInGrid={isChatInGrid}
            />
        </Modal>
    );
};

export default ShopDisplayForm;

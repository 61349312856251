import React, {FC, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/rootReducer";
import {Modal, Picklist, Option, ButtonMenu, MenuItem, Button} from "react-rainbow-components";
import FormInput from "../../components/form/FormInput";
import FormButton from "../../components/form/bottons/FormButton";
import {useFormik} from "formik";
import http from "../../../services/api";
import {login_url, pricing_plans_url, register_url, websites_url} from "../../../constants/contants";
import * as Yup from "yup";
import {toastMessage} from "../../../utils/util";
import {IWebsite,} from "../../../interfaces/website.interface";
import {useNavigate} from "react-router-dom";
import dayjs from "dayjs";
import {
    createWebsiteFailure,
    createWebsiteStart, createWebsiteSuccess,
    deleteWebsiteFailure, deleteWebsiteStart, deleteWebsiteSuccess,
    fetchWebsitesFailure,
    fetchWebsitesStart,
    fetchWebsitesSuccess
} from "../../../redux/slices/websitesSlice";
import WebsiteForm from "./modals/WebsiteForm";
import WebsiteManage from "./modals/WebsiteManage";
import EmptyMessage from "../../components/empty/EmptyMessage";
import Loader from "../../components/loader/Loader";



const WebsitesContainer: FC = () => {


    const { websites, loading, error } = useSelector((state: RootState) => state.websites);
    const dispatch = useDispatch();
    const {  user } = useSelector((state: RootState) => state.user);

    const [showModal, setShowModal] = useState(false);
    const [manageModal, setManageModal] = useState('');
    const [activeTab, serActiveTab] = useState('active');
    const [selectedWebsite, setSelectedWebsite] = useState<IWebsite>();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchWebsites = async () => {
            dispatch(fetchWebsitesStart());
            try {
                let response = await http.get<IWebsite[]>(websites_url);
                dispatch(fetchWebsitesSuccess(response.data));
            } catch (error: any) {
                dispatch(fetchWebsitesFailure('Failed to fetch websites'));
            }
        };

        fetchWebsites();
    }, []);


    console.log('websites', websites)


    return (
        <div className="nk-content">
            <div className="container-xl">
                <div className="nk-content-inner">
                    <div className="nk-content-body">
                        <div className="nk-block-head nk-page-head">
                            <div className="nk-block-head-between flex-wrap gap g-2">
                                <div className="nk-block-head-content">
                                    <h2 className="display-6">Websites</h2>
                                    <p>List of websites you enabled chatbot.</p>
                                </div>

                                {loading ? (
                                    <Button isLoading label="Button Brand" variant="brand" className="rainbow-m-around_medium" style={{borderRadius: 5}} />
                                ):(
                                    <Button
                                        label="Create New"
                                        variant="brand"
                                        size={`small`}
                                        className="rainbow-m-around_medium"
                                        borderRadius="semi-square"

                                        onClick={()=> {

                                            if(user?.aiTokens! < 100) {

                                                toastMessage(user?.plan?'Please choose a refill tokens to continue':'Please choose a plan tokens to continue','error')

                                            }else {

                                                setSelectedWebsite(undefined)
                                                setShowModal(true)
                                            }


                                        }}
                                    >
                                        <em className="icon ni ni-plus"/>
                                        <span className='ms-2'>Create New</span>
                                    </Button>
                                )}
                            </div>
                        </div>
                        <div className="nk-block">
                            <div className="card shadow-none">
                                <ul className="nav nav-tabs nav-tabs-s1 px-4" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className={`nav-link ${activeTab == 'active'&& 'active'}`} type="button" data-bs-toggle="tab" onClick={()=> serActiveTab('active')} aria-selected="true" role="tab">Active</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={`nav-link ${activeTab == 'deactivated'&& 'active'}`} type="button" data-bs-toggle="tab" onClick={()=> serActiveTab('deactivated')} aria-selected="false" role="tab" >Deactivated</button>
                                    </li>
                                </ul>
                                <div className="tab-content">
                                    <div className="table-responsive tab-pane fade active show" id="recents-documents-tab-pane"
                                         role="tabpanel">

                                        {loading ?

                                            <Loader/>
                                            :
                                            <table className="table table-middle mb-0">
                                                <tbody>

                                                {websites?.filter((website) => activeTab == 'active' ? !website.deactivated : website.deactivated).length ?

                                                    websites?.filter((website) => activeTab == 'active' ? !website.deactivated : website.deactivated).map((website) =>

                                                        <tr>
                                                            <td className="tb-col">
                                                                <div className="caption-text link line-clamp-2"
                                                                     onClick={() => navigate(`/website/${website._id}`)}>{website.websiteName} </div>
                                                            </td>
                                                            <td className="tb-col">
                                                                <div className="caption-text link line-clamp-2"
                                                                     onClick={() => navigate(`/website/${website._id}`)}>{website.websiteUrl} </div>
                                                            </td>
                                                            <td className="tb-col tb-col-sm">
                                                                <div
                                                                    className="caption-text line-clamp-2">{website.websiteAiToken} tokens
                                                                    remaining
                                                                </div>
                                                            </td>
                                                            <td className="tb-col tb-col-sm">
                                                                <div
                                                                    className="badge text-bg-dark-soft rounded-pill px-2 py-1 fs-6 lh-sm">{website.documents?.length} Documents
                                                                </div>
                                                            </td>
                                                            <td className="tb-col tb-col tb-col-sm">
                                                                <div
                                                                    className="fs-6 text-light d-inline-flex flex-wrap gap gx-2">
                                                                    <span>{dayjs(website.createdAt).format('MMM DD, YYYY')}</span>
                                                                    <span>{dayjs(website.createdAt).format('hh:mm A')}</span>
                                                                </div>
                                                            </td>
                                                            <td className="tb-col tb-col-end">
                                                                <ButtonMenu
                                                                    menuAlignment="right"
                                                                    menuSize="x-small"
                                                                    buttonVariant="base"
                                                                    icon={<em className="icon ni ni-more-h"></em>}
                                                                >
                                                                    <MenuItem
                                                                        icon={<em className="icon ni ni-edit"></em>}
                                                                        onClick={() => {

                                                                            setSelectedWebsite(website)
                                                                            setShowModal(true)
                                                                        }} label="Edit"/>
                                                                    <MenuItem
                                                                        icon={<em className="icon ni ni-trash"></em>}
                                                                        onClick={() => {

                                                                            setSelectedWebsite(website)
                                                                            setManageModal(website.deactivated ? 'activate' : 'deactivate')

                                                                        }}
                                                                        label={`${website.deactivated ? 'Activate' : 'Deactivate'}`}/>
                                                                    <MenuItem
                                                                        icon={<em className="icon ni ni-trash"></em>}
                                                                        onClick={() => {

                                                                            setSelectedWebsite(website)
                                                                            setManageModal('delete')
                                                                        }} label="Delete"/>
                                                                </ButtonMenu>
                                                            </td>
                                                        </tr>
                                                    ) :
                                                    <EmptyMessage
                                                        type="website"
                                                        title="No websites added."
                                                        description="You have not added any websites yet. Start adding to manage your favorite sites."
                                                        buttonText="Add Website"
                                                        buttonCallback={() => {
                                                            setShowModal(true)
                                                        }}
                                                    />
                                                }


                                                </tbody>
                                            </table>
                                        }


                                    </div>
                                    <div className="tab-pane fade" id="trash-documents-tab-pane" role="tabpanel">
                                        <div className="row justify-content-center py-5">
                                            <div className="col-md-7 col-lg-4">
                                                <div className="mb-5 text-center">
                                                    <img src="images/illustrations/no-documents.svg" alt=""/>
                                                </div>
                                                <div className="nk-block-head text-center">
                                                    <div className="nk-block-head-content">
                                                        <h1 className="nk-block-title mb-2">No documents in trash.</h1>
                                                        <p>All files have been permanently removed from the trash folder
                                                            and cannot be retrieved.</p>
                                                    </div>
                                                </div>
                                                <div className="text-center mt-4">
                                                    <a className="btn btn-primary" href="templates.html">Explore
                                                        Templates</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <WebsiteForm website={selectedWebsite!} setShowModal={(e: boolean) => setShowModal(e)}
                         showModal={showModal}/>
            <WebsiteManage website={selectedWebsite!} setManageModal={(e: string) => setManageModal(e)}
                           manageModal={manageModal}/>

        </div>
    );
};
export default WebsitesContainer;

import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    deleteDocumentStart,
    deleteDocumentSuccess,
    deleteDocumentFailure,
} from '../../../../redux/slices/websitesSlice';
import {IDocument, IWebsite} from '../../../../interfaces/website.interface';
import { RootState } from "../../../../redux/rootReducer";
import {
    update_account_url,
    update_country_url,
    update_password_url,
    websites_url
} from "../../../../constants/contants";
import http from "../../../../services/api";
import FormButton from "../../../components/form/bottons/FormButton";
import {Button, Modal, Option, Picklist} from "react-rainbow-components";
import { toastMessage } from "../../../../utils/util";
import FormInput from "../../../components/form/FormInput";
import {useFormik} from "formik";
import {IUser} from "../../../../interfaces/user.interface";
import {updateUserFailure, updateUserSuccess} from "../../../../redux/slices/userSlice";
import * as Yup from "yup";
import ct from "countries-and-timezones";
import {countries} from "../../../../utils/countries";

interface ProfileManageProps {
    showModal:boolean;
    setShowModal:any;
}


const validationSchema = Yup.object({
    fullName: Yup.string()
        .required('Full Name is required'),
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required')
});





const ProfileManage: React.FC<ProfileManageProps> = ({ showModal, setShowModal }) => {


    const dispatch = useDispatch();

    const { user } = useSelector((state: RootState) => state.user);

    const formik = useFormik({
        initialValues: {
            fullName: user?.fullName || '',
            email:user?.email || '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {

            try {
                const response =  await http.put<IUser>(update_account_url, {
                    fullName: values.fullName,
                    email: values.email,
                });

                dispatch(updateUserSuccess(response.data));

                // Update localStorage
                localStorage.setItem('user', JSON.stringify(response.data));
                toastMessage('Account updated successfully', 'success');
                setShowModal(false)
            } catch (error: any) {
                dispatch(updateUserFailure('Failed to update account'));
                toastMessage('Failed to update account', 'error');

            }
        },
    });



    return (
        <Modal
            id="modal-delete-document"
            title={`Edit Profile`}
            isOpen={showModal}
            onRequestClose={() => {
                setShowModal(false);
            }}
            footer={
                <div className="d-flex justify-content-end">
                    <FormButton type={`submit`} text={`Update`}
                                onClick={formik.handleSubmit}/>
                </div>
            }
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="row gy-3">
                    <div className="col-12">
                        <FormInput
                            label={`Full Name`}
                            name={`fullName`}
                            placeholder={`Enter your name`}
                            value={formik.values.fullName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.fullName}
                        />
                    </div>
                    <div className="col-12">
                        <FormInput
                            label="Email Address"
                            name="email"
                            type="email"
                            placeholder="Enter email address"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.email}
                        />
                    </div>
                </div>
            </form>
        </Modal>
    );
};

export default ProfileManage;

import React, { useState, forwardRef, useImperativeHandle, useRef, useEffect } from 'react';
import { FileSelector } from 'react-rainbow-components';
import axios from 'axios';
import http from "../../../services/api";
import {websites_url, shops_url, organizations_url} from "../../../constants/contants";
import {useDispatch, useSelector} from 'react-redux';
import {
    uploadDocumentStart,
    uploadDocumentFailure,
    uploadWebsiteDocumentSuccess
} from '../../../redux/slices/websitesSlice';
import {uploadShopDocumentSuccess, uploadShopPresentationDocumentSuccess} from "../../../redux/slices/shopsSlice";
import {updateGroupFailure, updateGroupStart, updateGroupSuccess} from "../../../redux/slices/organizationsSlice";
import {toastMessage} from "../../../utils/util";
import {RootState} from "../../../redux/rootReducer";

interface FileWithProgress {
    file: File;
    progress: number;
    completed: boolean;
    started: boolean;
    cancelTokenSource: any;
}

interface FileUploadHandle {
    uploadFiles: () => Promise<void>;
}

interface FileUploadProps {
    onFileCountChange: (count: number) => void;
    _id: string;
    uploadType: string;
    groupId?: string;
}

const FileUpload = forwardRef<FileUploadHandle, FileUploadProps>((props, ref) => {
    const { onFileCountChange, _id, uploadType, groupId } = props;
    const dispatch = useDispatch();

    const {  organization } = useSelector((state: RootState) => state?.organizations);

    const [files, setFiles] = useState<FileWithProgress[]>([]);
    const [completedFiles, setCompletedFiles] = useState<FileWithProgress[]>([]);
    const [message, setMessage] = useState('');
    const [uploadFailed, setUploadFailed] = useState(false);
    const fileSelectorRef = useRef<HTMLDivElement>(null);

    const handleFileChange = (fileList: FileList) => {
        const selectedFiles = Array.from(fileList).map(file => ({
            file,
            progress: 0,
            completed: false,
            started: false,
            cancelTokenSource: axios.CancelToken.source()
        }));

        setFiles(selectedFiles);
    };

    useEffect(() => {
        if (files.length === 0) {
            triggerFileSelector();
        }
        onFileCountChange(files.length);
    }, [files]);

    useImperativeHandle(ref, () => ({
        uploadFiles
    }));

    const uploadFiles = async () => {
        dispatch(updateGroupStart());
        setUploadFailed(false)
        const uploadPromises = files.map(fileWithProgress => {
            const { file, cancelTokenSource } = fileWithProgress;

            const formData = new FormData();
            formData.append('files', file);

            // Mark the file as started
            setFiles(prevFiles =>
                prevFiles.map(f =>
                    f.file.name === file.name ? { ...f, started: true } : f
                )
            );

            return http.post(uploadType == 'website'? `${websites_url}/${_id}/documents`: uploadType == 'organization'? `${organizations_url}/${_id}/group/${groupId}/documents`: uploadType === 'shop-upload'?`${shops_url}/${_id}/documents`:`${shops_url}/${_id}/presentation-documents`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                cancelToken: cancelTokenSource.token,
                onUploadProgress: (progressEvent) => {
                    if (progressEvent.lengthComputable) {
                        const progress = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 1));
                        const cappedProgress = Math.min(progress, 80);
                        setFiles(prevFiles =>
                            prevFiles.map(f =>
                                f.file.name === file.name ? { ...f, progress: cappedProgress } : f
                            )
                        );
                    }
                }
            }).then((response) => {
                const uploadedDocument = response.data.documents.find((doc:any) => doc.name === file.name);
                setFiles(prevFiles =>
                    prevFiles.map(f =>
                        f.file.name === file.name ? { ...f, progress: 100, completed: true } : f
                    )
                );
                setCompletedFiles(prevCompletedFiles =>
                    prevCompletedFiles.concat(fileWithProgress)
                );

                if(uploadType == 'website'){
                    dispatch(uploadWebsiteDocumentSuccess({ websiteId:_id, document: uploadedDocument }));

                }else if(uploadType == 'shop-upload'){
                    dispatch(uploadShopDocumentSuccess({ shopId:_id, document: uploadedDocument }));

                }else if(uploadType == 'shop-presentation-upload'){
                    dispatch(uploadShopPresentationDocumentSuccess({ shopId:_id, document: uploadedDocument }));

                }
                setMessage(response.data.message);

                triggerFileSelector();

                if (groupId) {
                    // Find the group with the matching groupId
                    const group = organization?.groups?.find((g) => g._id === groupId);

                    if (group) {
                        // Create a new object with a copy of the group data
                        const updatedGroup = {
                            ...group,
                            groupDocuments: [...(group.groupDocuments), response.data]
                        };

                        console.log('upload', '_id', _id, 'updatedGroup', updatedGroup)

                        // Dispatch the updated group
                        dispatch(updateGroupSuccess({
                            orgId: _id,
                            group: updatedGroup,
                        }));
                    }else if(uploadType === 'chat'){

                    }

                }

            }).catch(error => {
                if (axios.isCancel(error)) {
                    console.log('Upload canceled', file.name);
                } else {
                    console.error('Upload failed', error);
                    dispatch(updateGroupFailure(error.data.message));
                    setMessage(error.data.message);
                }

                setUploadFailed(true)
            });
        });

        try {
            await Promise.all(uploadPromises);
        } catch (error: any) {
            console.error('Some uploads failed', error);
            dispatch(updateGroupFailure(error.message));
        }
    };

    const cancelUpload = (fileName: string) => {
        setFiles(prevFiles => {
            const fileToCancel = prevFiles.find(f => f.file.name === fileName);
            if (fileToCancel && fileToCancel.cancelTokenSource) {
                fileToCancel.cancelTokenSource.cancel();
            }
            return prevFiles.filter(f => f.file.name !== fileName); // Remove the canceled file from the state
        });
    };

    const triggerFileSelector = () => {
        if (fileSelectorRef.current) {
            const button = fileSelectorRef.current.querySelector('[data-id="button-icon-element"]');
            if (button) {
                (button as HTMLElement).click();
            }
        }
    };

    return (
        <div className="rainbow-p-vertical_large rainbow-p-horizontal_medium rainbow-m_auto">
            <div ref={fileSelectorRef}>
                <FileSelector
                    label="File selector multiple"
                    labelAlignment={'left'}
                    placeholder="Drag & Drop or Click to Browse"
                    bottomHelpText="Can select multiple files"
                    variant="multiline"
                    multiple
                    value={files}
                    accept={uploadType == 'shop-presentation-upload'?".png,.jpg,.jpeg,.mp4,.mov":".pdf" }
                    onChange={(fileList: FileList) => handleFileChange(fileList)}
                />
            </div>

            {files && files.length > 0 && (
                <div className="card">
                    <div className="card-body">
                        {files.map(({ file, progress, completed, started }) => (
                            <div className={`mb-3`} key={file.name}>
                                <div className="d-flex align-items-center justify-content-between mb-1">
                                    <div className="fs-6 text-light fw-bold mb-0">{file.name}</div>
                                    <span className="link link-danger" onClick={() => cancelUpload(file.name)}>Cancel</span>
                                </div>
                                <div className="progress progress-md">
                                    <div
                                        className="progress-bar bg-gradient-custom gradient-start-cyan gradient-middle-purple-light gradient-end-pink-light gradient-angle-45"
                                        data-progress="74%" style={{ width: `${progress}%` }}></div>
                                </div>
                                <div className="fs-7 text-light mt-1"><span className="text-dark">{progress}</span>/100</div>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {completedFiles && completedFiles.length > 0 && (
                <div className="card mt-2">
                    <div className="card-body">
                        {completedFiles.map(({ file }) => (
                            <div className={`mb-3`} key={file.name}>
                                <div className="d-flex align-items-center justify-content-between mb-1">
                                    <div className="fs-6 text-light fw-bold mb-0">{file.name}</div>
                                    <span className="link link-primary">Completed</span>
                                </div>
                                <div className="progress progress-md">
                                    <div
                                        className="progress-bar bg-gradient-custom gradient-start-cyan gradient-middle-purple-light gradient-end-pink-light gradient-angle-45"
                                        data-progress="74%" style={{ width: `100%` }}></div>
                                </div>
                                <div className="fs-7 text-light mt-1"><span className="text-dark">100</span>/100</div>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {message && <div className={`alert mt-3 ${uploadFailed? 'text-bg-danger-soft': 'text-bg-info-soft'}`}>{message}</div>}
        </div>
    );
});

export default FileUpload;

import React, { useState, useEffect } from 'react';
import ReactFlow, { MiniMap, Controls, Background, Node, Edge } from 'reactflow';
import 'reactflow/dist/style.css';

// Your markdown content
const markdownContent: string = `
Rahul PV
  - Work Experience
    - POUMKI (Team Lead, 3.2 years)
    - CARCILITY (Senior Software Engineer, 3.5 years)
    - RYDEY (Freelancer, 6 months)
    - COPY SPOT TECHNOLOGIES (Software Developer, 1 year)
    - BLUERIENT TECHNOLOGIES (Worked for 3 years)
    - MOBITIA TECHNOLOGIES (Worked for 8 months)
  - Projects
    - BRAINAQUE (AI Chatbot)
      - Technologies: Python, FastAPI, LangChain, ReactJS
    - EXPLORESOUL.AI (AI-powered travel)
      - Technologies: ReactJS, NodeJS, AWS
    - eSlides (PowerPoint management)
      - Technologies: ReactJS, AWS, MongoDB
    - IZYCOURS (Online tutoring)
      - Technologies: ReactJS, AWS, MongoDB
  - Skills
    - JavaScript, Python, PHP, C, C++
    - LangChain, FastAPI, NextJS, ReactJS
  - Education
    - Bachelor of Computer Science (Amrita)
    - Higher Secondary (St. Joseph)
  - Personal Info
    - Date of Birth: 14 Nov 1989
    - Marital Status: Married
    - Nationality: Indian
  - Interests
    - Traveling
    - Photography
`;

// Define types for nodes and edges
interface ParsedGraph {
    nodes: Node[];
    edges: Edge[];
}

// Helper function to convert markdown-like text into nodes and edges
const parseMarkdownToGraph = (markdown: string): ParsedGraph => {
    const lines = markdown.trim().split('\n');
    const nodes: Node[] = [];
    const edges: Edge[] = [];
    let idCounter = 1;

    const parentStack: string[] = [];
    let parentId: string | null = null;

    lines.forEach((line, index) => {
        const trimmedLine = line.trim();
        const level = line.search(/\S|$/) / 2; // Calculate level by checking indentation

        const nodeId = `node-${idCounter++}`;
        const node: Node = {
            id: nodeId,
            data: { label: trimmedLine },
            position: { x: level * 150, y: index * 100 },
            draggable: true, // Make each node draggable
        };

        nodes.push(node);

        if (level === 0) {
            parentId = nodeId;
            parentStack[level] = nodeId;
        } else {
            const parentLevel = level - 1;
            if (parentStack[parentLevel]) {
                edges.push({
                    id: `edge-${parentStack[parentLevel]}-${nodeId}`,
                    source: parentStack[parentLevel],
                    target: nodeId,
                });
            }
            parentStack[level] = nodeId;
        }
    });

    return { nodes, edges };
};

const Test: React.FC = () => {
    const [elements, setElements] = useState<ParsedGraph>({ nodes: [], edges: [] });

    useEffect(() => {
        const graph = parseMarkdownToGraph(markdownContent);
        setElements(graph);
    }, []);

    return (
        <div style={{ height: '100vh' }}>
            <ReactFlow
                nodes={elements.nodes}
                edges={elements.edges}
            >
                <MiniMap />
                <Controls />
                <Background />
            </ReactFlow>
        </div>
    );
};

export default Test;

import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    deleteDocumentStart,
    deleteDocumentSuccess,
    deleteDocumentFailure,
} from '../../../../redux/slices/websitesSlice';
import {IDocument, IWebsite} from '../../../../interfaces/website.interface';
import { RootState } from "../../../../redux/rootReducer";
import {
    create_subscription_url, promoCode_validate_url,
    update_country_url,
    update_password_url, verify_subscription_url,
    websites_url
} from "../../../../constants/contants";
import http from "../../../../services/api";
import FormButton from "../../../components/form/bottons/FormButton";
import {Button, Modal, Option, Picklist} from "react-rainbow-components";
import { toastMessage } from "../../../../utils/util";
import FormInput from "../../../components/form/FormInput";
import {useFormik} from "formik";
import {IUser} from "../../../../interfaces/user.interface";
import {fetchUserFailure, updateUserFailure, updateUserSuccess} from "../../../../redux/slices/userSlice";
import * as Yup from "yup";
import ct from "countries-and-timezones";
import {countries} from "../../../../utils/countries";
import PayPalSubscriptionButton from "../../payment/PayPalSubscriptionButton";
import loadRazorpay from "../../../../utils/loadRazorpay";
import {IPricingPlan} from "../../../../interfaces/pricingPlans.interface";
import {IPaymentHistory} from "../../../../interfaces/paymentHistory.interface";
import {
    createPaymentHistoryFailure,
    createPaymentHistoryStart,
    createPaymentHistorySuccess, updatePaymentHistorySuccess
} from "../../../../redux/slices/paymentHistorySlice";
import {IPromoCode} from "../../../../interfaces/promoCode.interface";

interface ManageProps {
    showModal:boolean;
    setShowModal:any;
    plan: IPricingPlan;
    subscriptionType: string
}


const PaymentPlan: React.FC<ManageProps> = ({ showModal, setShowModal, plan, subscriptionType}) => {

    const { user } = useSelector((state: RootState) => state.user);

    const dispatch = useDispatch();
    const [couponCode, setCouponCode] = useState<string>('');
    const [activePromoCode, setActivePromoCode] = useState<IPromoCode | null>(null);
    const [loading, setLoading] = useState(false);

    const checkPromoCodeValid = async () => {
        console.log('checkPromoCodeValid', couponCode)
        setLoading(true);
        try {
            let response = await http.post<any>(promoCode_validate_url, {
                userId: user?._id,
                code: couponCode
            });
            console.log('response', response);
            setActivePromoCode(response.data.promoCode);
        } catch (error: any) {
            console.log('error', error?.data.message)
        }finally {
            setLoading(false);
        }
    };


    const createSubscription = async () => {


        const amount = user?.countryCode === 'IN'
            ? subscriptionType === 'monthly'
                ? plan.pricing.monthly.inr
                : plan.pricing.yearly.inr
            : subscriptionType === 'monthly'
                ? plan.pricing.monthly.usd
                : plan.pricing.yearly.usd;

        const currency = user?.countryCode === 'IN' ? 'inr' : 'usd';

        try {
            // Dispatch action to start creating payment history
            dispatch(createPaymentHistoryStart());

            // API call to create subscription
            let response = await http.post<IPaymentHistory>(create_subscription_url, {
                planId: plan._id,
                razorpayPlanId: plan.razorpayPlanId,
                subscriptionType,
                amount: parseFloat(totalAmount()),
                currency,
                gateway: 'razorpay',
            });

            // Dispatch action for successful payment history creation
            dispatch(createPaymentHistorySuccess(response.data));

            initializeRazorpay(response.data,subscriptionType)
        } catch (error) {
            // Dispatch action for failed payment history creation
            dispatch(fetchUserFailure('Initial Payment to fetch user'));
        }

    }
    const initializeRazorpay = async (paymentData:IPaymentHistory,subscriptionType:string) => {
        const isLoaded = await loadRazorpay();
        if (!isLoaded) {
            toastMessage('Razorpay SDK failed to load. Are you online?', 'error');
            return;
        }

        const options = {
            key: process.env.REACT_APP_RAZORPAY_KEY,
            subscription_id: paymentData.transactionId,
            name: process.env.REACT_APP_COMPANY_NAME,
            description: `${plan.title} - ${plan.subtitle} `,
            amount: 50,
            handler:async (response: any) => {



                console.log("response",response)



                let responseData = await http.post<{ paymentHistory: IPaymentHistory, userData: IUser }>(verify_subscription_url, {
                    razorpay_payment_id:response.razorpay_payment_id,
                    razorpay_signature:response.razorpay_signature,
                    razorpay_subscription_id:response.razorpay_subscription_id,
                    subscriptionType
                });


                dispatch(updateUserSuccess(responseData.data.userData));
                localStorage.setItem('user', JSON.stringify(responseData.data.userData));

                dispatch(updatePaymentHistorySuccess(responseData.data.paymentHistory));

            },
            theme: {
                color: '#37b6ff'
            },
            modal: {
                ondismiss: function(){
                    document.body.style.overflow = '';
                },
                escape: false,
                backdropclose: false,
                confirm_close: true,
            },
            config: {
                display: {
                    hide: true, // You can set this to true if you want to hide the display options (for full-screen)
                },
            },
        };

        const rzp = new (window as any).Razorpay(options);
        rzp.open();
        setShowModal(false)
    };

    const totalAmount = () => {
        // Check if a promo code is active
        let discountedAmount;
        if (activePromoCode?._id) {
            const discountPercentage = activePromoCode.percentage / 100;
            const originalPrice = user?.countryCode === 'IN'
                ? (subscriptionType === 'monthly' ? plan?.pricing.monthly.inr : plan?.pricing.yearly.inr)
                : (subscriptionType === 'monthly' ? plan?.pricing.monthly.usd : plan?.pricing.yearly.usd);

            // Apply the discount and add tax
            discountedAmount = (originalPrice * (1 - discountPercentage) * 1.18).toFixed(2);
        } else {
            // Calculate total without discount but with tax
            discountedAmount = user?.countryCode === 'IN'
                ? (subscriptionType === 'monthly' ? (plan?.pricing.monthly.inr * 1.18).toFixed(2) : (plan?.pricing.yearly.inr * 1.18).toFixed(2))
                : (subscriptionType === 'monthly' ? (plan?.pricing.monthly.usd * 1.18).toFixed(2) : (plan?.pricing.yearly.usd * 1.18).toFixed(2));
        }

        // Return currency symbol and amount
        return `${discountedAmount}`;
    };


    return (
        <Modal
            id="modal-add-website"

            size={`large`}
            title="Checkout"
            isOpen={showModal}
            onRequestClose={() => {
                setShowModal('close')
            }}
        >
            <div
                className='d-flex flex-row flex-wrap justify-content-between align-items-center p-4'
                style={{
                    borderRadius: 10,
                    background: 'linear-gradient(to top left, #BFEBFFFF, #F4E5FFFF, #BFEBFFFF)', // Adding gradient here
                }}
            >
                <div className='col-12 col-md-6'>
                    <div
                        className="media media-rg media-middle media-circle text-primary bg-primary bg-opacity-20 mb-3">
                        <em className="icon ni ni-spark-fill"></em>
                    </div>
                    <h2 className="h1">Change Subscription</h2>
                    <p>Are you sure you want to change your subscription? Your paid plan is valid till <span
                        className="text-dark">23 Mar, 2023</span>.</p>
                    <div className="card bg-lighter bg-opacity-80 shadow-none mt-2">
                        <div className="card-body">
                            <h5>What you will lose after changing your active subscription?</h5>
                            <ul className="list-dot gap gy-2 mt-2">
                                <li>You won’t have a dedicated account manager</li>
                                <li>No custom tools will be existed for AI content generations.</li>
                                <li>You’ll lose access to advance integrations.</li>
                                <li>No prioritized support will be provided for you.</li>
                                <li>Server response rate will be standard than faster.</li>
                                <li>Regular model updates instead of special features early access.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-5 p-4 shadow' style={{backgroundColor: '#fff', borderRadius: 10}}>
                    <div className="">
                        <div className="d-flex flex-wrap flex-grow-1">
                            <div
                                className="w-100 d-flex align-items-center justify-content-center justify-content-lg-end">
                                <div className="wide-xs w-100">
                                    <div className="pt-4">
                                        <div className="fs-4 fw-normal">Subscribe to {plan?.title} <span
                                            className={`text-capitalize`}>({subscriptionType})</span></div>
                                        <h3 className="display-1 fw-semibold">{user?.countryCode == 'IN' ? '₹' : '$'}{user?.countryCode == 'IN' ? subscriptionType == 'monthly' ? plan?.pricing.monthly.inr : plan?.pricing.yearly.inr : subscriptionType == 'monthly' ? plan?.pricing.monthly.usd : plan?.pricing.yearly.usd}
                                            <span className="caption-text text-light fw-normal"> per year</span></h3>
                                        <div className="fs-5 fw-normal mt-2">A {subscriptionType} {plan?.title} for
                                            genious
                                        </div>
                                    </div>
                                    <ul className="mt-4 gap gy-4 pb-2">
                                        <li className="d-flex justify-content-between">
                                            <div className="">
                                                <div className="caption-text">{plan?.title} <span
                                                    className={`text-capitalize`}>({subscriptionType})</span></div>
                                                <span className="sub-text">Qty 1 Billed {subscriptionType}</span>
                                            </div>
                                            <div
                                                className="caption-text">{user?.countryCode == 'IN' ? '₹' : '$'}{user?.countryCode == 'IN' ? subscriptionType == 'monthly' ? plan?.pricing.monthly.inr : plan?.pricing.yearly.inr : subscriptionType == 'monthly' ? plan?.pricing.monthly.usd : plan?.pricing.yearly.usd}</div>
                                        </li>
                                        <li className="d-flex justify-content-between">
                                            <div className="">
                                                <div className="caption-text">Subtotal</div>
                                            </div>
                                            <div
                                                className="caption-text">{user?.countryCode == 'IN' ? '₹' : '$'}{user?.countryCode == 'IN' ? subscriptionType == 'monthly' ? plan?.pricing.monthly.inr : plan?.pricing.yearly.inr : subscriptionType == 'monthly' ? plan?.pricing.monthly.usd : plan?.pricing.yearly.usd}</div>
                                        </li>
                                        <li className="d-flex justify-content-between">
                                            <div className="">
                                                <div className="caption-text">GST (18%)</div>
                                            </div>
                                            <div
                                                className="caption-text">{user?.countryCode == 'IN' ? '₹' : '$'}{user?.countryCode == 'IN' ? subscriptionType == 'monthly' ? (plan?.pricing?.monthly?.inr * 0.18).toFixed(2) : (plan?.pricing?.yearly?.inr * 0.18).toFixed(2) : subscriptionType == 'monthly' ? (plan?.pricing.monthly.usd * 0.18).toFixed(2) : (plan?.pricing.yearly.usd * 0.18).toFixed(2)}</div>
                                        </li>
                                    </ul>
                                        <div className="border-top border-bottom py-3">
                                            {activePromoCode?._id ? (
                                                <div className="promo-code-section p-2 border rounded shadow-sm">
                                                    <div
                                                        className="d-flex justify-content-between align-items-center mb-1">
                                                        <span className="text-success fw-bold">Coupon Applied!</span>
                                                        <em
                                                            className="icon ni ni-trash text-danger"
                                                            style={{cursor: 'pointer'}}
                                                            onClick={() => setActivePromoCode(null)}
                                                        ></em>
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <div className="text-muted">Coupon Discount</div>
                                                        <div className="text-success">- {activePromoCode?.percentage}%
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="row gx-gs gy-4">
                                                    <div className="form-group ">
                                                        <label htmlFor="exampleFormControlInputText1"
                                                               className="form-label">Have
                                                            a
                                                            coupon code?</label>
                                                        <div className="d-flex justify-content-between ">
                                                            <div className="form-control-wrap w-60">
                                                            <input type="text" className="form-control"
                                                                   onChange={(e) => setCouponCode(e.target.value)}
                                                                   placeholder="Enter coupon code"/>
                                                        </div>
                                                        <button disabled={couponCode === '' || loading} onClick={() => checkPromoCodeValid()}
                                                                className="btn btn-light btn-md"> Apply
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            )}
                                        </div>
                                    <div className="d-flex justify-content-between pt-2">
                                        <div className="caption-text">Total</div>
                                        <div className="caption-text text-nowrap">
                                            {user?.countryCode === 'IN' ? '₹' : '$'} {totalAmount()}
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-center w-100 mt-3">
                                        {user?.countryCode === "IN" ?

                                            <Button
                                                isLoading={loading}
                                                label="Proceed with payment"
                                                variant="brand"
                                                className="rainbow-m-around_medium"
                                                onClick={() => createSubscription()}
                                            />
                                            :
                                            <PayPalSubscriptionButton
                                                planId="P-45W37689GJ9125516M2GQ2LQ" // Replace with your actual Plan ID
                                                onSuccess={() => {
                                                }}
                                                onError={() => {
                                                }}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default PaymentPlan;

import React, { useRef } from 'react';
import { Button, Modal } from "react-rainbow-components";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import FormButton from "../../../components/form/bottons/FormButton";
import {IPaymentHistory} from "../../../../interfaces/paymentHistory.interface";

interface UserFormProps {
    showModal:boolean;
    setShowModal:any;
    payment: IPaymentHistory
}

const PaymentInvoice: React.FC<UserFormProps> = ({ payment, showModal, setShowModal }) => {
    const invoiceRef = useRef(null);

    const downloadInvoice = () => {
        const input = invoiceRef.current;

        html2canvas(input!).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            pdf.save(`invoice_${payment?._id}.pdf`);
        });
    };

    return (
        <Modal
            id="modal-delete-website"
            title="Download Invoice"
            size="large"
            isOpen={showModal}
            onRequestClose={() => setShowModal(false)}
            footer={
                <div className="d-flex justify-content-end gap-2">
                    <FormButton type="button" text="Cancel" onClick={() => setShowModal(false)} />
                    <Button
                        label="Download"
                        size="small"
                        variant="destructive"
                        borderRadius="semi-square"
                        onClick={downloadInvoice}
                    />
                </div>
            }
        >
            <div ref={invoiceRef} className="container">
                <div className="row">
                    <div className="col-12">
                        <h2>Invoice</h2>
                        <p><strong>Transaction ID:</strong> {payment?.transactionId}</p>
                        <p><strong>Payment Date:</strong> {new Date(payment?.paymentDate).toLocaleDateString()}</p>
                        <hr />

                        <div className="row">
                            <div className="col-6">
                                <address>
                                    <strong>Billed To:</strong><br />
                                    {payment?.userId?.fullName}<br />
                                    {payment?.userId?.email}
                                </address>
                            </div>
                            <div className="col-6 text-end">
                                <address>
                                    <strong>Payment Status:</strong><br />
                                    {payment?.paymentStatus}
                                </address>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <h3>Order Summary</h3>
                                <table className="table table-bordered">
                                    <thead>
                                    <tr>
                                        <th>Description</th>
                                        <th className="text-end">Amount</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>Plan Title</td>
                                        <td className="text-end">{payment?.planId?.title}</td>
                                    </tr>
                                    <tr>
                                        <td>Plan Subtitle</td>
                                        <td className="text-end">{payment?.planId?.subtitle}</td>
                                    </tr>
                                    <tr>
                                        <td>Tokens</td>
                                        <td className="text-end">{payment?.tokens}</td>
                                    </tr>
                                    <tr>
                                        <td>Payment Gateway</td>
                                        <td className="text-end">{payment?.paymentGateway}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Total</strong></td>
                                        <td className="text-end"><strong>{payment?.currency === 'usd' ? '$' : '₹'} {payment?.amount}</strong></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 text-end">
                                <p><strong>Authorized By:</strong> ExploreSoul Technologies</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default PaymentInvoice;

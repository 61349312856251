import { combineReducers } from '@reduxjs/toolkit';

import appBasicReducer from './slices/appBasicSlice';

import userReducer from './slices/userSlice';
import websitesReducer from './slices/websitesSlice';
import shopsReducer from './slices/shopsSlice';
import pricingPlansReducer from './slices/pricingPlansSlice';
import websiteChatReducer from './slices/websiteChatSlice';
import shopChatReducer from './slices/shopChatSlice';
import paymentHistoryReducer from './slices/paymentHistorySlice';
import dashboardReducer from './slices/dashboardStatisticsSlice';
import organizationReducer from './slices/organizationsSlice';
import organizationChatReducer from './slices/organizationChatSlice';
import publicOrganizationReducer from './slices/publicOrganizationSlice';


const rootReducer = combineReducers({
    appBasic:appBasicReducer,
    user:userReducer,
    websites:websitesReducer,
    shops:shopsReducer,
    pricingPlans: pricingPlansReducer,
    websiteChats: websiteChatReducer,
    shopChats: shopChatReducer,
    paymentHistory: paymentHistoryReducer,
    dashboardStatistics: dashboardReducer,
    organizations: organizationReducer,
    organizationChats: organizationChatReducer,
    publicOrganization: publicOrganizationReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;

import React, {FC, useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import FormButton from "../../../components/form/bottons/FormButton";
import FormInput from "../../../components/form/FormInput";
import {Badge, Button, ButtonMenu, FileSelector, MenuItem, Modal, Option, Picklist} from "react-rainbow-components";
import * as Yup from "yup";
import {useFormik} from "formik";
import http from "../../../../services/api";
import {IDisplay, IDocument, IShop} from "../../../../interfaces/shop.interface";
import FileUpload from "../../../components/upload/fileUpload";
import dayjs from "dayjs";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux/rootReducer";
import EmptyMessage from "../../../components/empty/EmptyMessage";
import ShopDisplayForm from "../modals/ShopDisplayForm";
import {shops_url} from "../../../../constants/contants";
import {deleteDisplaySuccess} from "../../../../redux/slices/shopsSlice";
import {fetchPricingPlansSuccess} from "../../../../redux/slices/pricingPlansSlice";
import {toastMessage} from "../../../../utils/util";
import ShopDisplayManage from "./ShopDisplayManage";


interface FileUploadHandle {
    uploadFiles: () => Promise<void>;
}


const ShopDisplays: React.FC = () => {

    const navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [selectedDisplay, setSelectedDisplay] = useState<IDisplay | undefined>(undefined);

    const { shop, loading, error } = useSelector((state: RootState) => state.shops);

    return (
        <div className="tab-pane fade show active" id="profile-tab-pane">
            <div
                className="d-flex align-items-center justify-content-between  mt-5 pb-1">
                <h5>Displays</h5>
                <button className="btn btn-primary" onClick={()=>setShowModal(true)} ><em className="icon ni ni-plus"></em><span>Add New</span></button>
            </div>
            <div className="nk-block">

                <div className="card shadow-none">
                    <table className="table table-middle mb-0">
                        <tbody>

                        {shop?.displays.length? shop?.displays.slice()
                                .sort((a, b) => dayjs(b.createdAt).valueOf() - dayjs(a.createdAt).valueOf()).map((display) => (

                                    <tr>
                                        <td className="tb-col">
                                            <div className="caption-text link line-clamp-2"
                                                 onClick={() => navigate(`/shop/${shop._id}/display/${display._id}`)}>{display?.name}</div>
                                        </td>
                                        {/*<td className="tb-col">*/}
                                        {/*    {display?.pushedDocuments?.map((pushedDocument) => {*/}

                                        {/*        const document = shop.documents.find(doc => doc._id === pushedDocument.documentId);*/}

                                        {/*        if (!document) return null; // If no document is found, return null or handle as needed*/}


                                        {/*        return (*/}
                                        {/*            <Badge*/}
                                        {/*                className="rainbow-m-around_medium"*/}
                                        {/*                label={document?.name}*/}
                                        {/*                variant="outline-brand"*/}
                                        {/*                title={document?.name}*/}
                                        {/*            />*/}
                                        {/*        )*/}


                                        {/*    })}*/}

                                        {/*</td>*/}

                                        <td className="tb-col">
                                            <div className="caption-text line-clamp-2">
                                                {display?.displayType}
                                            </div>
                                        </td>

                                        <td className="tb-col">
                                            <div className="caption-text line-clamp-2"><a
                                                href={`/public/shop/${shop._id}/display/${display._id}/${shop.apiKey}`}
                                                target={`_blank`}>Public URL</a>
                                            </div>
                                        </td>

                                        <td className="tb-col tb-col-md">
                                            <div className="fs-6 text-light d-inline-flex flex-wrap gap gx-2">
                                                <span>{dayjs(display.createdAt).format('MMM DD, YYYY')}</span>
                                                <span>{dayjs(display.createdAt).format('hh:mm A')}</span>
                                            </div>
                                        </td>
                                        <td className="tb-col tb-col-end">
                                            <ButtonMenu
                                                menuAlignment="right"
                                                menuSize="x-small"
                                                buttonVariant="base"
                                                icon={<em className="icon ni ni-more-h"></em>}
                                            >

                                                <MenuItem icon={<em className="icon ni ni-trash"></em>} onClick={() => {
                                                    setSelectedDisplay(display);
                                                    setShowDeleteModal(true);
                                                }} label="Delete"/>

                                                {/*<MenuItem icon={<em className="icon ni ni-edit"></em>} onClick={() => {*/}
                                                {/*    setSelectedDisplay(display)*/}
                                                {/*    setShowModal(true)*/}
                                                {/*}} label="Edit"/>*/}
                                            </ButtonMenu>
                                        </td>
                                    </tr>

                                )) :

                            <EmptyMessage
                                type="doc"
                                title="No displays available."
                                description="You have no displays stored. Upload documents to start organizing your files."
                                buttonText="Add Displays"
                                buttonCallback={()=>{
                                    setShowModal(true)
                                }}
                            />
                        }


                        </tbody>
                    </table>

                </div>

            </div>

            <ShopDisplayForm
                display={selectedDisplay}
                showModal={showModal}
                setShowModal={(e)=>{
                    setShowModal(e)
                    setSelectedDisplay(undefined)
                }}
            />

            {selectedDisplay && (
                <ShopDisplayManage
                    display={selectedDisplay}
                    showModal={showDeleteModal}
                    setShowModal={(e)=>{
                        setShowDeleteModal(e)
                        setSelectedDisplay(undefined)
                    }}/>
            )}

        </div>
    );
};
export default ShopDisplays;

import React from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
    createOrganizationStart,
    createOrganizationSuccess,
    createOrganizationFailure,
    updateOrganizationStart,
    updateOrganizationSuccess,
    updateOrganizationFailure,
} from '../../../../redux/slices/organizationsSlice'; // Updated actions for organization
import { RootState } from "../../../../redux/rootReducer";
import { organizations_url } from "../../../../constants/contants"; // Updated endpoint
import * as Yup from "yup";
import http from "../../../../services/api";
import { toastMessage } from "../../../../utils/util";
import FormInput from "../../../components/form/FormInput";
import {Button, Modal} from "react-rainbow-components";
import FormButton from "../../../components/form/bottons/FormButton";
import { updateUserSuccess } from "../../../../redux/slices/userSlice";
import { IOrganization } from "../../../../interfaces/organization.interface"; // Updated interface

interface OrganizationFormProps {
    organization: IOrganization | null; // If provided, it's edit mode; otherwise, it's create mode
    showModal: boolean;
    setShowModal: any;
}
const OrganizationForm: React.FC<OrganizationFormProps> = ({ organization, showModal, setShowModal }) => {
    const dispatch = useDispatch();

    const { user } = useSelector((state: RootState) => state.user);
    const { loading } = useSelector((state: RootState) => state?.organizations);

    const validationSchema = Yup.object({
        organizationName: Yup.string()
            .required('Organization Name is required'),
        organizationAiToken: Yup.number()
            .min(100, 'Organization Token must be greater than 100 ')
            .max(user?.aiTokens!, `Organization Token must be less than ${user?.aiTokens} `)
            .required('Organization Token is required'),
    });

    const formik = useFormik({
        initialValues: {
            organizationName: organization?.organizationName || '',
            organizationAiToken: organization?.organizationAiToken || 100,
        },
        enableReinitialize: true, // This allows the form to update when organization changes
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            if (organization) {
                // Update organization
                dispatch(updateOrganizationStart());

                try {
                    const response = await http.put<IOrganization>(`${organizations_url}/${organization._id}`, {
                        organizationName: values.organizationName,
                        organizationAiToken: values.organizationAiToken,
                        app: 'organization-bot'
                    });

                    if (user && values.organizationAiToken !== organization.organizationAiToken) {
                        dispatch(updateUserSuccess({
                            ...user,
                            aiTokens: organization.organizationAiToken > values.organizationAiToken
                                ? user.aiTokens + (organization.organizationAiToken - values.organizationAiToken)
                                : user.aiTokens - (values.organizationAiToken - organization.organizationAiToken),
                        }));
                    }

                    dispatch(updateOrganizationSuccess(response.data));
                    toastMessage('Organization Updated successfully', 'success');
                } catch (error: any) {
                    dispatch(updateOrganizationFailure(error!));
                    toastMessage('Failed to update organization', 'error');
                }
            } else {
                // Create new organization
                dispatch(createOrganizationStart());

                try {
                    const response = await http.post<IOrganization>(organizations_url, {
                        organizationName: values.organizationName,
                        organizationAiToken: values.organizationAiToken,
                        app: 'chat-bot',
                    });

                    dispatch(createOrganizationSuccess(response.data));

                    if (user) {
                        dispatch(updateUserSuccess({
                            ...user,
                            aiTokens: user.aiTokens - values.organizationAiToken,
                        }));
                    }

                    toastMessage('Organization Created successfully', 'success');
                } catch (error: any) {
                    dispatch(createOrganizationFailure('Failed to create organization'));
                    console.error('Failed to create organization', error);
                    toastMessage('Failed to create organization', 'error');
                }
            }

            setShowModal(false);
            formik.resetForm();
        },
    });

    return (
        <Modal
            id="modal-add-organization"
            title={organization ? 'Update Organization' : 'Create Organization'}
            isOpen={showModal}
            onRequestClose={() => {
                setShowModal(false);
                formik.resetForm();
            }}
            footer={
                <div className="d-flex justify-content-end">
                    {loading ? (
                            <Button isLoading label="Button Brand" variant="brand" className="rainbow-m-around_medium" style={{borderRadius: 5}} />
                        ):(
                            <FormButton type="submit" text={organization ? 'Update' : 'Create'} onClick={formik.handleSubmit} />
                        )}
                </div>
            }
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="row gy-3">
                    <div className="col-12">
                        <FormInput
                            label="Organization Name"
                            name="organizationName"
                            type="text"
                            placeholder="Enter Organization Name"
                            value={formik.values.organizationName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.organizationName}
                        />
                    </div>
                    <div className="col-12">
                        <FormInput
                            label="Organization Tokens"
                            name="organizationAiToken"
                            type="number"
                            placeholder="Enter Organization Tokens"
                            value={formik.values.organizationAiToken}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.organizationAiToken}
                        />
                    </div>
                </div>
            </form>
        </Modal>
    );
};

export default OrganizationForm;

import React, {FC, useEffect, useState} from 'react';

import CodeMirror, {EditorView} from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript'; // or any other language you need
import { oneDark } from '@codemirror/theme-one-dark'; // or any other theme you prefer

import WindowToolImg from '../../../assets/window-tool.svg'

const SdkUsage: FC = () => {

    return (
        <>
            <div className="nk-block">
                <div className="nk-block-head nk-block-head-sm">
                    <div className="nk-block-head-content">
                        <p className={`fst-italic`}>installing with npm</p>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header"
                         style={{backgroundColor: '#20252e', borderBottom: 'none'}}>
                        <img src={WindowToolImg} width={60}/>
                    </div>
                    <div className="card-body" style={{
                        backgroundColor: '#282c34',
                        borderBottomRightRadius: '5px',
                        borderBottomLeftRadius: '5px'
                    }}>
                        <CodeMirror
                            value={"import { BrainaqueChatBot} from 'brainaque-chat-sdk'\n" +
                                "\n" +
                                "function App() {\n" +
                                "\n" +
                                "  const theme ={\n" +
                                "    chatBotIcon:{\n" +
                                "      backgroundColor:'yellow'\n" +
                                "    }\n" +
                                "  }\n" +
                                "  return (\n" +
                                "    <div className=\"App\">\n" +
                                "      <BrainaqueChatBot apiKey={''}  theme={theme}' />\n" +
                                "    </div>\n" +
                                "  );\n" +
                                "}\n" +
                                "\n" +
                                "export default App;\n"}
                            height="100%"
                            theme={oneDark}
                            extensions={[javascript(), EditorView.editable.of(false)]}
                            readOnly={true}
                        />
                    </div>
                </div>
            </div>

        </>
    );
};
export default SdkUsage;

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import store from "./redux/store";
import {Provider} from "react-redux";
import {HelmetProvider } from 'react-helmet-async';

import '../src/assets/custom.scss'
import {Toaster} from "react-hot-toast";

import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
        <Provider store={store}>
            <GoogleOAuthProvider clientId="1088781261435-0ob2k2si581a4ut94bsa6pbi1i2rcqps.apps.googleusercontent.com">
                <HelmetProvider>
                    <App />
                    <Toaster />
                </HelmetProvider>
            </GoogleOAuthProvider>
        </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

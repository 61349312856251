import React, { useEffect } from 'react';
import { PayPalScriptProvider, PayPalButtons, FUNDING } from '@paypal/react-paypal-js';
import axios from 'axios';

interface PayPalSubscriptionButtonProps {
    planId: string;
    onSuccess: (details: any) => void;
    onError: (error: any) => void;
}

const PayPalSubscriptionButton: React.FC<PayPalSubscriptionButtonProps> = ({ planId, onSuccess, onError }) => {
    const clientId = process.env.REACT_APP_PAYPAL_CLIENT_ID || "Ab7gktejE9EFpifaq1QKmvhBIb6CjwUR3sttRaYvwVM2rGRTe0dEMnWo81ZVGDXItILdKXX2Sj7WSDZp";

    useEffect(() => {
        console.log('Client ID:', clientId);
        console.log('Plan ID:', planId);
    }, [clientId, planId]);

    const handleApprove = async (data: any, actions: any) => {
        console.log('Subscription approved:', data);
        if (actions.subscription) {
            try {
                const details = await actions.subscription.get();
                console.log('Subscription details:', details);
                const response = await axios.post('/api/paypal/capture-subscription', { subscriptionId: details.id });
                onSuccess(response.data);
            } catch (error) {
                console.error('Error capturing subscription:', error);
                onError(error);
            }
        } else {
            console.error('Subscription actions are not defined.');
            onError(new Error('Subscription actions are not defined.'));
        }
    };

    const handleError = (err: any) => {
        console.error('PayPal Checkout onError:', err);
        onError(err);
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', gap: '20px' }}>
            <PayPalScriptProvider options={{ clientId: clientId, vault: true, intent: 'subscription', components: 'buttons,funding-eligibility' }}>
                <div style={{ width: '200px' }}>
                    <PayPalButtons
                        fundingSource={FUNDING.PAYPAL}
                        style={{ layout: 'horizontal', color: 'gold', shape: 'rect', height: 40, tagline: false }} // Adjust the height here
                        createSubscription={(data, actions) => {
                            console.log('Creating subscription with plan ID:', planId);
                            return actions.subscription.create({
                                'plan_id': planId
                            });
                        }}
                        onApprove={handleApprove}
                        onError={handleError}
                    />
                </div>
                <div style={{ width: '200px' }}>
                    <PayPalButtons
                        fundingSource={FUNDING.CARD}
                        style={{ layout: 'horizontal', color: 'black', shape: 'rect', label:'subscribe', height: 40, tagline: false }} // Adjust the height here
                        createSubscription={(data, actions) => {
                            console.log('Creating subscription with plan ID:', planId);
                            return actions.subscription.create({
                                'plan_id': planId
                            });
                        }}
                        onApprove={handleApprove}
                        onError={handleError}
                    />
                </div>
            </PayPalScriptProvider>
        </div>
    );
};

export default PayPalSubscriptionButton;

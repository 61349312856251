import React, { useEffect, useState } from 'react';
import GridLayout, { Layout, ItemCallback } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { IDisplay, IGridItem, IShop } from "../../../../interfaces/shop.interface";
import { Picklist, Option, Input, Button, MultiSelect } from 'react-rainbow-components';
import GridItemForm from "../modals/GridItemForm";
import '../shop.scss';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/rootReducer";
import { useParams } from "react-router-dom";
import { fetchShopFailure, fetchShopStart, fetchShopSuccess } from "../../../../redux/slices/shopsSlice";
import http from "../../../../services/api";
import { shops_url } from "../../../../constants/contants";
import Slider from "react-slick";


const settings = {
    dots: false, // Disable dots
    arrows: false, // Disable arrows
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true, // Enable fade effect for smoother transitions
};

const ShopDisplayLayout: React.FC = () => {

    const { id, displayId } = useParams();

    const { shop, loading, error } = useSelector((state: RootState) => state.shops);

    const dispatch = useDispatch();

    const [display, setDisplay] = useState<IDisplay>();

    const [showModal, setShowModal] = useState(false);
    const [screenOrientation, setScreenOrientation] = useState<any>({
        label: 'Horizontal',
        name: 'horizontal',
    });

    const [selectedGridItem, setSelectedGridItem] = useState<any>(null);
    const [gridItemForm, setGridItemForm] = useState<IGridItem | null>(null);

    useEffect(() => {
        if (shop && shop.displays) {
            const foundDisplay = shop.displays.find(d => d._id === displayId);
            if (foundDisplay) {
                setDisplay({
                    ...foundDisplay,
                    gridItems: foundDisplay.gridItems || [], // Ensure gridItems is an array
                });
            }
        }
    }, [shop, displayId]);


    const fetchShop = async () => {
        dispatch(fetchShopStart());

        try {
            let response = await http.get<IShop>(`${shops_url}/${id}`);
            dispatch(fetchShopSuccess(response.data));
        } catch (error: any) {
            dispatch(fetchShopFailure('Failed to fetch shop'));
        }
    };

    useEffect(() => {
        fetchShop();
    }, [dispatch, id]);

    const handleSaveNewItem = (newItem: IGridItem) => {
        if (!display) return;

        let maxX = 0;
        let rowY = 0;

        display?.gridItems?.forEach(item => {
            if (item.y === rowY && item.x + item.w > maxX) {
                maxX = item.x + item.w;
            }
        });

        if (maxX + newItem.w > cols) {
            rowY += 1;
            maxX = 0;
        }

        const updatedNewItem = {
            ...newItem,
            x: maxX,
            y: rowY,
        };

        const updatedDisplay: IDisplay = {
            ...display,
            gridItems: [...display.gridItems, updatedNewItem],
        };
        setDisplay(updatedDisplay);
    };

    const onLayoutChange = (layout: Layout[]) => {
        if (!display) return;

        const updatedGridItems = layout.map(l => {
            const item = display.gridItems.find(i => i.i === l.i)!;
            return {
                ...item,
                x: l.x,
                y: l.y,
                w: l.w,
                h: l.h,
            };
        });
        setDisplay({ ...display, gridItems: updatedGridItems });
    };

    const onDragStop: ItemCallback = (layout, oldItem, newItem) => {
        if (!display) return;

        const { height, rowHeight } = getScreenDimensions();
        const maxRows = Math.floor(height / rowHeight);
        const maxYPosition = maxRows - newItem.h;

        const updatedItems = display.gridItems.map(item =>
            item.i === newItem.i
                ? {
                    ...item,
                    x: newItem.x,
                    y: Math.min(newItem.y, maxYPosition), // Constrain Y position
                    w: newItem.w,
                    h: newItem.h,
                }
                : item
        );
        setDisplay({ ...display, gridItems: updatedItems });
    };

    const onResizeStop: ItemCallback = (layout, oldItem, newItem) => {
        if (!display) return;

        const { height, rowHeight } = getScreenDimensions();
        const maxRows = Math.floor(height / rowHeight);
        const maxHeight = maxRows - newItem.y;

        if (newItem.h > maxHeight) {
            newItem.h = maxHeight;
        }

        const updatedItems = display.gridItems.map(item =>
            item.i === newItem.i
                ? { ...item, w: newItem.w, h: newItem.h }
                : item
        );
        setDisplay({ ...display, gridItems: updatedItems });
    };

    const getScreenDimensions = () => {
        if (screenOrientation.name === 'vertical') {
            return { width: 270, height: 480, cols: 9, rowHeight: 30 };
        }
        return { width: 480, height: 270, cols: 16, rowHeight: 30 };
    };

    const handleGridItemSelect = (option: any) => {
        if (!display) return;

        setSelectedGridItem(option.name);
        const selectedItem = display.gridItems.find(item => item.i === option.name) || null;
        setGridItemForm(selectedItem);
    };

    const handleFormChange = (field: string, value: any) => {
        setGridItemForm(prevState => {
            if (!prevState) return null;

            const updatedForm = { ...prevState, [field]: value };

            // Update the corresponding grid item in the display
            if (display) {
                const updatedGridItems = display.gridItems.map(item =>
                    item.i === updatedForm.i ? updatedForm : item
                );
                setDisplay({ ...display, gridItems: updatedGridItems });
            }

            return updatedForm;
        });
    };


    const handleUpdateGridItem = () => {
        if (!display || !gridItemForm) return;

        const updatedGridItems = display.gridItems.map(item =>
            item.i === gridItemForm.i ? gridItemForm : item
        );
        setDisplay({ ...display, gridItems: updatedGridItems });
    };

    const { width, height, cols, rowHeight } = getScreenDimensions();
    const maxRows = Math.floor(height / rowHeight);

    return (
        <div className="container">
            <div className="mb-3">
                <Picklist
                    label="Select Screen Orientation"
                    value={screenOrientation}
                    onChange={(option) => setScreenOrientation(option)}
                    className="mb-3"
                >
                    <Option name="horizontal" label="Horizontal" />
                    <Option name="vertical" label="Vertical" />
                </Picklist>
            </div>

            <button onClick={() => setShowModal(true)} className="btn btn-success mb-3">
                Add New Grid Item
            </button>

            <div className={`row`}>
                <div className={`col-8`}>
                    <div
                        className="tv-screen-container"
                        style={{
                            width: `${width}px`,
                            height: `${height}px`,
                            border: '2px solid black',
                            margin: '0 auto',
                            padding: '5px',
                            boxSizing: 'border-box',
                            overflow: 'hidden', // Prevent scrolling
                            position: 'relative',
                        }}
                    >
                        {display && display.gridItems && (
                            <GridLayout
                                className="layout"
                                layout={display?.gridItems?.map(item => ({
                                    i: item.i,
                                    x: item.x,
                                    y: item.y,
                                    w: item.w,
                                    h: item.h,
                                }))}
                                cols={cols}
                                rowHeight={rowHeight}
                                width={width - 10}
                                maxRows={maxRows}
                                onLayoutChange={onLayoutChange}
                                onDragStop={onDragStop}
                                onResizeStop={onResizeStop}
                                preventCollision={false} // Ensures items are constrained within the grid
                                compactType={null} // Disable compaction to avoid auto-moving items
                            >
                                {display.gridItems?.map(item => {
                                    return (
                                        <div
                                            key={item.i}
                                            className="grid-item border rounded p-1"
                                            style={{
                                                width: "100%", // Full width of the parent container
                                                height: "100%", // Full height of the parent container
                                                position: "relative", // Ensure proper positioning within the parent container
                                                overflow: "hidden", // Hide overflow to ensure the image fits perfectly
                                            }}
                                        >
                                            {item.gridType.name === 'image' && item.selectedDocuments.length > 0 ? (
                                                <Slider {...settings}>
                                                    {item.selectedDocuments.map((selectedDocument) => (
                                                        <div
                                                            onClick={() => {
                                                                handleGridItemSelect(item);
                                                                setShowModal(true);
                                                            }}
                                                            key={selectedDocument.value}
                                                            style={{
                                                                width: "100%",
                                                                height: "100%",
                                                                position: "relative",
                                                                overflow: "hidden",
                                                            }}
                                                        >
                                                            <img
                                                                src={selectedDocument.value}
                                                                alt={item.name} // Optional: Alt text for accessibility
                                                                style={{
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    objectFit: "cover",
                                                                    position: "absolute",
                                                                    top: 0,
                                                                    left: 0,
                                                                }}
                                                            />
                                                        </div>
                                                    ))}
                                                </Slider>
                                            ) : (
                                                <span>{item.name}</span>
                                            )}

                                        </div>
                                    );
                                })}

                            </GridLayout>
                        )}
                    </div>

                    <button onClick={() => setShowModal(true)} className="btn btn-success mb-3">
                       Save and Preview
                    </button>
                </div>
            </div>

            <GridItemForm
                selectedGridItem={selectedGridItem}
                showModal={showModal}
                setShowModal={(e)=>{
                    setShowModal(e)
                }}
                onSave={handleSaveNewItem}
                itemCount={display ? display.gridItems?.length : 0}
                shopDocuments={shop?.presentationDocuments || []} // Pass shop documents to the form
                documents={shop?.documents || []} // Pass shop documents to the form
            />

        </div>
    );
};

export default ShopDisplayLayout;

import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/rootReducer";
import { Modal, ButtonMenu, MenuItem, Button } from "react-rainbow-components";
import { useFormik } from "formik";
import http from "../../../services/api";
import { organizations_url } from "../../../constants/contants";
import * as Yup from "yup";
import { toastMessage } from "../../../utils/util";
import { IOrganization } from "../../../interfaces/organization.interface"; // Update to organization interface
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import {
    fetchOrganizationsFailure,
    fetchOrganizationsStart,
    fetchOrganizationsSuccess
} from "../../../redux/slices/organizationsSlice"; // Update redux actions
import OrganizationForm from "./modals/OrganizationForm"; // Update modal imports
import OrganizationManage from "./modals/OrganizationManage";
import EmptyMessage from "../../components/empty/EmptyMessage";
import Loader from "../../components/loader/Loader";


const OrganizationsContainer: FC = () => {

    const { organizations, loading, error } = useSelector((state: RootState) => state.organizations); // Update state
    const dispatch = useDispatch();
    const { user } = useSelector((state: RootState) => state.user);

    const [showModal, setShowModal] = useState(false);
    const [manageModal, setManageModal] = useState('');
    const [activeTab, serActiveTab] = useState('active');
    const [selectedOrganization, setSelectedOrganization] = useState<IOrganization>();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchOrganizations = async () => {
            dispatch(fetchOrganizationsStart());

            try {
                let response = await http.get<IOrganization[]>(organizations_url); // Fetch organizations
                dispatch(fetchOrganizationsSuccess(response.data));
            } catch (error: any) {
                dispatch(fetchOrganizationsFailure('Failed to fetch organizations'));
            }
        };

        fetchOrganizations();
    }, []);

    return (
        <div className="nk-content">
            <div className="container-xl">
                <div className="nk-content-inner">
                    <div className="nk-content-body">
                        <div className="nk-block-head nk-page-head">
                            <div className="nk-block-head-between flex-wrap gap g-2">
                                <div className="nk-block-head-content">
                                    <h2 className="display-6">Organizations</h2>
                                    <p>List of organizations you have created or manage.</p>
                                </div>
                                {
                                    loading ? (
                                        <Button isLoading label="Button Brand" variant="brand" className="rainbow-m-around_medium" style={{borderRadius: 5}} />
                                    ):(
                                        <Button
                                            label="Create New"
                                            variant="brand"
                                            size={`small`}
                                            className="rainbow-m-around_medium"
                                            borderRadius="semi-square"
                                            onClick={() => {
                                        if (user?.aiTokens! < 100) {
                                            toastMessage(user?.plan ? 'Please choose a refill tokens to continue' : 'Please choose a plan tokens to continue', 'error');
                                        } else {
                                            setSelectedOrganization(undefined);
                                            setShowModal(true);
                                        }
                                    }}
                                        >
                                            <em className="icon ni ni-plus"/>
                                            <span className='ms-2'>Create New</span>
                                        </Button>
                                    )}
                            </div>
                        </div>
                        <div className="nk-block">
                            <div className="card shadow-none">
                                <ul className="nav nav-tabs nav-tabs-s1 px-4" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className={`nav-link ${activeTab === 'active' && 'active'}`} type="button" data-bs-toggle="tab" onClick={() => serActiveTab('active')} aria-selected="true" role="tab">Active</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={`nav-link ${activeTab === 'deactivated' && 'active'}`} type="button" data-bs-toggle="tab" onClick={() => serActiveTab('deactivated')} aria-selected="false" role="tab">Deactivated</button>
                                    </li>
                                </ul>
                                <div className="tab-content">
                                    <div className="table-responsive tab-pane fade active show" id="recents-documents-tab-pane" role="tabpanel">
                                        {loading ? <Loader /> :
                                            <table className="table table-middle mb-0">
                                                <tbody>
                                                {organizations?.filter((organization) => activeTab === 'active' ? !organization.deactivated : organization.deactivated).length ?
                                                    organizations?.filter((organization) => activeTab === 'active' ? !organization.deactivated : organization.deactivated).map((organization) =>
                                                        <tr key={organization._id}>
                                                            <td className="tb-col">
                                                                <div className="caption-text link line-clamp-2" onClick={() => navigate(`/organization/${organization._id}`)}>{organization.organizationName} </div>
                                                            </td>
                                                            <td className="tb-col">
                                                                <div className="caption-text link line-clamp-2" onClick={() => navigate(`/organization/${organization._id}`)}>{organization.organizationUrl} </div>
                                                            </td>
                                                            <td className="tb-col tb-col-sm">
                                                                <div className="caption-text line-clamp-2">{organization.organizationAiToken} tokens remaining </div>
                                                            </td>
                                                            <td className="tb-col tb-col-sm">
                                                                <div className="badge text-bg-dark-soft rounded-pill px-2 py-1 fs-6 lh-sm">{organization.organizationType}</div>
                                                            </td>
                                                            <td className="tb-col tb-col tb-col-sm">
                                                                <div className="fs-6 text-light d-inline-flex flex-wrap gap gx-2">
                                                                    <span>{dayjs(organization.createdAt).format('MMM DD, YYYY')}</span>
                                                                    <span>{dayjs(organization.createdAt).format('hh:mm A')}</span>
                                                                </div>
                                                            </td>
                                                            <td className="tb-col tb-col-end">
                                                                <ButtonMenu
                                                                    menuAlignment="right"
                                                                    menuSize="x-small"
                                                                    buttonVariant="base"
                                                                    icon={<em className="icon ni ni-more-h"></em>}
                                                                >
                                                                    <MenuItem
                                                                        icon={<em className="icon ni ni-edit"></em>}
                                                                        onClick={() => {
                                                                            setSelectedOrganization(organization);
                                                                            setShowModal(true);
                                                                        }} label="Edit" />
                                                                    <MenuItem
                                                                        icon={<em className="icon ni ni-trash"></em>}
                                                                        onClick={() => {
                                                                            setSelectedOrganization(organization);
                                                                            setManageModal(organization.deactivated ? 'activate' : 'deactivate');
                                                                        }}
                                                                        label={`${organization.deactivated ? 'Activate' : 'Deactivate'}`} />
                                                                    <MenuItem
                                                                        icon={<em className="icon ni ni-trash"></em>}
                                                                        onClick={() => {
                                                                            setSelectedOrganization(organization);
                                                                            setManageModal('delete');
                                                                        }} label="Delete" />
                                                                </ButtonMenu>
                                                            </td>
                                                        </tr>
                                                    ) :
                                                    <EmptyMessage
                                                        type="organization"
                                                        title="No organizations added."
                                                        description="You have not added any organizations yet. Start adding to manage your organizations."
                                                        buttonText="Add Organization"
                                                        buttonCallback={() => {
                                                            setShowModal(true);
                                                        }}
                                                    />
                                                }
                                                </tbody>
                                            </table>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <OrganizationForm organization={selectedOrganization!} setShowModal={(e: boolean) => setShowModal(e)} showModal={showModal} />
            <OrganizationManage organization={selectedOrganization!} setManageModal={(e: string) => setManageModal(e)} manageModal={manageModal} />
        </div>
    );
};

export default OrganizationsContainer;

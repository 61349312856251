import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    deleteOrganizationStart,
    deleteOrganizationSuccess,
    deleteOrganizationFailure,
    updateOrganizationStart,
    updateOrganizationSuccess,
    updateOrganizationFailure,
} from '../../../../redux/slices/organizationsSlice'; // Updated actions for organization
import { RootState } from "../../../../redux/rootReducer";
import { organizations_url } from "../../../../constants/contants";
import http from "../../../../services/api";
import { toastMessage } from "../../../../utils/util";
import { Button, Modal } from "react-rainbow-components";
import FormButton from "../../../components/form/bottons/FormButton";
import { updateUserSuccess } from '../../../../redux/slices/userSlice';
import { IOrganization } from '../../../../interfaces/organization.interface';
import {useNavigate} from "react-router-dom";

interface OrganizationManageProps {
    organization: IOrganization | null; // If provided, it's edit mode; otherwise, it's create mode
    manageModal: string;
    setManageModal: (modalState: string) => void;
}

const OrganizationManage: React.FC<OrganizationManageProps> = ({ organization, manageModal, setManageModal }) => {
    const dispatch = useDispatch();
    const { user } = useSelector((state: RootState) => state.user);
    const {loading} = useSelector((state: RootState) => state.organizations);
    const navigate = useNavigate();

    const handleDelete = async (organizationId: string) => {
        dispatch(deleteOrganizationStart());
        try {
            await http.delete(`${organizations_url}/${organizationId}`);
            dispatch(deleteOrganizationSuccess(organizationId));
            setManageModal('');

            if (user) {
                dispatch(updateUserSuccess({
                    ...user,
                    aiTokens: user.aiTokens + organization!.organizationAiToken,
                }));
            }

            navigate('/organizations');
            toastMessage('Organization deleted successfully', 'success');
        } catch (error: any) {
            dispatch(deleteOrganizationFailure('Failed to delete organization'));
            toastMessage('Failed to delete organization', 'error');
        }
    };

    const handleUpdate = async (organizationId: string) => {
        dispatch(updateOrganizationStart());
        try {
            const response = await http.put(`${organizations_url}/${organizationId}`, {
                deactivate: organization?.deactivated ? 'no' : 'yes',
            });
            dispatch(updateOrganizationSuccess(response.data));
            setManageModal('');

            toastMessage(`Organization ${organization?.deactivated ? 'activated' : 'deactivated'} successfully`, 'success');
        } catch (error: any) {
            dispatch(updateOrganizationFailure('Failed to update organization'));
            toastMessage('Failed to update organization', 'error');
        }
    };

    return (
        <Modal
            id="modal-manage-organization"
            className='text-center'
            title={manageModal === 'delete' ? "Delete Organization" : organization?.deactivated ? "Activate Organization" : "Deactivate Organization"}
            isOpen={!!manageModal}
            onRequestClose={() => setManageModal('')}
            footer={
                <div className="d-flex justify-content-end gap-2">
                    <FormButton type="button" text="Cancel" onClick={() => setManageModal('')} />
                    {manageModal === 'delete' ? (
                        loading ? (
                                <Button isLoading label="Button Brand" variant="brand" className="rainbow-m-around_medium" style={{borderRadius: 5, backgroundColor: 'red'}} />
                            ):(
                        <Button
                            label="Delete"
                            variant="destructive"
                            size={`small`}
                            borderRadius="semi-square"
                            className="rainbow-m-around_medium"
                            onClick={() => handleDelete(organization?._id!)}
                        />)
                    ) : (
                        loading ? (
                                <Button isLoading label="Button Brand" variant="brand" className="rainbow-m-around_medium" style={{borderRadius: 5, backgroundColor: 'red'}} />
                            ):(
                        <Button
                            label={organization?.deactivated ? 'Activate' : 'Deactivate'}
                            variant={organization?.deactivated ? 'success' : 'destructive'}
                            borderRadius="semi-square"
                            size={`small`}
                            className="rainbow-m-around_medium"
                            onClick={() => handleUpdate(organization?._id!)}
                        />
                        )
                    )}
                </div>
            }
        >
            Are you sure you want to {manageModal === 'delete' ? 'delete' : organization?.deactivated ? 'activate' : 'deactivate'} this organization?
            <div className='fw-bold'>{manageModal === 'delete' && "This action cannot be undone!"}</div>
        </Modal>
    );
};

export default OrganizationManage;

import React, {FC, useEffect, useState} from 'react';

import logo from "@src/assets/images/logo.png";
import dayjs from "dayjs";

const Footer: FC = () => {

    return (
        <div className="nk-footer">
            <div className="container-xl">
                <div className="d-flex align-items-center flex-wrap justify-content-between mx-n3">
                    <div className="nk-footer-links px-3">
                        <ul className="nav nav-sm">
                            <li className="nav-item">
                                <a className="nav-link" href="/">Home</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/public#">Pricing</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/public#">Privacy Policy</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/public#">FAQ</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/public#">Contact</a>
                            </li>
                        </ul>
                    </div>
                    <div className="nk-footer-copyright fs-6 px-3"> © {dayjs().year()} All Rights Reserved to <a
                        href="https://exploresoul.com/" target={`_blank`}>ExploreSoul Technologies</a>.
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;

import React, { FC, useEffect, useState } from 'react';
import { Badge, Breadcrumb, Breadcrumbs, Button, Chart, Dataset } from "react-rainbow-components";
import { useNavigate, useParams } from "react-router-dom";
import http from "../../../services/api";
import { IOrganization } from "../../../interfaces/organization.interface";
import { organization_chats_url, organizations_url } from "../../../constants/contants";
import OrganizationDashboard from "./tabs/OrganizationDashboard";
import OrganizationSettings from "./tabs/OrganizationSettings";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/rootReducer";
import { fetchOrganizationFailure, fetchOrganizationStart, fetchOrganizationSuccess } from "../../../redux/slices/organizationsSlice";

import { BrainaqueChatBot } from 'brainaque-chat-sdk';
import OrganizationChatHistory from "./tabs/OrganizationChatHistory";
import {
    fetchOrganizationChatsFailure,
    fetchOrganizationChatsStart,
    fetchOrganizationChatsSuccess
} from "../../../redux/slices/organizationChatSlice";
import { IOrganizationChats } from "../../../interfaces/organizationchat.interface";
import OrganizationChatBotSettings from "./tabs/OrganizationChatBotSettings";
import OrganizationMembers from "./tabs/OrganizationMembers";
import OrganizationGroups from "./tabs/OrganizationGroups";
import OrganizationRoles from "./tabs/OrganizationRoles";


const OrganizationContainer: FC = () => {
    const navigate = useNavigate();
    const { id, _page, groupId } = useParams();

    const [activeTab, setActiveTab] = useState('overview');

    const dispatch = useDispatch();
    const { organization, loading, error, organizations } = useSelector((state: RootState) => state.organizations);
    console.log('organ organizations', organizations);
    const fetchOrganization = async () => {
        dispatch(fetchOrganizationStart());

        try {
            let response = await http.get<IOrganization>(`${organizations_url}/${id}`);
            dispatch(fetchOrganizationSuccess(response.data));
        } catch (error: any) {
            dispatch(fetchOrganizationFailure('Failed to fetch organization'));
        }
    };

    const fetchOrganizationChats = async () => {
        dispatch(fetchOrganizationChatsStart());

        try {
            let response = await http.get<IOrganizationChats[]>(`${organization_chats_url}/${id}`);
            dispatch(fetchOrganizationChatsSuccess(response.data));
        } catch (error: any) {
            dispatch(fetchOrganizationChatsFailure('Failed to fetch organization chats'));
        }
    };

    useEffect(() => {
        fetchOrganization();
        fetchOrganizationChats();
    }, [dispatch, id]);

    useEffect(() => {
        if (_page) {
            setActiveTab(_page);
        }
    }, [_page]);

    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const values = [18, 42, 58, 50, 19, 0, 24, 50, 33, 20, 5, 35];

    const BarChartExample = () => (
        <div className="rainbow-p-vertical_large">
            <div className="rainbow-align-content_center rainbow-m-vertical_large rainbow-m_auto">
                <Chart labels={labels} type="bar">
                    <Dataset key="Sales" title="Sales" values={values} backgroundColor="#01b6f5" />
                </Chart>
            </div>
        </div>
    );

    const dataset = [
        { value: 10, color: '#fe4849' },
        { value: 15, color: '#ff6837' },
        { value: 42, color: '#ffcc00' },
        { value: 33, color: '#1ad1a3' },
    ];

    function renderDataset() {
        const data: any = [];
        const colors: any = [];
        dataset.forEach(set => {
            data.push(set.value);
            colors.push(set.color);
        });

        return <Dataset title="Data" values={data} backgroundColor={colors} />;
    }

    const PieChartExample = () => (
        <div className="rainbow-p-vertical_xx-large rainbow-p-horizontal_medium">
            <div className="rainbow-align-content_center rainbow-m-vertical_large rainbow-m_auto">
                <Chart labels={labels} type="pie" legendPosition="right">
                    {renderDataset()}
                </Chart>
            </div>
        </div>
    );

    return (
        <div className="nk-content">
            <div className="container-xl">
                <div className="nk-content-inner">
                    <div className="nk-content-body">
                        <div className="nk-block-head nk-page-head">
                            <div className="nk-block-head-between">
                                <div className="nk-block-head-content">
                                    <h2 className="display-6 d-flex align-items-start">{organization?.organizationName}
                                        <Badge
                                            className="rainbow-s-around_small fs-8"
                                            label={organization?.deactivated ? 'Deactivated' : 'Active'}
                                            variant={organization?.deactivated ? 'error' : 'success'}
                                            title={organization?.deactivated ? 'Deactivated' : 'Active'}
                                            style={{ letterSpacing: 'initial' }}
                                        />
                                    </h2>
                                </div>
                            </div>
                        </div>
               {/*         {!organization?.apiKey && (
                            <div className="nk-block">
                                <div className="alert alert-danger alert-dismissible fade show mb-4 rounded-3" role="alert">
                                    <p className="small">Create a token to test and use the chats now! You can create it from the <a className={`link link-danger`} onClick={() => setActiveTab('settings')}>settings tab</a>.</p>
                                </div>
                            </div>
                        )}
*/}
                        <div className="nk-block">
                            <div className={`d-flex nav-tabs align-items-center justify-content-between`}>
                                <ul className="nav nav-tabs-s1">
                                    <li className="nav-item">
                                        <button className={`nav-link ${activeTab === 'overview' ? 'active' : ''}`}
                                                onClick={() => navigate(`/organization/${id}/overview`)}
                                                type="button">Overview
                                        </button>
                                    </li>
                                    <li className="nav-item">
                                        <button
                                            className={`nav-link ${activeTab === 'groups' || activeTab === 'group' ? 'active' : ''}`}
                                            onClick={() => navigate(`/organization/${id}/groups`)} type="button">Groups
                                        </button>
                                    </li>
                                    <li className="nav-item">
                                        <button className={`nav-link ${activeTab === 'members' ? 'active' : ''}`}
                                                onClick={() => navigate(`/organization/${id}/members`)}
                                                type="button">Members
                                        </button>
                                    </li>


                                    <li className="nav-item">
                                        <button className={`nav-link ${activeTab === 'roles' ? 'active' : ''}`}
                                                onClick={() => navigate(`/organization/${id}/roles`)}
                                                type="button">Roles
                                        </button>
                                    </li>

                                    <li className="nav-item">
                                        <button className={`nav-link ${activeTab === 'settings' ? 'active' : ''}`}
                                                onClick={() => navigate(`/organization/${id}/settings`)}
                                                type="button">Settings
                                        </button>
                                    </li>
                                </ul>

                                <div className="nav nav-tabs-s1">
                                    <button className={`btn  fw-bold btn-sm btn-primary`}
                                            type="button">

                                        <a style={{color: '#fff'}} href={`/chat-organization/${id}`}
                                           target={`_blank`}>Visit Chat</a>
                                        <em className="icon  fw-bold ni ni-arrow-right"></em>
                                    </button>
                                </div>

                            </div>

                            <div className="tab-content">
                                {activeTab === 'overview' && <OrganizationDashboard/>}
                                {activeTab === 'groups' && id && organization && <OrganizationGroups initialActiveGroup={groupId? organization?.groups.find((group)=>group._id.toString() === groupId) : organization?.groups[0]}/>}
                                {activeTab === 'members' && id && <OrganizationMembers/>}
                                {activeTab === 'chat-history' && id && <OrganizationChatHistory/>}
                                {activeTab === 'roles' && id && <OrganizationRoles/>}
                                {activeTab === 'settings' && id && <OrganizationSettings/>}
                                {activeTab === 'chatbot-settings' && id && <OrganizationChatBotSettings/>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <BrainaqueChatBot apiKey={organization?.apiKey!}/>
        </div>
    );
};
export default OrganizationContainer;

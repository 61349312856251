// src/slices/paymentHistorySlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {IPaymentHistory} from "../../interfaces/paymentHistory.interface";


interface PaymentHistoryState {
    paymentHistory: IPaymentHistory[];
    loading: boolean;
    error: string | null;
}

const initialState: PaymentHistoryState = {
    paymentHistory: [],
    loading: false,
    error: null,
};


const paymentHistorySlice = createSlice({
    name: 'paymentHistory',
    initialState,
    reducers: {
        fetchPaymentHistoryStart(state) {
            state.loading = true;
            state.error = null;
        },
        fetchPaymentHistorySuccess(state, action: PayloadAction<IPaymentHistory[]>) {
            state.paymentHistory = action.payload;
            state.loading = false;
        },
        fetchPaymentHistoryFailure(state, action: PayloadAction<string>) {
            state.loading = false;
            state.error = action.payload;
        },
        createPaymentHistoryStart(state) {
            state.loading = true;
            state.error = null;
        },
        createPaymentHistorySuccess(state, action: PayloadAction<IPaymentHistory>) {
            state.paymentHistory.push(action.payload);
            state.loading = false;
        },
        createPaymentHistoryFailure(state, action: PayloadAction<string>) {
            state.loading = false;
            state.error = action.payload;
        },
        updatePaymentHistoryStart(state) {
            state.loading = true;
            state.error = null;
        },
        updatePaymentHistorySuccess(state, action: PayloadAction<IPaymentHistory>) {
            const index = state.paymentHistory.findIndex(
                (item) => item.transactionId === action.payload.transactionId
            );
            if (index !== -1) {
                state.paymentHistory[index] = action.payload;
            }
            state.loading = false;
        },
        updatePaymentHistoryFailure(state, action: PayloadAction<string>) {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const {
    fetchPaymentHistoryStart,
    fetchPaymentHistorySuccess,
    fetchPaymentHistoryFailure,
    createPaymentHistoryStart,
    createPaymentHistorySuccess,
    createPaymentHistoryFailure,
    updatePaymentHistoryStart,
    updatePaymentHistorySuccess,
    updatePaymentHistoryFailure,
} = paymentHistorySlice.actions;

export default paymentHistorySlice.reducer;
import FormButton from "../../../components/form/bottons/FormButton";
import {Button, Modal} from "react-rainbow-components";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux/rootReducer";
import {
    deleteWebsiteFailure,
    deleteWebsiteStart,
    deleteWebsiteSuccess, updateWebsiteFailure,
    updateWebsiteStart, updateWebsiteSuccess
} from "../../../../redux/slices/websitesSlice";
import http from "../../../../services/api";
import {organizations_url, shops_url} from "../../../../constants/contants";
import {updateUserSuccess} from "../../../../redux/slices/userSlice";
import {toastMessage} from "../../../../utils/util";
import {IShop} from "../../../../interfaces/shop.interface";
import {IGroup, IMember, IRole} from "../../../../interfaces/organization.interface";
import {
    deleteGroupSuccess,
    deleteMemberStart,
    deleteMemberSuccess,
    deleteRoleSuccess
} from "../../../../redux/slices/organizationsSlice";

interface Props {
    role?: IRole; // If provided, it's edit mode; otherwise, it's create mode
    showModal:boolean;
    setShowModal:any;
    setActiveGroup?:any
}

const RoleManage: React.FC<Props> = ({ role, showModal,setShowModal, setActiveGroup }) => {


    const dispatch = useDispatch();

    const {  organization } = useSelector((state: RootState) => state.organizations);

    const handleDelete =  async (organisationId: string) => {
        const roleId = role?._id;
        dispatch(deleteMemberStart());
        try {
            await http.delete(`${organizations_url}/${organisationId}/roles/${roleId}`);
            if(roleId){
                dispatch(deleteRoleSuccess({orgId: organisationId, roleId: roleId}));
            }
            setShowModal(false)

            setActiveGroup(undefined)

            toastMessage('Group Deleted successfully', 'success')
        } catch (error: any) {
            dispatch(deleteWebsiteFailure('Failed to delete Group'));
        }
    };
    const handleUpdate =  async (shopId: string) => {
        dispatch(updateWebsiteStart());
        try {
            const response = await http.put(`${shops_url}/${shopId}`);
            dispatch(updateWebsiteSuccess(response.data));
            setShowModal(false)

            toastMessage('Website Deactivated successfully', 'success')
        } catch (error: any) {
            dispatch(updateWebsiteFailure('Failed to Deactivated shop'));
        }
    };

    return(
        <Modal
            id="modal-delete-shop"
            title={"Delete Role"}

            isOpen={!!showModal}
            onRequestClose={() => {
                setShowModal(false)
            }}
            footer={
                <div className="d-flex justify-content-end gap-2">
                    <FormButton type={`button`} text={`Cancel`}  onClick={()=>setShowModal(false)} />
                        <Button label="Delete" size={`small`} variant="destructive" borderRadius="semi-square" className="rainbow-m-around_medium" onClick={()=>{
                            handleDelete(organization?._id!)
                        }} />
                </div>
            }
        >

            Are you sure you want to delete this role?
        </Modal>
    )
}
export default RoleManage;
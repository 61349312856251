import React, {useState} from 'react';
import {useFormik} from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/rootReducer';
import * as Yup from 'yup';
import http from '../../../../services/api';
import { toastMessage } from '../../../../utils/util';
import FormInput from '../../../components/form/FormInput';
import {Button, CheckboxToggle, Modal, Option, Picklist, RadioGroup} from 'react-rainbow-components';
import FormButton from '../../../components/form/bottons/FormButton';
import {IGroup, IRole} from '../../../../interfaces/organization.interface';
import {organizations_url, public_member_organizations_url} from '../../../../constants/contants';
import {
    createMemberStart,
    createMemberSuccess,
    createMemberFailure,
    updateMemberStart,
    updateMemberSuccess,
    updateMemberFailure,
    updateGroupStart,
    updateGroupSuccess,
    updateGroupFailure,
    createGroupStart,
    createGroupSuccess,
    createGroupFailure, updateRoleStart, updateRoleSuccess, createRoleStart, createRoleSuccess, createRoleFailure,
} from '../../../../redux/slices/organizationsSlice'; // Import necessary Redux actions

interface MemberFormProps {
    showModal: boolean;
    setShowModal:any
    role?:IRole
}
const RoleForm: React.FC<MemberFormProps> = ({ showModal,setShowModal, role }) => {
    const dispatch = useDispatch();
    const { user } = useSelector((state: RootState) => state.user);
    const { organization, loading } = useSelector((state: RootState) => state.organizations); // Get the current organization


    const [value, setValue] = useState('anonymous');



    const validationSchema = Yup.object({
        name: Yup.string().required('Name is required'),
        manageGroup: Yup.boolean().required('Iis required'),
        manageDocument: Yup.boolean().required('Iis required'),
        manageMember: Yup.boolean().required('Iis required'),
        isAdmin: Yup.boolean().required('Iis required')

    });


    const formik = useFormik({
        initialValues: {
            name: role?.name || '',
            manageGroup: role?.manageGroup || false,
            manageDocument: role?.manageDocument || false,
            manageMember: role?.manageMember || false,
            isAdmin: role?.isAdmin || false,
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: async (values) => {

            if (role) {
                dispatch(updateRoleStart());

                try {
                    if (organization) {
                        const response: any = await http.put<IRole>(
                            `${organizations_url}/${organization._id}/roles/${role._id}`,
                            {
                                name: values.name,
                                manageGroup: values.manageGroup,
                                manageDocument: values.manageDocument,
                                manageMember: values.manageMember,
                                isAdmin: values.isAdmin,
                            }
                        );

                        dispatch(updateRoleSuccess({
                            orgId: organization._id,
                            role: response.data.role,
                        }));
                        toastMessage('Role Updated successfully', 'success');
                    }
                } catch (error: any) {
                    dispatch(updateGroupFailure(error.message));
                    toastMessage('Failed to update role', 'error');
                }
            } else {
                dispatch(createRoleStart());

                try {
                    if (organization) {
                        const response: any = await http.post<IRole>(
                            `${organizations_url}/${organization._id}/roles`,
                            {
                                name: values.name,
                                manageGroup: values.manageGroup,
                                manageDocument: values.manageDocument,
                                manageMember: values.manageMember,
                                isAdmin: values.isAdmin,
                            }
                        );
                        dispatch(createRoleSuccess({
                            orgId: organization._id,
                            role: response.data.role,
                        }));
                        toastMessage('Role Created successfully', 'success');
                    }
                } catch (error: any) {
                    dispatch(createRoleFailure(error.message));
                    toastMessage('Failed to create role', 'error');
                }
            }

            setShowModal(false);
            formik.resetForm();
        },
    });

    return (
        <Modal
            id="modal-add-member"
            title={role ? 'Update Role' : 'Create Role'}
            isOpen={showModal}
            onRequestClose={() => {
                setShowModal(false);
                formik.resetForm();
            }}
            footer={
                <div className="d-flex justify-content-end">
                    {loading ? (
                        <Button
                            isLoading
                            label="Processing..."
                            variant="brand"
                            className="rounded-pill px-4 py-2"
                            style={{ backgroundColor: '#007bff', border: 'none' }}
                        />
                    ) : (
                        <FormButton
                            type="submit"
                            text={role ? 'Update' : 'Create'}
                            onClick={formik.handleSubmit}
                        />
                    )}
                </div>
            }
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="p-1">
                    <h5 className="text-secondary">Role Information</h5>
                    <div className="row gy-3">
                        <div className="col-12">
                            <FormInput
                                label="Role Name"
                                name="name"
                                type="text"
                                placeholder="Enter Role Name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.errors.name as string | undefined}
                            />
                        </div>
                    </div>

                    <hr/>

                    <h5 className="text-secondary">Admin Access</h5>
                    <div className="row gy-3">
                        <div className="col-12">
                            <CheckboxToggle
                                id="checkbox-toggle-isAdmin"
                                label="Is Admin"
                                value={formik.values.isAdmin}
                                onChange={() => {
                                    const isAdmin = !formik.values.isAdmin;
                                    formik.setFieldValue('isAdmin', isAdmin);
                                    if (isAdmin) {
                                        formik.setFieldValue('manageGroup', true);
                                        formik.setFieldValue('manageDocument', true);
                                        formik.setFieldValue('manageMember', true);
                                    }
                                }}
                            />
                        </div>
                    </div>

                    <hr/>

                    <h5 className="text-secondary">Permissions</h5>
                    <div className="row gy-3">
                        <div className="col-12">
                            <CheckboxToggle
                                disabled={formik.values.isAdmin}
                                id="checkbox-toggle-manageGroup"
                                label="Can Manage Group"
                                value={formik.values.manageGroup}
                                onChange={() => formik.setFieldValue('manageGroup', !formik.values.manageGroup)}
                            />
                        </div>
                        <div className="col-12">
                            <CheckboxToggle
                                disabled={formik.values.isAdmin}
                                id="checkbox-toggle-manageDocument"
                                label="Can Manage Documents"
                                value={formik.values.manageDocument}
                                onChange={() => formik.setFieldValue('manageDocument', !formik.values.manageDocument)}
                            />
                        </div>
                        <div className="col-12">
                            <CheckboxToggle
                                disabled={formik.values.isAdmin}
                                id="checkbox-toggle-manageMember"
                                label="Can Manage Members"
                                value={formik.values.manageMember}
                                onChange={() => formik.setFieldValue('manageMember', !formik.values.manageMember)}
                            />
                        </div>
                    </div>

                </div>
            </form>
        </Modal>

    );
};

export default RoleForm;

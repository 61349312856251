// src/slices/websitesSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {IDocument, IWebsite} from '../../interfaces/website.interface';

interface WebsitesState {
    websites: IWebsite[] | null;
    website: IWebsite | null;
    loading: boolean;
    error: string | null;
}

const initialState: WebsitesState = {
    websites: null,
    website: null,
    loading: false,
    error: null,
};

const websitesSlice = createSlice({
    name: 'websites',
    initialState,
    reducers: {
        fetchWebsitesStart(state) {
            state.loading = true;
            state.error = null;
        },
        fetchWebsitesSuccess(state, { payload }: PayloadAction<IWebsite[]>) {
            state.websites = payload;
            state.loading = false;
        },
        fetchWebsitesFailure(state, { payload }: PayloadAction<string>) {
            state.error = payload;
            state.loading = false;
        },
        fetchWebsiteStart(state) {
            state.loading = true;
            state.error = null;
        },
        fetchWebsiteSuccess(state, { payload }: PayloadAction<IWebsite>) {
            state.website = payload;
            state.loading = false;
        },
        fetchWebsiteFailure(state, { payload }: PayloadAction<string>) {
            state.error = payload;
            state.loading = false;
        },
        deleteWebsiteStart(state) {
            state.loading = true;
            state.error = null;
        },
        deleteWebsiteSuccess(state, { payload }: PayloadAction<string>) {
            if (state.websites) {
                state.websites = state.websites.filter(website => website._id !== payload);
            }
            state.loading = false;
        },
        deleteWebsiteFailure(state, { payload }: PayloadAction<string>) {
            state.error = payload;
            state.loading = false;
        },
        createWebsiteStart(state) {
            state.loading = true;
            state.error = null;
        },
        createWebsiteSuccess(state, { payload }: PayloadAction<IWebsite>) {
            if (state.websites) {
                state.websites.push(payload);
            } else {
                state.websites = [payload];
            }
            state.loading = false;
        },
        createWebsiteFailure(state, { payload }: PayloadAction<string>) {
            state.error = payload;
            state.loading = false;
        },
        updateWebsiteStart(state) {
            state.loading = true;
            state.error = null;
        },
        updateWebsiteSuccess(state, { payload }: PayloadAction<IWebsite>) {
            if (state.websites) {
                const index = state.websites.findIndex(website => website._id === payload._id);
                if (index !== -1) {
                    state.websites[index] = payload;
                }
            }
            if (state.website) {
                state.website = payload;
            }
            state.loading = false;
        },
        updateWebsiteFailure(state, { payload }: PayloadAction<string>) {
            state.error = payload;
            state.loading = false;
        },
        setWebsites(state, { payload }: PayloadAction<IWebsite[] | null>) {
            state.websites = payload;
        },
        setLoading(state, { payload }: PayloadAction<boolean>) {
            state.loading = payload;
        },
        setError(state, { payload }: PayloadAction<string | null>) {
            state.error = payload;
        },
        clearWebsites(state) {
            state.websites = null;
            state.website = null;
            state.loading = false;
            state.error = null;
        },
        updateApiKeyStart(state) {
            state.loading = true;
            state.error = null;
        },
        updateApiKeySuccess(state, { payload }: PayloadAction<{ id: string, apiKey: string, apiKeyName: string }>) {
            if (state.websites) {
                const index = state.websites.findIndex(website => website._id === payload.id);
                if (index !== -1) {
                    state.websites[index].apiKey = payload.apiKey;
                    state.websites[index].apiKeyName = payload.apiKeyName;
                }
            }

            if(state.website){
                state.website.apiKey = payload.apiKey;
                state.website.apiKeyName = payload.apiKeyName;
            }
            state.loading = false;
        },
        updateApiKeyFailure(state, { payload }: PayloadAction<string>) {
            state.error = payload;
            state.loading = false;
        },
        uploadDocumentStart(state) {
            state.loading = true;
            state.error = null;
        },
        uploadWebsiteDocumentSuccess(state, { payload }: PayloadAction<{ websiteId: string, document: IDocument }>) {
            if (state.websites) {
                const website = state.websites.find(website => website._id === payload.websiteId);
                if (website) {
                    website.documents.push(payload.document);
                }
            }
            if (state.website && state.website._id === payload.websiteId) {
                state.website.documents.push(payload.document);
            }
            state.loading = false;
        },
        uploadDocumentFailure(state, { payload }: PayloadAction<string>) {
            state.error = payload;
            state.loading = false;
        },
        deleteDocumentStart(state) {
            state.loading = true;
            state.error = null;
        },
        deleteDocumentSuccess(state, { payload }: PayloadAction<string>) {
            if (state.website) {
                state.website.documents = state.website.documents.filter(doc => doc._id !== payload);
            }
            state.loading = false;
        },
        deleteDocumentFailure(state, { payload }: PayloadAction<string>) {
            state.error = payload;
            state.loading = false;
        },
    },
});

export const {
    fetchWebsitesStart,
    fetchWebsitesSuccess,
    fetchWebsitesFailure,
    fetchWebsiteStart,
    fetchWebsiteSuccess,
    fetchWebsiteFailure,
    deleteWebsiteStart,
    deleteWebsiteSuccess,
    deleteWebsiteFailure,
    createWebsiteStart,
    createWebsiteSuccess,
    createWebsiteFailure,
    updateWebsiteStart,
    updateWebsiteSuccess,
    updateWebsiteFailure,
    setWebsites,
    setLoading,
    setError,
    clearWebsites,
    updateApiKeyStart,
    updateApiKeySuccess,
    updateApiKeyFailure,
    uploadDocumentStart,
    uploadWebsiteDocumentSuccess,
    uploadDocumentFailure,
    deleteDocumentStart,
    deleteDocumentSuccess,
    deleteDocumentFailure,
} = websitesSlice.actions;
export default websitesSlice.reducer;

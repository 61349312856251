import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
    createWebsiteStart,
    createWebsiteSuccess,
    createWebsiteFailure,
    updateWebsiteStart,
    updateWebsiteSuccess,
    updateWebsiteFailure,
    fetchWebsiteStart,
    fetchWebsiteSuccess,
    fetchWebsiteFailure, deleteWebsiteStart, deleteWebsiteSuccess, deleteWebsiteFailure,
} from '../../../../redux/slices/websitesSlice';
import { IWebsite } from '../../../../interfaces/website.interface';
import {RootState} from "../../../../redux/rootReducer";
import {delete_account_url, websites_url} from "../../../../constants/contants";
import * as Yup from "yup";
import http from "../../../../services/api";
import {toastMessage} from "../../../../utils/util";
import FormInput from "../../../components/form/FormInput";
import {Button, Modal, Option, Picklist} from "react-rainbow-components";
import FormButton from "../../../components/form/bottons/FormButton";
import {set} from "lodash";
import {updateUserSuccess} from "../../../../redux/slices/userSlice";

interface UserFormProps {
    showModal:boolean;
    setShowModal:any;
}


const DeleteAccount: React.FC<UserFormProps> = ({showModal ,setShowModal }) => {


    const dispatch = useDispatch();

    const {  user } = useSelector((state: RootState) => state.user);

    const handleDelete =  async () => {
        dispatch(deleteWebsiteStart());
        try {
            await http.delete(`${delete_account_url}`);

            localStorage.clear()
            window.location.reload()
            toastMessage('Account Deleted successfully', 'success')
        } catch (error: any) {
            dispatch(deleteWebsiteFailure('Failed to delete website'));
        }
    };


    return (
        <Modal
            id="modal-delete-website"
            title={ "Delete Profile"}

            isOpen={showModal}
            onRequestClose={() => {
                setShowModal('')
            }}
            footer={
                <div className="d-flex justify-content-end gap-2">
                    <FormButton type={`button`} text={`Cancel`}  onClick={()=>setShowModal('')} />

                    <Button label="Delete" size={`small`} variant="destructive" borderRadius="semi-square" className="rainbow-m-around_medium" onClick={()=>{
                        handleDelete()
                    }} />

                </div>
            }
        >

            Are you sure you want to delete this Account?
        </Modal>
    );
};

export default DeleteAccount;

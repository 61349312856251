import React, {FC, useEffect, useState} from 'react';

import logo from "../../../assets/logo.svg";
import LogoImg from "../../../assets/logo-full.png";
import {useNavigate} from "react-router-dom";

const AuthHeader: FC = () => {


    const navigate = useNavigate();
    return (

        <>
            <div className="nk-shape bg-shape-blur-a start-0 top-0"></div>
            <div className="nk-shape bg-shape-blur-b end-0 bottom-0"></div>
            <div onClick={() => navigate("/")} className="text-center pt-5">
            <span className="logo-link cursor-pointer">
                <img role='button' className="logo-img" width={200} src={LogoImg} alt=""/>
            </span>
            </div>
        </>

    );
};

export default AuthHeader;

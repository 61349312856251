// src/slices/userSlice.ts
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
    IGroup,
    IPublicGroupDocument,
    IPublicMemberGroup,
    IPublicOrganization
} from "../../interfaces/organization.interface";
import {toastMessage} from "../../utils/util";
import {IChatMessage} from "../../interfaces/organizationchat.interface";

interface publicOrganizationState {
    publicOrganization: IPublicOrganization | null;
    loading: boolean;
    error: string | null;
}

const initialState: publicOrganizationState = {
    publicOrganization: null,
    loading: false,
    error: null,
};

const publicOrganizationSlice = createSlice({
    name: 'publicOrganization',
    initialState,
    reducers: {
        fetchPublicOrganizationStart(state) {
            state.loading = true;
            state.error = null;
        },
        fetchPublicOrganizationSuccess(state, { payload }: PayloadAction<IPublicOrganization>) {
            state.publicOrganization = payload;
            state.loading = false;
        },
        fetchPublicOrganizationFailure(state, { payload }: PayloadAction<string>) {
            state.error = payload;
            state.loading = false;
        },
        uploadPublicOrganizationDocumentStart(state) {
            state.loading = true;
            state.error = null;
        },
        uploadPublicOrganizationDocumentSuccess(state, { payload }: PayloadAction<{ groupId: string, document: IPublicGroupDocument }>) {
            const group: any  = state.publicOrganization?.memberGroups.find((group : IPublicMemberGroup) => group._id === payload.groupId);

            if (!group) {
                console.error("No matching public organization found for groupId:", payload.groupId);
            } else {
                console.log('Public Organization:', group);
                group?.groupDocuments.push(payload.document);
            }

            state.loading = false;
        },
        uploadPublicOrganizationDocumentFailure(state, { payload }: PayloadAction<string>) {
            state.error = payload;
            state.loading = false;
        },
        deletePublicOrganizationDocumentStart(state) {
            state.loading = true;
            state.error = null;
        },
        deletePublicOrganizationDocumentSuccess(state, { payload }: PayloadAction<{ groupId: string, documentId: string }>) {
            let group: any  = state.publicOrganization?.memberGroups.find((group : IPublicMemberGroup) => group._id === payload.groupId);
            if (group) {
                const filteredDocuments = group?.groupDocuments.filter((doc: IPublicGroupDocument) => doc._id !== payload.documentId);
                group.groupDocuments = [...filteredDocuments];
            }
            state.loading = false;
        },
        deletePublicOrganizationDocumentFailure(state, { payload }: PayloadAction<string>) {
            toastMessage(`${payload}`, 'error');
            state.error = payload;
            state.loading = false;
        },
        updatePublicGroupMemberStart(state) {
            state.loading = true;
            state.error = null;
        },
        updatePublicGroupMemberSuccess(state, { payload }: PayloadAction<{ group: IGroup }>) {
            const organization = state.publicOrganization;
            if (organization) {
                const groupIndex = organization.memberGroups.findIndex(group => group._id === payload.group._id);
                if (groupIndex !== -1 && state?.publicOrganization) {
                    state.publicOrganization.memberGroups[groupIndex].groupMembers = payload.group.groupMembers;
                }
            }
            state.loading = false;
        },
        updatePublicGroupMemberFailure(state, { payload }: PayloadAction<string>) {
            toastMessage(`${payload}`, 'error');
            state.error = payload;
            state.loading = false;
        },

        updatePublicOrganizationMemberStart(state) {
            state.loading = true;
            state.error = null;
        },
        updatePublicOrganizationMemberSuccess(
            state,
            { payload }: PayloadAction<{ groupId: string, group: IGroup }>
        ) {
            console.log('updatePublicOrganizationMemberSuccess state state', payload);

            if (state.publicOrganization && state.publicOrganization.memberGroups) {
                state.publicOrganization.memberGroups = state.publicOrganization.memberGroups.map((g) => {
                    if (g._id === payload.groupId) {
                        // Update the group members if groupId matches
                        return {
                            ...g,
                            groupMembers: payload.group.groupMembers,
                        };
                    }
                    return g; // Return other groups unchanged
                });
            }

            // Display success message after the operation
            toastMessage('Member Updated successfully', 'success');
        },

        updatePublicOrganizationMemberFailure(state, { payload }: PayloadAction<string>) {
            toastMessage(`${payload}`, 'error');
            state.error = payload;
            state.loading = false;
        },

        updatePublicOrganizationChatsStart(state) {
            state.loading = true;
            state.error = null;
        },
        updatePublicOrganizationChatsSuccess(
            state,
            { payload }: PayloadAction<{ chat: any }>
        ) {
            if (state.publicOrganization) {
                state.publicOrganization.messages.push(payload.chat);
            }
        },
        updatePublicOrganizationChatsFailure(state, { payload }: PayloadAction<string>) {
            toastMessage(`${payload}`, 'error');
            state.error = payload;
            state.loading = false;
        },

        updatePublicOrganizationChatMessageSuccess(
            state,
            { payload }: PayloadAction<{ sessionId: string; chat: any }>
        ) {
            if (state.publicOrganization) {
                // Find the message corresponding to the given sessionId
                const messageIndex = state.publicOrganization.messages.findIndex(m => m?.sessionId === payload.sessionId);

                // If a message was found, update its chats
                if (messageIndex !== -1) {
                    state.publicOrganization.messages[messageIndex].chats.push(payload.chat);
                } else {
                    // Optionally handle the case where no message was found
                    console.warn(`No message found with sessionId: ${payload.sessionId}`);
                }
            }
        }

    },
});

export const {
    fetchPublicOrganizationStart,
    fetchPublicOrganizationSuccess,
    fetchPublicOrganizationFailure,
    uploadPublicOrganizationDocumentStart,
    uploadPublicOrganizationDocumentSuccess,
    uploadPublicOrganizationDocumentFailure,
    deletePublicOrganizationDocumentStart,
    deletePublicOrganizationDocumentSuccess,
    deletePublicOrganizationDocumentFailure,
    updatePublicOrganizationMemberStart,
    updatePublicOrganizationMemberSuccess,
    updatePublicOrganizationMemberFailure,
    updatePublicGroupMemberStart,
    updatePublicGroupMemberSuccess,
    updatePublicGroupMemberFailure,
    updatePublicOrganizationChatsStart,
    updatePublicOrganizationChatsSuccess,
    updatePublicOrganizationChatsFailure,
    updatePublicOrganizationChatMessageSuccess,
} = publicOrganizationSlice.actions;

export default publicOrganizationSlice.reducer;

import React, {FC, useEffect} from 'react';


import Image404 from '../../../assets/404.svg'
import AuthHeader from "../../components/headers/AuthHeader";
import {setUser} from "../../../redux/slices/userSlice";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

const Logout: FC = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(setUser(null));
        localStorage.clear()
        navigate('/');
    }, []);
    return (
        <>

        </>

    );
};

export default Logout;

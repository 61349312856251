import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
    createWebsiteStart,
    createWebsiteSuccess,
    createWebsiteFailure,
    updateWebsiteStart,
    updateWebsiteSuccess,
    updateWebsiteFailure,
    fetchWebsiteStart,
    fetchWebsiteSuccess,
    fetchWebsiteFailure, deleteWebsiteStart, deleteWebsiteSuccess, deleteWebsiteFailure,
} from '../../../../redux/slices/websitesSlice';
import { IWebsite } from '../../../../interfaces/website.interface';
import {RootState} from "../../../../redux/rootReducer";
import {websites_url} from "../../../../constants/contants";
import * as Yup from "yup";
import http from "../../../../services/api";
import {toastMessage} from "../../../../utils/util";
import FormInput from "../../../components/form/FormInput";
import {Button, Modal, Option, Picklist} from "react-rainbow-components";
import FormButton from "../../../components/form/bottons/FormButton";
import {set} from "lodash";
import {updateUserSuccess} from "../../../../redux/slices/userSlice";
import {useNavigate} from "react-router-dom";

interface WebsiteFormProps {
    website: IWebsite; // If provided, it's edit mode; otherwise, it's create mode
    manageModal:string;
    setManageModal:any;
}


const WebsiteManage: React.FC<WebsiteFormProps> = ({ website,manageModal,setManageModal }) => {


    const dispatch = useDispatch();

    const {  user } = useSelector((state: RootState) => state.user);
    const { loading } = useSelector((state: RootState) => state.websites);

    const navigate = useNavigate();

    const handleDelete =  async (websiteId: string) => {
        dispatch(deleteWebsiteStart());
        try {
            await http.delete(`${websites_url}/${websiteId}`);
            dispatch(deleteWebsiteSuccess(websiteId));
            setManageModal('')

            if(user){
                dispatch(updateUserSuccess({
                    ...user,
                    aiTokens: user.aiTokens+website.websiteAiToken
                }));

            }
            navigate('/websites');
            toastMessage('Website Deleted successfully', 'success')
        } catch (error: any) {
            dispatch(deleteWebsiteFailure('Failed to delete website'));
        }
    };
    const handleUpdate =  async (websiteId: string) => {
        dispatch(updateWebsiteStart());
        try {
            const response = await http.put(`${websites_url}/${websiteId}`, {
                deactivate: website?.deactivated?'no':'yes'
            });
            dispatch(updateWebsiteSuccess(response.data));
            setManageModal('')

            toastMessage('Website Deactivated successfully', 'success')
        } catch (error: any) {
            dispatch(updateWebsiteFailure('Failed to Deactivated website'));
        }
    };


    return (
        <Modal
            id="modal-delete-website"
            title={manageModal == 'delete'? "Delete Website": website?.deactivated? "Activate Website":"Deactivate Website"}

            isOpen={manageModal?true:false}
            onRequestClose={() => {
                setManageModal('')
            }}
            footer={
                <div className="d-flex justify-content-end gap-2">
                    <FormButton type={`button`} text={`Cancel`}  onClick={()=>setManageModal('')} />
                    {manageModal == 'delete'?
                        loading ? (
                                <Button isLoading label="Button Brand" variant="destructive" className="rainbow-m-around_medium" style={{borderRadius: 5, backgroundColor: 'red'}} />
                            ):(
                        <Button label="Delete" variant="destructive" borderRadius="semi-square" className="rainbow-m-around_medium" onClick={()=>{
                            handleDelete(website?._id!)
                        }} />
                        )
                        :
                        loading ? (
                                <Button isLoading label="Button Brand" variant="success" className="rainbow-m-around_medium" style={{borderRadius: 5}} />
                            ) : (
                        <Button label={`${ website?.deactivated? 'Activate':'Deactivate' }`} variant={`${ website?.deactivated? 'success':'destructive' }`} borderRadius="semi-square" className="rainbow-m-around_medium" onClick={()=>{
                            handleUpdate(website?._id!)
                        }} />
                        )
                    }

                </div>
            }
        >

            Are you sure you want to delete this website?
        </Modal>
    );
};

export default WebsiteManage;

import React, { FC, useState } from 'react';
import { Avatar, Badge, Modal } from "react-rainbow-components";
import FormButton from "../../../components/form/bottons/FormButton";
import FormInput from "../../../components/form/FormInput";
import * as Yup from "yup";
import { useFormik } from "formik";
import http from "../../../../services/api";
import { IApiKey, IOrganization } from "../../../../interfaces/organization.interface";
import { organizations_url } from "../../../../constants/contants";
import { toastMessage } from "../../../../utils/util";
import CopyClipInput from "../../../components/form/CopyClipInput";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/rootReducer";
import OrganizationChatBotBasicSettings from "../modals/OrganizationChatBotBasicSettings";
import {updateApiKeyFailure, updateApiKeyStart, updateApiKeySuccess} from "../../../../redux/slices/organizationsSlice";

const validationSchema = Yup.object({
    apiKeyName: Yup.string().required('API Key Name is required'),
});

interface ColorInputValue {
    hex: string;
    alpha: number;
    isValid: boolean;
}

const OrganizationChatBotSettings: React.FC = () => {
    const [showModal, setShowModal] = useState(false);
    const [showBotImageModal, setShowBotImageModal] = useState(false);
    const [showApiKeyModal, setShowApiKeyModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showBotModal, setShowBotModal] = useState(false);
    const [apiKeyData, setApiKeyData] = useState('');

    const [files, setFiles] = useState([]);
    const [value, setValue] = useState<ColorInputValue>({ hex: '#FFFFFF', alpha: 1, isValid: true });
    const [manageModal, setManageModal] = useState('');

    const dispatch = useDispatch();
    const { organization, loading, error } = useSelector((state: RootState) => state.organizations);

    const formik = useFormik({
        initialValues: {
            apiKeyName: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            dispatch(updateApiKeyStart());
            try {
                const { data } = await http.post<IApiKey>(`${organizations_url}/${organization?._id}/api-key`, { apiKeyName: values.apiKeyName });
                setApiKeyData(data.apiKey);
                dispatch(updateApiKeySuccess({ _id: organization?._id!, apiKey: data.apiKey, apiKeyName: values.apiKeyName }));
                setShowModal(false);
                setShowApiKeyModal(true);
            } catch (error) {
                dispatch(updateApiKeyFailure('Failed to update API key'));
            }
        },
    });

    return (
        <>
            <div className="nk-block">
                <div className="d-flex align-items-center justify-content-between border-bottom border-light mt-5 pb-1">
                    <h5>ChatBot Settings</h5>
                    <span className="link link-primary fw-normal" onClick={() => setShowBotModal(true)}>Edit</span>
                </div>
                {organization && (
                    <table className="table table-flush table-middle mb-0">
                        <tbody>
                        <tr>
                            <td className="tb-col">
                                <span className="fs-15px text-light">Bot Name</span>
                            </td>
                            <td className="tb-col">
                                    <span className="fs-15px text-base">
                                        {organization.bot.botName}
                                    </span>
                            </td>
                        </tr>
                        <tr>
                            <td className="tb-col">
                                <span className="fs-15px text-light">Bot Title</span>
                            </td>
                            <td className="tb-col">
                                    <span className="fs-15px text-base">
                                        {organization.bot.botTitle}
                                    </span>
                            </td>
                        </tr>
                        <tr>
                            <td className="tb-col">
                                <span className="fs-15px text-light">Icon</span>
                            </td>
                            <td className="tb-col">
                                {organization?.bot.botIcon ? (
                                    <Avatar
                                        src={organization?.bot.botIcon!}
                                        assistiveText={organization?.organizationName}
                                        size="medium"
                                        backgroundColor={organization?.bot.primaryColor.hex}
                                    />
                                ) : (
                                    <Avatar
                                        icon={
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                 strokeWidth="2" strokeLinecap="round"
                                                 strokeLinejoin="round" className="feather feather-message-square animate">
                                                <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                                            </svg>
                                        }
                                        assistiveText={organization?.organizationName}
                                        size="medium"
                                        backgroundColor={organization?.bot.primaryColor.hex}
                                    />
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td className="tb-col">
                                <span className="fs-15px text-light">Show Form</span>
                            </td>
                            <td className="tb-col">
                                <Badge className="rainbow-m-around_medium" label={organization?.bot.botShowForm ? 'Showing' : 'No'} variant={organization?.bot.botShowForm ? 'success' : 'error'} />
                            </td>
                        </tr>
                        <tr>
                            <td className="tb-col">
                                <span className="fs-15px text-light">Logo</span>
                            </td>
                            <td className="tb-col">
                                {organization?.bot.botLogo && (
                                    <img src={organization?.bot.botLogo!} width={30} />
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td className="tb-col">
                                <span className="fs-15px text-light">Welcome Message</span>
                            </td>
                            <td className="tb-col">
                                <span className="fs-15px text-base" dangerouslySetInnerHTML={{ __html: organization.bot.botWelcomeMessage }}></span>
                            </td>
                        </tr>
                        <tr>
                            <td className="tb-col">
                                <span className="fs-15px text-light">Primary Color</span>
                            </td>
                            <td className="tb-col">
                                <span className="px-2 rounded-2 me-1" style={{ backgroundColor: organization.bot.primaryColor.hex }}></span>{organization.bot.primaryColor.hex}
                            </td>
                        </tr>
                        <tr>
                            <td className="tb-col">
                                <span className="fs-15px text-light">Bot Bubble Color</span>
                            </td>
                            <td className="tb-col">
                                <span className="px-2 rounded-2 me-1" style={{ backgroundColor: organization.bot.chatBot.primaryColor.hex }}></span>{organization.bot.chatBot.primaryColor.hex}
                                <span className="px-2 rounded-2 me-1" style={{ backgroundColor: organization.bot.chatBot.secondaryColor.hex }}></span>{organization.bot.chatBot.secondaryColor.hex}
                            </td>
                        </tr>
                        <tr>
                            <td className="tb-col">
                                <span className="fs-15px text-light">User Bubble Color</span>
                            </td>
                            <td className="tb-col">
                                <span className="px-2 rounded-2 me-1" style={{ backgroundColor: organization.bot.chatUser.primaryColor.hex }}></span>{organization.bot.chatUser.primaryColor.hex}
                                <span className="px-2 rounded-2 me-1" style={{ backgroundColor: organization.bot.chatUser.secondaryColor.hex }}></span>{organization.bot.chatUser.secondaryColor.hex}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                )}
            </div>

            <OrganizationChatBotBasicSettings organization={organization!} setShowModal={(e: boolean) => setShowBotModal(e)} showModal={showBotModal} />
        </>
    );
};

export default OrganizationChatBotSettings;

import React, {FC, useEffect, useState} from 'react';

import CodeMirror, {EditorView} from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript'; // or any other language you need
import { oneDark } from '@codemirror/theme-one-dark'; // or any other theme you prefer

import WindowToolImg from '../../../assets/window-tool.svg'

const SdkInstallation: FC = () => {

    return (
        <>
            <div className="nk-block">
                <div className="nk-block-head nk-block-head-sm">
                    <div className="nk-block-head-content">
                        <h3 className="nk-block-title">Install</h3>
                        <p>React Rainbow Components is available as an npm package </p>
                        <p className={`fst-italic`}>installing with npm</p>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header"
                         style={{backgroundColor: '#20252e', borderBottom: 'none'}}>
                        <img src={WindowToolImg} width={60}/>
                    </div>
                    <div className="card-body" style={{
                        backgroundColor: '#282c34',
                        borderBottomRightRadius: '5px',
                        borderBottomLeftRadius: '5px'
                    }}>
                        <CodeMirror
                            value={`$ npm i brainaque-chat-sdk --save`}
                            height="100%"
                            theme={oneDark}
                            extensions={[javascript(), EditorView.editable.of(false)]}
                            readOnly={true}
                        />
                    </div>
                </div>
            </div>

            <div className="nk-block">
                <div className="nk-block-head nk-block-head-sm">
                    <div className="nk-block-head-content">
                        <p className={`fst-italic`}>installing with yarn</p>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header"
                         style={{backgroundColor: '#20252e', borderBottom: 'none'}}>
                        <img src={WindowToolImg} width={60}/>
                    </div>
                    <div className="card-body" style={{
                        backgroundColor: '#282c34',
                        borderBottomRightRadius: '5px',
                        borderBottomLeftRadius: '5px'
                    }}>
                        <CodeMirror
                            value={`$ yarn add brainaque-chat-sdk`}
                            height="100%"
                            theme={oneDark}
                            extensions={[javascript(), EditorView.editable.of(false)]}
                            readOnly={true}
                        />
                    </div>
                </div>
            </div>

        </>
    );
};
export default SdkInstallation;

import React from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/rootReducer';
import * as Yup from 'yup';
import http from '../../../../services/api';
import { toastMessage } from '../../../../utils/util';
import FormInput from '../../../components/form/FormInput';
import { Button, Modal } from 'react-rainbow-components';
import FormButton from '../../../components/form/bottons/FormButton';
import { ILoginMember, IMember } from '../../../../interfaces/organization.interface';
import { base_url, organizations_url, public_member_organizations_url } from '../../../../constants/contants';
import {
    createMemberStart,
    createMemberSuccess,
    createMemberFailure,
    updateMemberStart,
    updateMemberSuccess,
    updateMemberFailure,
    setOrganizationUserSuccess, getOrganizationUserSuccess,
} from '../../../../redux/slices/organizationsSlice';
import axios from "axios";

interface MemberFormProps {
    member?: IMember;
    showModal: boolean;
    isPublicMember?: boolean;
    publicOrganizationId?: string;
    setShowModal: any;
}

const MemberForm: React.FC<MemberFormProps> = ({
                                                   member,
                                                   isPublicMember = false,
                                                   showModal,
                                                   setShowModal,
                                                   publicOrganizationId
                                               }) => {
    const dispatch = useDispatch();
    const { user } = useSelector((state: RootState) => state.user);
    const { organization, loading } = useSelector((state: RootState) => state.organizations);
    const { organizationMember } = useSelector((state: RootState) => state?.organizations);

    const validationSchema = Yup.object({
        firstname: Yup.string().required('First Name is required'),
        lastname: Yup.string().required('Last Name is required'),
        email: Yup.string().email('Invalid email address').required('Email is required'),
        password: Yup.string().min(6, 'Password must be at least 6 characters'),
        role: Yup.string().required('Role is required'), // Changed to string
    });

    const formik = useFormik({
        initialValues: {
            firstname: member?.firstname || '',
            lastname: member?.lastname || '',
            password: '',
            email: member?.email || '',
            role: member?.role || '', // Simplified for standard select
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            if (member) {
                // Update existing member
                dispatch(updateMemberStart());
                try {
                    let response: any
                    if(isPublicMember){
                        response = await axios.put<IMember>(`${base_url}${public_member_organizations_url}/${organizationMember?.organizationId}/member/${organizationMember?.member?._id}`,
                            {
                                firstname: values.firstname,
                                lastname: values.lastname,
                                email: values.email,
                                ...(values.password !== '' && { password: values.password }),
                                role: values.role,
                            },{
                                headers: {
                                    Authorization: `Bearer ${organizationMember?.member.token}`,
                                },
                            }
                        );
                        const {data} = response;
                        const editedMemberDetails = {
                            ...organizationMember,
                            organizationId: organizationMember?.organizationId || "",  // provide default empty string if undefined
                            organizationName: organizationMember?.organizationName || "",  // provide default if undefined
                            apiKey: organizationMember?.apiKey || "",  // provide default if undefined
                            member: {
                                ...member,
                                firstname: data.member?.firstname,
                                lastname: data.member?.lastname,
                            }
                        };
                        dispatch(getOrganizationUserSuccess(editedMemberDetails));
                    }else {
                        response = await http.put<IMember>(`${organizations_url}/${organization?._id}/members/${member._id}`,
                            {
                                firstname: values.firstname,
                                lastname: values.lastname,
                                email: values.email,
                                ...(values.password !== '' && { password: values.password }),
                                role: values.role,
                            }
                        );
                        dispatch(updateMemberSuccess({
                            orgId: organization?._id!,
                            member: response.data.member,
                        }));
                    }
                    toastMessage('Member Updated successfully', 'success');
                } catch (error: any) {
                    console.log('update member error', error);
                    dispatch(updateMemberFailure(error.message));
                    toastMessage(`${error.data.message}`, 'error');
                }
            } else {
                // Create new member
                dispatch(createMemberStart());
                try {
                    const response: any = await http.post<IMember>(`${organizations_url}/${organization?._id}/members`, {
                        firstname: values.firstname,
                        lastname: values.lastname,
                        email: values.email,
                        password: values.password,
                        role: values.role,
                    });
                    dispatch(createMemberSuccess({
                        orgId: organization?._id!,
                        member: response.data.member,
                    }));
                    toastMessage('Member Created successfully', 'success');
                } catch (error: any) {
                    dispatch(createMemberFailure(error.message));
                    console.error('Failed to create member', error);
                    toastMessage('Failed to create member', 'error');
                }
            }

            setShowModal(false);
            formik.resetForm();
        },
    });

    return (
        <Modal
            id="modal-add-member"
            title={member ? 'Update Member' : 'Create Member'}
            isOpen={showModal}
            onRequestClose={() => {
                setShowModal(false);
                formik.resetForm();
            }}
            footer={
                <div className="d-flex justify-content-end">
                    {loading ? (
                        <Button isLoading label="Button Brand" variant="brand" className="rainbow-m-around_medium" style={{ borderRadius: 5 }} />
                    ) : (
                        <FormButton type="submit" text={member ? 'Update' : 'Create'} onClick={formik.handleSubmit} />
                    )}
                </div>
            }
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="row gy-3">
                    <div className="col-12">
                        <FormInput
                            label="First Name"
                            name="firstname"
                            type="text"
                            placeholder="Enter First Name"
                            value={formik.values.firstname}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.firstname as string | undefined}
                        />
                    </div>
                    <div className="col-12">
                        <FormInput
                            label="Last Name"
                            name="lastname"
                            type="text"
                            placeholder="Enter Last Name"
                            value={formik.values.lastname}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.lastname as string | undefined}
                        />
                    </div>
                    {!isPublicMember && (
                        <div className="col-12">
                            <FormInput
                                label="Email"
                                name="email"
                                type="email"
                                disabled={!!member}
                                placeholder="Enter Email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.errors.email as string | undefined}
                            />
                        </div>
                    )}
                    <div className="col-12">
                        <FormInput
                            label="Password"
                            name="password"
                            type="password"
                            placeholder="Enter Password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.password as string | undefined}
                        />
                    </div>
                    <div className="col-12">
                        {!isPublicMember && (
                            <div className="form-group">
                                <label htmlFor="role" className="form-label">Select Role</label>
                                <select
                                    id="role"
                                    name="role"
                                    className={`form-control form-select bg-transparent select-focus ${formik.errors.role ? 'is-invalid border-light' : ''}`}
                                    value={formik.values.role}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                >
                                    <option value="" disabled>Choose Role</option>
                                    {organization?.roles.map((role) => (
                                        <option key={role._id} value={role._id}>
                                            {role.name.charAt(0).toUpperCase() + role.name.slice(1)}
                                        </option>
                                    ))}
                                </select>
                                {formik.errors.role && (
                                    <div className="invalid-feedback fs-7">{formik.errors.role}</div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </form>
        </Modal>
    );
};

export default MemberForm;

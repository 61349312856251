import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
    createWebsiteStart,
    createWebsiteSuccess,
    createWebsiteFailure,
    updateWebsiteStart,
    updateWebsiteSuccess,
    updateWebsiteFailure,
    fetchWebsiteStart,
    fetchWebsiteSuccess,
    fetchWebsiteFailure, deleteWebsiteStart, deleteWebsiteSuccess, deleteWebsiteFailure,
} from '../../../../redux/slices/websitesSlice';
import {RootState} from "../../../../redux/rootReducer";
import {shops_url, websites_url} from "../../../../constants/contants";
import * as Yup from "yup";
import http from "../../../../services/api";
import {toastMessage} from "../../../../utils/util";
import FormInput from "../../../components/form/FormInput";
import {Button, Modal, Option, Picklist} from "react-rainbow-components";
import FormButton from "../../../components/form/bottons/FormButton";
import {set} from "lodash";
import {updateUserSuccess} from "../../../../redux/slices/userSlice";
import {IShop} from "../../../../interfaces/shop.interface";

interface ShopFormProps {
    shop: IShop; // If provided, it's edit mode; otherwise, it's create mode
    manageModal:string;
    setManageModal:any;
}


const ShopManage: React.FC<ShopFormProps> = ({ shop,manageModal,setManageModal }) => {


    const dispatch = useDispatch();

    const {  user } = useSelector((state: RootState) => state.user);
    const { loading } = useSelector((state: RootState) => state.websites);

    const handleDelete =  async (shopId: string) => {
        dispatch(deleteWebsiteStart());
        try {
            await http.delete(`${shops_url}/${shopId}`);
            dispatch(deleteWebsiteSuccess(shopId));
            setManageModal('')

            if(user){
                dispatch(updateUserSuccess({
                    ...user,
                    aiTokens: user.aiTokens+shop.shopAiToken
                }));

            }

            toastMessage('Website Deleted successfully', 'success')
        } catch (error: any) {
            dispatch(deleteWebsiteFailure('Failed to delete shop'));
        }
    };
    const handleUpdate =  async (shopId: string) => {
        dispatch(updateWebsiteStart());
        try {
            const response = await http.put(`${shops_url}/${shopId}`, {
                deactivate: shop?.deactivated?'no':'yes'
            });
            dispatch(updateWebsiteSuccess(response.data));
            setManageModal('')

            toastMessage('Website Deactivated successfully', 'success')
        } catch (error: any) {
            dispatch(updateWebsiteFailure('Failed to Deactivated shop'));
        }
    };


    return (
        <Modal
            id="modal-delete-shop"
            title={manageModal == 'delete'? "Delete Website": shop?.deactivated? "Activate Website":"Deactivate Website"}

            isOpen={!!manageModal}
            onRequestClose={() => {
                setManageModal('')
            }}
            footer={
                <div className="d-flex justify-content-end gap-2">
                    <FormButton type={`button`} text={`Cancel`}  onClick={()=>setManageModal('')} />
                    {manageModal == 'delete' ? (
                            <Button isLoading={loading} label="Delete" variant="destructive" borderRadius="semi-square" className="rainbow-m-around_medium" onClick={()=>{
                                handleDelete(shop?._id!)
                            }} />
                        ):(
                                <Button isLoading={loading} label={`${ shop?.deactivated? 'Activate':'Deactivate' }`} variant={`${ shop?.deactivated? 'success':'destructive' }`} borderRadius="semi-square" className="rainbow-m-around_medium" onClick={()=>{
                                    handleUpdate(shop?._id!)
                                }} />
                        )
                    }

                </div>
            }
        >

            Are you sure you want to delete this shop?
        </Modal>
    );
};

export default ShopManage;

import React, { FC, useState } from 'react';
import { Avatar, FileSelector, Modal } from "react-rainbow-components";
import FormButton from "../../../components/form/bottons/FormButton";
import FormInput from "../../../components/form/FormInput";
import * as Yup from "yup";
import { useFormik } from "formik";
import http from "../../../../services/api";
import { IApiKey, IOrganization } from "../../../../interfaces/organization.interface";
import { organizations_url } from "../../../../constants/contants";
import { toastMessage } from "../../../../utils/util";
import CopyClipInput from "../../../components/form/CopyClipInput";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
    updateApiKeyFailure,
    updateApiKeyStart,
    updateApiKeySuccess
} from "../../../../redux/slices/organizationsSlice";
import { RootState } from "../../../../redux/rootReducer";
import OrganizationManage from "../modals/OrganizationManage";
import OrganizationForm from "../modals/OrganizationForm";

const validationSchema = Yup.object({
    apiKeyName: Yup.string().required('API Key Name is required'),
});

interface ColorInputValue {
    hex: string;
    alpha: number;
    isValid: boolean;
}

const OrganizationSettings: React.FC = () => {
    const [showModal, setShowModal] = useState(false);
    const [showApiKeyModal, setShowApiKeyModal] = useState(false);
    const [showModalForEdit, setShowModalForEdit] = useState(false);
    const [showBotModal, setShowBotModal] = useState(false);
    const [apiKeyData, setApiKeyData] = useState('');

    const [value, setValue] = useState<ColorInputValue>({ hex: '#FFFFFF', alpha: 1, isValid: true });
    const [manageModal, setManageModal] = useState('');

    const dispatch = useDispatch();
    const { organization, loading, error } = useSelector((state: RootState) => state.organizations);

    const formik = useFormik({
        initialValues: {
            apiKeyName: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            dispatch(updateApiKeyStart());
            try {
                const { data } = await http.post<IApiKey>(`${organizations_url}/${organization?._id}/api-key`, { apiKeyName: values.apiKeyName });
                setApiKeyData(data.apiKey);
                dispatch(updateApiKeySuccess({ _id: organization?._id!, apiKey: data.apiKey, apiKeyName: values.apiKeyName }));
                setShowModal(false);
                setShowApiKeyModal(true);
            } catch (error) {
                dispatch(updateApiKeyFailure('Failed to update API key'));
            }
        },
    });

    return (
        <>
            <div className="nk-block">
                <div className="d-flex align-items-center justify-content-between border-bottom border-light mt-5 pb-1">
                    <h5>Advanced Settings</h5>
                </div>
                <table className="table table-flush table-middle mb-0">
                    <tbody>
                    <tr>
                        <td className="tb-col">
                            <span className="fs-15px text-light">Deactivate Organization</span>
                        </td>
                        <td className="tb-col">
                                <span className="fs-15px text-base">
                                    <span className="link link-primary fw-normal"
                                          onClick={() => setManageModal(organization?.deactivated ? 'activate' : 'deactivate')}>
                                        {`${organization?.deactivated ? 'Activate' : 'Deactivate'}`}
                                    </span>
                                </span>
                        </td>
                    </tr>
                    <tr>
                        <td className="tb-col">
                            <span className="fs-15px text-light">Delete Organization</span>
                        </td>
                        <td className="tb-col">
                                <span className="fs-15px text-base">
                                    <span className="link link-primary fw-normal"
                                          onClick={() => setManageModal('delete')}>Delete</span>
                                </span>
                        </td>
                    </tr>
                    <tr>
                        <td className="tb-col">
                            <span className="fs-15px text-light">Edit Organization</span>
                        </td>
                        <td className="tb-col">
                                <span className="fs-15px text-base">
                                    <span className="link link-primary fw-normal"
                                          onClick={()=> setShowModalForEdit(true)}
                                    >Edit</span>
                                </span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <Modal
                id="modal-new-api-key"
                title="Create new API key"
                isOpen={showModal}
                onRequestClose={() => {
                    setShowModal(false);
                    formik.resetForm();
                }}
                footer={
                    <div className="d-flex justify-content-end">
                        <FormButton type="submit" text="Create" onClick={formik.handleSubmit}/>
                    </div>
                }
            >
                <form>
                    <div className="row gy-3">
                        <div className="col-12 mb-3">
                            <span className="fs-7">
                                This API key is tied to your user and can make requests against
                                the selected project. If you are removed from the organization or project, this key
                                will be disabled.
                            </span>
                            {organization?.apiKey && (
                                <span className="fs-7 d-block text-danger mt-2">Note: Creating new key will replace old key</span>
                            )}
                        </div>
                    </div>
                    <div className="row gy-3">
                        <div className="col-12">
                            <FormInput
                                label="API Key Name"
                                name="apiKeyName"
                                type="text"
                                placeholder="Enter API Key Name"
                                value={formik.values.apiKeyName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.errors.apiKeyName}
                            />
                        </div>
                    </div>
                </form>
            </Modal>

            <Modal
                id="modal-show-api-key"
                title="Save your key"
                isOpen={showApiKeyModal}
                onRequestClose={() => {
                    setShowApiKeyModal(false);
                }}
                footer={
                    <div className="d-flex justify-content-end">
                        <FormButton type="submit" text="Done" onClick={() => {
                            setApiKeyData('');
                            setShowApiKeyModal(false);
                        }} />
                    </div>
                }
            >
                <form>
                    <div className="row gy-3">
                        <div className="col-12">
                            <p className="fs-7">
                                Please save this secret key somewhere safe and accessible. For security reasons, you won't be able to view it again through your account. If you lose this secret key, you'll need to generate a new one.
                            </p>
                        </div>
                    </div>
                    <div className="row gy-3">
                        <div className="col-12">
                            <CopyClipInput value={apiKeyData} />
                        </div>
                    </div>
                </form>
            </Modal>

            <OrganizationManage organization={organization!} setManageModal={(e: string) => setManageModal(e)} manageModal={manageModal} />
            <OrganizationForm organization={organization!} setShowModal={()=> setShowModalForEdit(false)} showModal={showModalForEdit}/>
        </>
    );
};

export default OrganizationSettings;

import React, {FC, useEffect, useState} from 'react';

import CodeMirror, {EditorView} from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript'; // or any other language you need
import { oneDark } from '@codemirror/theme-one-dark';
import SdkInstallation from "./SdkInstallation";
import SdkUsage from "./SdkUsage";
import SdkOverview from "./SdkOverview"; // or any other theme you prefer


const SdkContainer: FC = () => {

    const [activeTab, setActiveTab] = useState('overview');

    return (
        <div className="nk-content">
            <div className="container-xl">
                <div className="nk-content-inner">
                    <div className="nk-content-body">
                        <div className="nk-block-head nk-page-head">
                            <div className="nk-block-head-between">
                                <div className="nk-block-head-content">
                                    <h2 className="display-6">Chat Bot SDK</h2>
                                    <span>Brainaque SK is a collection of components that will reliably help you build your application in a snap. Give it a hack and let us know what you think.</span>
                                </div>

                            </div>
                        </div>
                        <div className="nk-block">
                            <ul className="nav nav-tabs mb-3 nav-tabs-s1">
                                <li className="nav-item">
                                    <button className={`nav-link ${activeTab === 'overview'? 'active':''}`} onClick={()=> setActiveTab('overview')} type="button" >Overview
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button className={`nav-link ${activeTab === 'installation'? 'active':''}`} onClick={()=> setActiveTab('installation')} type="button" >Installation
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button className={`nav-link ${activeTab === 'usage'? 'active':''}`} onClick={()=> setActiveTab('usage')} type="button" >Usage
                                    </button>
                                </li>
                            </ul>
                            <div className="tab-content">

                                {activeTab === 'overview' && (
                                    <SdkOverview/>
                                )}
                                {activeTab === 'installation' && (
                                    <SdkInstallation/>
                                )}

                                {activeTab === 'usage' && (
                                    <SdkUsage/>
                                )}


                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};
export default SdkContainer;

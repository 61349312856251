import React, {FC, useEffect, useState} from 'react';

import './authentication.scss'
import {useNavigate} from "react-router-dom";
import Footer from "../../components/footer/Footer";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import http from "../../../services/api";
import app from "../../App";
import {toastMessage} from "../../../utils/util";


const ForgotPassword: FC = () => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);

    const validationSchema = Yup.object({
        email: Yup.string()
            .email('Invalid email address')
            .required('Email is required')
    });


    const handleSubmit = async (values: { email: string }, { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }) => {
        try {
            // Simulate an API request
            const payload = { email: values.email, app: 'chat-bot' }; // Include app if required by the API
            const response = await http.post('/forgot-password', payload);
            if(response?.status === 200){
                toastMessage(`Check Your Mail ${response.data.message}`, 'success');
            }
        } catch (error) {
            console.error("Error during API call", error);
        } finally {
            // Set isSubmitting to false to enable the form again
            setSubmitting(false);
        }
    };

    return (

        <div className="nk-app-root " data-sidebar-collapse="lg">
            <div className="nk-main">
                <div className="nk-wrap has-shape flex-column">
                    <div className="nk-shape bg-shape-blur-a start-0 top-0"></div>
                    <div className="nk-shape bg-shape-blur-b end-0 bottom-0"></div>
                    <div className="text-center pt-5">
                        <a href="/" className="logo-link">
                            <div className="logo-wrap">
                                <img className="logo-img logo-light" src="images/logo.png"  alt="" />
                                <img className="logo-img logo-dark" src="images/logo-dark.png" alt="" />
                                <img className="logo-img logo-icon" src="images/logo-icon.png"   alt="" />
                            </div>
                        </a>
                    </div>
                    <div className="container p-2 p-sm-4 mt-auto">
                        <div className="row justify-content-center">
                            <div className="col-md-7 col-lg-5 col-xl-5 col-xxl-4">
                                <div className="nk-block">
                                    <div className="nk-block-head text-center mb-4 pb-2">
                                        <div className="nk-block-head-content">
                                            <h1 className="nk-block-title mb-1">Reset Your Password</h1>
                                            <p className="small">Enter your email address and we will send you instructions to reset your password.</p>
                                        </div>
                                    </div>
                                    <Formik
                                        initialValues={{ email: '' }}
                                        validationSchema={validationSchema}
                                        onSubmit={handleSubmit}
                                    >
                                        {({ isSubmitting }) => (
                                            <Form>
                                                <div className="row gy-3">
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <label className="form-label">Email Address</label>
                                                            <div className="form-control-wrap">
                                                                <Field
                                                                    className="form-control"
                                                                    type="email"
                                                                    name="email"
                                                                    placeholder="Enter email address"
                                                                />
                                                                <ErrorMessage name="email" component="div" className="text-danger" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="d-grid">
                                                            <button className="btn btn-primary" type="submit" disabled={isSubmitting}>
                                                                {isSubmitting ? 'Sending...' : 'Send Link'}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                    <div className="text-center mt-3">
                                        <p className="small"><a href={``} onClick={()=> navigate("/login")}>Return to Login</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>

    );
};
export default ForgotPassword;

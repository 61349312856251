import React, { FC, useEffect, useState } from 'react';
import { Button } from 'react-rainbow-components';

type Props = {
    text: string;
    type?: 'submit' | 'reset' | 'button';
    onClick?: () => void;
    isValid?: boolean;
    disabled?: boolean;
};

const FormButton: FC<Props> = ({ text, type, onClick, isValid, disabled }) => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(isValid ? true : false);
    }, [isValid]);

    return (
        <Button
            variant="brand"
            className="rainbow-m-around_medium"
            borderRadius={`semi-square`}
            size={`small`}
            onClick={() => {
                if (onClick) {
                    onClick();
                }

                if (isValid) {
                    setLoading(true);
                }
            }}
            disabled={disabled ? disabled : loading}
            type={type}
        >
            {loading && (
                <div className="spinner-border spinner-border-sm text-white fs-8 me-1" role="status">
                    <span className="sr-only"></span>
                </div>
            )}
            <span>{text}</span>
        </Button>
    );
};

export default FormButton;

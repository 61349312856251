import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {IOrganization, IMember, IGroup, ILoginMember, IRole} from '../../interfaces/organization.interface';

interface OrganizationsState {
    organizations: IOrganization[] | null;
    organization: IOrganization | null;
    organizationMember:ILoginMember | null;
    loading: boolean;
    error: string | null;
}

const initialState: OrganizationsState = {
    organizations: null,
    organization: null,
    organizationMember: null,
    loading: false,
    error: null,
};

const organizationsSlice = createSlice({
    name: 'organizations',
    initialState,
    reducers: {
        // Fetching organizations
        fetchOrganizationsStart(state) {
            state.loading = true;
            state.error = null;
        },
        fetchOrganizationsSuccess(state, { payload }: PayloadAction<IOrganization[]>) {
            state.organizations = payload;
            state.loading = false;
        },
        fetchOrganizationsFailure(state, { payload }: PayloadAction<string>) {
            state.error = payload;
            state.loading = false;
        },

        // Fetching single organization
        fetchOrganizationStart(state) {
            state.loading = true;
            state.error = null;
        },
        fetchOrganizationSuccess(state, { payload }: PayloadAction<IOrganization>) {
            state.organization = payload;
            state.loading = false;
        },
        fetchOrganizationFailure(state, { payload }: PayloadAction<string>) {
            state.error = payload;
            state.loading = false;
        },

        // API Key Management
        updateApiKeyStart(state) {
            state.loading = true;
            state.error = null;
        },
        updateApiKeySuccess(state, { payload }: PayloadAction<{ _id: string; apiKey: string; apiKeyName: string }>) {
            const organization = state.organizations?.find(org => org._id === payload._id);
            if (organization) {
                organization.apiKey = payload.apiKey;
                organization.apiKeyName = payload.apiKeyName;
            }

            if (state.organization && state.organization._id === payload._id) {
                state.organization.apiKey = payload.apiKey;
                state.organization.apiKeyName = payload.apiKeyName;
            }

            state.loading = false;
        },
        updateApiKeyFailure(state, { payload }: PayloadAction<string>) {
            state.error = payload;
            state.loading = false;
        },

        // Managing Organization-Level Members
        createMemberStart(state) {
            state.loading = true;
            state.error = null;
        },
        createMemberMultipleSuccess(state, { payload }: PayloadAction<{ members: IMember[] }>) {
            if (state.organization && state.organization.members) {
                state.organization.members = payload?.members;
            }
            state.loading = false;
        },
        createMemberSuccess(state, { payload }: PayloadAction<{ orgId: string, member: IMember }>) {
            const organization = state.organization;
            if (organization) {
                organization.members.push(payload.member); // Add member at organization level
            }

            state.loading = false;
        },
        createMemberFailure(state, { payload }: PayloadAction<string>) {
            state.error = payload;
            state.loading = false;
        },

        updateMemberStart(state) {
            state.loading = true;
            state.error = null;
        },
        updateMemberSuccess(state, { payload }: PayloadAction<{ orgId: string, member: IMember }>) {
            const organization = state.organization;
            if (organization) {
                const memberIndex = organization?.members.findIndex(mem => mem._id === payload.member._id);
                if (memberIndex !== -1) {
                    organization.members[memberIndex] = payload.member; // Update organization-level member
                }
            }
            state.loading = false;
        },
        updateMemberFailure(state, { payload }: PayloadAction<string>) {
            state.error = payload;
            state.loading = false;
        },

        deleteMemberStart(state) {
            state.loading = true;
            state.error = null;
        },
        deleteMemberSuccess(state, { payload }: PayloadAction<{ orgId: string, userId: string }>) {
            const organization = state.organization;
            if (organization) {
                organization.members = organization.members.filter(member => member._id !== payload.userId); // Delete organization-level member
            }
            if (state.organization && organization) {
                state.organization = organization;
            }

            state.loading = false;
        },
        deleteMemberFailure(state, { payload }: PayloadAction<string>) {
            state.error = payload;
            state.loading = false;
        },

        // Set Organization User
        setOrganizationUserStart(state) {
            state.loading = true;
            state.error = null;
        },
        setOrganizationUserSuccess(state, { payload }: PayloadAction<ILoginMember>) {
            state.organizationMember = payload; // Store the organization member separately
            state.loading = false;
        },
        setOrganizationUserFailure(state, { payload }: PayloadAction<string>) {
            state.error = payload;
            state.loading = false;
        },

        // Get Organization User
        getOrganizationUserStart(state) {
            state.loading = true;
            state.error = null;
        },
        getOrganizationUserSuccess(state, { payload }: PayloadAction<ILoginMember>) {
            state.organizationMember = payload; // Store the fetched member separately
            state.loading = false;
        },
        getOrganizationUserFailure(state, { payload }: PayloadAction<string>) {
            state.error = payload;
            state.loading = false;
        },

        // General state management
        setOrganizations(state, { payload }: PayloadAction<IOrganization[] | null>) {
            state.organizations = payload;
        },
        setLoading(state, { payload }: PayloadAction<boolean>) {
            state.loading = payload;
        },
        setError(state, { payload }: PayloadAction<string | null>) {
            state.error = payload;
        },
        clearOrganizations(state) {
            state.organizations = null;
            state.organization = null;
            state.loading = false;
            state.error = null;
        },
        // Deleting an organization
        deleteOrganizationStart(state) {
            state.loading = true;
            state.error = null;
        },
        deleteOrganizationSuccess(state, { payload }: PayloadAction<string>) {
            if (state.organizations) {
                state.organizations = state.organizations.filter(org => org._id !== payload);
            }
            state.loading = false;
        },
        deleteOrganizationFailure(state, { payload }: PayloadAction<string>) {
            state.error = payload;
            state.loading = false;
        },

        // Updating an organization
        updateOrganizationStart(state) {
            state.loading = true;
            state.error = null;
        },
        updateOrganizationSuccess(state, { payload }: PayloadAction<IOrganization>) {
            if (state.organizations) {
                const index = state.organizations.findIndex(org => org._id === payload._id);
                if (index !== -1) {
                    state.organizations[index] = payload;
                }
            }
            if (state.organization && state.organization._id === payload._id) {
                state.organization = payload;
            }
            state.loading = false;
        },
        updateOrganizationFailure(state, { payload }: PayloadAction<string>) {
            state.error = payload;
            state.loading = false;
        }, createOrganizationStart(state) {
            state.loading = true;
            state.error = null;
        },
        createOrganizationSuccess(state, { payload }: PayloadAction<IOrganization>) {
            if (state.organizations) {
                state.organizations.push(payload);
            } else {
                state.organizations = [payload];
            }
            state.loading = false;
        },
        createOrganizationFailure(state, { payload }: PayloadAction<string>) {
            state.error = payload;
            state.loading = false;
        },

        // Creating Group
        createGroupStart(state) {
            state.loading = true;
            state.error = null;
        },
        createGroupSuccess(state, { payload }: PayloadAction<{ orgId: string, group: IGroup }>) {
            const organization = state.organizations?.find(org => org._id === payload.orgId);
            if (organization) {
                organization.groups.push(payload.group);
            }
            if (state.organization && state.organization._id === payload.orgId) {
                state.organization.groups.push(payload.group);
            }
            state.loading = false;
        },
        createGroupFailure(state, { payload }: PayloadAction<string>) {
            state.error = payload;
            state.loading = false;
        },

        // Updating Group
        updateGroupStart(state) {
            state.loading = true;
            state.error = null;
        },
        updateGroupSuccess(state, { payload }: PayloadAction<{ orgId: string, group: IGroup }>) {
            const organization = state.organizations?.find(org => org._id === payload.orgId);
            if (organization) {
                const groupIndex = organization.groups.findIndex(group => group._id === payload.group._id);
                if (groupIndex !== -1) {
                    organization.groups[groupIndex] = payload.group;
                }
            }
            if (state.organization && state.organization._id === payload.orgId) {
                const groupIndex = state.organization.groups.findIndex(group => group._id === payload.group._id);
                if (groupIndex !== -1) {
                    state.organization.groups[groupIndex] = payload.group;
                }
            }
            state.loading = false;
        },

        deleteGroupSuccess(state, { payload }: PayloadAction<{ groupId: string }>) {
            const organization = state.organization;
            if (organization) {
                organization.groups = organization.groups.filter(group => group._id !== payload.groupId); // Delete organization-level member
            }
            if (state.organization && organization) {
                state.organization = organization;
            }

            state.loading = false;
        },
        updateGroupFailure(state, { payload }: PayloadAction<string>) {
            state.error = payload;
            state.loading = false;
        },
        // Role Management
        createRoleStart(state) {
            state.loading = true;
            state.error = null;
        },
        createRoleSuccess(state, { payload }: PayloadAction<{ orgId: string, role: IRole }>) {
            const organization = state.organizations?.find(org => org._id === payload.orgId);
            if (organization) {
                organization.roles.push(payload.role);
            }
            if (state.organization && state.organization._id === payload.orgId) {
                state.organization.roles.push(payload.role);
            }
            state.loading = false;
        },
        createRoleFailure(state, { payload }: PayloadAction<string>) {
            state.error = payload;
            state.loading = false;
        },

        updateRoleStart(state) {
            state.loading = true;
            state.error = null;
        },
        updateRoleSuccess(state, { payload }: PayloadAction<{ orgId: string, role: IRole }>) {
            const organization = state.organizations?.find(org => org._id === payload.orgId);
            if (organization) {
                const roleIndex = organization.roles.findIndex(role => role._id === payload.role._id);
                if (roleIndex !== -1) {
                    organization.roles[roleIndex] = payload.role;
                }
            }
            if (state.organization && state.organization._id === payload.orgId) {
                const roleIndex = state.organization.roles.findIndex(role => role._id === payload.role._id);
                if (roleIndex !== -1) {
                    state.organization.roles[roleIndex] = payload.role;
                }
            }
            state.loading = false;
        },
        updateRoleFailure(state, { payload }: PayloadAction<string>) {
            state.error = payload;
            state.loading = false;
        },

        deleteRoleStart(state) {
            state.loading = true;
            state.error = null;
        },
        deleteRoleSuccess(state, { payload }: PayloadAction<{ orgId: string, roleId: string }>) {
            const organization = state.organizations?.find(org => org._id === payload.orgId);
            if (organization) {
                organization.roles = organization.roles.filter(role => role._id !== payload.roleId);
            }
            if (state.organization && state.organization._id === payload.orgId) {
                state.organization.roles = state.organization.roles.filter(role => role._id !== payload.roleId);
            }
            state.loading = false;
        },
        deleteRoleFailure(state, { payload }: PayloadAction<string>) {
            state.error = payload;
            state.loading = false;
        },
    },
});

export const {
    fetchOrganizationsStart,
    fetchOrganizationsSuccess,
    fetchOrganizationsFailure,
    fetchOrganizationStart,
    fetchOrganizationSuccess,
    fetchOrganizationFailure,
    createMemberStart,
    createMemberSuccess,
    createMemberFailure,
    updateMemberStart,
    updateMemberSuccess,
    updateMemberFailure,
    updateApiKeyStart,
    updateApiKeySuccess,
    updateApiKeyFailure,
    deleteMemberStart,
    deleteMemberSuccess,
    deleteMemberFailure,
    setOrganizations,
    setLoading,
    setError,
    clearOrganizations,
    deleteOrganizationStart,
    deleteOrganizationSuccess,
    deleteOrganizationFailure,
    updateOrganizationStart,
    updateOrganizationSuccess,
    updateOrganizationFailure,
    createOrganizationStart,
    createOrganizationSuccess,
    createOrganizationFailure,
    createGroupStart,
    createGroupSuccess,
    deleteGroupSuccess,
    createGroupFailure,
    updateGroupStart,
    updateGroupSuccess,
    updateGroupFailure,
    setOrganizationUserStart,
    setOrganizationUserSuccess,
    createMemberMultipleSuccess,
    setOrganizationUserFailure,
    getOrganizationUserStart,
    getOrganizationUserSuccess,
    getOrganizationUserFailure,
    createRoleStart,
    createRoleSuccess,
    createRoleFailure,
    updateRoleStart,
    updateRoleSuccess,
    updateRoleFailure,
    deleteRoleStart,
    deleteRoleSuccess,
    deleteRoleFailure,

} = organizationsSlice.actions;

export default organizationsSlice.reducer;

import React, {FC, useEffect, useState} from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './authentication.scss';
import { useNavigate } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import FormButton from "../../components/form/bottons/FormButton";
import AuthHeader from "../../components/headers/AuthHeader";
import FormInput from "../../components/form/FormInput";
import http from "../../../services/api";
import {register_url} from "../../../constants/contants";
import {IUserResponse} from "../../../interfaces/user.interface";
import {Picklist, Option, PicklistOption, Button} from "react-rainbow-components";
import {countries} from "../../../utils/countries";
import axios from "axios";
import ct from "countries-and-timezones";

import GoogleImg from "../../../assets/google.png"
import {useGoogleLogin} from "@react-oauth/google";
import {createUserFailure, createUserStart, createUserSuccess, fetchUserStart} from "../../../redux/slices/userSlice";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/rootReducer";


const validationSchema = Yup.object({
    fullName: Yup.string()
        .required('Full Name is required'),
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    password: Yup.string()
        .min(6, 'Password must be at least 6 characters')
        .required('Password is required'),
    country: Yup.object()
        .required('Country is required'),
});

interface CountryOption {
    value: string;
    label: string;
}
const Register: FC = () => {
    const navigate = useNavigate();

    let user = localStorage.getItem("user");
    const { loading } = useSelector((state: RootState) => state.user);

    const [userCountry, setUserCountry] = useState<CountryOption>();
    const [allCountries, setAllCountries] = useState(countries);
    const [filter, setFilter] = useState('');
    const [timezone, setTimezone] = useState('');

    useEffect(() => {

        if(user){
            navigate("/dashboard");
        }
    }, [user]);
    useEffect(() => {

        if(filter && countries){
            setAllCountries(
                countries.filter(country =>
                    country.label.toLowerCase().includes(filter.toLowerCase())
                )
            );
        }else{
            setAllCountries(countries)
        }

        }, [filter]);


    useEffect(() => {

        const getUserCountry = async () => {
            const country:any = await fetchUserCountry();

            if(country){

                setUserCountry(country)
                formik.setFieldValue('country', country)

                const countryData = ct.getCountry(country.value);

                let timezoneData:any = countryData.timezones
                if(timezoneData){

                    setTimezone(timezoneData)
                }

            }

        };
        getUserCountry();
    }, []);

    const dispatch = useDispatch();


    const login = useGoogleLogin({
        onSuccess: tokenResponse => console.log(tokenResponse),
    });
    const fetchUserCountry = async () => {
        try {
            const response = await axios.get('https://ipinfo.io/json'); // Basic IP lookup without token
            const country = response.data.country;
            return countries.find((c: any) => c.value === country);
        } catch (error) {
            console.error('Error fetching user country:', error);
            return null;
        }
    };


    const formik = useFormik({
        initialValues: {
            fullName: '',
            email: '',
            password: '',
            country:  userCountry,
            app:'chat-bot'
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            dispatch(createUserStart());
            try {
                await http.post<IUserResponse>(register_url, {
                    fullName: values.fullName,
                    email: values.email,
                    password: values.password,
                    countryCode:  values.country?.value,
                    timezone:  timezone[0],
                    app:'chat-bot'
                });


                localStorage.setItem('verifyEmail', JSON.stringify({
                    fullName: values.fullName,
                    email: values.email
                }));
                navigate('/verify-email');
                dispatch(createUserSuccess());
            } catch (error:any) {
                console.log('error')
                dispatch(createUserFailure(error))
            }
        },
    });

    return (
        <div className="nk-app-root " data-sidebar-collapse="lg">
            <div className="nk-main">
                <div className="nk-wrap has-shape flex-column">
                    <AuthHeader />
                    <div className="container p-2 p-sm-4 mt-auto">
                        <div className="row justify-content-center">
                            <div className="col-md-7 col-lg-5 col-xl-5 col-xxl-4">
                                <div className="nk-block">
                                    <div className="nk-block-head text-center mb-4 pb-2">
                                        <div className="nk-block-head-content">
                                            <h1 className="nk-block-title mb-1">Create Your Account</h1>
                                            <p className="small">Email verification is required for signup to ensure security, and your email will only be used for identity verification.</p>
                                        </div>
                                    </div>
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className="row gy-3">
                                            <div className="col-12">
                                                <FormInput
                                                    label={`Full Name`}
                                                    name={`fullName`}
                                                    placeholder={`Enter your name`}
                                                    value={formik.values.fullName}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    error={formik.errors.fullName}
                                                    />
                                            </div>
                                            <div className="col-12">
                                                <FormInput
                                                    label="Email Address"
                                                    name="email"
                                                    type="email"
                                                    placeholder="Enter email address"
                                                    value={formik.values.email}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    error={formik.errors.email}
                                                />
                                            </div>
                                            <div className="col-12">
                                                <FormInput
                                                    label="Password"
                                                    name="password"
                                                    type="password"
                                                    placeholder="Enter Password"
                                                    value={formik.values.password}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    error={formik.errors.password}
                                                />
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor={`picklist-country`}>Country</label>
                                                    <div className="form-control-wrap">
                                                        <Picklist
                                                            borderRadius="semi-square"
                                                            id="picklist-country"
                                                            className={`picklist-country`}
                                                            onChange={value => {


                                                                console.log("value",value)

                                                                formik.setFieldValue('country',{
                                                                    value: value.name,
                                                                    label: value.label,
                                                                })

                                                                const countryCode:any = value.name

                                                                const countryData = ct.getCountry(countryCode);

                                                                let timezoneData:any = countryData.timezones
                                                                if(timezoneData){

                                                                    setTimezone(timezoneData)
                                                                }



                                                            }}
                                                            value={formik.values.country}
                                                            label="Select a Country"
                                                            hideLabel
                                                            enableSearch
                                                            onSearch={(val)=>{setFilter(val)}}
                                                        >
                                                            {allCountries.map((country) => (
                                                                <Option key={country.value} name={country.value} label={country.label} />
                                                            ))}
                                                        </Picklist>
                                                        {formik.errors.country ? (
                                                            <div className="fs-7 text-danger mt-1">{formik.errors.country}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="d-grid">
                                                    {loading ? (
                                                        <Button isLoading label="Button Brand" variant="brand" className="rainbow-m-around_medium" style={{borderRadius: 5}} />
                                                    ):(
                                                        <FormButton type={`submit`}  text={`Create Account`}/>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div className="text-center mt-3">
                                        <p>Already have an account? <span className="small link" onClick={() => navigate("/login")}>Login</span></p>
                                    </div>
                                    <div className="my-3 text-center">
                                        <h6 className="overline-title overline-title-sep"><span>OR</span></h6>
                                    </div>
                                    <div className="row g-2">
                                        <div className="col-12">
                                            <button className="btn btn-outline-light w-100" onClick={() => login()}>
                                                <img src={GoogleImg} alt="" className="icon"/>
                                                <span className="fw-medium">Continue with Google</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </div>
        </div>
    );
};

export default Register;

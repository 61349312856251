import React, {FC, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/rootReducer";



type Props = {
    label: string,
    name: string,
    placeholder: string,
    value: string | number,
    onChange: any,
    onBlur: any,
    type?: 'text' | 'password' | 'email' | 'number',
    error?: string,
    className?: any,
    disabled?: boolean
}

const FormInput: FC<Props> = ({label, type,name, value,placeholder,onChange, onBlur, error,className, disabled = false}) => {

    const [showPassword, setShowPassword] = useState(false);
    const {  user } = useSelector((state: RootState) => state.user);


    return (
        <div className="form-group">
            <label className="form-label" htmlFor={name}>{label}</label>
            <div className="form-control-wrap">

                {type === 'password' && value !== '' && (
                    <span onClick={() => setShowPassword(!showPassword)}
                          className="password-toggle link form-control-icon end"
                          title="Toggle show/hide password">
                        {showPassword ?
                            <em className="icon ni ni-eye active"></em>
                            :
                            <em className="icon ni ni-eye-off active"></em>

                        }
                    </span>
                )}


                <input
                    className={`form-control ${className}`}
                    type={showPassword?'text':type}
                    id={name}
                    name={name}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    disabled={disabled}
                />
                {error ? (
                    <div className="fs-7 text-danger mt-1">{error}</div>
                ) : null}
            </div>
        </div>
    );
};

export default FormInput;

import React from "react";
import FormButton from "../../../../components/form/bottons/FormButton";
import {Button, Modal} from "react-rainbow-components";
import {
    deleteGroupSuccess,
    deleteMemberStart,
    deleteMemberSuccess, updateGroupFailure,
    updateGroupStart,
    updateGroupSuccess
} from "../../../../../redux/slices/organizationsSlice";
import http from "../../../../../services/api";
import {documents_url, group_url, groups_url, organizations_url} from "../../../../../constants/contants";
import {toastMessage} from "../../../../../utils/util";
import {deleteWebsiteFailure} from "../../../../../redux/slices/websitesSlice";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../../redux/rootReducer";

interface GroupDocumentManageInterface {
    showModal: boolean;
    setShowModal: (value: boolean)=> void;
    groupId: string;
    documentId?: string;
}

const GroupDocumentManage: React.FC<GroupDocumentManageInterface> = ({showModal, setShowModal, groupId, documentId}) => {

    const dispatch = useDispatch();

    const { organization, loading } = useSelector((state: RootState) => state?.organizations);

    const handleDelete = async () => {
        dispatch(updateGroupStart());
        try {
            const response = await http.delete(`${organizations_url}/${organization?._id}${groups_url}/${groupId}${documents_url}/${documentId}`);

            if (response.status === 200 && organization?._id) {
                // Find the group data
                let newData = organization?.groups?.map((group) => {
                    if (group._id === groupId) {
                        // Filter out the deleted document from groupDocuments
                        const updatedDocuments = group?.groupDocuments?.filter((document) => document?._id !== documentId);

                        // Return the group with updated groupDocuments
                        return {
                            ...group,
                            groupDocuments: updatedDocuments
                        };
                    } else {
                        // Return the group as-is if it's not the one being updated
                        return group;
                    }
                });
                // Dispatch success action with the updated group data
                dispatch(updateGroupSuccess({ orgId: organization?._id, group: newData[0]}));
                setShowModal(false);
                // Close the modal and show success message
                toastMessage('Document deleted successfully', 'success');
            } else {
                // Close the modal and show error message
                toastMessage(`${response?.data}`, 'error');
            }

        } catch (error: any) {
            console.error('handleDelete error', error);
            toastMessage(`${error?.data?.message}`, 'error');
            // Dispatch failure action with the correct error message
            dispatch(updateGroupFailure('Failed to delete document'));
        }
    };

    return(
        <>
            <Modal
                id="modal-delete-shop"
                title={"Delete Document"}
                isOpen={showModal}
                onRequestClose={() => {
                    setShowModal(false)
                }}
                footer={
                    <div className="d-flex justify-content-end gap-2">
                        <FormButton type={`button`} text={`Cancel`}  onClick={()=>setShowModal(false)} />
                        <Button isLoading={loading} label="Delete" variant="destructive" borderRadius="semi-square" className="rainbow-m-around_medium" size={`small`} onClick={handleDelete} />
                    </div>
                }
            >

                Are you sure you want to delete this Document?
            </Modal>
        </>
    )
};

export default GroupDocumentManage;
import React, {FC, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux/rootReducer";
import {Badge, Button, Modal, Option, Picklist} from "react-rainbow-components";

import dayjs from "dayjs";
import {useNavigate} from "react-router-dom";
import BuyToken from "../modals/BuyToken";
import PaymentInvoice from "../modals/PaymentInvoice";



const AccountSummary: FC = () => {

    const { user } = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();


    const [showBuyTokenModal, setShowBuyTokenModal] = useState(false);
    const [subscriptionType, setSubscriptionType] = useState('');


    useEffect(() => {
        if(user){
            const startDate = new Date(user?.plan?.startDate);
            const endDate = new Date(user?.plan?.endDate);

            let subscriptionType = '';

            if (startDate && endDate) {
                const diffTime = Math.abs(endDate.getTime() - startDate.getTime());
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                if (diffDays >= 365 && diffDays <= 366) {
                    subscriptionType = 'yearly';
                } else if (diffDays >= 28 && diffDays <= 31) {
                    subscriptionType = 'monthly';
                } else {
                    subscriptionType = 'irregular'; // Optional case for irregular plans
                }
            }

            setSubscriptionType(subscriptionType)

        }
    }, []);

    return (
        <div className="tab-pane fade show active">
            <div className="d-flex align-items-center justify-content-between border-bottom border-light mt-5 pb-1">
                <h5>Tokens </h5>
                <Button label="Buy Tokens" variant="brand" className="rainbow-m-around_medium" style={{borderRadius: 5}}  onClick={()=>setShowBuyTokenModal(true)} />
            </div>
            <table className="table table-flush table-middle mb-0">
                <tbody>
                <tr>
                    <td className="tb-col">
                        <span className="fs-15px text-light">Plan</span>
                    </td>
                    <td className="tb-col">
                        <h6 className="lead-text mt-2">{user?.aiTokens} <span className="text-light">tokens left</span>
                        </h6>
                    </td>
                    <td className="tb-col tb-col-end tb-col-sm"></td>
                </tr>
                </tbody>
            </table>

            <div className="d-flex align-items-center justify-content-between border-bottom border-light mt-5 pb-1">
                <h5>Plan </h5>
                <span className="link link-primary fw-normal"  onClick={()=> navigate('/plans')}>Change Plan</span>
            </div>
            <table className="table table-flush table-middle mb-0">
                <tbody>
                <tr>
                    <td className="tb-col">
                        <span className="fs-15px text-light">Plan</span>
                    </td>
                    <td className="tb-col">
                        <span className="fs-15px text-base">{user?.plan.planId.title}</span>
                    </td>
                    <td className="tb-col tb-col-end tb-col-sm"></td>
                </tr>
                <tr>
                    <td className="tb-col">
                        <span className="fs-15px text-light">Plan Status</span>
                    </td>
                    <td className="tb-col">
                        <span className="fs-15px text-base">  {user?.plan.status}</span>
                    </td>
                    <td className="tb-col tb-col-end tb-col-sm"></td>
                </tr>
                </tbody>
            </table>


            {user &&(
                <BuyToken showModal={showBuyTokenModal}  plan={user?.plan.planId} subscriptionType={subscriptionType}  setShowModal={(e: boolean) => setShowBuyTokenModal(e)} />
            )}


        </div>
    );
};
export default AccountSummary;

import React, {FC, useEffect, useRef, useState} from 'react';
import {Avatar, FileSelector, Modal} from "react-rainbow-components";
import FormButton from "../../../components/form/bottons/FormButton";
import FileUpload from "../../../components/upload/fileUpload";
import FormInput from "../../../components/form/FormInput";
import * as Yup from "yup";
import {useFormik} from "formik";
import http from "../../../../services/api";
import {IApiKey, IShop} from "../../../../interfaces/shop.interface";
import {shops_url} from "../../../../constants/contants";
import {toastMessage} from "../../../../utils/util";
import CopyClipInput from "../../../components/form/CopyClipInput";
import dayjs from "dayjs";
import {useDispatch, useSelector} from "react-redux";
import {
    updateApiKeyFailure,
    updateApiKeyStart,
    updateApiKeySuccess
} from "../../../../redux/slices/shopsSlice";
import {RootState} from "../../../../redux/rootReducer";
import ShopManage from "../modals/ShopManage";



const validationSchema = Yup.object({
    apiKeyName: Yup.string()
        .required('API Key Name is required'),
});


interface ColorInputValue {
    hex: string;
    alpha: number;
    isValid: boolean;
}
const ShopSettings: React.FC = () => {

    const [showModal, setShowModal] = useState(false);
    const [showApiKeyModal, setShowApiKeyModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showBotModal, setShowBotModal] = useState(false);
    const [apiKeyData, setApiKeyData] = useState('');

    const [files, setFiles] = useState([]);


    const [value, setValue] = useState<ColorInputValue>({ hex: '#FFFFFF', alpha: 1, isValid: true });
    const [manageModal, setManageModal] = useState('');

    const dispatch = useDispatch();
    const { shop, loading, error } = useSelector((state: RootState) => state.shops);


    const formik = useFormik({
        initialValues: {
            apiKeyName: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {

                dispatch(updateApiKeyStart());
                try {
                    const { data } = await http.post<IApiKey>(`${shops_url}/${shop?._id}/api-key`, { apiKeyName:values.apiKeyName });
                    setApiKeyData(data.apiKey)
                    dispatch(updateApiKeySuccess({ id:shop?._id!, apiKey: data.apiKey, apiKeyName:values.apiKeyName }));
                } catch (error) {
                    dispatch(updateApiKeyFailure('Failed to update api key'));

                }


                setShowModal(false)
                setShowApiKeyModal(true)
            } catch (error:any) {
                console.log('error')
            }
        },
    });


    return (
        <>

            <div className="nk-block">


                <div className="d-flex align-items-center justify-content-between border-bottom border-light mt-5 pb-1">
                    <h5>API Key</h5>
                    <span className="link link-primary fw-normal" onClick={() => setShowModal(true)}>Create New</span>
                </div>
                <table className="table table-flush table-middle mb-0">
                    <tbody>
                    {shop?.apiKey ?

                        <tr>
                            <td className="tb-col">
                            <span className="fs-15px text-light">{shop?.apiKeyName}</span>
                            </td>
                            <td className="tb-col tb-col-sm">
                                <span className="fs-15px text-base">{shop?.apiKey}</span>
                            </td>
                            <td className="tb-col">
                                <div className="fs-6 text-light d-inline-flex flex-wrap gap gx-2">
                                    <span>{dayjs(shop?.apiKeyCreatedAt).format('MMM DD, YYYY')}</span>
                                    <span>{dayjs(shop.apiKeyCreatedAt).format('hh:mm A')}</span>
                                </div>
                            </td>
                        </tr>
                        :
                        <tr>
                            <td className="tb-col">
                                <span className="fs-15px text-danger">No key found</span>
                            </td>
                        </tr>
                    }

                    </tbody>
                </table>

            </div>


            <div className="nk-block">
                <div className="d-flex align-items-center justify-content-between border-bottom border-light mt-5 pb-1">
                    <h5>Advanced Settings</h5>
                </div>
                <table className="table table-flush table-middle mb-0">
                    <tbody>
                    <tr>
                        <td className="tb-col">
                            <span className="fs-15px text-light">Deactivate Shop</span>
                        </td>
                        <td className="tb-col">
                            <span className="fs-15px text-base">
                                <span className="link link-primary fw-normal"
                                      onClick={() => setManageModal(shop?.deactivated ? 'activate' : 'deactivate')}>{`${shop?.deactivated ? 'Activate' : 'Deactivate'}`}</span>
                            </span>

                        </td>
                    </tr>

                    <tr>
                        <td className="tb-col">
                            <span className="fs-15px text-light">Delete Shop</span>
                        </td>
                        <td className="tb-col">
                            <span className="fs-15px text-base"><span className="link link-primary fw-normal"
                                                                      onClick={() => setShowDeleteModal(true)}>Delete</span></span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <Modal
                id="modal-new-api-key"
                title="Create new API key"
                isOpen={showModal}
                onRequestClose={() => {
                    setShowModal(false)
                    formik.resetForm()
                }}
                footer={
                    <div className="d-flex justify-content-end">

                        <FormButton type={`submit`} text={`Create`} onClick={formik.handleSubmit}/>

                    </div>
                }
            >
                <form>
                    <div className="row gy-3">
                        <div className="col-12 mb-3">
                            <span className={`fs-7`}>This API key is tied to your user and can make requests against
                                the selected project. If you are removed from the organization or project, this key
                                will be disabled.</span>
                            {shop?.apiKey && (
                                <span className={`fs-7 d-block text-danger mt-2`}>Note: Creating new key will replace old key</span>
                            )}

                        </div>

                    </div>
                    <div className="row gy-3">
                        <div className="col-12">
                            <FormInput
                                label="API Key Name"
                                name="apiKeyName"
                                type="text"
                                placeholder="Enter API Key Name"
                                value={formik.values.apiKeyName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.errors.apiKeyName}
                            />
                        </div>

                    </div>

                </form>
            </Modal>

            <Modal
                id="modal-show-api-key"
                title="Save your key"
                isOpen={showApiKeyModal}
                onRequestClose={() => {
                    setShowApiKeyModal(false)
                }}
                footer={
                    <div className="d-flex justify-content-end">

                        <FormButton type={`submit`} text={`Done`} onClick={() => {
                            setApiKeyData('')
                            setShowApiKeyModal(false)
                        }}/>

                    </div>
                }
            >
                <form>
                    <div className="row gy-3">
                        <div className="col-12">
                            <p className={`fs-7`}>Please save this secret key somewhere safe and accessible. For
                                security reasons, you won't be able to view it again through your OpenAI account. If you
                                lose this secret key, you'll need to generate a new one.</p>

                        </div>

                    </div>
                    <div className="row gy-3">
                        <div className="col-12">

                            <CopyClipInput value={apiKeyData}/>

                        </div>

                    </div>

                </form>
            </Modal>

            <ShopManage shop={shop!} setManageModal={(e: string) => setManageModal(e)}
                           manageModal={manageModal}/>


        </>
    );
};
export default ShopSettings;

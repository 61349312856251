import {Button, Modal} from "react-rainbow-components";
import FormButton from "../../../../components/form/bottons/FormButton";
import React from "react";
import http from "../../../../../services/api";
import {
    base_url,
    documents_url,
    groups_url,
    public_member_organizations_url,
    public_organizations_url
} from "../../../../../constants/contants";
import {updateGroupStart} from "../../../../../redux/slices/organizationsSlice";
import {useDispatch, useSelector} from "react-redux";
import {
    deletePublicOrganizationDocumentFailure,
    deletePublicOrganizationDocumentStart, deletePublicOrganizationDocumentSuccess
} from "../../../../../redux/slices/publicOrganizationSlice";
import {RootState} from "../../../../../redux/rootReducer";
import axios from "axios";
import {IGroup, IPublicMemberGroup, IPublicOrganization} from "../../../../../interfaces/organization.interface";

interface PublicDocumentManagePageInterface {
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    groupId: string;
    documentId: string;
    setActiveGroup?: (value: any) => void;
}

const PublicDocumentManagePage: React.FC<PublicDocumentManagePageInterface> = ({showModal, setShowModal, groupId, documentId, setActiveGroup}) => {

    const {publicOrganization, loading} = useSelector((state: RootState) => state?.publicOrganization);

    const dispatch = useDispatch();
    const { organizationMember } = useSelector((state: RootState) => state.organizations);

    const handleDelete = async () => {
        try {
            dispatch(deletePublicOrganizationDocumentStart());

            let response = await axios.delete<IPublicOrganization>(
                `${base_url}${public_organizations_url}/${publicOrganization?._id}${groups_url}/${groupId}${documents_url}/${documentId}`,
                {
                    headers: {
                        Authorization: `Bearer ${organizationMember?.member.token}`,
                    },
                }
            );
            console.log("response", response);
            if(response.status === 200){
                dispatch(deletePublicOrganizationDocumentSuccess({groupId, documentId}));
                setShowModal(false);

                if (setActiveGroup) {
                    const existingGroup: IPublicMemberGroup | null = publicOrganization?.memberGroups?.find((g)=> g?._id === groupId) || null

                    if (setActiveGroup && existingGroup) {
                        // Merge existingGroup with newData
                        const updatedDocuments = existingGroup.groupDocuments.filter((d)=> d?._id !== documentId) || null
                        const newData = {
                            ...existingGroup, // Include all fields from the existing group
                            groupDocuments: updatedDocuments,
                        };

                        setActiveGroup(newData);
                    }
                }
            }

        }catch (error: any){
            console.log("error", error.response.data.message);
            dispatch(deletePublicOrganizationDocumentFailure(error.response.data.message));
        }
    };

    return(
        <Modal
            id="modal-delete-document"
            title={"Delete Document"}
            isOpen={showModal}
            onRequestClose={() => {
                setShowModal(false);
            }}
            footer={
                <div className="d-flex justify-content-end gap-2">
                    <FormButton type={`button`} text={`Cancel`} onClick={() => setShowModal(false)} />
                    <Button isLoading={loading} label="Delete" variant="destructive" borderRadius="semi-square" className="rainbow-m-around_medium" onClick={() => {
                        handleDelete();
                    }} />
                </div>
            }
        >
            Are you sure you want to delete this document?
        </Modal>
    )
};

export default PublicDocumentManagePage;
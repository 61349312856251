import React, {FC, useEffect, useState} from 'react';
import './dashboard.scss'
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/rootReducer";
import {fetchWebsitesFailure, fetchWebsitesStart, fetchWebsitesSuccess} from "../../../redux/slices/websitesSlice";
import http from "../../../services/api";
import {IWebsite} from "../../../interfaces/website.interface";
import {dashboard_url, websites_url} from "../../../constants/contants";
import {fetchDashboardFailure, fetchDashboardStart, fetchDashboardSuccess} from "../../../redux/slices/dashboardStatisticsSlice";
import {IDashboard} from "../../../interfaces/dashboardStatistics.interface";
import {useNavigate} from "react-router-dom";
import dayjs from "dayjs";
// @ts-ignore

import VectorMap from 'jsvectormap';
import 'jsvectormap/dist/jsvectormap.min.css';
import 'jsvectormap/dist/maps/world.js';
import {Chart, Dataset} from "react-rainbow-components";
import EmptyMessage from "../../components/empty/EmptyMessage"; // Load the world map


const Dashboard: FC = () => {

    const dispatch = useDispatch();

    const { user } = useSelector((state: RootState) => state.user);
    const { dashboardStatistics, loading, error } = useSelector((state: RootState) => state.dashboardStatistics);
    const navigate = useNavigate();

    const [pieChartData, setPieChartData] = useState<Array<{ label: string, value: number, color: string, code: string }>>([]);

    useEffect(() => {
        const fetchDashboard = async () => {
            dispatch(fetchDashboardStart());

            try {
                let response = await http.get<IDashboard>(dashboard_url);
                dispatch(fetchDashboardSuccess(response.data));
            } catch (error: any) {
                dispatch(fetchDashboardFailure('Failed to fetch websites'));
            }
        };

        fetchDashboard();
    }, []);

    // ---------------------------------------- this API for create new PromoCode ------------------------------------------>
    // useEffect(() => {
    //     const mockPromoData = {
    //         promoName: "WeeklyDeal",
    //         promoDescription: "Enjoy a 30% discount on all items this week only!",
    //         code: "WEEKLY30",
    //         percentage: 30,
    //         validFrom: "2024-10-30T00:00:00Z",  // Adjust date to current week
    //         validTo: "2024-11-05T23:59:59Z",      // Set end date for a week-long period
    //         totalCount: 10
    //     };
    //
    //     const test = async () => {
    //
    //         try {
    //             let response = await http.post<any>('/promocode', mockPromoData);
    //
    //             console.log('response',response)
    //
    //         } catch (error: any) {
    //             console.log('error',error)
    //         }
    //     };
    //
    //     test();
    // }, []);
    // ---------------------------------------- this API for create new PromoCode ------------------------------------------>

    useEffect(() => {
        if (dashboardStatistics) {
            // Create an object to store counts by country name
            const countryMap:any = {};

            // Process organization countries
            dashboardStatistics?.organizationCountries?.forEach(country => {
                const countryName = country.countryName;
                const countryCode = country.countryCode;

                const coords = [country.countryLat, country.countryLon];
                if (!countryMap[countryName]) {
                    countryMap[countryName] = {
                        code: countryCode,
                        name: countryName,
                        coords: coords,
                        organizationCount: 0,
                        websiteCount: 0
                    };
                }
                countryMap[countryName].organizationCount += 1;
            });

            // Process website countries
            dashboardStatistics?.websiteCountries?.forEach(country => {
                const countryName = country.countryName;
                const countryCode = country.countryCode;
                const coords = [country.countryLat, country.countryLon];
                if (!countryMap[countryName]) {
                    countryMap[countryName] = {
                        code: countryCode,
                        name: countryName,
                        coords: coords,
                        organizationCount: 0,
                        websiteCount: 0
                    };
                }
                countryMap[countryName].websiteCount += 1;
            });

            // Create a combined markers array from the countryMap
            const markers = Object.values(countryMap).map((country: any) => ({
                name: `${country.name} (Organization: ${country.organizationCount}, Website: ${country.websiteCount})`,
                coords: country.coords,
                style: {
                    fill: country.organizationCount > 0 && country.websiteCount > 0 ? '#FFD700' : country.organizationCount > 0 ? '#FF5722' : '#4285F4', // Yellow for both, Orange for organization, Blue for website
                    stroke: country.organizationCount > 0 && country.websiteCount > 0 ? '#FFD700' : country.organizationCount > 0 ? '#FF5722' : '#4285F4'
                }
            }));

            const map = new VectorMap({
                selector: '#worldMap',
                map: 'world',
                backgroundColor: '#f4f4f4',
                regionStyle: {
                    initial: {
                        fill: '#E3E3E3'
                    },
                    hover: {
                        fill: '#4285F4'
                    }
                },
                markers: markers,
                markerStyle: {
                    initial: {
                        fill: '#FF5722',
                        stroke: '#FF5722',
                    },
                    hover: {
                        stroke: '#FFFFFF',
                        "stroke-width": 2
                    }
                },
            });

            // Create pie chart data from countryMap
            const pieChart:any = Object.values(countryMap).map((country: any) => ({
                code: country.code, // The country name
                label: country.name, // The country name
                value: country.organizationCount + country.websiteCount, // Sum of both counts for pie chart value
                color: `#${Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0')}` // Generate random hex color
            }));

            // Set pie chart data
            setPieChartData(pieChart);

            return () => {
                map?.destroy();
            };
        }
    }, [dashboardStatistics]);

// Pie Chart Component
    const PieChart: FC = () => (
        <div className="rainbow-p-vertical_xx-large rainbow-p-horizontal_medium">
            <div className="rainbow-align-content_center rainbow-m-vertical_large rainbow-m_auto">
                <Chart showLegend={false} style={{ width: '350px'}} labels={pieChartData?.map((data) => data.label)} type="pie" legendPosition="right">
                    <Dataset
                        title="Chats by Country"
                        values={pieChartData?.map((data) => data.value)}
                        backgroundColor={pieChartData?.map((data) => data.color)}
                    />
                </Chart>
            </div>
        </div>
    );


    return (
        <div className="container-xl">
            <div className="nk-content-inner">
                <div className="nk-content-body">
                    <div className="nk-block-head nk-page-head">
                        <div className="nk-block-head-between">
                            <div className="nk-block-head-content">
                                <h2 className="display-6 mt-0 mt-sm-5 mt-lg-0">Welcome {user?.fullName}!</h2>
                            </div>
                        </div>
                    </div>
                    <div className="nk-block">
                        <div className="row">
                        <div className="col-md-6">
                                <div className="card  bg-gray-soft bg-opacity-10 border-0">
                                    <div className="card-body row">
                                        <div className="col-sm-6">
                                            <div className="card  bg-purple bg-opacity-10 border-0">
                                                <div className="card-body">
                                                    <div
                                                        className="d-flex align-items-center justify-content-between my-1">
                                                        <div className="fs-6 text-light mb-1 text-nowrap">Websites</div>
                                                    </div>
                                                    <h5 className="fs-1">{dashboardStatistics?.websiteOverview.total} <small
                                                        className="fs-3 text-nowrap">Websites</small></h5>
                                                    <div className="fs-7 text-light mt-1"><span
                                                        className="text-dark">{dashboardStatistics?.websiteOverview.thisMonthPercentage.toFixed(2)}</span> %
                                                        Chats this month
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-2 mt-sm-0">
                                            <div className="card  bg-blue bg-opacity-10 border-0">
                                                <div className="card-body px-3 py-1">
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <div className="fs-6 text-light mb-0 text-nowrap">Website Documents</div>
                                                    </div>
                                                    <h5 className="fs-1">{dashboardStatistics?.websiteOverview.documents} <small
                                                        className="fs-3 text-nowrap">Documents</small></h5>

                                                </div>
                                            </div>
                                            <div className="card  mt-2 bg-blue bg-opacity-10 border-0">
                                                <div className="card-body px-3 py-1">
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <div className="fs-6 text-light mb-0 text-nowrap">Website Chats</div>
                                                    </div>
                                                    <h5 className="fs-1 text-nowrap">{dashboardStatistics?.websiteOverview.chats} <small
                                                        className="fs-3 text-nowrap">Chats</small></h5>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-6">
                                <div className="card  bg-gray-soft bg-opacity-10 border-0">
                                    <div className="card-body row">
                                        <div className="col-sm-6">
                                            <div className="card  bg-purple bg-opacity-10 border-0">
                                                <div className="card-body">
                                                    <div
                                                        className="d-flex align-items-center justify-content-between my-1">
                                                        <div className="fs-7 text-light mb-1">Organization</div>
                                                    </div>
                                                    <h5 className="fs-1">{dashboardStatistics?.organizationOverview.total}
                                                        <small
                                                            className="fs-3 ms-1">Organizations</small></h5>
                                                    <div className="fs-7 text-light mt-1"><span
                                                        className="text-dark">{dashboardStatistics?.organizationOverview.thisMonthPercentage.toFixed(2)}</span> %
                                                        Chats this month
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-2 mt-sm-0">
                                            <div className="card  bg-blue bg-opacity-10 border-0">
                                                <div className="card-body px-3 py-1">
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <div className="fs-6 text-light mb-0">Organization Documents</div>
                                                    </div>
                                                    <h5 className="fs-1">{dashboardStatistics?.organizationOverview.documents}
                                                        <small
                                                            className="fs-3 ms-1">Documents</small></h5>

                                                </div>
                                            </div>
                                            <div className="card mt-2 bg-blue bg-opacity-10 border-0">
                                                <div className="card-body px-3 py-1">
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <div className="fs-6 text-light mb-0">Organization Chats</div>
                                                    </div>
                                                    <h5 className="fs-1">{dashboardStatistics?.organizationOverview.chats}
                                                        <small
                                                            className="fs-3 ms-1">Chats</small></h5>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="row my-3">
                        <div className={`col-md-6`}>
                            <div className="nk-block-head nk-page-head">
                                <div className="nk-block-head-between">
                                    <div className="nk-block-head-content">
                                        <h3 className="display-6 fs-3">Sales by Locations</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="nk-block">
                                <div id="worldMap"
                                     style={{width: 'auto', height: '320px', borderRadius: '10px'}}></div>

                            </div>
                        </div>
                        <div className={`col-md-6`}>
                            <div className="nk-block-head nk-page-head">
                                <div className="nk-block-head-between">
                                    <div className="nk-block-head-content py-2">
                                        <h3 className="display-6 fs-3"></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="nk-block">
                                {pieChartData.length ? (
                                <div className="card card-full bg-blue bg-opacity-10 border-0">
                                        <div className="row card-body">
                                            <div className={`col-md-6 d-flex justify-content-center`}>
                                                <PieChart/>
                                            </div>
                                            <div className={`col-md-6 mt-3 mt-md-0`}>
                                                <div
                                                    className="d-flex justify-content-between align-items-center border-bottom">
                                                    <h5 className="font-weight-bold mb-2">Top Countries</h5>
                                                </div>
                                                <div className="py-1">

                                                    {pieChartData.map((country) => {
                                                        return (
                                                            <div
                                                                className="d-flex justify-content-between align-items-center mb-3">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src={`https://hatscripts.github.io/circle-flags/flags/${(country.code).toLowerCase()}.svg`}
                                                                        alt="United States" className="rounded-circle"
                                                                        width="30"
                                                                        height="30"/>
                                                                    <span className="ms-1">{country.label}</span>
                                                                </div>
                                                                <span
                                                                    className="badge bg-light text-dark py-1 px-2">{country.value}</span>
                                                            </div>
                                                        )
                                                    })}


                                                </div>
                                            </div>

                                        </div>
                                </div>
                                ):(
                                    <div className="card card-full bg-blue bg-opacity-10 border-0">
                                        <EmptyMessage
                                            type="doc"
                                            title="No Data available."
                                            description=""
                                            buttonText="Upload Document"
                                            buttonCallback={()=>{
                                            }}
                                            noButton={true}
                                        />
                                    </div>
                                )}

                                <div className="card card-full bg-cyan bg-opacity-10 border-0 mt-3">
                                    <div className="card-body p-1">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="d-flex align-items-center">
                                                    <div className="avtar avtar-s bg-light-warning flex-shrink-0">
                                                        <i className="ph-duotone ph-lightning f-20"></i>
                                                    </div>
                                                    <div className="flex-grow-1 ms-2">
                                                        <p className="mb-0 text-muted">Website chats</p>
                                                        <h6 className="mb-0">{dashboardStatistics?.websiteCountries.length}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="d-flex align-items-center">
                                                    <div className="avtar avtar-s bg-light-danger flex-shrink-0">
                                                        <i className="ph-duotone ph-map-pin f-20"></i>
                                                    </div>
                                                    <div className="flex-grow-1 ms-2">
                                                        <p className="mb-0 text-muted">Organization chats</p>
                                                        <h6 className="mb-0">{dashboardStatistics?.organizationCountries.length} </h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>


                    <div className={`row`}>
                        <div className={`col-md-6 mt-4`}>
                            <div className="nk-block">
                                    <div className="card table-card">
                                        <div className="nk-block-head p-2">
                                            <div className="nk-block-head-between">
                                                <div className="nk-block-head-content">
                                                    <h4 className="fs-4 fw-normal">Website chats</h4>
                                                </div>
                                                <div className="nk-block-head-content">
                                                    <a href="/websites" className="link"><span>See All</span> <em
                                                        className="icon ni ni-chevron-right"></em></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                { dashboardStatistics?.recentWebsiteChats.length ? (
                                                <table className="table table-hover" id="pc-dt-simple">
                                                    <thead>
                                                    <tr>
                                                        <th className='text-nowrap'>Website</th>
                                                        <th className='text-nowrap'>Country</th>
                                                        <th className='text-nowrap'>Conversation</th>
                                                        <th className='text-nowrap'>Last Used</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>

                                                    {dashboardStatistics?.recentWebsiteChats.map((recentChat) => {

                                                        return (
                                                            <tr>
                                                                <td className="tb-col">
                                                                    <div className="caption-text link link-primary"
                                                                         onClick={() => {
                                                                             navigate(`/website/${recentChat.websiteId._id}`)
                                                                         }}>{recentChat.websiteId.websiteName} </div>
                                                                </td>
                                                                <td className="tb-col">
                                                                    <div
                                                                        className="badge text-bg-dark-soft rounded-pill px-2 py-1 fs-6 lh-sm">{recentChat.countryName === 'Country information not found'? '': recentChat.countryName}
                                                                    </div>
                                                                </td>
                                                                <td className="tb-col">
                                                                    <div
                                                                        className="badge text-bg-dark-soft rounded-pill px-2 py-1 fs-6 lh-sm">{recentChat.chats.length}
                                                                    </div>
                                                                </td>
                                                                <td className="tb-col">
                                                                    <div
                                                                        className="fs-6 text-light d-inline-flex flex-wrap gap gx-2">
                                                                        <span className='text-nowrap'>{dayjs(recentChat.chats[recentChat.chats.length - 1].createdAt).format('MMM DD, YYYY')} </span>
                                                                        <span className='text-nowrap'>{dayjs(recentChat.chats[recentChat.chats.length - 1].createdAt).format('hh:mm A')}</span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                    </tbody>
                                                </table>
                                                ):(
                                                    <EmptyMessage
                                                        type="doc"
                                                        title="No Data available."
                                                        description=""
                                                        buttonText="Upload Document"
                                                        buttonCallback={()=>{
                                                        }}
                                                        noButton={true}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                            </div>

                        </div>
                        <div className={`col-md-6 mt-4`}>
                            <div className="nk-block">
                                <div className="card table-card">
                                    <div className="nk-block-head p-2">
                                        <div className="nk-block-head-between">
                                            <div className="nk-block-head-content">
                                                <h4 className="fs-4 fw-normal">Organization chats</h4>
                                            </div>
                                            <div className="nk-block-head-content">
                                                <a href="/organizations" className="link"><span>See All</span> <em
                                                    className="icon ni ni-chevron-right"></em></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            { dashboardStatistics?.recentOrganizationChats.length? (
                                                <table className="table table-hover" id="pc-dt-simple">
                                                    <thead>
                                                    <tr>
                                                        <th className='text-nowrap'>Organization</th>
                                                        <th className='text-nowrap'>Country</th>
                                                        <th className='text-nowrap'>Conversation</th>
                                                        <th className='text-nowrap'>Last Used</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>

                                                    {dashboardStatistics?.recentOrganizationChats.map((recentChat) => {

                                                        return (
                                                            <tr>
                                                                <td className="tb-col">
                                                                    <div className="caption-text link link-primary"
                                                                         onClick={() => {
                                                                             navigate(`/organization/${recentChat.organizationId._id}`)
                                                                         }}>{recentChat.organizationId.organizationName} </div>
                                                                </td>
                                                                <td className="tb-col">
                                                                    <div
                                                                        className="badge text-bg-dark-soft rounded-pill px-2 py-1 fs-6 lh-sm">{recentChat.countryName === 'Country information not found'? '': recentChat.countryName}
                                                                    </div>
                                                                </td>
                                                                <td className="tb-col">
                                                                    <div
                                                                        className="badge text-bg-dark-soft rounded-pill px-2 py-1 fs-6 lh-sm">{recentChat.chats.length}
                                                                    </div>
                                                                </td>
                                                                <td className="tb-col">
                                                                    <div
                                                                        className="fs-6 text-light d-inline-flex flex-wrap gap gx-2">
                                                                        <span className='text-nowrap'>{dayjs(recentChat.chats[recentChat.chats.length - 1].createdAt).format('MMM DD, YYYY')} </span>
                                                                        <span className='text-nowrap'>{dayjs(recentChat.chats[recentChat.chats.length - 1].createdAt).format('hh:mm A')}</span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                    </tbody>
                                                </table>
                                            ):(
                                                <EmptyMessage
                                                    type="doc"
                                                    title="No Data available."
                                                    description=""
                                                    buttonText="Upload Document"
                                                    buttonCallback={()=>{
                                                    }}
                                                    noButton={true}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>


                </div>
            </div>
        </div>
    );
};
export default Dashboard;

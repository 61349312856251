import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
    createShopStart,
    createShopSuccess,
    createShopFailure,
    updateShopStart,
    updateShopSuccess,
    updateShopFailure,
    fetchShopStart,
    fetchShopSuccess,
    fetchShopFailure,
} from '../../../../redux/slices/shopsSlice';
import {RootState} from "../../../../redux/rootReducer";
import {shops_url} from "../../../../constants/contants";
import * as Yup from "yup";
import http from "../../../../services/api";
import {toastMessage} from "../../../../utils/util";
import FormInput from "../../../components/form/FormInput";
import {Button, Modal, Option, Picklist} from "react-rainbow-components";
import FormButton from "../../../components/form/bottons/FormButton";
import {updateUserSuccess} from "../../../../redux/slices/userSlice";
import {IShop} from "../../../../interfaces/shop.interface";

interface ShopFormProps {
    shop: IShop; // If provided, it's edit mode; otherwise, it's create mode
    showModal:boolean;
    setShowModal:any;
}



const ShopForm: React.FC<ShopFormProps> = ({ shop,showModal,setShowModal }) => {
    const dispatch = useDispatch();

    const {  user } = useSelector((state: RootState) => state.user);
    const { loading } = useSelector((state: RootState) => state.shops);

    const validationSchema = Yup.object({
        shopName: Yup.string()
            .required('Shop Name is required'),
        shopAiToken: Yup.number()
            .min(100,'Shop Token must be greater than 100 ')
            .max(user?.aiTokens!,`Shop Token must be less than ${user?.aiTokens} `)
            .required('Shop Token is required'),
    });

    const formik = useFormik({
        initialValues: {
            shopName: shop?.shopName || '',
            shopAiToken:  shop?.shopAiToken || 100,
        },
        enableReinitialize: true, // This allows the form to update when shop changes
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            if (shop) {

                dispatch(updateShopStart());

                try {
                    const response = await http.put<IShop>(`${shops_url}/${shop._id}`, {
                        shopName: values.shopName,
                        shopAiToken: values.shopAiToken,
                        app:'chat-bot'
                    });

                    if(user && values.shopAiToken !== shop.shopAiToken){



                        dispatch(updateUserSuccess({
                            ...user,
                            aiTokens : shop.shopAiToken > values.shopAiToken ?user.aiTokens+(shop.shopAiToken  - values.shopAiToken ): user.aiTokens-(values.shopAiToken -shop.shopAiToken  )
                        }));

                    }

                    dispatch(updateShopSuccess(response.data));


                    toastMessage('Shop Updated successfully', 'success')
                } catch (error:any) {
                    dispatch(updateShopFailure(error!));
                }
            } else {
                dispatch(createShopStart());
                try {
                    let response = await http.post<IShop>(shops_url, {
                        shopName: values.shopName,
                        shopAiToken: values.shopAiToken,
                        app: 'shop-bot',
                    });
                    dispatch(createShopSuccess(response.data));
                    if(user){
                        dispatch(updateUserSuccess({
                            ...user,
                            aiTokens:(user.aiTokens-values.shopAiToken)
                        }));

                    }

                    toastMessage('Shop Created successfully', 'success');
                } catch (error: any) {
                    dispatch(createShopFailure('Failed to create shop'));
                    console.error('Failed to create shop', error);
                    toastMessage('Failed to create shop', 'error');
                }
            }

            setShowModal(false)
            formik.resetForm();
        },
    });

    return (
        <Modal
            id="modal-add-shop"
            title={shop? 'Update Shop': 'Create Shop'}

            isOpen={showModal}
            onRequestClose={() => {
                setShowModal(false)
                formik.resetForm();
            }}
            footer={
                <div className="d-flex justify-content-end">
                    {loading ? (
                        <Button isLoading label="Button Brand" variant="brand" className="rainbow-m-around_medium" style={{borderRadius: 5}} />
                    ):(
                        <FormButton type={`submit`} text={shop? 'Update': 'Create'} onClick={formik.handleSubmit} />
                    )}
                </div>
            }
        >


            <form onSubmit={formik.handleSubmit}>
                <div className="row gy-3">
                    <div className="col-12">
                        <FormInput
                            label="Shop Name"
                            name="shopName"
                            type="text"
                            placeholder="Enter Shop Name"
                            value={formik.values.shopName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.shopName}
                        />
                    </div>
                    <div className="col-12">
                        <FormInput
                            label="Shop Tokens"
                            name="shopAiToken"
                            type="number"
                            placeholder="Enter Shop Tokens"
                            value={formik.values.shopAiToken}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.shopAiToken}
                        />
                    </div>

                </div>
            </form>
        </Modal>
    );
};

export default ShopForm;

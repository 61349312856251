import React from 'react';
import Markdown from 'react-markdown';
interface ChatBubbleBotProps {
    chat: { message: string };
    theme?: {
        primaryColor: string;
        chatBot: {
            primaryColor: string;
            secondaryColor: string;
        };
        chatUser: {
            primaryColor: string;
            secondaryColor: string;
        };
    };
    defaultThemeStyles: {
        primaryColor: string;
        secondaryColor: string;
    };
}

const ChatBubbleBot: React.FC<ChatBubbleBotProps> = ({ chat, theme, defaultThemeStyles }) => {


    return (
        <div
            className="chat-bubble you p-2 text-wrap"
            style={{
                backgroundColor: theme?.chatBot.primaryColor || defaultThemeStyles.primaryColor,
                color: theme?.chatBot.secondaryColor || defaultThemeStyles.secondaryColor,
                borderRadius: '0 15px 15px 15px',
                maxWidth: '90%',
            }}
        >
                {chat.message}
        </div>
    );
};

export default ChatBubbleBot;

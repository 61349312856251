import React from "react";
import { Navigate } from 'react-router-dom';
import FeatureContainer from "./pages/FeatureContainer";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../redux/rootReducer";
import {setUser} from "../redux/slices/userSlice";


function PrivateRoute({ children }:any) {
    let userData = localStorage.getItem("user");
    const dispatch = useDispatch();

    if(userData == null){
       return  <Navigate to={{pathname: '/'}}/>
    }else{

        dispatch(setUser(JSON.parse(userData)));

        return <FeatureContainer>{children}</FeatureContainer>
    }



}

export default PrivateRoute;



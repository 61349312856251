import React from 'react';
import { Spinner } from 'react-rainbow-components';
import styled from 'styled-components';



const Loader: React.FC = () => {
    return (
        <div className="rainbow-align-content_center rainbow-position_relative rainbow-p-vertical_xx-large">
            <Spinner variant="brand" size="medium" />
        </div>
    );
};

export default Loader;

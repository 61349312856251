import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
    createWebsiteStart,
    createWebsiteSuccess,
    createWebsiteFailure,
    updateWebsiteStart,
    updateWebsiteSuccess,
    updateWebsiteFailure,
    fetchWebsiteStart,
    fetchWebsiteSuccess,
    fetchWebsiteFailure, deleteWebsiteStart, deleteWebsiteSuccess, deleteWebsiteFailure,
} from '../../../../redux/slices/websitesSlice';
import { IWebsite } from '../../../../interfaces/website.interface';
import {RootState} from "../../../../redux/rootReducer";
import {shops_url, websites_url} from "../../../../constants/contants";
import * as Yup from "yup";
import http from "../../../../services/api";
import {toastMessage} from "../../../../utils/util";
import FormInput from "../../../components/form/FormInput";
import {Button, Modal, Option, Picklist} from "react-rainbow-components";
import FormButton from "../../../components/form/bottons/FormButton";
import {set} from "lodash";
import {updateUserSuccess} from "../../../../redux/slices/userSlice";
import {IDisplay} from "../../../../interfaces/shop.interface";
import {deleteDisplayStart, deleteDisplaySuccess} from "../../../../redux/slices/shopsSlice";

interface ModalProps {
    display: IDisplay; // If provided, it's edit mode; otherwise, it's create mode
    showModal: boolean;
    setShowModal: (e: boolean) => void;
}


const ShopDisplayManage: React.FC<ModalProps> = ({ display, showModal,setShowModal }) => {


    const dispatch = useDispatch();

    const {  shop } = useSelector((state: RootState) => state.shops);

    const handleDelete =  async (displayId: string) => {
        const shopId = shop?._id;
        dispatch(deleteDisplayStart());
        try {
            await http.delete(`${shops_url}/${shop?._id}/displays/${displayId}`);
            if (shopId) {
                dispatch(deleteDisplaySuccess({shopId, displayId}));
            }
            setShowModal(false)

            // if(user){
            //     dispatch(updateUserSuccess({
            //         ...user,
            //         aiTokens: user.aiTokens+display.websiteAiToken
            //     }));
            //
            // }

            toastMessage('Display Deleted successfully', 'success')
        } catch (error: any) {
            dispatch(deleteWebsiteFailure('Failed to delete website'));
        }
    };

    return (
        <Modal
            id="modal-delete-website"
            title={"Delete Display"}

            isOpen={showModal}
            onRequestClose={() => {
                setShowModal(false)
            }}
            footer={
                <div className="d-flex justify-content-end gap-2">
                    <FormButton type={`button`} text={`Cancel`}  onClick={()=>setShowModal(false)} />
                        <Button label="Delete" variant="destructive" borderRadius="semi-square" className="rainbow-m-around_medium" onClick={()=>{
                            handleDelete(display?._id!)
                        }} />
                </div>
            }
        >

            Are you sure you want to delete this Display?
        </Modal>

    // <Modal
    //     isOpen={showDeleteModal}
    //     onRequestClose={()=> setShowDeleteModal(false)}
    //     title="Are you Sure?"
    //     footer={
    //         <div className="rainbow-flex rainbow-justify_spread">
    //             <Button label="Cancel" variant="neutral" onClick={() => setShowDeleteModal(false)}/>
    //             <Button label="Delete" variant="brand" onClick={()=> deleteDisplay()}/>
    //         </div>
    //     }
    // >
    //     <p style={textStyles}>
    //         Are you sure you want to delete?
    //         <div> You Will not be able to recover this Display details.</div>
    //     </p>
    // </Modal>
    );
};

export default ShopDisplayManage;

import React, { useState, forwardRef, useImperativeHandle, useRef, useEffect } from 'react';
import { FileSelector } from 'react-rainbow-components';
import axios from 'axios';
import http from "../../../services/api";
import {
    websites_url,
    shops_url,
    organizations_url,
    base_url,
    public_organizations_url
} from "../../../constants/contants";
import {useDispatch, useSelector} from 'react-redux';
import {
    uploadDocumentStart,
    uploadDocumentFailure,
    uploadWebsiteDocumentSuccess
} from '../../../redux/slices/websitesSlice';
import {uploadShopDocumentSuccess, uploadShopPresentationDocumentSuccess} from "../../../redux/slices/shopsSlice";
import {updateGroupFailure, updateGroupStart, updateGroupSuccess} from "../../../redux/slices/organizationsSlice";
import {toastMessage} from "../../../utils/util";
import {RootState} from "../../../redux/rootReducer";
import {
    fetchPublicOrganizationStart,
    fetchPublicOrganizationSuccess,
    uploadPublicOrganizationDocumentFailure,
    uploadPublicOrganizationDocumentStart,
    uploadPublicOrganizationDocumentSuccess
} from "../../../redux/slices/publicOrganizationSlice";
import {IGroup, IPublicMemberGroup} from "../../../interfaces/organization.interface";

interface FileWithProgress {
    file: File;
    progress: number;
    completed: boolean;
    started: boolean;
    cancelTokenSource: any;
}

interface FileUploadHandle {
    uploadFiles: () => Promise<void>;
}

interface FileUploadProps {
    onFileCountChange: (count: number) => void;
    _id: string;
    uploadType: string;
    groupId?: string;
    setActiveGroup?: (value: any)=> void;
}

const PublicOrganizationFileUpload = forwardRef<FileUploadHandle, FileUploadProps>((props, ref) => {
    const { publicOrganization, loading } = useSelector((state: RootState) => state.publicOrganization); // Get the current organization
    const { onFileCountChange, _id, uploadType, groupId, setActiveGroup } = props;
    const dispatch = useDispatch();

    const {  organization } = useSelector((state: RootState) => state?.organizations);
    const {  organizationMember } = useSelector((state: RootState) => state?.organizations);

    const [files, setFiles] = useState<FileWithProgress[]>([]);
    const [completedFiles, setCompletedFiles] = useState<FileWithProgress[]>([]);
    const [message, setMessage] = useState('');
    const [uploadFailed, setUploadFailed] = useState(false);
    const fileSelectorRef = useRef<HTMLDivElement>(null);

    const handleFileChange = (fileList: FileList) => {
        const selectedFiles = Array.from(fileList).map(file => ({
            file,
            progress: 0,
            completed: false,
            started: false,
            cancelTokenSource: axios.CancelToken.source()
        }));

        setFiles(selectedFiles);
    };

    useEffect(() => {
        if (files.length === 0) {
            triggerFileSelector();
        }
        onFileCountChange(files.length);
    }, [files]);

    useImperativeHandle(ref, () => ({
        uploadFiles
    }));

    const uploadFiles = async () => {
        dispatch(uploadPublicOrganizationDocumentStart());
        setUploadFailed(false)
        const uploadPromises = files.map(fileWithProgress => {
            const { file, cancelTokenSource } = fileWithProgress;

            const formData = new FormData();
            formData.append('files', file);

            // Mark the file as started
            setFiles(prevFiles =>
                prevFiles.map(f =>
                    f.file.name === file.name ? { ...f, started: true } : f
                )
            );

            return axios.post(`${base_url}${public_organizations_url}/${_id}/group/${groupId}/documents`, formData, {
                headers: {
                    Authorization: `Bearer ${organizationMember?.member.token}`,
                    'Content-Type': 'multipart/form-data',
                },
                cancelToken: cancelTokenSource.token,
                onUploadProgress: (progressEvent) => {
                    if (progressEvent.lengthComputable) {
                        const progress = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 1));
                        const cappedProgress = Math.min(progress, 80);
                        setFiles(prevFiles =>
                            prevFiles.map(f =>
                                f.file.name === file.name ? { ...f, progress: cappedProgress } : f
                            )
                        );
                    }
                }
            }).then((response) => {
                const uploadedDocument = response.data.documents.find((doc:any) => doc.name === file.name);
                setFiles(prevFiles =>
                    prevFiles.map(f =>
                        f.file.name === file.name ? { ...f, progress: 100, completed: true } : f
                    )
                );
                setCompletedFiles(prevCompletedFiles =>
                    prevCompletedFiles.concat(fileWithProgress)
                );

                // Dispatch the updated group
                if(groupId){
                    dispatch(uploadPublicOrganizationDocumentSuccess({
                        groupId: groupId,
                        document: uploadedDocument,
                    }));
                }

                console.log('response.data', response.data.documents)
                if (response?.data?.documents && setActiveGroup) {
                    const existingGroup: IPublicMemberGroup | null = publicOrganization?.memberGroups?.find((g) => g?._id === groupId) || null;

                    if (setActiveGroup && existingGroup) {
                        // Merge existingGroup with newData, flattening the array in case of nested arrays
                        const newData = {
                            ...existingGroup, // Include all fields from the existing group
                            groupDocuments: [...existingGroup.groupDocuments, ...response?.data?.documents].flat(), // Ensure flat array
                        };

                        setActiveGroup(newData);
                    }
                }

                setMessage(response.data.message);

                triggerFileSelector();

            }).catch(error => {
                // Handle specific error cases
                if (axios.isCancel(error)) {
                    console.log('Upload canceled', file.name);
                } else if (error.response) {
                    // Server responded with a status other than 200 range
                    console.error('Server error', error.response);
                    dispatch(uploadPublicOrganizationDocumentFailure(error.response.data.message));
                    toastMessage(error.response.data.message, 'error');
                } else if (error.request) {
                    // Request was made but no response received
                    console.error('Network error', error.request);
                    setMessage('Network error occurred. Please try again later.');
                    dispatch(uploadPublicOrganizationDocumentFailure('Network error occurred'));
                } else {
                    // Other unknown errors
                    console.error('Unknown error', error.message);
                    setMessage('An unknown error occurred. Please try again later.');
                    dispatch(uploadPublicOrganizationDocumentFailure(error.message));
                }

                setUploadFailed(true)
            });
        });

        try {
            await Promise.all(uploadPromises);
        } catch (error: any) {
            console.error('Some uploads failed', error);
            dispatch(uploadPublicOrganizationDocumentFailure(error.message));
        }
    };

    const cancelUpload = (fileName: string) => {
        setFiles(prevFiles => {
            const fileToCancel = prevFiles.find(f => f.file.name === fileName);
            if (fileToCancel && fileToCancel.cancelTokenSource) {
                fileToCancel.cancelTokenSource.cancel();
            }
            return prevFiles.filter(f => f.file.name !== fileName); // Remove the canceled file from the state
        });
    };

    const triggerFileSelector = () => {
        if (fileSelectorRef.current) {
            const button = fileSelectorRef.current.querySelector('[data-id="button-icon-element"]');
            if (button) {
                (button as HTMLElement).click();
            }
        }
    };

    return (
        <div className="rainbow-p-vertical_large rainbow-p-horizontal_medium rainbow-m_auto">
            <div ref={fileSelectorRef}>
                <FileSelector
                    label="File selector multiple"
                    labelAlignment='left'
                    placeholder="Drag & Drop or Click to Browse"
                    bottomHelpText="Can select multiple files"
                    variant="multiline"
                    multiple
                    value={files}
                    accept={uploadType == 'shop-presentation-upload'?".png,.jpg,.jpeg,.mp4,.mov":".pdf" }
                    onChange={(fileList: FileList) => handleFileChange(fileList)}
                />
            </div>

            {files && files.length > 0 && (
                <div className="card">
                    <div className="card-body">
                        {files.map(({ file, progress, completed, started }) => (
                            <div className={`mb-3`} key={file.name}>
                                <div className="d-flex align-items-center justify-content-between mb-1">
                                    <div className="fs-6 text-light fw-bold mb-0">{file.name}</div>
                                    <span className="link link-danger" onClick={() => cancelUpload(file.name)}>Cancel</span>
                                </div>
                                <div className="progress progress-md">
                                    <div
                                        className="progress-bar bg-gradient-custom gradient-start-cyan gradient-middle-purple-light gradient-end-pink-light gradient-angle-45"
                                        data-progress="74%" style={{ width: `${progress}%` }}></div>
                                </div>
                                <div className="fs-7 text-light mt-1"><span className="text-dark">{progress}</span>/100</div>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {completedFiles && completedFiles.length > 0 && (
                <div className="card mt-2">
                    <div className="card-body">
                        {completedFiles.map(({ file }) => (
                            <div className={`mb-3`} key={file.name}>
                                <div className="d-flex align-items-center justify-content-between mb-1">
                                    <div className="fs-6 text-light fw-bold mb-0">{file.name}</div>
                                    <span className="link link-primary">Completed</span>
                                </div>
                                <div className="progress progress-md">
                                    <div
                                        className="progress-bar bg-gradient-custom gradient-start-cyan gradient-middle-purple-light gradient-end-pink-light gradient-angle-45"
                                        data-progress="74%" style={{ width: `100%` }}></div>
                                </div>
                                <div className="fs-7 text-light mt-1"><span className="text-dark">100</span>/100</div>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {message && <div className={`alert mt-3 ${uploadFailed? 'text-bg-danger-soft': 'text-bg-info-soft'}`}>{message}</div>}
        </div>
    );
});

export default PublicOrganizationFileUpload;

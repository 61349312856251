import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../redux/rootReducer";
import OrganizationGroups from "../OrganizationGroups";
import OrganizationGroupMembers from "./OrganizationGroupMembers";
import OrganizationGroupDocuments from "./OrganizationGroupDocuments";
import React, {useEffect, useState} from "react";
import {IGroup} from "../../../../../interfaces/organization.interface";
import {ButtonMenu, MenuItem, Sidebar, SidebarItem} from "react-rainbow-components";
import GroupForm from "../../modals/GroupForm";
import GroupManage from "../../modals/GroupManage";
import OrganizationGroupOverView from "./OrganizationGroupOverView";

// Define the shape of the state
interface Props {
    activeGroup?: IGroup;
    setActiveGroup:any
}

const OrganizationGroupViewMore: React.FC<Props> = ({activeGroup, setActiveGroup}) => {
    const navigate = useNavigate();

    const { _groupPage } = useParams();

    const [activeTab, setActiveTab] = useState("overview");
    const { organization, loading, error } = useSelector((state: RootState) => state.organizations);


    const [showEditModal, setShowEditModal] = useState(false);
    const [manageModal, setManageModal] = useState('');

    const [showGroupModal, setShowGroupModal] = useState(false);

    useEffect(() => {
        if(_groupPage){
            setActiveTab(_groupPage)
        }
    }, [_groupPage]);


    return (
        <>
                <div className="nk-block w-100 m-2">

                    <ul className="nav nav-tabs mb-3 nav-tabs-s1 align-items-center">

                        <li className="nav-item">

                            <button

                                className={`nav-link ${activeTab === "overview" ? "active" : ""}`}

                                onClick={() => {
                                    setActiveTab("overview")
                                    navigate(`/organization/${organization?._id}/groups/${activeGroup?._id}/overview`)

                                }}

                                type="button"

                            >

                                Overview

                            </button>

                        </li>

                        <li className="nav-item">

                            <button

                                className={`nav-link ${activeTab === "members" ? "active" : ""}`}

                                onClick={() => {
                                    setActiveTab("members")
                                    navigate(`/organization/${organization?._id}/groups/${activeGroup?._id}/members`)

                                }}
                                type="button"

                            >

                                Members

                            </button>

                        </li>

                        <li className="nav-item">

                            <button

                                className={`nav-link ${activeTab === "documents" ? "active" : ""}`}

                                onClick={() => {
                                    setActiveTab("documents")
                                    navigate(`/organization/${organization?._id}/groups/${activeGroup?._id}/documents`)

                                }}
                                type="button"

                            >

                                Documents

                            </button>

                        </li>
                        <li className="nav-item">
                            <ButtonMenu
                                menuAlignment="left"
                                menuSize="medium"
                                buttonVariant={"base"}
                                icon={<em className="icon ni ni-more-v fs-3 p-0"></em>}
                            >
                                <MenuItem
                                    icon={<em className="icon ni ni-edit"></em>}
                                    onClick={() => {
                                        setShowEditModal(true)
                                    }} label="Edit"/>
                                <MenuItem
                                    icon={<em className="icon ni ni-trash"></em>}
                                    onClick={() => {
                                        setManageModal('delete')
                                    }} label="Delete"/>
                            </ButtonMenu>
                        </li>

                    </ul>

                    <div className="tab-content">

                        {activeTab === "overview"&& activeGroup && <OrganizationGroupOverView group={activeGroup} />}

                        {activeTab === "groups" && <OrganizationGroups/>}

                        {activeTab === "members" && activeGroup && (

                            <OrganizationGroupMembers group={activeGroup}/>

                        )}

                        {activeTab === "documents" && activeGroup && (
                            <OrganizationGroupDocuments group={activeGroup}/>
                        )}

                    </div>
                </div>

            <GroupForm group={activeGroup} showModal={showEditModal}
                       setShowModal={(e: boolean) => setShowEditModal(e)}/>
            <GroupManage group={activeGroup} setActiveGroup={setActiveGroup} manageModal={manageModal} setManageModal={() => setManageModal('')}/>
        </>

)
};

export default OrganizationGroupViewMore;
import React, { FC, useState } from 'react';
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/rootReducer";

import { IChatMessage, IOrganizationChats } from "../../../../interfaces/organizationchat.interface";
import EmptyMessage from "../../../components/empty/EmptyMessage";
import RoleForm from "../modals/RoleForm";
import {IRole,} from "../../../../interfaces/organization.interface";
import {Badge, ButtonIcon, ButtonMenu, MenuItem} from "react-rainbow-components";
import RoleManage from "../modals/RoleManage";

const OrganizationRoles: React.FC = () => {

    const { organization, loading, error, organizations } = useSelector((state: RootState) => state.organizations);


    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [activeRole, setActiveRole] = useState<IRole>();



    return (
        <div className="tab-pane fade show active" id="profile-tab-pane">
            <div className="d-flex align-items-center justify-content-between my-2">
                <h5>Roles</h5>
                <button className="btn btn-primary btn-sm" onClick={() => {
                    setShowModal(true)

                    setActiveRole(undefined)
                }}>
                    <em className="icon ni ni-plus"></em><span>Create Role</span>
                </button>
            </div>
            <div className="nk-block">
                <div className="card shadow-none">
                    {organization?.roles?.length ? (
                        <table className="table table-middle mb-0">
                            <thead className={`border-bottom`}>
                                <th>

                                </th>
                                <th className={`fs-6 fw-light  text-center`}>
                                    Manage Group
                                </th>
                                <th className={`fs-6 fw-light  text-center`}>
                                    Manage Document
                                </th>
                                <th className={`fs-6 fw-light  text-center`}>
                                    Manage Member

                                </th>
                                <th className={`fs-6 fw-light  text-center`}>
                                    Is Admin
                                </th>
                            </thead>
                            <tbody>
                            {organization.roles?.map((role) => {

                                return (
                                    <tr className={``} key={role._id}>
                                        <td className="tb-col  text-center">
                                            <div className="caption-text line-clamp-2">
                                                {role.name}
                                            </div>
                                        </td>

                                        <td className="tb-col  text-center">
                                            <div className="caption-text line-clamp-2">

                                                     {role.manageGroup ?
                                                         <em className="ni ni-check-circle-fill text-primary "></em>
                                                         :
                                                         <em className="ni ni-cross-circle-fill  text-danger"></em>
                                                     }
                                            </div>
                                        </td>


                                        <td className="tb-col  text-center">
                                            <div className="caption-text line-clamp-2">
                                                {role.manageDocument ?
                                                    <em className="ni ni-check-circle-fill text-primary "></em>
                                                    :
                                                    <em className="ni ni-cross-circle-fill  text-danger"></em>
                                                }

                                            </div>
                                        </td>
                                        <td className="tb-col  text-center">
                                            <div className="caption-text line-clamp-2">

                                                {role.manageMember ?
                                                    <em className="ni ni-check-circle-fill text-primary "></em>
                                                    :
                                                    <em className="ni ni-cross-circle-fill  text-danger"></em>
                                                }


                                            </div>
                                        </td>
                                        <td className="tb-col text-center">
                                            <div className="caption-text line-clamp-2">


                                                {role.isAdmin ?
                                                    <em className="ni ni-check-circle-fill text-primary "></em>
                                                    :
                                                    <em className="ni ni-cross-circle-fill  text-danger"></em>
                                                }



                                            </div>
                                        </td>

                                        <td className="tb-col tb-col-end">
                                            <ButtonMenu
                                                menuAlignment="right"
                                                menuSize="x-small"
                                                buttonVariant="base"
                                                icon={<em className="icon ni ni-more-h"></em>}
                                            >
                                                <MenuItem
                                                    icon={<em className="icon ni ni-edit"></em>}
                                                    onClick={() => {

                                                        setActiveRole(role)
                                                        setShowModal(true)
                                                    }} label="Edit"/>
                                                <MenuItem
                                                    icon={<em className="icon ni ni-trash"></em>}
                                                    onClick={() => {
                                                        setActiveRole(role)
                                                        setShowDeleteModal(true)
                                                    }} label="Delete"/>
                                            </ButtonMenu>
                                        </td>

                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    ) : (
                        <EmptyMessage
                            type="chat"
                            title="No chat history found."
                            description="You haven't started any conversations yet. Begin chatting to see your message history here."
                            buttonText="Start Chat"
                            buttonCallback={() => {
                                // Handle chat start logic
                            }}
                        />
                    )}
                </div>
            </div>



                <RoleForm showModal={showModal} setShowModal={(e: boolean) => setShowModal(e)} role={activeRole}/>
                <RoleManage showModal={showDeleteModal} setShowModal={(e: boolean) => setShowDeleteModal(e)} role={activeRole}/>

        </div>
    );
};

export default OrganizationRoles;

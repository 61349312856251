import React, {FC, useEffect, useRef, useState} from 'react';
import './authentication.scss';
import Footer from "../../components/footer/Footer";
import AuthHeader from "../../components/headers/AuthHeader";
import {useLocation, useNavigate} from "react-router-dom";
import http from "../../../services/api";
import {IUserResponse} from "../../../interfaces/user.interface";
import {resend_welcome_email_url, verify_email_url} from "../../../constants/contants";
import {toastMessage} from "../../../utils/util";
import FormButton from "../../components/form/bottons/FormButton";
import {CommonResponse} from "../../../interfaces/app.interface";
import {useFormik} from "formik";
import * as Yup from "yup";
import FormInput from "../../components/form/FormInput";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/rootReducer";
import {
    emailValidationUserFailure,
    emailValidationUserStart,
    emailValidationUserSuccess
} from "../../../redux/slices/userSlice";
import {Button} from "react-rainbow-components";


const validationSchema = Yup.object({
    code: Yup.string()
        .min(20, 'Verification code must be at least 20 characters')
        .required('Verification code is required'),

});
const VerifyEmail: FC = () => {

    const navigate = useNavigate();
    const {loading} = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch();

    let user = localStorage.getItem("user");

    useEffect(() => {

        if(user){
            navigate("/dashboard");
        }
    }, [user]);

    const [userData, setUserData] = useState<{ fullName: string; email: string }>();
    const [resetLoading, setResetLoading] = useState(false);
    const [emailVerified, setEmailVerified] = useState(false);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    // Example: Get a specific query parameter
    const code = queryParams.get('code');


    useEffect(() => {
        const verifyEmailData = localStorage.getItem('verifyEmail');
        if (verifyEmailData) {
            setUserData(JSON.parse(verifyEmailData));
        } else {
            navigate(`/login`);
        }
    }, [navigate]);

    const resendWelcomeEmail = async () => {
        try {
            let response = await http.post<CommonResponse>(resend_welcome_email_url, {
                email: userData?.email,
                app: 'chat-bot',
            });

            setResetLoading(!resetLoading);
            toastMessage(response.data.message, 'success');
        } catch (error: any) {
            console.log("Error");
        }
    };

    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        formik.handleChange(e);
        if (e.target.value && index < 5) {
            inputRefs.current[index + 1]?.focus();
        }
    };

    const formik = useFormik({
        initialValues: {
            code: code?code:'',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            dispatch(emailValidationUserStart());
            try {
                const response = await http.post<IUserResponse>(verify_email_url, {
                    code: values.code
                });

                toastMessage(response.data.message, 'success');
                setEmailVerified(true)
                dispatch(emailValidationUserSuccess());

            } catch (error: any) {
                dispatch(emailValidationUserFailure(error));
                console.log('error');
            }
        },
    });

    return (
        <div className="nk-app-root" data-sidebar-collapse="lg">
            <div className="nk-main">
                <div className="nk-wrap has-shape flex-column">
                    <AuthHeader />
                    <div className="container p-2 p-sm-4 mt-auto">
                        <div className="row justify-content-center">
                            {emailVerified ?
                                <div className="col-md-7 col-lg-4">
                                    <div className="nk-block">
                                        <div className="mb-5 text-center">
                                            <img src="images/illustrations/envelope-verified.svg" alt=""/>
                                        </div>
                                        <div className="nk-block-head text-center">
                                            <div className="nk-block-head-content">
                                                <h1 className="nk-block-title mb-1">Email Verified!</h1>
                                                <p>Thank you for taking the time to verify your email. This simple step
                                                    helps us ensure the security of your account.</p>
                                            </div>
                                        </div>
                                        <div className="d-grid mt-4 pt-2">
                                            <span className="btn btn-primary" onClick={()=>navigate('/login')}>Continue</span>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="col-md-7 col-lg-4">
                                    <div className="nk-block">
                                        <div className="mb-5 text-center">
                                            <img src="images/illustrations/envelope-send.svg" alt=""/>
                                        </div>
                                        <div className="nk-block-head text-center">
                                            <div className="nk-block-head-content">
                                                <h1 className="nk-block-title mb-1">Check Your Email</h1>
                                                <p>
                                                    Please check the email address <strong
                                                    className="fw-bold">{userData?.email}</strong> for instructions to
                                                    verify your email.
                                                </p>
                                            </div>
                                        </div>
                                        <form onSubmit={formik.handleSubmit}>
                                            <div className="row gy-3">
                                                <div className="col-12">
                                                    <FormInput
                                                        label={`Enter Verification Code`}
                                                        name={`code`}
                                                        placeholder={`Enter Verification Code`}
                                                        value={formik.values.code}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        error={formik.errors.code}
                                                        className={`text-center`}
                                                    />
                                                </div>
                                                <div className="col-12">
                                                    <div className="d-grid mt-2">
                                                        {loading ? (
                                                            <Button isLoading label="Button Brand" variant="brand"
                                                                    className="rainbow-m-around_medium"
                                                                    style={{borderRadius: 5}}/>
                                                        ) : (
                                                            <FormButton type={`submit`} text={`Verify Email`}
                                                                        isValid={formik.isSubmitting}/>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        <div className="text-center mt-3">
                                            <p onClick={resendWelcomeEmail}>
                                                Didn't receive verification? <span
                                                className="small link">Resent</span> / <span
                                                className="small link" onClick={() => navigate('/login')}>Login</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>}

                        </div>
                    </div>
                    <Footer/>
                </div>
            </div>
        </div>
    );
};

export default VerifyEmail;

import React, {FC, useEffect, useState} from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Footer from "../../components/footer/Footer";
import './authentication.scss';
import http from "../../../services/api";
import {toastMessage} from "../../../utils/util";
import {useNavigate} from "react-router-dom";

// Define validation schema using Yup
const validationSchema = Yup.object({
    password: Yup.string()
        .min(6, 'Password must be at least 6 characters')
        .required('Password is required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords must match')
        .required('Confirm Password is required')
});

const ResetPassword: FC = () => {

    const [token, setToken] = useState<string | null>(null);
    const [decodedToken, setDecodedToken] = useState<any | null>(null);

    const navigate = useNavigate();

    const params = new URLSearchParams(window.location.search);
    const tokenFromUrl = params.get('token'); // Extract token
    const appFromUrl = params.get('app'); // Extract app (optional)

    // Handle form submission
    const handleSubmit = async (values: { password: string, confirmPassword: string }, { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }) => {
        console.log('Form Submitted:', values);
        try {
            const payload = {newPassword: values.confirmPassword}
            const response = await http.post(`/reset-password?token=${tokenFromUrl}&app=${appFromUrl}`, payload);
            console.log('response', response);
            toastMessage(`${response.data.message}`, 'success');
            navigate('/login');
        }catch (error){
            console.log("error", error);
        } finally {
            // Set isSubmitting to false to enable the form again
            setSubmitting(false);
        }
    };

    return (
        <div className="nk-app-root" data-sidebar-collapse="lg">
            <div className="nk-main">
                <div className="nk-wrap has-shape flex-column">
                    <div className="nk-shape bg-shape-blur-a start-0 top-0"></div>
                    <div className="nk-shape bg-shape-blur-b end-0 bottom-0"></div>
                    <div className="text-center">
                        <a href="index.html" className="logo-link">
                            <div className="logo-wrap">
                                <img className="logo-img logo-light" src="images/logo.png" alt="" />
                                <img className="logo-img logo-dark" src="images/logo-dark.png" alt="" />
                                <img className="logo-img logo-icon" src="images/logo-icon.png" alt="" />
                            </div>
                        </a>
                    </div>
                    <div className="container p-2 p-sm-4">
                        <div className="row justify-content-center">
                            <div className="col-md-7 col-lg-4">
                                <div className="nk-block">
                                    <div className="mb-5 text-center">
                                        <img src="images/illustrations/envelope-verified.svg" alt="" />
                                    </div>
                                    <div className="nk-block-head text-center">
                                        <div className="nk-block-head-content">
                                            <h1 className="nk-block-title mb-1">Reset Password</h1>
                                            <p>Please enter a new password below.</p>
                                        </div>
                                    </div>
                                    {/* Formik for handling form submission and validation */}
                                    <Formik
                                        initialValues={{ password: '', confirmPassword: '' }}
                                        validationSchema={validationSchema}
                                        onSubmit={handleSubmit}
                                    >
                                        {({ isSubmitting }) => (
                                            <Form>
                                                <div className="form-group">
                                                    <label htmlFor="password" className="form-label">Password</label>
                                                    <Field
                                                        type="password"
                                                        name="password"
                                                        className="form-control"
                                                        placeholder="Enter new password"
                                                    />
                                                    <ErrorMessage name="password" component="div" className="text-danger" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
                                                    <Field
                                                        type="password"
                                                        name="confirmPassword"
                                                        className="form-control"
                                                        placeholder="Confirm your new password"
                                                    />
                                                    <ErrorMessage name="confirmPassword" component="div" className="text-danger" />
                                                </div>
                                                <div className="d-grid pt-2">
                                                    <button className="btn btn-primary" type="submit" disabled={isSubmitting}>
                                                        {isSubmitting ? 'Submitting...' : 'Reset Password'}
                                                    </button>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;

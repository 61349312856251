import React, {useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import FormButton from "../../../components/form/bottons/FormButton";
import {Button, ButtonMenu, MenuItem, Modal} from "react-rainbow-components";
import * as Yup from "yup";
import {IDocument} from "../../../../interfaces/website.interface";
import FileUpload from "../../../components/upload/fileUpload";
import dayjs from "dayjs";
import {useSelector} from "react-redux";
import {RootState} from "../../../../redux/rootReducer";
import DocumentManage from "../modals/DocumentManage";
import EmptyMessage from "../../../components/empty/EmptyMessage";
import DocumentViewer from "../modals/DocumentViewer";


const validationSchema = Yup.object({
    websiteName: Yup.string()
        .required('Website Name is required'),
    websiteUrl: Yup.string()
        .url('Invalid URL format')
        .required('Website URL is required'),
    websiteType: Yup.object({
        name: Yup.string().required('Website type is required'),
    }).required('Website type is required'),
});
interface FileUploadHandle {
    uploadFiles: () => Promise<void>;
}


const WebsiteDocuments: React.FC = () => {

    const navigate = useNavigate();



    const [showModal, setShowModal] = useState(false);
    const [showDocumentModal, setShowDocumentModal] = useState(false);

    const [fileCount, setFileCount] = useState(0);
    const [manageModal, setManageModal] = useState('');

    const [selectedDocument, setSelectedDocument] = useState<IDocument>();

    const fileUploadRef = useRef<FileUploadHandle>(null);
    const { website, loading, error } = useSelector((state: RootState) => state.websites);

    const [uploadLoading, setUploadLoading] = useState<boolean>(false);

    const handleUpload = () => {
        setUploadLoading(true);
        if (fileUploadRef.current) {
            fileUploadRef.current.uploadFiles();
        }
        setUploadLoading(false);
    };

    console.log('website?.documents', website?.documents)

    return (
        <div className="tab-pane fade show active" id="profile-tab-pane">
            <div
                className="d-flex align-items-center justify-content-between  mt-2 pb-2">
                <h5>Documents</h5>
                <button className="btn btn-primary btn-sm" disabled={uploadLoading} onClick={()=>setShowModal(true)} ><em className="icon ni ni-plus"></em><span>Upload</span></button>
            </div>
            <div className="nk-block">

                <div className="card shadow-none">
                    <table className="table table-middle mb-0">
                        <tbody>

                        {website?.documents.length? website?.documents.slice()
                                .sort((a, b) => dayjs(b.createdAt).valueOf() - dayjs(a.createdAt).valueOf()).map((document) => (

                            <tr>
                                <td className="tb-col">
                                    <div className="caption-text line-clamp-2 cursor-pointer" onClick={()=> {
                                        setSelectedDocument(document)
                                        setShowDocumentModal(true)
                                    }}>{document.name}</div>
                                </td>
                                <td className="tb-col tb-col-sm">
                                    <div className="badge text-bg-dark-soft rounded-pill px-2 py-1 fs-6 lh-sm">{document.extension}</div>
                                </td>
                                <td className="tb-col">
                                    <div className={`badge rounded-pill px-2 py-1 fs-6 lh-sm ${document.isProcessed? 'text-bg-success-soft ':'text-bg-warning-soft'}`}>{document.isProcessed ? 'Processed' : 'Processing'}</div>
                                </td>
                                <td className="tb-col tb-col-sm">
                                    <div className={`badge rounded-pill px-2 py-1 fs-6 lh-sm ${document.isProcessed? 'text-bg-success-soft ':'text-bg-warning-soft'}`}>{document.logs && document.logs.length?document.logs[document.logs.length-1].percent:''}</div>
                                </td>
                                <td className="tb-col tb-col-md">
                                    <div className="fs-6 text-light d-inline-flex flex-wrap gap gx-2">
                                        <span>{dayjs(document.createdAt).format('MMM DD, YYYY')}</span>
                                        <span>{dayjs(document.createdAt).format('hh:mm A')}</span>
                                    </div>
                                </td>
                                <td className="tb-col tb-col-end">
                                    <ButtonMenu
                                        menuAlignment="right"
                                        menuSize="x-small"
                                        buttonVariant="base"
                                        icon={<em className="icon ni ni-more-h"></em>}
                                    >

                                        <MenuItem icon={<em className="icon ni ni-trash"></em>} onClick={()=> {

                                            setSelectedDocument(document)
                                            setManageModal('delete')
                                        }} label="Delete"/>
                                    </ButtonMenu>
                                </td>
                            </tr>

                        )):

                            <EmptyMessage
                                type="doc"
                                title="No documents available."
                                description="You have no documents stored. Upload documents to start organizing your files."
                                buttonText="Upload Document"
                                buttonCallback={()=>{
                                    setShowModal(true)
                                }}
                            />
                        }


                        </tbody>
                    </table>

                </div>

            </div>
            <Modal
                id="modal-upload-documents"
                title="Upload Documents"
                isOpen={showModal}
                onRequestClose={() => {
                    setShowModal(false)
                }}
                footer={
                    <div className="d-flex justify-content-end">
                        {loading ? (
                            <Button isLoading label="Button Brand" variant="brand" className="rainbow-m-around_medium"  style={{borderRadius: 5}} />
                        ) : (
                            <FormButton type={`button`} text={`Upload`} disabled={fileCount === 0} onClick={handleUpload}/>
                        )}
                    </div>
                }
            >
                <form>
                    <div className="row gy-3">
                        <div className="col-12">

                                <FileUpload ref={fileUploadRef} uploadType={`website`} _id={website?._id!}
                                            onFileCountChange={(count) => setFileCount(count)}/>


                        </div>

                    </div>
                </form>
            </Modal>
            <DocumentManage document={selectedDocument!}  setManageModal={(e:string)=> setManageModal(e)} manageModal={manageModal} />

            <DocumentViewer document={selectedDocument!} setShowModal={(e: boolean) => setShowDocumentModal(e)}
                         showModal={showDocumentModal}/>


        </div>
    );
};
export default WebsiteDocuments;

import React, {FC, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/rootReducer";
import {Modal, Option, Picklist} from "react-rainbow-components";
import FormButton from "../../components/form/bottons/FormButton";
import FormInput from "../../components/form/FormInput";
import loadRazorpay from "../../../utils/loadRazorpay";
import {toastMessage} from "../../../utils/util";
import PayPalSubscriptionButton from "../payment/PayPalSubscriptionButton";
import PaymentPlan from "./modals/PaymentPlan";
import {IPricingPlan} from "../../../interfaces/pricingPlans.interface";
import SwitchPlan from "./modals/SwitchPlan";
import dayjs from "dayjs";


const PricingPlans: FC = () => {

    const [activeTab, setActiveTab] = useState('monthly');
    const { user } = useSelector((state: RootState) => state.user);


    const [showModal, setShowModal] = useState(false);
    const [showSwitchModal, setShowSwitchModal] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState<IPricingPlan>();

    const { plans, loading, error } = useSelector((state: RootState) => state.pricingPlans);



    return (
        <div className="nk-content">

            <div className="nk-content-body">
                <div className="pt-4">
                    <div className="pricing-toggle-wrap mb-4">
                        <button className={`pricing-toggle-button ${activeTab === 'monthly' ? 'active' : ''}`}
                                onClick={() => setActiveTab('monthly')}>Monthly
                        </button>
                        <button className={`pricing-toggle-button ${activeTab === 'yearly' ? 'active' : ''}`}
                                onClick={() => setActiveTab('yearly')}>Yearly (Save
                            25%)
                        </button>
                    </div>
                </div>
                <div className="nk-block">
                    <div className="row g-0 mt-5">

                        {[...plans].sort((a, b) => a.tokens - b.tokens).map((plan) => (
                            <div className={`col mx-2`} key={plan.id}>

                                <div
                                    className={`pricing ${plan.popular ? 'pricing-featured bg-primary' : ''} ${plan.id === 1 ? 'rounded-start' : ''} ${plan.id === plans.length ? 'rounded-end' : ''}  mx-n1px my-xl-n1px  mt-5`}>
                                    {plan.popular && (
                                        <div
                                            className="position-absolute text-center py-1 px-4 text-bg-primary rounded-top start-0 end-0 bottom-100">
                                            <div className="fw-medium lh-sm fs-14px">Most plan popular</div>
                                        </div>
                                    )}
                                    <div className={`pricing-content ${plan.popular ? 'bg-primary-soft' : ''}`}>
                                        <div className="w-sm-70 w-md-50 w-xl-100 text-center text-xl-start mx-auto">

                                            <span className=" fs-6  mb-3">{plan.title}</span>
                                            {plan.popular ?
                                                <h3 className="mb-3 fw-bold text-gradient-custom gradient-start-primary-light gradient-middle-primary-light gradient-end-blue-light gradient-angle-90">{plan.subtitle}</h3>
                                                :
                                                <h3 className="fw-normal fw-bold text-dark">{plan.subtitle}</h3>

                                            }
                                            <div className="pricing-price-wrap">

                                                <div className="pricing-price-wrap">

                                                    <div
                                                        className={`pricing-price  ${activeTab === 'monthly' ? 'monthly' : 'yearly'}`}>
                                                        {user?.countryCode == 'IN' ?
                                                            <h5 className=" mb-3 fs-3 text-danger fw-semibold">{`${activeTab === 'monthly' ? `${plan.pricing.monthly.inr} INR` : `${plan.pricing.yearly.inr} INR`}`}
                                                                <span
                                                                    className="caption-text text-light fw-normal"> / {activeTab === 'monthly' ? 'month' : 'year'}</span>
                                                            </h5>
                                                            :
                                                            <h5 className=" mb-3 fs-3 text-danger fw-semibold">{`${activeTab === 'monthly' ? `${plan.pricing.monthly.usd} USD` : `${plan.pricing.yearly.usd} USD`}`}
                                                                <span
                                                                    className="caption-text text-light fw-normal"> / {activeTab === 'monthly' ? 'month' : 'year'}</span>
                                                            </h5>
                                                        }

                                                    </div>
                                                </div>

                                            </div>
                                            <div className="mb-2">
                                                {plan.popular ?
                                                    <div className="pricing-toggle-content monthly active">
                                                    <button className="btn btn-sm btn-gradient w-100" onClick={()=>{
                                                            if(user?.plan.planId._id != plan._id) {

                                                                setSelectedPlan(plan)

                                                                const planType = dayjs(user?.plan?.endDate).diff(dayjs(user?.plan?.startDate), 'month') === 12 ? 'yearly' : 'monthly';

                                                                let upgradingPlan = false;

                                                                const isUpgrading = (currentPlan: number, selectedPlan: number | undefined) => {
                                                                    return selectedPlan !== undefined && currentPlan < selectedPlan;
                                                                };

                                                                const checkPricing = (currentPricing: any, selectedPricing: any) => {
                                                                    if (user?.countryCode === 'IN') {
                                                                        if (currentPricing.inr && isUpgrading(currentPricing.inr, selectedPricing?.inr)) {
                                                                            return true;
                                                                        }
                                                                    } else {
                                                                        if (currentPricing.usd && isUpgrading(currentPricing.usd, selectedPricing?.usd)) {
                                                                            return true;
                                                                        }
                                                                    }
                                                                    return false;
                                                                };

                                                                if (planType === 'monthly') {
                                                                    upgradingPlan = checkPricing(user?.plan.planId.pricing.monthly, selectedPlan?.pricing.monthly);
                                                                } else {
                                                                    upgradingPlan = checkPricing(user?.plan.planId.pricing.yearly, selectedPlan?.pricing.yearly);
                                                                }

                                                                if(!upgradingPlan) {

                                                                    setShowSwitchModal(true)
                                                                }else {
                                                                    setShowModal(true)

                                                                }

                                                            }
                                                        }} disabled={user?.plan.planId._id === plan._id}  > {user?.plan.planId._id === plan._id ? 'Your Plan' : 'Upgrade Now'}</button>
                                                    </div>
                                                    :
                                                    <button onClick={()=>{
                                                        if(user?.plan.planId._id != plan._id) {
                                                            setSelectedPlan(plan)
                                                            setShowModal(true)
                                                        }
                                                    }} className="btn btn-sm btn-outline-light w-100" disabled={user?.plan.planId._id === plan._id} > {user?.plan.planId._id === plan._id ? 'Your Plan' : 'Upgrade Now'}</button>
                                                }


                                                <div
                                                    className="d-flex align-items-center justify-content-center text-center text-light fs-12px lh-lg fst-italic mt-1">
                                                    <svg width="13" height="13" viewBox="0 0 13 13"
                                                         className="text-danger" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6.5 2.375V10.625" stroke="currentColor"
                                                              stroke-width="1.25"
                                                              stroke-linecap="round" stroke-linejoin="round"></path>
                                                        <path d="M2.9281 4.4375L10.0719 8.5625" stroke="currentColor"
                                                              stroke-width="1.25"
                                                              stroke-linecap="round" stroke-linejoin="round"></path>
                                                        <path d="M2.9281 8.5625L10.0719 4.4375" stroke="currentColor"
                                                              stroke-width="1.25"
                                                              stroke-linecap="round" stroke-linejoin="round"></path>
                                                    </svg>
                                                    <span className="ms-1">Cancel Anytime</span>
                                                </div>
                                            </div>
                                            <ul className="pricing-features">
                                                {plan.features.map((feature, i) =>
                                                    <li><em
                                                        className="icon text-primary ni ni-check-circle"></em><span>{feature.name}</span>
                                                    </li>
                                                )}

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        ))}
                    </div>
                    <div className="mt-5">
                        <h5>Want to learn more about our pricing &amp; plans?</h5>
                        <p>Read our <a href="pricing-plans.html">Plans</a>, <a
                            href="#">Billing &amp; Payment</a>, <a href="#">FAQs</a> to learn more about our
                            service.</p>
                    </div>
                </div>
            </div>


            {selectedPlan &&(
                // <PaymentPlan showModal={showModal} setShowModal={(e:boolean)=> setShowModal(e)} plan={selectedPlan} subscriptionType={activeTab} />
                <SwitchPlan plan={selectedPlan} subscriptionType={activeTab} showModal={showModal} setShowModal={(e:string)=> {
                    setShowModal(false)
                }} />
            )}

            {/*<SwitchPlan plan={selectedPlan} subscriptionType={activeTab} showModal={showSwitchModal} setShowModal={(e:string)=> {*/}
            {/*    setShowSwitchModal(false)*/}

            {/*    if(e !== 'close') {*/}
            {/*        setShowModal(true)*/}
            {/*    }*/}
            {/*}} />*/}




        </div>
    );
};
export default PricingPlans;

import React, {FC, useEffect, useState} from 'react';

import './authentication.scss'
import {useNavigate} from "react-router-dom";
import Footer from "../../components/footer/Footer";

import AuthHeader from "../../components/headers/AuthHeader";
import * as Yup from "yup";
import {useFormik} from "formik";
import http from "../../../services/api";
import {IUser, IUserResponse} from "../../../interfaces/user.interface";
import {google_login_url, login_url} from "../../../constants/contants";
import {toastMessage} from "../../../utils/util";
import FormInput from "../../components/form/FormInput";
import FormButton from "../../components/form/bottons/FormButton";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/rootReducer";
import GoogleImg from "../../../assets/google.png";
import {GoogleLogin, useGoogleLogin, useGoogleOneTapLogin} from "@react-oauth/google";
import {fetchUserFailure, fetchUserStart, fetchUserSuccess} from "../../../redux/slices/userSlice";
import ct from "countries-and-timezones";
import axios from "axios";
import {countries} from "../../../utils/countries";
import { Button } from 'react-rainbow-components';



const validationSchema = Yup.object({
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    password: Yup.string()
        .min(6, 'Password must be at least 6 characters')
        .required('Password is required'),
});


interface CountryOption {
    value: string;
    label: string;
}
const Login: FC = () => {

    const navigate = useNavigate();

    let user = localStorage.getItem("user");
    const dispatch = useDispatch();

    const { loading } = useSelector((state: RootState) => state.user);


    const [userCountry, setUserCountry] = useState<CountryOption>();
    const [allCountries, setAllCountries] = useState(countries);
    const [timezone, setTimezone] = useState('');



    useEffect(() => {

        if(user){
            navigate("/dashboard");
        }
    }, [user]);

    const fetchUserCountry = async () => {
        try {
            const response = await axios.get('https://ipinfo.io/json'); // Basic IP lookup without token
            const country = response.data.country;
            return countries.find((c: any) => c.value === country);
        } catch (error) {
            console.error('Error fetching user country:', error);
            return null;
        }
    };

    useEffect(() => {

        const getUserCountry = async () => {
            const country:any = await fetchUserCountry();

            if(country){
                setUserCountry(country)
                formik.setFieldValue('country', country)

                const countryData = ct.getCountry(country.value);

                let timezoneData:any = countryData.timezones
                if(timezoneData){

                    setTimezone(timezoneData)
                }

            }

        };
        getUserCountry();
    }, []);


    const login = useGoogleLogin({
        onSuccess: async tokenResponse => {
            try {
                console.log('login')
                let user = await http.post<IUser>(google_login_url, {
                    token: tokenResponse.access_token,
                    countryCode:  userCountry?.value,
                    timezone:  timezone[0],
                });
                dispatch(fetchUserSuccess(user.data));

                localStorage.setItem('user', JSON.stringify(user.data))

                navigate('/dashboard');
            } catch (error:any) {
                console.log('error');
            }

        },
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            dispatch(fetchUserStart());
            try {
                let user = await http.post<IUser>(login_url, {
                    email: values.email,
                    password: values.password
                });
                dispatch(fetchUserSuccess(user.data));
                localStorage.setItem('user', JSON.stringify(user.data))
                navigate('/dashboard');
            } catch (error:any) {
                dispatch(fetchUserFailure(error));
            }
        },
    });

    return (
        <div className="nk-app-root " data-sidebar-collapse="lg">
            <div className="nk-main">
                <div className="nk-wrap has-shape flex-column">
                   <AuthHeader  />
                    <div className="container p-2 p-sm-4 mt-auto">
                        <div className="row justify-content-center">
                            <div className="col-md-7 col-lg-5 col-xl-5 col-xxl-4">
                                <div className="nk-block">
                                    <div className="nk-block-head text-center mb-4 pb-2">
                                        <div className="nk-block-head-content">
                                            <h1 className="nk-block-title mb-1">Log into Your Account</h1>
                                            <p className="small">Sign in to your account to customize your content
                                                generation settings and view your history.</p>
                                        </div>
                                    </div>
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className="row gy-3">
                                            <div className="col-12">
                                                <FormInput
                                                    label="Email Address"
                                                    name="email"
                                                    type="email"
                                                    placeholder="Enter email address"
                                                    value={formik.values.email}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    error={formik.errors.email}
                                                />
                                            </div>
                                            <div className="col-12">
                                                <FormInput
                                                    label="Password"
                                                    name="password"
                                                    type="password"
                                                    placeholder="Enter Password"
                                                    value={formik.values.password}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    error={formik.errors.password}
                                                />
                                            </div>
                                            <div className="col-12">
                                                <span className="link small"
                                                      onClick={() => navigate("/forgot-password")}>Forgot
                                                    password?</span>
                                            </div>

                                            <div className="col-12">
                                                <div className="d-grid">
                                                    {loading ? (
                                                        <Button isLoading label="Button Brand" variant="brand" className="rainbow-m-around_medium" style={{borderRadius: 5}} />
                                                    ):(
                                                        <FormButton type={`submit`} text={`Login`} />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div className="text-center mt-3">
                                        <p>Don’t have an account? <span className="small link"
                                            onClick={() => navigate("/register")}>Sign
                                            up</span></p>
                                    </div>
                                    <div className="my-3 text-center">
                                        <h6 className="overline-title overline-title-sep"><span>OR</span></h6>
                                    </div>
                                    <div className="row g-2">
                                        <div className="col-12">

                                            <button className="btn btn-outline-light w-100" onClick={() => login()}>
                                                <img src={GoogleImg} alt="" className="icon"/>
                                                <span className="fw-medium">Continue with Google</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </div>
        </div>

    );
};
export default Login;

// src/redux/websiteChatSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IWebsiteChats } from '../../interfaces/websitechat.interface';

interface ChatState {
    websiteChats: IWebsiteChats[];
    loading: boolean;
    error: string | null;
}

const initialState: ChatState = {
    websiteChats: [],
    loading: false,
    error: null,
};

const websiteChatSlice = createSlice({
    name: 'websiteChats',
    initialState,
    reducers: {
        fetchWebsiteChatsStart(state) {
            state.loading = true;
            state.error = null;
        },
        fetchWebsiteChatsSuccess(state, action: PayloadAction<IWebsiteChats[]>) {
            state.websiteChats = action.payload;
            state.loading = false;
        },
        fetchWebsiteChatsFailure(state, action: PayloadAction<string>) {
            state.loading = false;
            state.error = action.payload;
        },
        deleteWebsiteChat(state, action: PayloadAction<string>) {
            state.websiteChats = state.websiteChats.filter(websiteChat => websiteChat.sessionId !== action.payload);
        },
    },
});

export const {
    fetchWebsiteChatsStart,
    fetchWebsiteChatsSuccess,
    fetchWebsiteChatsFailure,
    deleteWebsiteChat,
} = websiteChatSlice.actions;

export default websiteChatSlice.reducer;

import toast from "react-hot-toast";
import React from "react";
import {countries} from "./countries";
import axios from "axios";


export const toastMessage = (message:any,type:any) => {

    if(type === 'error'){

        toast.error(message, {
            position: "bottom-center",
            duration: 5000,
        })

    }else{
        toast.success(message, {
            position: "bottom-center",
            duration: 5000,
        })


    }

}

export function getInitials(name: string): string {
    const names = name.split(' ');
    const initials = names.map(n => n[0]).join('');
    return initials.toUpperCase();
}
const useViewport = () => {
    const [width, setWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    // Return the width so we can use it in our components
    return { width };
}

import React, {FC, useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import FormButton from "../../../../components/form/bottons/FormButton";
import FormInput from "../../../../components/form/FormInput";
import {Button, ButtonMenu, FileSelector, MenuItem, Modal, Option, Picklist} from "react-rainbow-components";
import * as Yup from "yup";
import {useFormik} from "formik";
import http from "../../../../../services/api";
import FileUpload from "../../../../components/upload/fileUpload";
import dayjs from "dayjs";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../redux/rootReducer";
import EmptyMessage from "../../../../components/empty/EmptyMessage";
import DocumentManage from "../../modals/DocumentManage";
import DocumentViewer from "../../modals/DocumentViewer";
import {IDocument, IGroup} from "../../../../../interfaces/organization.interface";
import GroupDocumentManage from "../modals/GroupDocumentManage";



const validationSchema = Yup.object({
    websiteName: Yup.string()
        .required('Website Name is required'),
    websiteUrl: Yup.string()
        .url('Invalid URL format')
        .required('Website URL is required'),
    websiteType: Yup.object({
        name: Yup.string().required('Website type is required'),
    }).required('Website type is required'),
});
interface FileUploadHandle {
    uploadFiles: () => Promise<void>;
}

const OrganizationDocuments: React.FC<{group:IGroup}> = ({group}) => {

    const {organization, loading} = useSelector((state: RootState) => state?.organizations);

    const [selectedDocument, setSelectedDocument] = useState<IDocument>();
    const [showDocumentViewModal, setShowDocumentViewModal] = useState(false);

    const [showDocumentModal, setShowDocumentModal] = useState(false);
    const [fileCount, setFileCount] = useState(0);

    const [documentManage, setDocumentManage] = useState<boolean>(false);

    const fileUploadRef = useRef<FileUploadHandle>(null);

    const handleUpload = () => {
        if (fileUploadRef.current) {
            fileUploadRef?.current?.uploadFiles();
        }
    };

    return (
        <div className="tab-pane fade show active" id="profile-tab-pane">
            <div
                className="d-flex align-items-center justify-content-between pb-2">
                <h5>Documents</h5>
                <button className="btn btn-sm btn-primary" onClick={()=> setShowDocumentModal(true)} ><em className="icon ni ni-plus"></em><span>Upload</span></button>
            </div>
            <div className="nk-block">

                <div className="card shadow-none">
                    <table className="table table-middle mb-0">
                        <tbody>
                        {group?.groupDocuments && group?.groupDocuments.length? group?.groupDocuments?.slice()
                                .sort((a, b) => dayjs(b.createdAt).valueOf() - dayjs(a.createdAt).valueOf())?.map((document) => (

                                    <tr>
                                        <td role='button' className="tb-col" onClick={() => {
                                            setSelectedDocument(document)
                                            setShowDocumentViewModal(true)
                                        }}>
                                            <div className="caption-text line-clamp-2 cursor-pointer">{document.name}</div>
                                        </td>
                                        <td className="tb-col tb-col-sm">
                                            <div
                                                className="badge text-bg-dark-soft rounded-pill px-2 py-1 fs-6 lh-sm">{document.extension}</div>
                                        </td>
                                        <td className="tb-col">
                                            <div
                                                className={`badge rounded-pill px-2 py-1 fs-6 lh-sm ${document.isProcessed ? 'text-bg-success-soft ' : 'text-bg-warning-soft'}`}>{document.isProcessed ? 'Processed' : 'Processing'}</div>
                                        </td>
                                        <td className="tb-col tb-col-sm">
                                            <div
                                                className={`badge rounded-pill px-2 py-1 fs-6 lh-sm ${document.isProcessed ? 'text-bg-success-soft ' : 'text-bg-warning-soft'}`}>{document.logs && document.logs.length ? document.logs[document.logs.length - 1].percent : ''}</div>
                                        </td>
                                        <td className="tb-col tb-col-md">
                                            <div className="fs-6 text-light d-inline-flex flex-wrap gap gx-2">
                                                <span>{dayjs(document.createdAt).format('MMM DD, YYYY')}</span>
                                                <span>{dayjs(document.createdAt).format('hh:mm A')}</span>
                                            </div>
                                        </td>
                                        {/*<td className="tb-col tb-col-sm">*/}
                                        {/*    <em onClick={() => {*/}
                                        {/*        setSelectedDocument(document)*/}
                                        {/*        setDocumentManage(true)*/}
                                        {/*    }} className="icon ni ni-trash fs-3 link link-primary"></em>*/}
                                        {/*</td>*/}
                                        <td className="tb-col tb-col-end">
                                            <ButtonMenu
                                                menuAlignment="right"
                                                menuSize="x-small"
                                                buttonVariant="base"
                                                icon={<em className="icon ni ni-more-h"></em>}
                                            >
                                                <MenuItem
                                                    icon={<em className="icon ni ni-trash"></em>}
                                                    onClick={() => {
                                                        setSelectedDocument(document)
                                                        setDocumentManage(true)
                                                    }} label="Delete"/>
                                            </ButtonMenu>
                                        </td>
                                    </tr>

                                )) :

                            <EmptyMessage
                                type="doc"
                                title="No documents available."
                                description="You have no documents stored. Upload documents to start organizing your files."
                                buttonText="Upload Document"
                                buttonCallback={() => {
                                    setShowDocumentModal(true)
                                }}
                            />
                        }
                        </tbody>
                    </table>
                </div>
            </div>

            <Modal
                id="modal-upload-documents"
                title="Upload Documents"
                isOpen={showDocumentModal}
                onRequestClose={() => {
                    setShowDocumentModal(false)
                }}
                footer={
                    <div className="d-flex justify-content-end">
                        {loading ? (
                            <Button isLoading label="Uploading" variant="brand" borderRadius="semi-square"
                                    className="rainbow-m-around_medium"/>
                        ) : (
                            <FormButton type={`button`} text={`Upload`} disabled={fileCount === 0}
                                        onClick={handleUpload}/>
                        )}

                    </div>
                }
            >
                <form>
                    <div className="row gy-3">
                        <div className="col-12">

                            <FileUpload ref={fileUploadRef} uploadType={`organization`} _id={organization?._id!}
                                        groupId={group?._id!}
                                        onFileCountChange={(count) => setFileCount(count)}/>
                        </div>

                    </div>
                </form>
            </Modal>

            <DocumentViewer group={group} document={selectedDocument!}
                            setShowModal={(e: boolean) => setShowDocumentViewModal(e)}
                            showModal={showDocumentViewModal}/>

            <GroupDocumentManage showModal={documentManage} setShowModal={() => setDocumentManage(false)}
                                 groupId={group?._id} documentId={selectedDocument?._id}/>

        </div>
    );
};
export default OrganizationDocuments;

import React, { FC, useEffect, useState } from 'react';
import {Helmet} from "react-helmet-async";
import {
    IGroup,
    IOrganization,
    IPublicGroupMember,
    IPublicOrganization
} from "../../../../interfaces/organization.interface";
import {Avatar} from "react-rainbow-components";
import {useSelector} from "react-redux";
import {RootState} from "../../../../redux/rootReducer";
import MemberForm from "../modals/MemberForm";
import PublicMemberForm from "../modals/PublicMemberForm";

interface Props {
    organization: IPublicOrganization;
}


const OrganizationMemberSettings: FC<Props> = ({organization}) => {

    const { organizationMember, loading, error } = useSelector((state: RootState) => state.organizations);


    const [showMemberForm, setShowMemberForm] = useState(false);



    return (
        <div className="tab-pane active show" id="pills-setting" role="tabpanel" aria-labelledby="pills-setting-tab">
            <div>
                <div className="pt-4 mx-2">
                    <h4 className="mb-4">Settings</h4>
                </div>

                <div className="text-center border-bottom">
                    <div className="mb-2 profile-user">
                        <Avatar
                            className="rainbow-m-around_x-small"
                            assistiveText={`${organizationMember?.member.firstname} ${organizationMember?.member.lastname}`}
                            initials={`${organizationMember?.member.firstname?.charAt(0).toUpperCase()}${organizationMember?.member.lastname?.charAt(0).toUpperCase()}`}
                            title={`${organizationMember?.member.firstname} ${organizationMember?.member.lastname}`}
                            size={`large`}
                        />
                    </div>

                    <h5 className="font-size-16 mb-3 text-truncate">{organizationMember?.member.firstname} {organizationMember?.member.lastname}</h5>
                </div>

                <div className="px-2 pt-4 user-profile-desc" data-simplebar="init">

                    <ul className="list-group">


                        <li className="list-group-item d-flex justify-content-between">
                            <div className="mt-2">
                                <p className="text-muted mb-1">Name</p>
                                <h5 className="font-size-14">{organizationMember?.member.firstname} {organizationMember?.member.lastname}</h5>
                            </div>

                            <div className="float-end">
                                <button type="button" className="btn btn-light btn-sm" onClick={() => {
                                    setShowMemberForm(true)
                                }}><i
                                    className="ri-edit-fill me-1 ms-0 align-middle"></i> Edit
                                </button>
                            </div>
                        </li>
                        <li className="list-group-item">
                            <div className="mt-2">
                                <p className="text-muted mb-1">Email</p>
                                <h5 className="font-size-14">{organizationMember?.member.email}</h5>
                            </div>
                        </li>
                    </ul>
                </div>


            </div>

            {organizationMember && (
                <PublicMemberForm organization={organization} member={organizationMember} showModal={showMemberForm}
                                  setShowModal={(e: boolean) => setShowMemberForm(e)}/>
            )}

        </div>

    );
};
export default OrganizationMemberSettings;

import React, {FC} from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {ILoginMember, IOrganization} from "../../../../interfaces/organization.interface";
import axios from "axios";
import {base_url, public_organizations_url} from "../../../../constants/contants";
import {useDispatch} from "react-redux";
import {setOrganizationUserSuccess} from "../../../../redux/slices/organizationsSlice";
import {useNavigate} from "react-router-dom";
import LogoImg from "../../../../assets/logo.png";
import {toastMessage} from "../../../../utils/util";

interface Props {
    organization: IOrganization;
}

const OrganizationLogin: FC<Props> = ({ organization }) => {


    const navigate = useNavigate();

    const dispatch = useDispatch();

    const validationSchema = Yup.object({
        email: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
        password: Yup.string()
            .min(6, 'Password must be at least 6 characters')
            .required('Password is required'),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            // Handle form submission here
            try {
                const response = await axios.post(`${base_url}/${public_organizations_url}/${organization._id}/login`, {
                    email: values.email,
                    password: values.password,
                });

                console.log('response', response);
                if(response.status == 200){
                    toastMessage(`Login Success`, 'success')
                    const organizationUser: ILoginMember = response.data;
                    console.log("organizationUser", organizationUser);
                    dispatch(setOrganizationUserSuccess(organizationUser));
                    localStorage.setItem('organizationUser', JSON.stringify(organizationUser));
                }else {
                    toastMessage(`${response?.data?.message}`, 'error')
                }

            }catch(error: any) {
                console.log(error);
                toastMessage(`${error?.response?.data?.message}`, 'error')
            }
        },
    });

    return (
        <div className="account-pages my-5 pt-sm-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6 col-xl-5">
                        <div className="text-center mb-4">
                            <div className='d-flex flex-wrap flex-row align-items-center justify-content-center gap-2 mb-3'>
                                {organization?.bot?.botLogo ?
                                    (<img src={organization?.bot.botLogo} alt="Sample" height={55} width={55}/>)
                                    : <img src={LogoImg} alt="Sample" height={55} width={55}/>
                                }
                            </div>
                            <h2>Sign in</h2>
                            <p className="text-muted mb-4">Sign in to continue to <span
                                className='fw-bold fs-4'>{organization.organizationName}</span>.</p>
                        </div>

                        <div className="card">
                            <div className="card-body p-4">
                                <div className="p-3">
                                <form onSubmit={formik.handleSubmit}>
                                        <div className="mb-3">
                                            <label className="form-label">Email</label>
                                            <div className="input-group mb-3 bg-light-subtle rounded-3">
                                                <span className="input-group-text text-muted" id="basic-addon3">
                                                   <em className="icon ni ni-user-check-fill fs-4"></em>
                                                </span>
                                                <input
                                                    type="text"
                                                    className={`form-control form-control-lg border-light bg-light-subtle ${formik.touched.email && formik.errors.email ? 'is-invalid' : ''}`}
                                                    placeholder="Enter Email"
                                                    aria-label="Enter Email"
                                                    aria-describedby="basic-addon3"
                                                    name="email"
                                                    value={formik.values.email}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.touched.email && formik.errors.email ? (
                                                    <div className="invalid-feedback">{formik.errors.email}</div>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className="mb-4">
                                            <label className="form-label">Password</label>
                                            <div className="input-group mb-3 bg-light-subtle rounded-3">
                                                <span className="input-group-text text-muted" id="basic-addon4">
                                                    <em className="icon ni ni-lock-alt-fill fs-4"></em>
                                                </span>
                                                <input
                                                    type="password"
                                                    className={`form-control form-control-lg border-light bg-light-subtle ${formik.touched.password && formik.errors.password ? 'is-invalid' : ''}`}
                                                    placeholder="Enter Password"
                                                    aria-label="Enter Password"
                                                    aria-describedby="basic-addon4"
                                                    name="password"
                                                    value={formik.values.password}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.touched.password && formik.errors.password ? (
                                                    <div className="invalid-feedback">{formik.errors.password}</div>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className="d-grid">
                                            <button className="btn btn-primary waves-effect waves-light" type="submit">
                                                Sign in
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div className="mt-5 text-center">
                            <p>© {new Date().getFullYear()} Brainaque. Crafted with <i className="mdi mdi-heart text-danger"></i> by Exploresoul Technologies</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrganizationLogin;

import React, {FC, useEffect, useRef, useState} from 'react';
import EmptyMessage from "../../../../components/empty/EmptyMessage";
import {IGroup} from "../../../../../interfaces/organization.interface";
import MemberForm from "../../modals/MemberForm";
import {ButtonMenu, MenuItem} from "react-rainbow-components";
import GroupMemberManage from "../modals/GroupMemberManage";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../redux/rootReducer";
import dayjs from "dayjs";

interface OrganizationGroupMembersInterFace {
    group: IGroup;
}

const OrganizationGroupMembers: React.FC<OrganizationGroupMembersInterFace> = ({group}) => {

    const navigate = useNavigate();

    const { organization } = useSelector((state: RootState) => state.organizations )
    const [showMemberForm, setShowMemberForm] = useState<boolean>(false);

    const findRole = (roleId: string) => {
        const role = organization?.roles.find((role) => role?._id === roleId);
        return role?.name || 'Unknown Role'; // Provide fallback if role is not found
    };

    return (
        <div className="tab-pane fade show active" id="profile-tab-pane">
            <div
                className="d-flex align-items-center justify-content-between my-2">
                <h5>Members</h5>
                <button className="btn btn-primary btn-sm" onClick={()=> setShowMemberForm(true)}>Manage</button>
            </div>
            {
                group && group.groupMembers && group.groupMembers.length > 0 ? (
                    <div className="nk-block">

                        <div className="card shadow-none border-0" style={{backgroundColor: '#f8f8f8'}}>
                            <table className="table table-middle mb-0">
                                <tbody>

                                {group? group.groupMembers?.map((member) => (
                                        <tr>
                                            <td className="tb-col">
                                                <div className="caption-text line-clamp-2 cursor-pointer" onClick={() => {
                                                }}>{member.firstname} {member.lastname}</div>
                                            </td>
                                            <td className="tb-col">
                                                <div className="caption-text line-clamp-2 cursor-pointer" onClick={() => {


                                                }}>{member.email}</div>
                                            </td>
                                            <td className="tb-col">
                                                <div className="caption-text line-clamp-2 cursor-pointer" onClick={() => {
                                                }}>{findRole(member.role)}</div>
                                            </td>
                                            <td className="tb-col">
                                                <div className="fs-6 text-light d-inline-flex flex-wrap gap gx-2">
                                                    <span>{dayjs(member.updatedAt).format('MMM DD, YYYY')}</span>
                                                    <span>{dayjs(member.updatedAt).format('hh:mm A')}</span>
                                                </div>
                                            </td>

                                        </tr>

                                    )) :

                                    <EmptyMessage
                                        type='organization'
                                        title="No Members Available."
                                        description="It seems there are no Members to display at the moment. You can create a Member by either clicking the button below or the one in the top right corner."
                                        buttonText="Add Members"
                                        buttonCallback={() => {
                                            setShowMemberForm(true);
                                        }}
                                    />
                                }


                                </tbody>
                            </table>

                        </div>

                    </div>
                ) : (
                    <EmptyMessage
                        type='organization'
                        title="No Members Available."
                        description="It seems there are no Members to display at the moment. You can create a Member by either clicking the button below or the one in the top right corner."
                        buttonText="Add Members"
                        buttonCallback={() => {
                            setShowMemberForm(true);
                        }}
                    />
                )
            }
            <GroupMemberManage groupId={group?._id} showModal={showMemberForm} setShowModal={(e: boolean) => setShowMemberForm(e)}/>
        </div>
    );
};
export default OrganizationGroupMembers;

import React, {FC, useEffect, useState} from 'react';
import {RootState} from "../../../../redux/rootReducer";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import http from "../../../../services/api";
import {payment_history_url} from "../../../../constants/contants";
import {
    fetchPaymentHistoryFailure,
    fetchPaymentHistoryStart,
    fetchPaymentHistorySuccess
} from "../../../../redux/slices/paymentHistorySlice";
import {IPaymentHistory} from "../../../../interfaces/paymentHistory.interface";
import PaymentInvoice from "../modals/PaymentInvoice";
import dayjs from "dayjs";
import CancelSubscription from "../modals/CancelSubscription";


const PaymentBilling: FC = () => {

    const { user } = useSelector((state: RootState) => state.user);
    const { paymentHistory } = useSelector((state: RootState) => state.paymentHistory);
    const { plans, loading, error } = useSelector((state: RootState) => state.pricingPlans);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [showInvoiceModal, setShowInvoiceModal] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [selectedPayment, setSelectedPayment] = useState<IPaymentHistory>();


    useEffect(() => {
        const fetchPaymentHistory = async () => {
            dispatch(fetchPaymentHistoryStart());

            try {
                let response = await http.get<IPaymentHistory[]>(payment_history_url);
                dispatch(fetchPaymentHistorySuccess(response.data));
            } catch (error: any) {
                dispatch(fetchPaymentHistoryFailure('Failed to fetch websites'));
            }
        };

        fetchPaymentHistory();
    }, []);

    const findSavePercentage = () => {
        const currentPlan = user?.plan.planId;
        if (currentPlan?.pricing?.monthly?.inr) {
            const currentMonthlyPrice = currentPlan.pricing.monthly.inr * 12;
            const currentYearlyPrice = currentPlan.pricing.yearly.inr;
            const savingsPercentage = ((currentMonthlyPrice - currentYearlyPrice) / currentMonthlyPrice) * 100;

            return Math.round(savingsPercentage); // Round to nearest integer if needed
        }
        return 0; // Return 0 if any pricing data is missing
    };

    console.log('user', user)

    return (
        <div className="tab-pane fade active show" id="payment-billing-tab-pane" role="tabpanel">
            <div className="d-flex flex-wrap align-items-center justify-content-between border-bottom border-light mt-5 mb-4 pb-1">
                <h5 className="mb-0">Your Subscription</h5>
                <ul className="d-flex gap gx-4">
                    <li>
                        <a className="link link-danger fw-normal" onClick={()=>setShowCancelModal(true)}>Cancel Subscription</a>
                    </li>
                    <li>
                        <a className="link link-primary fw-normal" onClick={()=> navigate('/plans')} >Change Plan</a>
                    </li>
                </ul>
            </div>
            {user && dayjs(user?.plan?.endDate).diff(dayjs(user?.plan?.startDate), 'month') !== 0 && (
                    <div className="alert alert-warning alert-dismissible fade show mb-4 rounded-6" role="alert">
                        <p className="small mb-0">
                            Switch to our yearly plan and enjoy the same great features with a
                            fantastic <strong>{findSavePercentage()}% discount</strong>! Make the smart choice today and
                            save
                            more!
                        </p>
                        <div className="d-inline-flex position-absolute end-0 top-50 translate-middle-y me-2">
                            <button type="button" className="btn btn-xs btn-icon btn-warning rounded-pill"
                                    data-bs-dismiss="alert">
                                <em className="icon ni ni-cross"></em>
                            </button>
                        </div>
                    </div>
            )}
            <div className="row g-gs">
                <div className="col-xl-3 col-sm-6">
                    <div className="card shadow-none">
                        <div className="card-body">
                            <div className="text-light mb-2">Plan</div>
                            <h3 className="fw-normal">{user?.plan.planId.title}</h3>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                    <div className="card shadow-none">
                        <div className="card-body">
                            <div className="text-light mb-2">Recurring Payment</div>
                            <h3 className="fw-normal">{user?.countryCode == 'IN' ? '₹' : '$'}
                                {user? user.countryCode == 'IN' ?
                                    ( dayjs(user.plan.endDate).diff( dayjs(user.plan.startDate), 'month') ?
                                        `${user.plan.planId.pricing.monthly.inr} /Month` :
                                        `${user.plan.planId.pricing.yearly.inr} /Year`) :
                                    ( dayjs(user.plan.endDate).diff( dayjs(user.plan.startDate), 'month') ?
                                        `${user.plan.planId.pricing.monthly.usd} /Month` :
                                        `${user.plan.planId.pricing.yearly.usd} /Year`) :
                                    ''}</h3>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                    <div className="card shadow-none">
                        <div className="card-body">
                            <div className="text-light mb-2">Next Due Date</div>
                            <h3 className="fw-normal">{dayjs(user?.plan.endDate).format('MMM DD, YYYY')}</h3>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                    <div className="card shadow-none">
                        <div className="card-body">
                            <div className="text-light mb-2">Payment Method</div>
                            <div className="d-flex align-items-center">
                                <img src="images//icons/paypal.png" alt="" className="icon" />
                                    <h3 className="fw-normal ms-2">{user?.countryCode == 'IN' ? 'RazorPay': 'PayPal'}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-center justify-content-between border-bottom border-light mt-5 mb-4 pb-2">
                <h5>Billing History</h5>
            </div>
            <div className="card">
                <table className="table table-middle mb-0">
                    <thead className="table-light">
                    <tr>
                        <th className="tb-col">
                            <div className="fs-13px text-base">Subscription</div>
                        </th>
                        <th className="tb-col tb-col-md">
                            <div className="fs-13px text-base">Payment Date</div>
                        </th>
                        <th className="tb-col tb-col-sm">
                            <div className="fs-13px text-base">Total</div>
                        </th>
                        <th className="tb-col tb-col-sm">
                            <div className="fs-13px text-base">Status</div>
                        </th>
                        <th className="tb-col"></th>
                    </tr>
                    </thead>
                    <tbody>


                    {paymentHistory.map((payment) =>
                        <tr>
                            <td className="tb-col">
                                <div className="caption-text">
                                    {payment.planId.title} - {Math.floor((new Date(payment.userId.plan.endDate).getTime() - new Date(payment.userId.plan.startDate).getTime()) / (1000 * 60 * 60 * 24))} Days
                                    <div className="d-sm-none dot bg-success"></div>
                                </div>
                            </td>
                            <td className="tb-col tb-col-md">
                                <div className="fs-6 text-light d-inline-flex flex-wrap gap gx-2">
                                    <span>{new Date(payment.paymentDate).toLocaleDateString()}</span>
                                    <span>{new Date(payment.paymentDate).toLocaleTimeString()}</span>
                                </div>
                            </td>
                            <td className="tb-col tb-col-sm">
                                <div className="fs-6 text-light">{payment.currency == 'inr'? '₹':'$'}{payment.amount.toFixed(2)}</div>
                            </td>
                            <td className="tb-col tb-col-sm">
                                <div className={`badge ${payment.paymentStatus === 'pending'? 'text-bg-danger-soft':'text-bg-success-soft' } text-capitalize rounded-pill px-2 py-1 fs-6 lh-sm`}>
                                    {payment.paymentStatus}
                                </div>
                            </td>
                            <td className="tb-col tb-col-end">
                                <a className="link" onClick={()=>{
                                    setShowInvoiceModal(true)
                                    setSelectedPayment(payment)
                                }}>
                                    Get Invoice
                                </a>
                            </td>
                        </tr>
                    )}


                    </tbody>
                </table>
            </div>

            <PaymentInvoice showModal={showInvoiceModal}  setShowModal={(e: boolean) => setShowInvoiceModal(e)} payment={selectedPayment!} />
            <CancelSubscription showModal={showCancelModal}  setShowModal={(e: boolean) => setShowCancelModal(e)} />
        </div>

    );
};
export default PaymentBilling;

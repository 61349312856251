// src/redux/organizationChatSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IOrganizationChats } from '../../interfaces/organizationchat.interface';

interface ChatState {
    organizationChats: IOrganizationChats[];
    loading: boolean;
    error: string | null;
}

const initialState: ChatState = {
    organizationChats: [],
    loading: false,
    error: null,
};

const organizationChatSlice = createSlice({
    name: 'organizationChats',
    initialState,
    reducers: {
        fetchOrganizationChatsStart(state) {
            state.loading = true;
            state.error = null;
        },
        fetchOrganizationChatsSuccess(state, action: PayloadAction<IOrganizationChats[]>) {
            state.organizationChats = action.payload;
            state.loading = false;
        },
        fetchOrganizationChatsFailure(state, action: PayloadAction<string>) {
            state.loading = false;
            state.error = action.payload;
        },
        deleteOrganizationChat(state, action: PayloadAction<string>) {
            state.organizationChats = state.organizationChats.filter(
                organizationChat => organizationChat.sessionId !== action.payload
            );
        },
    },
});

export const {
    fetchOrganizationChatsStart,
    fetchOrganizationChatsSuccess,
    fetchOrganizationChatsFailure,
    deleteOrganizationChat,
} = organizationChatSlice.actions;

export default organizationChatSlice.reducer;

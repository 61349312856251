import React, {FC, useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import FormButton from "../../../components/form/bottons/FormButton";
import FormInput from "../../../components/form/FormInput";
import {ButtonMenu, FileSelector, ImportRecordsFlow, MenuItem, Modal, Option, Picklist} from "react-rainbow-components";
import * as Yup from "yup";
import {useFormik} from "formik";
import http from "../../../../services/api";
import {IDocument, IShop} from "../../../../interfaces/shop.interface";
import FileUpload from "../../../components/upload/fileUpload";
import dayjs from "dayjs";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux/rootReducer";
import EmptyMessage from "../../../components/empty/EmptyMessage";
import DocumentViewer from "../../websites/modals/DocumentViewer";
import DocumentManage from "../../websites/modals/DocumentManage";
import MemberForm from "../modals/MemberForm";
import {IMember} from "../../../../interfaces/organization.interface";
import MemberManage from "../modals/memberManage";
import {multiple_Members_url, organizations_url} from "../../../../constants/contants";
import {
    createMemberFailure, createMemberMultipleSuccess,
    createMemberStart,
    createMemberSuccess,
    updateMemberSuccess
} from "../../../../redux/slices/organizationsSlice";
import {toastMessage} from "../../../../utils/util";


interface FileUploadHandle {
    uploadFiles: () => Promise<void>;
}

const OrganizationMembers: React.FC = () => {

    const { organization, loading, error } = useSelector((state: RootState) => state.organizations);

    const [showMemberForm, setShowMemberForm] = useState(false);
    const [showMemberImport, setShowMemberImport] = useState(false);

    const [selectedMember, setSelectedMember] = useState<IMember>();

    const [showEditModal, setShowEditModal] = useState(false);

    const [manageModal, setManageModal] = useState('');

    const dispatch = useDispatch();

    const findRole = (roleId: string) => {
        const role = organization?.roles.find((role) => role?._id === roleId);
        return role?.name || 'Unknown Role'; // Provide fallback if role is not found
    };

    const createMultipleMembers = async (members: any) => {
        dispatch(createMemberStart());
       try {
           const response: any = await http.post(`${organizations_url}/${organization?._id}${multiple_Members_url}`,members)
           if(response.status === 200){
               console.log('response.data.member', response.data.members)
               dispatch(createMemberMultipleSuccess({
                   members: response.data.members,
               }));
               toastMessage('Members Created successfully', 'success');
               setShowMemberImport(false)
           }
       }catch (error: any){
           console.log("createMultipleMembers Error:", error)
           dispatch(createMemberFailure(error.message));
       }
    };

    return (
        <div className="tab-pane fade show active" id="profile-tab-pane">
            <div
                className="d-flex align-items-center justify-content-between my-2">
                <h5>Members</h5>

                <ButtonMenu
                    menuAlignment="right"
                    menuSize="x-small"
                    borderRadius="semi-square"
                    buttonVariant="brand"
                    label={`Add Member`}
                    buttonSize={`small`}
                    icon={<em className="icon ni ni-plus"></em>}
                >
                    <MenuItem
                        icon={<em className="icon ni ni-edit"></em>}
                        onClick={() => {

                            setShowMemberForm(true)
                        }} label="Create Member"/>
                    <MenuItem
                        icon={<em className="icon ni ni-download"></em>}
                        onClick={() => {
                            setShowMemberImport(true)
                        }} label="Import Members"/>
                </ButtonMenu>


            </div>

            {
                organization && organization.members?.length > 0 ? (
                    <div className="nk-block">

                            <div className="card shadow-none">
                                <table className="table table-middle mb-0">
                                    <tbody>

                                    {organization? organization.members?.map((member) => (

                                            <tr>
                                                <td className="tb-col">
                                                    <div className="caption-text line-clamp-2 cursor-pointer" onClick={() => {


                                                    }}>{member.firstname} {member.lastname}</div>
                                                </td>
                                                <td className="tb-col">
                                                    <div className="caption-text line-clamp-2 cursor-pointer" onClick={() => {


                                                    }}>{member.email}</div>
                                                </td>
                                                <td className="tb-col">
                                                    <div className="caption-text line-clamp-2 cursor-pointer" onClick={() => {


                                                    }}>{findRole(member?.role)}</div>
                                                </td>
                                                <td className="tb-col">
                                                    <div className="fs-6 text-light d-inline-flex flex-wrap gap gx-2">
                                                        <span>{dayjs(member.createdAt).format('MMM DD, YYYY')}</span>
                                                        <span>{dayjs(member.createdAt).format('hh:mm A')}</span>
                                                    </div>
                                                </td>
                                                <td className="tb-col tb-col-end">
                                                    <ButtonMenu
                                                        menuAlignment="right"
                                                        menuSize="x-small"
                                                        buttonVariant="base"
                                                        icon={<em className="icon ni ni-more-h"></em>}
                                                    >
                                                        <MenuItem
                                                            icon={<em className="icon ni ni-edit"></em>}
                                                            onClick={() => {

                                                                setSelectedMember(member)
                                                                setShowEditModal(true)
                                                            }} label="Edit"/>
                                                        <MenuItem
                                                            icon={<em className="icon ni ni-trash"></em>}
                                                            onClick={() => {

                                                                setSelectedMember(member)
                                                                setManageModal('delete')
                                                            }} label="Delete"/>
                                                    </ButtonMenu>
                                                </td>
                                            </tr>

                                        )) :

                                        <EmptyMessage
                                            type="organization"
                                            title="No Members available."
                                            description="You have no Members stored. Create Members to start organizing."
                                            buttonText="Select Members"
                                            buttonCallback={() => {
                                                setShowMemberForm(true)
                                            }}
                                        />
                                    }


                                    </tbody>
                                </table>

                            </div>

                        </div>
                    ) : (
                    <EmptyMessage
                        type='organization'
                        title="No Members Available."
                        description="It seems there are no Members to display at the moment. You can create a Member by either clicking the button below or the one in the top right corner."
                        buttonText="Create Member"
                        buttonCallback={() => {
                            setShowMemberForm(true)
                        }}
                    />
                    // <div className='d-flex flex-column align-items-center justify-content-center mt-5 w-90'>
                    //     <h2>No Members Available</h2>
                    //     <div>
                    //         <p className='text-center'>It seems there are no Members to display at the moment. You can create a Member by clicking the button in the top right corner.</p>
                    //     </div>
                    // </div>
                )
            }

            <MemberForm showModal={showMemberForm} setShowModal={(e: boolean) => setShowMemberForm(e)}/>
            <MemberForm member={selectedMember} showModal={showEditModal} setShowModal={(e: boolean) => {
                setShowEditModal(e);
            }}/>
            <MemberManage manageModal={manageModal} member={selectedMember} setManageModal={(e: boolean) => {setManageModal(''); setSelectedMember(undefined)}}/>

            <ImportRecordsFlow
                isOpen={showMemberImport}
                onRequestClose={()=>setShowMemberImport(false)}
                schema={{
                    collection: 'contacts',
                    attributes: {
                        firstName: {
                            type: String,
                            required: true,
                        },
                        email: {
                            type: String,
                            required: true,
                        },
                        phone: {
                            type: String,
                            required: true,
                        },
                        lastName: String,
                    },
                }}
                onComplete={(data) => createMultipleMembers(data.data)}
                actionType="add-records"
            />

        </div>
    );
};
export default OrganizationMembers;

import React, {FC, useEffect, useRef, useState} from 'react';
import {Helmet} from "react-helmet-async";
import {
    IGroup,
    IOrganization,
    IPublicGroupMember, IPublicMemberGroup,
    IPublicOrganization
} from "../../../../interfaces/organization.interface";
import {Avatar, AvatarMenu, MenuDivider, MenuItem} from "react-rainbow-components";
import axios from "axios";
import {base_url, public_member_organizations_url, public_organizations_url} from "../../../../constants/contants";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {RootState} from "../../../../redux/rootReducer";
import EmptyMessage from "../../../components/empty/EmptyMessage";



interface Props {
    organization: IPublicOrganization;
    setActiveGroup: any;
}


const OrganizationGroups: FC<Props> = ({organization, setActiveGroup}) => {

    const [searchQuery, setSearchQuery] = useState<string>("");
    const [selectedGroup, setSelectedGroup] = useState<IPublicMemberGroup | undefined>(undefined)

    const chatListRef = useRef<HTMLDivElement>(null); // Use HTMLDivElement type for ref
    const prevGroupId = localStorage.getItem('prevGroupId');

    useEffect(() => {
        if (prevGroupId && organization && organization.memberGroups.length) {
            const group: IPublicMemberGroup | undefined = organization.memberGroups.find((g) => g?._id.toString() === prevGroupId.toString());
            if (group) {
                setSelectedGroup(group);
                setActiveGroup(group)
            }else {
                setSelectedGroup(undefined);
                setActiveGroup(undefined)
            }
        }
    }, [organization, prevGroupId]);


    return (
        <div className="tab-pane fade show active" id="pills-groups" role="tabpanel" aria-labelledby="pills-groups-tab" style={{height: '100vh'}}>
            <div>
                <div className="pt-4 mx-2">
                    <h4 className="mb-4">Groups</h4>
                    <div className="search-box chat-search-box px-3 bg-light"
                         style={{borderRadius: 10, backgroundColor: 'red'}}>
                        <div className="input-group mb-3">
                                        <span className="input-group-text text-muted bg-light pe-1 ps-3"
                                              id="basic-addon1">
                                            <i className="ri-search-line search-icon font-size-18"></i>
                                        </span>
                            <input type="text" onChange={(e) => setSearchQuery(e.target.value)} value={searchQuery}
                                   className="form-control bg-light" placeholder="Search Group Name"
                                   aria-label="Search groups..." aria-describedby="basic-addon1"/>
                        </div>
                    </div>
                </div>

                <div className="">
                    <div className="chat-message-list px-2" style={{height: '70vh'}}>

                        <ul className="list-unstyled chat-list chat-user-list">
                            {
                                organization.memberGroups.length ? (
                                    organization.memberGroups.filter((g)=> g.groupName.toLowerCase().includes(searchQuery.toLowerCase())).map((memberGroup: any) =>
                                            <li onClick={() => {
                                                setSelectedGroup(memberGroup)
                                                setActiveGroup(memberGroup)
                                                if(prevGroupId !== memberGroup._id){
                                                    localStorage.removeItem('sessionId')
                                                }
                                                localStorage.setItem('prevGroupId', memberGroup?._id);
                                            }} className={`${selectedGroup?._id?.toString() === memberGroup?._id?.toString() ? 'active' : '' }`}>
                                                <a className={`link cursor-pointer`}>
                                                    <div className="d-flex gap-2">
                                                        <div className="chat-user-img online align-self-center ms-0">
                                                            <Avatar
                                                                className="rainbow-m-around_x-small text-capitalize"
                                                                assistiveText={memberGroup?.groupName}
                                                                initials={memberGroup?.groupName}
                                                                title={memberGroup?.groupName}
                                                            />
                                                        </div>

                                                        <div className="flex-grow-1 overflow-hidden">
                                                            <h5 className="text-truncate font-size-16 mb-0 text-capitalize">{memberGroup?.groupName}</h5>
                                                            <p
                                                                className="chat-user-message font-size-12 text-truncate mb-0">{`${memberGroup?.groupDocuments?.length  > 0 ? memberGroup?.groupDocuments?.length  === 1 ? "1 Document" : `${memberGroup?.groupDocuments?.length} Documents` : "No Documents Uploaded" }`}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>
                                        )
                                ): (
                                    <div className='h-100 mt-3 w-100 d-flex justify-content-center align-items-center'>
                                        <div className='d-flex flex-column align-items-center'>
                                            <EmptyMessage
                                                type="chat"
                                                title="No Group Available!"
                                                description="It seems you haven't created a group yet or you haven't been assigned to any group in your organization."
                                                buttonText="Add Group"
                                                buttonCallback={() => {
                                                }}
                                                noButton={true}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default OrganizationGroups;

import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
    createWebsiteStart,
    createWebsiteSuccess,
    createWebsiteFailure,
    updateWebsiteStart,
    updateWebsiteSuccess,
    updateWebsiteFailure,
    fetchWebsiteStart,
    fetchWebsiteSuccess,
    fetchWebsiteFailure, deleteWebsiteStart, deleteWebsiteSuccess, deleteWebsiteFailure,
} from '../../../../redux/slices/websitesSlice';
import { IWebsite } from '../../../../interfaces/website.interface';
import {RootState} from "../../../../redux/rootReducer";
import {websites_url} from "../../../../constants/contants";
import * as Yup from "yup";
import http from "../../../../services/api";
import {toastMessage} from "../../../../utils/util";
import FormInput from "../../../components/form/FormInput";
import {Button, Modal, Option, Picklist} from "react-rainbow-components";
import FormButton from "../../../components/form/bottons/FormButton";
import {set} from "lodash";
import {updateUserSuccess} from "../../../../redux/slices/userSlice";
import {IChatMessage} from "../../../../interfaces/websitechat.interface";
import dayjs from "dayjs";


import './chat.scss';
interface WebsiteFormProps {
    website: IWebsite; // If provided, it's edit mode; otherwise, it's create mode
    showModal:boolean;
    setShowModal:any;
    chats:IChatMessage[]
}


const WebsiteChat: React.FC<WebsiteFormProps> = ({ website, chats,showModal,setShowModal }) => {


    const dispatch = useDispatch();

    const {  user } = useSelector((state: RootState) => state.user);


    return (
        <Modal
            id="modal-delete-website"
            title={"Chats Website"}

            isOpen={showModal}
            onRequestClose={() => {
                setShowModal('')
            }}
            size={`large`}
            footer={
                <div className="d-flex justify-content-end gap-2">
                    <FormButton type={`button`} text={`Close`} onClick={() => setShowModal(false)}/>
                </div>
            }
        >

            <div className="msg-body">
                <ul>

                    {chats.map((chat)=>{


                        if (chat.user === 'me'){
                            return (
                                <li className="sender">
                                    <p> {chat.message}</p>
                                    <span className="time">{dayjs(chat.createdAt).format('hA')}</span>
                                </li>
                            )
                        }else{
                            return (
                                <li className="repaly">
                                    <p> {chat.message}</p>
                                    <span className="time">{dayjs(chat.createdAt).format('hA')}</span>
                                </li>
                            )
                        }



                    })}


                </ul>
            </div>


        </Modal>
    );
};

export default WebsiteChat;

import React, {FC, useEffect, useState} from 'react';

import AccountProfile from "./tabs/AccountProfile";
import AccountSubscription from "./tabs/AccountSubscription";
import {useNavigate, useParams} from "react-router-dom";
import {setUser, updateUserSuccess} from "../../../redux/slices/userSlice";
import {useDispatch} from "react-redux";
import PaymentBilling from "./tabs/PaymentBilling";
import AccountSummary from "./tabs/AccountSummary";


const AccountContainer: FC = () => {


    //_page
    const { _page } = useParams();
    const navigate = useNavigate();


    const [activeTab, setActiveTab] = useState(_page?_page:'summary')


    useEffect(() => {

        if(_page){
            setActiveTab(_page);
        }
    }, [_page]);
    return (
        <div className="nk-content">
            <div className="container-xl">
                <div className="nk-content-inner">
                    <div className="nk-content-body">
                        <div className="nk-block-head nk-page-head">
                            <div className="nk-block-head-between">
                                <div className="nk-block-head-content">
                                    <h2 className="display-6">Personal Account</h2>
                                    <span>Personal Account Personal Account</span>
                                </div>
                                <div className="nk-block-head-content">
                                    <div className="d-flex gap gx-4">

                                        <span className={`link link-primary fw-normal`} onClick={()=>{

                                            navigate('/logout');
                                        }}>Logout</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="nk-block">
                            <ul className="nav nav-tabs mb-3 nav-tabs-s1">
                                <li className="nav-item">
                                    <button className={`nav-link ${activeTab === 'summary'? 'active':''}`} onClick={()=> navigate('/account/summary')} type="button" >Summary
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button className={`nav-link ${activeTab === 'profile'? 'active':''}`} onClick={()=> navigate('/account/profile')} type="button" >Profile
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button className={`nav-link ${activeTab === 'payment-billing'? 'active':''}`} onClick={()=> navigate('/account/payment-billing')} type="button" >Payment & Billing
                                    </button>
                                </li>
                            </ul>
                            <div className="tab-content">


                                {activeTab === 'summary' &&(
                                    <AccountSummary  />
                                )}
                                {activeTab === 'profile' &&(
                                    <AccountProfile  />
                                )}
                                {activeTab === 'payment-billing' &&(
                                    <PaymentBilling />
                                )}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};
export default AccountContainer;

import React from 'react';
import {useFormik} from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/rootReducer';
import * as Yup from 'yup';
import http from '../../../../services/api';
import { toastMessage } from '../../../../utils/util';
import FormInput from '../../../components/form/FormInput';
import {Input, Modal, Option, Picklist} from 'react-rainbow-components';
import FormButton from '../../../components/form/bottons/FormButton';
import {ILoginMember, IMember, IPublicOrganization} from '../../../../interfaces/organization.interface';
import {base_url, organizations_url, public_member_organizations_url} from '../../../../constants/contants';
import {
    createMemberStart,
    createMemberSuccess,
    createMemberFailure,
    updateMemberStart,
    updateMemberSuccess,
    updateMemberFailure, setOrganizationUserSuccess,
} from '../../../../redux/slices/organizationsSlice';
import axios from "axios"; // Import necessary Redux actions

interface MemberFormProps {
    member?: ILoginMember; // If provided, it's edit mode; otherwise, it's create mode
    organization:IPublicOrganization;
    showModal: boolean;
    setShowModal: any;
}
const PublicMemberForm: React.FC<MemberFormProps> = ({ member,organization, showModal, setShowModal }) => {
    const dispatch = useDispatch();
    const { user } = useSelector((state: RootState) => state.user);

    const validationSchema = Yup.object({
        firstname: Yup.string().required('First Name is required'),
        lastname: Yup.string().required('Last Name is required'),
        password: Yup.string().min(6, 'Password must be at least 6 characters'), // Validation for the password
        role: Yup.object({
            name: Yup.string().required('Role is required'), // example of a field inside the object
        }).required('Role is required'),
    });


    const formik = useFormik({
        initialValues: {
            firstname: member?.member.firstname || '',
            lastname: member?.member.lastname || '',
            password:'',
            email: member?.member.email || '',
            role: member?.member.role ? { name: `${member?.member.role}`, label: `${member?.member.role}` } : {name: '', label: ''},
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            console.log('values', values)
            console.log('values member', member)
            if (member) {
                // Update existing member
              //  dispatch(updateMemberStart());

                try {

                    if(organization){

                        let response = await axios.put<ILoginMember>(
                            `${base_url}${public_member_organizations_url}/${organization._id}/member`,
                            {
                                firstname: values.firstname,
                                lastname: values.lastname,
                                email: values.email,
                                password: values.password,
                                role: values.role?.name,
                            },
                            {
                                headers: {
                                    Authorization: `Bearer ${member?.member.token}`, // Replace with the appropriate token type
                                },
                            }
                        );

                        const organizationUserResponse = response.data;

                        dispatch(setOrganizationUserSuccess(organizationUserResponse));
                        localStorage.removeItem('organizationUser');
                        localStorage.setItem('organizationUser', JSON.stringify(organizationUserResponse));

                        toastMessage('Member Updated successfully', 'success');
                    }
                } catch (error: any) {
                    dispatch(updateMemberFailure(error.message));
                    console.error('Failed to update member', error);
                    toastMessage('Failed to update member', 'error');
                }
            }
            setShowModal(false);
            formik.resetForm();
        },
    });

    return (
        <Modal
            id="modal-add-member"
            title={member ? 'Update Member' : 'Create Member'}
            isOpen={showModal}
            onRequestClose={() => {
                setShowModal(false);
                formik.resetForm();
            }}
            footer={
                <div className="d-flex justify-content-end">
                    <FormButton type="submit" text={member ? 'Update' : 'Create'} onClick={formik.handleSubmit} />
                </div>
            }
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="row gy-3">
                    <div className="col-12">
                        <FormInput
                            label="First Name"
                            name="firstname"
                            type="text"
                            placeholder="Enter First Name"
                            value={formik.values.firstname}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.firstname as string | undefined}
                        />
                    </div>
                    <div className="col-12">
                        <FormInput
                            label="Last Name"
                            name="lastname"
                            type="text"
                            placeholder="Enter Last Name"
                            value={formik.values.lastname}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.lastname as string | undefined}
                        />
                    </div>
                        <div className="col-12">

                            <Input
                                label="Email"
                                placeholder="Placeholder text"
                                className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
                                borderRadius="semi-rounded"
                                labelAlignment={`left`}
                                value={formik.values.email}
                                disabled={true}
                            />

                        </div>

                    <div className="col-12">
                        <FormInput
                            label="Password"
                            name="password"
                            type="password"
                            placeholder="Enter Password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.password as string | undefined}
                        />
                    </div>
                </div>
            </form>
        </Modal>
    );
};

export default PublicMemberForm;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDocument, IShop, IDisplay } from '../../interfaces/shop.interface';
import {toastMessage} from "../../utils/util";

interface ShopsState {
    shops: IShop[] | null;
    shop: IShop | null;
    loading: boolean;
    error: string | null;
}

const initialState: ShopsState = {
    shops: null,
    shop: null,
    loading: false,
    error: null,
};

const shopsSlice = createSlice({
    name: 'shops',
    initialState,
    reducers: {
        fetchShopsStart(state) {
            state.loading = true;
            state.error = null;
        },
        fetchShopsSuccess(state, { payload }: PayloadAction<IShop[]>) {
            state.shops = payload;
            state.loading = false;
        },
        fetchShopsFailure(state, { payload }: PayloadAction<string>) {
            state.error = payload;
            state.loading = false;
        },
        fetchShopStart(state) {
            state.loading = true;
            state.error = null;
        },
        fetchShopSuccess(state, { payload }: PayloadAction<IShop>) {
            state.shop = payload;
            state.loading = false;
        },
        fetchShopFailure(state, { payload }: PayloadAction<string>) {
            state.error = payload;
            state.loading = false;
        },
        deleteShopStart(state) {
            state.loading = true;
            state.error = null;
        },
        deleteShopSuccess(state, { payload }: PayloadAction<string>) {
            if (state.shops) {
                state.shops = state.shops.filter(shop => shop._id !== payload);
            }
            state.loading = false;
        },
        deleteShopFailure(state, { payload }: PayloadAction<string>) {
            state.error = payload;
            state.loading = false;
        },
        createShopStart(state) {
            state.loading = true;
            state.error = null;
        },
        createShopSuccess(state, { payload }: PayloadAction<IShop>) {
            if (state.shops) {
                state.shops.push(payload);
            } else {
                state.shops = [payload];
            }
            state.loading = false;
        },
        createShopFailure(state, { payload }: PayloadAction<string>) {
            state.error = payload;
            state.loading = false;
        },
        updateShopStart(state) {
            state.loading = true;
            state.error = null;
        },
        updateShopSuccess(state, { payload }: PayloadAction<IShop>) {
            if (state.shops) {
                const index = state.shops.findIndex(shop => shop._id === payload._id);
                if (index !== -1) {
                    state.shops[index] = payload;
                }
            }
            if (state.shop) {
                state.shop = payload;
            }
            state.loading = false;
        },
        updateShopFailure(state, { payload }: PayloadAction<string>) {
            state.error = payload;
            state.loading = false;
        },
        setShops(state, { payload }: PayloadAction<IShop[] | null>) {
            state.shops = payload;
        },
        setLoading(state, { payload }: PayloadAction<boolean>) {
            state.loading = payload;
        },
        setError(state, { payload }: PayloadAction<string | null>) {
            state.error = payload;
        },
        clearShops(state) {
            state.shops = null;
            state.shop = null;
            state.loading = false;
            state.error = null;
        },
        updateApiKeyStart(state) {
            state.loading = true;
            state.error = null;
        },
        updateApiKeySuccess(state, { payload }: PayloadAction<{ id: string, apiKey: string, apiKeyName: string }>) {
            if (state.shops) {
                const index = state.shops.findIndex(shop => shop._id === payload.id);
                if (index !== -1) {
                    state.shops[index].apiKey = payload.apiKey;
                    state.shops[index].apiKeyName = payload.apiKeyName;
                }
            }

            if (state.shop) {
                state.shop.apiKey = payload.apiKey;
                state.shop.apiKeyName = payload.apiKeyName;
            }
            state.loading = false;
        },
        updateApiKeyFailure(state, { payload }: PayloadAction<string>) {
            state.error = payload;
            state.loading = false;
        },
        uploadDocumentStart(state) {
            state.loading = true;
            state.error = null;
        },
        uploadShopDocumentSuccess(state, { payload }: PayloadAction<{ shopId: string, document: IDocument }>) {
            if (state.shops) {
                const shop = state.shops.find(shop => shop._id === payload.shopId);
                if (shop) {
                    shop.documents.push(payload.document);
                }
            }
            if (state.shop && state.shop._id === payload.shopId) {
                state.shop.documents.push(payload.document);
            }
            state.loading = false;
        },
        uploadDocumentFailure(state, { payload }: PayloadAction<string>) {
            state.error = payload;
            state.loading = false;
        },
        deleteDocumentStart(state) {
            state.loading = true;
            state.error = null;
        },
        deleteDocumentSuccess(state, { payload }: PayloadAction<string>) {
            if (state.shop) {
                state.shop.documents = state.shop.documents.filter(doc => doc._id !== payload);
            }
            state.loading = false;
        },
        deleteDocumentFailure(state, { payload }: PayloadAction<string>) {
            state.error = payload;
            state.loading = false;
        },

        uploadPresentationDocumentStart(state) {
            state.loading = true;
            state.error = null;
        },
        uploadShopPresentationDocumentSuccess(state, { payload }: PayloadAction<{ shopId: string, document: IDocument }>) {
            if (state.shops) {
                const shop = state.shops.find(shop => shop._id === payload.shopId);
                if (shop) {
                    shop.presentationDocuments.push(payload.document);
                }
            }
            if (state.shop && state.shop._id === payload.shopId) {
                state.shop.presentationDocuments.push(payload.document);
            }
            state.loading = false;
        },
        uploadPresentationDocumentFailure(state, { payload }: PayloadAction<string>) {
            state.error = payload;
            state.loading = false;
        },
        deletePresentationDocumentStart(state) {
            state.loading = true;
            state.error = null;
        },
        deletePresentationDocumentSuccess(state, { payload }: PayloadAction<string>) {
            if (state.shop) {
                state.shop.presentationDocuments = state.shop.presentationDocuments.filter(doc => doc._id !== payload);
            }
            state.loading = false;
        },
        deletePresentationDocumentFailure(state, { payload }: PayloadAction<string>) {
            state.error = payload;
            state.loading = false;
        },

        // Additions for createDisplay, updateDisplay, and deleteDisplay
        createDisplayStart(state) {
            state.loading = true;
            state.error = null;
        },
        createDisplaySuccess(state, { payload }: PayloadAction<{ shopId: string, display: IDisplay }>) {
            if (state.shops) {
                const shop = state.shops.find(shop => shop._id === payload.shopId);
                if (shop) {
                    shop.displays.push(payload.display);
                }
            }
            if (state.shop && state.shop._id === payload.shopId) {
                state.shop.displays.push(payload.display);
            }
            state.loading = false;
        },
        createDisplayFailure(state, { payload }: PayloadAction<string>) {
            state.error = payload;
            state.loading = false;
        },

        updateDisplayStart(state) {
            state.loading = true;
            state.error = null;
        },
        updateDisplaySuccess(state, { payload }: PayloadAction<{ shopId: string, display: IDisplay }>) {
            if (state.shops) {
                const shop = state.shops.find(shop => shop._id === payload.shopId);
                if (shop) {
                    const displayIndex = shop.displays.findIndex(d => d._id === payload.display._id);
                    if (displayIndex !== -1) {
                        shop.displays[displayIndex] = payload.display;
                    }
                }
            }
            if (state.shop && state.shop._id === payload.shopId) {
                const displayIndex = state.shop.displays.findIndex(d => d._id === payload.display._id);
                if (displayIndex !== -1) {
                    state.shop.displays[displayIndex] = payload.display;
                }
            }
            state.loading = false;
        },
        updateDisplayFailure(state, { payload }: PayloadAction<string>) {
            state.error = payload;
            state.loading = false;
        },

        // Deleting a display
        deleteDisplayStart(state) {
            state.loading = true;
            state.error = null;
        },
        deleteDisplaySuccess(state, { payload }: PayloadAction<{ shopId: string, displayId: string }>) {
            if (state.shops) {
                const shop = state.shops.find(shop => shop._id === payload.shopId);
                if (shop) {
                    shop.displays = shop.displays.filter(display => display._id !== payload.displayId);
                }
            }
            if (state.shop && state.shop._id === payload.shopId) {
                state.shop.displays = state.shop.displays.filter(display => display._id !== payload.displayId);
            }
            state.loading = false;
        },
        deleteDisplayFailure(state, { payload }: PayloadAction<string>) {
            state.error = payload;
            state.loading = false;
        },
    },
});

export const {
    fetchShopsStart,
    fetchShopsSuccess,
    fetchShopsFailure,
    fetchShopStart,
    fetchShopSuccess,
    fetchShopFailure,
    deleteShopStart,
    deleteShopSuccess,
    deleteShopFailure,
    createShopStart,
    createShopSuccess,
    createShopFailure,
    updateShopStart,
    updateShopSuccess,
    updateShopFailure,
    setShops,
    setLoading,
    setError,
    clearShops,
    updateApiKeyStart,
    updateApiKeySuccess,
    updateApiKeyFailure,
    uploadDocumentStart,
    uploadShopDocumentSuccess,
    uploadDocumentFailure,
    deleteDocumentStart,
    deleteDocumentSuccess,
    deleteDocumentFailure,
    createDisplayStart, // For creating a display
    createDisplaySuccess,
    createDisplayFailure,
    updateDisplayStart, // For updating a display
    updateDisplaySuccess,
    updateDisplayFailure,
    deleteDisplayStart, // For deleting a display
    deleteDisplaySuccess,
    deleteDisplayFailure,
    uploadPresentationDocumentStart,
    uploadShopPresentationDocumentSuccess,
    uploadPresentationDocumentFailure,
    deletePresentationDocumentStart,
    deletePresentationDocumentSuccess,
    deletePresentationDocumentFailure


} = shopsSlice.actions;
export default shopsSlice.reducer;

import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    deleteDocumentStart,
    deleteDocumentSuccess,
    deleteDocumentFailure,
} from '../../../../redux/slices/websitesSlice';
import {IDocument, IWebsite} from '../../../../interfaces/website.interface';
import { RootState } from "../../../../redux/rootReducer";
import {update_country_url, update_password_url, websites_url} from "../../../../constants/contants";
import http from "../../../../services/api";
import FormButton from "../../../components/form/bottons/FormButton";
import {Button, Modal, Option, Picklist} from "react-rainbow-components";
import { toastMessage } from "../../../../utils/util";
import FormInput from "../../../components/form/FormInput";
import {useFormik} from "formik";
import {IUser} from "../../../../interfaces/user.interface";
import {updateUserFailure, updateUserSuccess} from "../../../../redux/slices/userSlice";
import * as Yup from "yup";
import ct from "countries-and-timezones";
import {countries} from "../../../../utils/countries";

interface CountryManageProps {
    showModal:boolean;
    setShowModal:any;
}

interface PickerOption {
    name: string;
    label: string;
}

const countrySchema = Yup.object({
    countryCode: Yup.object()
        .required('Country is required'),
    timezone: Yup.object()
        .required('Timezone is required'),
});
const CountryManage: React.FC<CountryManageProps> = ({ showModal, setShowModal }) => {

    const dispatch = useDispatch();

    const { user } = useSelector((state: RootState) => state.user);


    const [userCountryCode, setUserCountryCode] = useState<PickerOption>();
    const [userTimezone, setUserTimezone] = useState<PickerOption>();
    const [availableTimezones, setAvailableTimezones] = useState([]);
    const [allCountries, setAllCountries] = useState(countries);
    const [filter, setFilter] = useState('');


    const getUserCountry = async () => {



        if(user){
            console.log("getUserCountry user", user);
            const country:any =  countries.find((c: any) => c.value === user.countryCode);

            console.log("getUserCountry country", country);
            if(country){
                setUserCountryCode({name: country.value, label:country.label})

                const countryData = ct.getCountry(country.value);

                let timezoneData:any = countryData.timezones
                if(timezoneData){
                    setAvailableTimezones(timezoneData)
                    setUserTimezone({name: user?.timezone, label:user.timezone})
                    await formik.setFieldValue('timezone', {name: user.timezone, label: user.timezone})

                }

                await formik.setFieldValue('countryCode', {name: country.value, label: country.label})
            }
        }

    };
    useEffect(() => {

        if(user){

            formik.setFieldValue('fullName', user.fullName);
            formik.setFieldValue('email', user.email);



            getUserCountry();
        }
    }, [user]);
    useEffect(() => {

        if(filter && countries){
            setAllCountries(
                countries.filter(country =>
                    country.label.toLowerCase().includes(filter.toLowerCase())
                )
            );
        }else{
            setAllCountries(countries)
        }

    }, [filter]);



    const formik = useFormik({
        initialValues: {
            countryCode: userCountryCode,
            timezone: userTimezone,
        },
        enableReinitialize:true,
        validationSchema: countrySchema,
        onSubmit: async (values) => {
            try {
                const response = await http.put<IUser>(update_country_url, {
                    countryCode:  values.countryCode?.name,
                    timezone:  values.timezone?.name,
                });

                dispatch(updateUserSuccess(response.data));

                localStorage.setItem('user', JSON.stringify(response.data));

                toastMessage('Country updated successfully', 'success');

                setShowModal(false)

            } catch (error:any) {
                console.log('error')
            }
        },
    });




    return (
        <Modal
            id="modal-delete-document"
            title={`Change Country`}
            isOpen={showModal}
            onRequestClose={() => {
                setShowModal(false);
            }}
            footer={
                <div className="d-flex justify-content-end">
                    <FormButton type={`submit`} text={`Update`}
                                onClick={formik.handleSubmit}/>
                </div>
            }
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="row gy-3">
                    <div className="col-12">
                        <div className="form-group">
                            <label className="form-label" htmlFor={`picklist-country`}>Country</label>
                            <div className="form-control-wrap">
                                <Picklist
                                    borderRadius="semi-square"
                                    id="picklist-country"
                                    className={`picklist-country`}
                                    onChange={value => {
                                        formik.setFieldValue('countryCode', value)

                                        const newCountryCode: any = value
                                        setUserCountryCode(newCountryCode)
                                        const countryCode: any = value.name
                                        const countryData = ct.getCountry(countryCode);

                                        let timezoneData: any = countryData.timezones
                                        if (timezoneData) {

                                            setAvailableTimezones(timezoneData)
                                            setUserTimezone({
                                                label: `${timezoneData[0]}`,
                                                name: `${timezoneData[0]}`
                                            })
                                            formik.setFieldValue('timezone', {
                                                label: `${timezoneData[0]}`,
                                                name: `${timezoneData[0]}`
                                            })

                                        }
                                    }}
                                    value={formik.values.countryCode}
                                    label="Select a Country"
                                    hideLabel
                                    enableSearch
                                    onSearch={(val) => {
                                        setFilter(val)
                                    }}
                                >
                                    {allCountries.map((country) => (
                                        <Option key={country.value} name={country.value} label={country.label}/>
                                    ))}
                                </Picklist>
                                {formik.errors.countryCode ? (
                                    <div
                                        className="fs-7 text-danger mt-1">{formik.errors.countryCode}</div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label className="form-label" htmlFor={`picklist-country`}>Timezone</label>
                            <div className="form-control-wrap">
                                <Picklist
                                    borderRadius="semi-square"
                                    id="picklist-timezone"
                                    className={`picklist-country`}
                                    onChange={value => formik.setFieldValue('timezone', value)}
                                    value={formik.values.timezone}
                                    label="Select a timezone"
                                    hideLabel
                                >
                                    {availableTimezones?.map((timezone, key) => (
                                        <Option key={key} name={timezone} label={timezone}/>
                                    ))}
                                </Picklist>
                                {formik.errors.timezone ? (
                                    <div className="fs-7 text-danger mt-1">{formik.errors.timezone}</div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </Modal>
    );
};

export default CountryManage;
